import { OpportunitySearchCols, MilesonteCols, AccountSearchCols, ContactCols, LeadCols, ENTITIES, GitHubCols } from './Constants'
import { MSXFetchXMLs } from './fetchXmls';
import { iSearchOption } from '../Interfaces/iSearchOption';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { opportunity_attributes } from '../EntityMetaData/opportunity_attributes';
import { lead_attributes } from '../EntityMetaData/leads_attributes';
import { account_attributes } from '../EntityMetaData/accounts_attributes';
import { contact_attributes } from '../EntityMetaData/contacts_attributes';
import { msp_engagementmilestone_attributes } from '../EntityMetaData/msp_engagementmilestone';

export const SearchEntityOptions: iSearchOption[] = [
    { id: 1, label: "Opportunities", entity: "opportunity", entityName: "Opportunity", columns: OpportunitySearchCols, group: "Entities", useSearchAPI: true, withoutSearchTextQuery: MSXFetchXMLs.Opportunities, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 2, label: "Leads", entity: "lead", entityName: "Lead", columns: LeadCols, group: "Entities", useSearchAPI: true, withoutSearchTextQuery: MSXFetchXMLs.Leads, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 3, label: "Accounts", entity: "account", entityName: "Account", columns: AccountSearchCols, group: "Entities", useSearchAPI: true, withoutSearchTextQuery: MSXFetchXMLs.Accounts, entityMetaData: account_attributes, entityDefinition: ENTITIES.account },
    { id: 4, label: "Contacts", entity: "contact", entityName: "Contact", columns: ContactCols, group: "Entities", useSearchAPI: true, withoutSearchTextQuery: MSXFetchXMLs.Contacts, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 5, label: "Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", columns: MilesonteCols, group: "Entities", useSearchAPI: true, withoutSearchTextQuery: MSXFetchXMLs.Milestones, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    // { id: 22, label: "GitHub Opties (SalesForce)", isExternalAPI: true, entity: "github", entityName: "github", columns: GitHubCols, group: "External APIs", useSearchAPI: false, withoutSearchTextQuery: MSXFetchXMLs.GitHubOpportunities, withSearchTextQuery: MSXFetchXMLs.GitHubOpportunities },

    { id: 25, label: "My Open Opportunities", savedqueryid: "00000000-0000-0000-00aa-000010003000", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 23, label: "My Deal Team Opportunities", savedqueryid: "ecceb5f8-7268-e311-940c-002dd80b25dd", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 10, label: "My Account Team Opportunities", savedqueryid: "35450cc6-bf89-e511-80e8-3863bb3ce598", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 11, label: "My Accounts Opportunities", savedqueryid: "894232f8-63da-e711-8122-3863bb351f08", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 7, label: "All Opportunities", savedqueryid: "c6f6fc1b-1b3e-e711-80e1-00155d871bb4", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 24, label: "My Opportunities with Hygiene Issues", savedqueryid: "1188d0b9-8523-ed11-b83e-000d3a319b2c", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 15, label: "My Opportunities Closed in Current Fiscal Year", savedqueryid: "31dae384-a86f-df11-986c-00155d2e3002", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 32, label: "Opportunities in Accounts I Follow", savedqueryid: "7564834e-d956-e511-80e0-3863bb3ce598", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 8, label: "Deals for Tracking | For CEs Only", savedqueryid: "d589dc5f-88e5-e711-810e-3863bb3c0560", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 9, label: "Deals not claimed in my Account | For CEs Only", savedqueryid: "786f3a60-068a-ea11-a811-000d3a36e1a7", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    { id: 12, label: "My Assigned Deals | For CEs Only", savedqueryid: "39470584-048a-ea11-a811-000d3a36e1a7", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },

    // { id: 6, label: "All Consumption Opportunities", savedqueryid: "bb1f8cbc-d1a1-ec11-b3fe-0022480b199d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 13, label: "My Billed Pipeline", savedqueryid: "329055d8-39c5-ec11-983e-6045bd00b093", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 14, label: "My Closed Deals", savedqueryid: "a7093dc3-f7a7-eb11-a812-00224809f78b", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 16, label: "My Co-Sell Open Opportunities", savedqueryid: "3b9ff74a-d71e-e911-a84c-000d3a309ec9", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 17, label: "My Co-Sell Team Opportunities", savedqueryid: "28da00d7-d71e-e911-a84c-000d3a309ec9", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 18, label: "My Consumed Pipeline", savedqueryid: "4ffda0bd-eec5-ec11-983e-6045bd00bfed", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 19, label: "My Open Committed At Risk Opportunities", savedqueryid: "cc03c533-8283-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 20, label: "My Open Committed Opportunities", savedqueryid: "4b9bcffc-8183-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 21, label: "My Open Uncommitted Opportunities", savedqueryid: "1672957a-8283-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 22, label: "My Open Uncommitted Upside Opportunities", savedqueryid: "5e15135f-8283-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 26, label: "My Team Committed At Risk Opportunities", savedqueryid: "c97fc15b-8383-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 27, label: "My Team Committed Opportunities", savedqueryid: "4a9ac525-8383-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 28, label: "My Team Uncommitted Opportunities", savedqueryid: "9e4acfb9-8383-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 29, label: "My Team Uncommitted Upside Opportunities", savedqueryid: "225f329b-8383-e911-a84a-000d3a309c3d", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 30, label: "Open Opportunities", savedqueryid: "00000000-0000-0000-00aa-000010003001", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 31, label: "Opportunities I Follow(UCI)", savedqueryid: "03569240-fa09-ea11-a811-000d3a36e07e", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },
    // { id: 33, label: "Recent Opportunities", savedqueryid: "9c3f7ee8-eca7-e211-9fb6-00155dd0ea05", entity: "opportunity", entityName: "Opportunity", group: "Opportunity Views", columns: OpportunitySearchCols, entityMetaData: opportunity_attributes, entityDefinition: ENTITIES.opportunity },

    { id: 34, savedqueryid: "5a926b99-3a5f-df11-ae90-00155d2e3002", label: "All Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 35, savedqueryid: "00000000-0000-0000-00aa-000010001006", label: "All Open Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 36, savedqueryid: "b382e734-8252-ec11-8c62-000d3a5bc9ee", label: "All Open Leads With SequenceName", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 37, savedqueryid: "f17ce234-7f76-eb11-a812-000d3a33231f", label: "CSS Presales Feedback from Digital Sales", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 38, savedqueryid: "27a3c805-317b-eb11-a812-000d3a33231f", label: "CSS Presales Hygiene Issues with Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 39, savedqueryid: "37e4e0cf-f909-ea11-a811-000d3a36e07e", label: "Leads I Follow(UCI)", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 40, savedqueryid: "26907af4-c289-e511-80e8-3863bb3ce598", label: "Leads in Accounts I Follow", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 41, savedqueryid: "17ac6b71-63da-e711-8122-3863bb351f08", label: "My Account Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 42, savedqueryid: "b4355955-c289-e511-80e8-3863bb3ce598", label: "My Account Team Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 43, savedqueryid: "6c68ae51-20ae-e511-80f4-3863bb35bf30", label: "My Disqualified Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 44, savedqueryid: "3f4249b6-b147-ee11-be6d-6045bd03262d", label: "My Expired Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 45, savedqueryid: "e017a85f-1fae-e511-80f4-3863bb35bf30", label: "My Open Hot Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 46, savedqueryid: "0e04176a-63e2-ed11-a7c6-6045bd0324dc", label: "My Open HR Leads - Not engaged", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 47, savedqueryid: "7ee7c352-1052-ec11-8c62-000d3a5bc4b3", label: "My Open Leads With SequenceName", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 48, savedqueryid: "64a9a9c4-1fae-e511-80f4-3863bb35bf30", label: "My Qualified Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 49, savedqueryid: "a11a951e-a151-e511-80e0-3863bb3ce598", label: "My Team's Active Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 50, savedqueryid: "79ff4cf6-9352-ec11-8c62-000d3a5bc9ee", label: "My Team's Active Leads With SequenceName", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },
    { id: 51, savedqueryid: "7a06a3fe-6c51-e311-9405-00155db3ba1e", label: "Recent Leads", entity: "lead", entityName: "Lead", group: "Lead Views", columns: LeadCols, entityMetaData: lead_attributes, entityDefinition: ENTITIES.lead },

    { id: 52, savedqueryid: "3defa8ef-4147-e311-9405-00155db3ba1e", label: "My Team Accounts", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account },
    { id: 53, savedqueryid: "2596c0a6-97ba-ea11-a812-000d3a33231f", label: "Accounts I Follow(UCI)", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },
    { id: 54, savedqueryid: "39c59893-141d-ec11-b6e6-000d3a5bfb72", label: "Accounts Needing Attention", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },
    { id: 55, savedqueryid: "00000000-0000-0000-00aa-000010001002", label: "Active Accounts", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },
    { id: 56, savedqueryid: "65ffaf9a-e8c5-432d-860b-32f841b00d87", label: "All Accounts", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },
    { id: 57, savedqueryid: "6eb25d47-7e30-ee11-bdf4-6045bd0324dc", label: "DS&I Engaged | ACR-A", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },
    { id: 58, savedqueryid: "00000000-0000-0000-00aa-000010001001", label: "My Owned Accounts", entity: "account", entityName: "Account", group: "Account Views", columns: AccountSearchCols, entityMetaData: account_attributes, entityDefinition: ENTITIES.account, },

    { id: 59, savedqueryid: "cea92a59-45c9-4cef-805d-3069f20f7e3d", label: "Active Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 60, savedqueryid: "c06d3635-95e5-49de-91fa-b610e8ad1a68", label: "Inactive Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 61, savedqueryid: "54c2ccc6-b582-ed11-81aa-000d3a336048", label: "Milestone Due in Next 60 days and NOT Committed", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 62, savedqueryid: "62608c79-ba82-ed11-81aa-000d3a336048", label: "Milestones At Risk / Blocked but due in Next 60 days", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 63, savedqueryid: "265d26d8-75b1-e611-80f8-3863bb36dd00", label: "Milestones SubGrid View", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 64, savedqueryid: "a01e5c2e-e968-e811-a83b-000d3a30956c", label: "My Deal Team's Active Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 65, savedqueryid: "43fa6fa9-d863-e811-a838-000d3a309c3d", label: "My Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },
    { id: 66, savedqueryid: "3f23d840-7b23-ed11-b83e-000d3a318500", label: "My Milestones with Hygiene Issues", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones Views", columns: MilesonteCols, entityMetaData: msp_engagementmilestone_attributes, entityDefinition: ENTITIES.msp_engagementmilestone },

    { id: 67, savedqueryid: "00000000-0000-0000-00aa-000010001003", label: "My Active Contacts", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 68, savedqueryid: "00000000-0000-0000-00aa-000010001004", label: "Active Contacts", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 69, savedqueryid: "0d5d377b-5e7c-47b5-bab1-a5cb8b4ac105", label: "All Contacts", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 70, savedqueryid: "3e234996-e2ab-eb11-a812-000d3a5aded7", label: "Auto Deactivated Contacts", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 71, savedqueryid: "1abe8f3c-ec2c-ed11-9db1-002248047bd0", label: "Contacts | Quality Issues", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 72, savedqueryid: "3bc0e2ef-f909-ea11-a811-000d3a36e07e", label: "Contacts I Follow(UCI)", entity: "contact", entityName: "Contact", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 73, savedqueryid: "d859c7bd-6bb7-ed11-83fd-6045bd0324dc", label: "Engaged Contacts", entity: "contact", entityName: "Contacts Views", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 74, savedqueryid: "00000000-0000-0000-00aa-000010001033", label: "Inactive Contacts", entity: "contact", entityName: "Contacts Views", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },
    { id: 75, savedqueryid: "8c362231-d647-ea11-a812-000d3a36efb2", label: "My Team Contacts", entity: "contact", entityName: "Contacts Views", group: "Contact Views", columns: ContactCols, entityMetaData: contact_attributes, entityDefinition: ENTITIES.contact },

];

export const SearchOptions = [
    { key: 1, text: "Entites", itemType: DropdownMenuItemType.Header },
    { key: 2, text: "Opportunities", entity: "opportunity", entityName: "Opportunity", columns: OpportunitySearchCols, group: "Entities" },
    { key: 3, text: "Leads", entity: "lead", entityName: "Lead", columns: LeadCols, group: "Entities" },
    { key: 4, text: "Accounts", entity: "account", entityName: "Account", columns: AccountSearchCols, group: "Entities" },
    { key: 5, text: "Contacts", entity: "contact", entityName: "Contact", columns: ContactCols, group: "Entities" },
    { key: 6, text: "Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", columns: MilesonteCols, group: "Entities" },

    { key: 7, text: "Opportunity", itemType: DropdownMenuItemType.Header },
    { key: 8, text: "My Owned Open Opportunities", entity: "opportunity", entityName: "Opportunity", group: "Opportunity", columns: OpportunitySearchCols, fetchXML: MSXFetchXMLs.MyOwnedOpenOpportunities },
    { key: 9, text: "My Opportunities", entity: "opportunity", entityName: "Opportunity", group: "Opportunity", columns: OpportunitySearchCols, fetchXML: MSXFetchXMLs.MyOpportunities },
    { key: 10, text: "All Opportunities", entity: "opportunity", entityName: "Opportunity", group: "Opportunity", columns: OpportunitySearchCols, fetchXML: MSXFetchXMLs.AllOpportunities },
    { key: 11, text: "Open Opportunities", entity: "opportunity", entityName: "Opportunity", group: "Opportunity", columns: OpportunitySearchCols, fetchXML: MSXFetchXMLs.OpenOpportunities },

    { key: 12, text: "Lead", itemType: DropdownMenuItemType.Header },
    { key: 13, text: "My Leads", entity: "lead", entityName: "Lead", group: "Lead", columns: LeadCols },
    { key: 14, text: "All Leads", entity: "lead", entityName: "Lead", group: "Lead", columns: LeadCols },
    { key: 15, text: "In Progress Leads", entity: "lead", entityName: "Lead", group: "Lead", columns: LeadCols },

    { key: 16, text: "Account", itemType: DropdownMenuItemType.Header },
    { key: 17, text: "My Accounts", entity: "account", entityName: "Account", group: "Account", columns: AccountSearchCols, },
    { key: 18, text: "All Accounts", entityName: "Account", group: "Account", columns: AccountSearchCols, },
    { key: 19, text: "In Progress Accounts", entity: "account", entityName: "Account", group: "Account", columns: AccountSearchCols, },

    { key: 20, text: "Contact", itemType: DropdownMenuItemType.Header },
    { key: 21, text: "My Contacts", entity: "contact", entityName: "Contact", group: "Contact", columns: ContactCols, },
    { key: 22, text: "All Contacts", entityName: "Contact", group: "Contact", columns: ContactCols, },
    { key: 23, text: "In Progress Contacts", entity: "contact", entityName: "Contact", group: "Contact", columns: ContactCols, },

    { key: 24, text: "Milestones", itemType: DropdownMenuItemType.Header },
    { key: 25, text: "My Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones" },
    { key: 26, text: "All Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones" },
    { key: 27, text: "In Progress Milestones", entity: "msp_engagementmilestone", entityName: "Milestones", group: "Milestones" },
];