/* istanbul ignore file */
import { BasicTelemetryProps, ITelemetryConfig, SeverityLevel, TelemetryEventProps } from './TelemetryService.types';
import {
    ApplicationInsights,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';

import { getTelemetryConfigForEnv, stringifyProperties } from './Telemetryhelper';
import { getEnvUrl } from '../Utils/utils';
import { STRING_CONSTANTS } from '../Utils/Constants';

export class Telemetry {
    appInsights: ApplicationInsights;
    telemetryConfig: ITelemetryConfig;
    basicTelemetryProps: BasicTelemetryProps;

    constructor() {
        const envUrl = getEnvUrl();
        this.telemetryConfig = getTelemetryConfigForEnv(envUrl);
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.telemetryConfig.AIKey,
                disableFetchTracking: false,
            },
        });

        this.appInsights.loadAppInsights();
        const telemetryInitializer = (envelope: ITelemetryItem) => {



            if (
                envelope.name != null &&
                envelope.name!.toLowerCase().includes('.remotedependency')
            ) {
                if (
                    envelope.baseData &&
                    envelope.baseData.type!.toLowerCase() == 'ajax' &&
                    envelope.baseData.target
                ) {
                    const urlMatch = this.telemetryConfig.TrackedDependencyUrls?.find(
                        (x) =>
                            envelope
                                .baseData!.target!.toLowerCase()
                                .includes(x.toLowerCase()),
                    );
                    if (!urlMatch) {
                        //Don't track depedency
                        return false;
                    }
                }
            }
            if (!envelope.data) {
                return;
            }
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);

        let tmpMsxLiteCorrelationId: string = "";
        //@ts-ignore
        if (window[STRING_CONSTANTS.WINDOWS_MSX_LITE_SEARCH_CORRELATION_ID]) {
            //@ts-ignore
            tmpMsxLiteCorrelationId = window[STRING_CONSTANTS.WINDOWS_MSX_LITE_SEARCH_CORRELATION_ID];
        }

        this.basicTelemetryProps = {
            SessionId: crypto.randomUUID().toString(),
            CorrelationId: tmpMsxLiteCorrelationId,
        };

    }
    setCommonPropsForTelemetry(telemetryProps: any): void {
        this.basicTelemetryProps = telemetryProps;
    }

    trackTrace(
        message: string,
        severity: SeverityLevel,
        properties?: TelemetryEventProps,
    ): void {
        try {
            const customProps = stringifyProperties({
                ...this.basicTelemetryProps,
                ...properties,
            });
            this.appInsights.trackTrace(
                {
                    message: message,
                },
                customProps,
            );
        } catch (err) { }
    };

    trackException(
        exception: Error,
        properties?: TelemetryEventProps,
        severityLevel?: SeverityLevel,
    ) {
        try {
            const customProps = stringifyProperties({
                ...this.basicTelemetryProps,
                ...properties,
            });
            const exp = {
                exception: exception,
                severityLevel: severityLevel,
                properties: customProps,
            };
            this.appInsights.trackException(exp);
        } catch (err) { }
    };

    trackEvent(name: string, properties?: any) {
        try {
            const customProps = stringifyProperties({
                ...this.basicTelemetryProps,
                ...properties,
            });
            this.appInsights.trackEvent(
                {
                    name: name,
                },
                customProps,
            );
        } catch (err) { }
    };

    trackMetric(
        name: string,
        value: number,
        properties?: TelemetryEventProps,
    ) {
        try {
            const customProps = stringifyProperties({
                ...this.basicTelemetryProps,
                ...properties,
            });
            this.appInsights.trackMetric({ name: name, average: value }, customProps);
        } catch (err) { } //don't let this break the app
    };

    trackVerbose(message: string, properties?: TelemetryEventProps) {
        this.trackTrace(message, SeverityLevel.Verbose, properties);
    };

    trackError(message: string, properties?: TelemetryEventProps) {
        this.trackTrace(message, SeverityLevel.Error, properties);
    };

    trackCriticalError(
        message: string,
        properties?: TelemetryEventProps,
    ) {
        this.trackTrace(message, SeverityLevel.Critical, properties);
    };

    trackInfo(message: string, properties?: TelemetryEventProps) {
        this.trackTrace(message, SeverityLevel.Information, properties);
    };

    trackWarning(
        message: string,

        properties?: TelemetryEventProps,
    ) {
        this.trackTrace(message, SeverityLevel.Warning, properties);
    };
}