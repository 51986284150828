import { D365_ROOT_URL, D365_API_URL, STRING_CONSTANTS } from '../Utils/Constants'
import { NUMBER_CONSTANTS } from "../Utils/Constants";
import { iSearchOption } from "../Interfaces/iSearchOption";
import { BatchRequestComposer } from "../Utils/DataverseBatch/BatchRequestComposer";
import { IBatchRequest } from "../Interfaces/IBatchRequest";
import { BatchResponseDecoder } from "../Utils/DataverseBatch/BatchResponseDecoder";
import { IBatchResponse } from "../Interfaces/IBatchResponse";
import { MSXFetchXMLs } from "../Utils/fetchXmls";
import { ISavedQuery } from '../Interfaces/ISavedQuery';
import { SavedQueries } from '../Utils/savedqueries';

export async function SearchByAllBatch(keyword: string, viewsSelected: any, tmpToken: string) {

    let chectExtAPICalls = viewsSelected.filter((tmpView: iSearchOption) => {
        return tmpView.isExternalAPI === true
    })
    if (chectExtAPICalls.length > 0)
        return SearchByAllBatchParallel(keyword, viewsSelected, tmpToken);

    let requests: IBatchRequest[] = [];

    let tmpPromizes: any[] = [];
    viewsSelected.map((tmpSelectedOption: iSearchOption) => {

        let tmpSavedQuery: ISavedQuery = {};
        if (tmpSelectedOption.savedqueryid) {
            let tmpSavedQueries = SavedQueries.filter((tmpQuery: ISavedQuery) => {
                return tmpQuery.savedqueryid === tmpSelectedOption.savedqueryid
            })
            if (tmpSavedQueries.length > 0)
                tmpSavedQuery = tmpSavedQueries[0];
        }

        if (keyword.length > 0) {
            if (tmpSelectedOption.useSearchAPI) {
                var reqbody = { "search": keyword, "top": "" + NUMBER_CONSTANTS.MAX_SEARCH_RECORDS.toString() + "", "useFuzzy": "true", "suggestType": "advanced", "entities": ["" + tmpSelectedOption.entity + ""], "returntotalrecordcount": "true" };
                requests.push({
                    httpMethod: "POST",
                    uRL: D365_ROOT_URL + "/api/search/v1.0/query",
                    payload: JSON.stringify(reqbody)
                });
            }
            else {
                // if (tmpSelectedOption.withSearchTextQuery) {
                //     const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                //     requests.push({
                //         httpMethod: "GET",
                //         uRL: tmpAPIEndpoint,
                //     });
                // }
                if (tmpSavedQuery.savedqueryid) {
                    const tmpAPIEndpoint = D365_API_URL + (tmpSavedQuery.fetchxml ?? "").replaceAll(STRING_CONSTANTS.MORECONDITION, keyword).replaceAll(STRING_CONSTANTS.MAX_SEARCH_RECORDS, NUMBER_CONSTANTS.MAX_SEARCH_RECORDS.toString());
                    requests.push({
                        httpMethod: "GET",
                        uRL: tmpAPIEndpoint,
                    });
                }
            }
        }
        else {
            if (tmpSelectedOption.withoutSearchTextQuery) {
                const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withoutSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                requests.push({
                    httpMethod: "GET",
                    uRL: tmpAPIEndpoint,
                });
            }
            else {
                if (tmpSavedQuery.savedqueryid) {
                    const tmpAPIEndpoint = D365_API_URL + (tmpSavedQuery.savedquery ?? "").replaceAll(STRING_CONSTANTS.MAX_SEARCH_RECORDS, NUMBER_CONSTANTS.MAX_SEARCH_RECORDS.toString());
                    requests.push({
                        httpMethod: "GET",
                        uRL: tmpAPIEndpoint,
                    });
                }
            }
        }
    })

    let guidBatchID = crypto.randomUUID();
    let r = BatchRequestComposer(guidBatchID.toString(), requests, true)
    let tmpSearchResult = await makeBatchCall(r.value, r.key, tmpToken);
    let tmpResponse: IBatchResponse[] = BatchResponseDecoder(tmpSearchResult, true);
    return tmpResponse;
}
export async function SearchByAllBatchParallel(keyword: string, viewsSelected: any, tmpToken: string) {
    let requests: IBatchRequest[] = [];
    let tmpPromizes: any[] = [];
    let tmpExternalAPIs: any[] = [];
    viewsSelected.map((tmpSelectedOption: iSearchOption) => {
        if (tmpSelectedOption.isExternalAPI) {
            const tmpAPIEndpoint = tmpSelectedOption?.withoutSearchTextQuery?.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
            const request: RequestInit = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            tmpPromizes.push({ url: tmpAPIEndpoint, req: request, isD365Batch: false, contentId: tmpSelectedOption.id?.toString() });
        }
        else {
            if (keyword.length > 0) {
                if (tmpSelectedOption.useSearchAPI) {
                    var reqbody = { "search": keyword, "top": "" + NUMBER_CONSTANTS.MAX_SEARCH_RECORDS.toString() + "", "useFuzzy": "true", "suggestType": "advanced", "entities": ["" + tmpSelectedOption.entity + ""], "returntotalrecordcount": "true" };
                    requests.push({
                        httpMethod: "POST",
                        uRL: D365_ROOT_URL + "/api/search/v1.0/query",
                        payload: JSON.stringify(reqbody),
                        contentId: tmpSelectedOption.id?.toString()
                    });
                }
                else {
                    if (tmpSelectedOption.withSearchTextQuery) {
                        const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                        requests.push({
                            httpMethod: "GET",
                            uRL: tmpAPIEndpoint,
                            contentId: tmpSelectedOption.id?.toString()
                        });
                    }
                }
            }
            else {
                if (tmpSelectedOption.withoutSearchTextQuery) {
                    const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withoutSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                    requests.push({
                        httpMethod: "GET",
                        uRL: tmpAPIEndpoint,
                        contentId: tmpSelectedOption.id?.toString()
                    });
                }
            }

        }
    })

    let guidBatchID = crypto.randomUUID();
    let r = BatchRequestComposer(guidBatchID.toString(), requests, true)
    tmpPromizes.push({ req: r, isD365Batch: true });
    const tmpSearchResult = await Promise.all(tmpPromizes.map(async (tempReq: any) => {
        if (tempReq.isD365Batch) {
            let tmpSearchResult = await makeBatchCall(tempReq.req.value, tempReq.req.key, tmpToken);
            let tmpResponse: IBatchResponse[] = BatchResponseDecoder(tmpSearchResult, true);
            return { Response: tmpResponse, isD365Batch: true };
        }
        else {
            try {
                const resp = await fetch(tempReq.url, tempReq.req).then((response) => { return response.json() }).then((data: any) => { return data });
                return { Response: { Response: JSON.stringify({ value: resp.searchRecords, totalrecordcount: resp.searchRecords.length }), ContentId: tempReq.contentId, StatusCode: 200 }, isD365Batch: false };
            }
            catch
            {
                return { Response: { Response: JSON.stringify({ value: [], totalrecordcount: 0 }), ContentId: tempReq.contentId, StatusCode: 400 }, isD365Batch: false };

            }
        }
    }));
    let tmpReturnResps: any[] = [];
    tmpSearchResult.forEach((tmp: any) => {
        if (tmp.isD365Batch) {
            tmp.Response.forEach((tmp1: any) => {
                tmpReturnResps.push(tmp1);
            });
        }
        else {
            tmpReturnResps.push(tmp.Response);
        }

    });

    let tmpReturnRespsFinal: any[] = [];

    viewsSelected.map((tmpSelectedOption: iSearchOption) => {
        const tmps = tmpReturnResps.filter((tmpResult: any) => {
            return tmpResult.ContentId === tmpSelectedOption.id?.toString();
        });
        if (tmps.length > 0) {
            tmpReturnRespsFinal.push(tmps[0]);
        }
    });
    return tmpReturnRespsFinal
}

export async function Save(payloads: any[], tmpToken: string) {
    let requests: IBatchRequest[] = [];
    payloads.map((payload: any) => {
        requests.push({
            contentId: payload["contentId"],
            httpMethod: payload["httpMethod"],
            uRL: D365_API_URL + payload["relativeUrl"],
            payload: JSON.stringify(payload["payload"])
        });
    })
    let guidBatchID = crypto.randomUUID();
    let r = BatchRequestComposer(guidBatchID.toString(), requests, true)
    let tmpSearchResult = await makeBatchCall(r.value, r.key, tmpToken);
    let tmpResponse: IBatchResponse[] = BatchResponseDecoder(tmpSearchResult, true);
    return tmpResponse;
}

export async function makeBatchCall(strInput: string, strBatchId: string, tmpToken: string) {
    let tmpData: any = {};
    const tmpAPIEndpoint = D365_API_URL + "$batch";
    const request: RequestInit = {
        method: 'Post',
        body: strInput,
        headers: {
            'Content-Type': "multipart/mixed;boundary=batch_" + strBatchId,
            'Authorization': `Bearer ${tmpToken}`,
            'Prefer': 'odata.continue-on-error',
            'Accept': 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0',
            'Expect': '100-continue'
        },
    }

    await fetch(tmpAPIEndpoint, request)
        .then((response) => {
            if (!response.ok) {
            }
            return response.text()
        })
        .then((data) => {
            tmpData = data;
        })
    return tmpData;
}

export async function SearchHomePageData(tmpToken: string) {

    let requests: IBatchRequest[] = []
    const fetchEntity = [MSXFetchXMLs.MyOpportunitiesMEX, MSXFetchXMLs.MyLeadsMEX, MSXFetchXMLs.MyMilestonesMEX, MSXFetchXMLs.MyAccountsMEX, MSXFetchXMLs.MyActivitiesMEX, MSXFetchXMLs.MyContactMEX, MSXFetchXMLs.MyMilestonesByStatusMEX]
    fetchEntity.map((tmpEntity: any) => {
        const tmpAPIEndpoint =
            D365_API_URL + tmpEntity
        requests.push({
            httpMethod: 'GET',
            uRL: tmpAPIEndpoint,
        })
    }
    )

    let guidBatchID = crypto.randomUUID()
    const r = BatchRequestComposer(guidBatchID.toString(), requests, true)
    let tmpSearchResult = await makeBatchCall(r.value, r.key, tmpToken)
    let tmpResponse: IBatchResponse[] = BatchResponseDecoder(
        tmpSearchResult,
        true
    )
    // debugger
    return tmpResponse
}