import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { setVirtualParent } from '@fluentui/dom-utilities';
import { FocusTrapZone } from '@fluentui/react/lib/FocusTrapZone';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { SearchEntityOptions } from '../Utils/searchOptions';
import { iSearchOption } from '../Interfaces/iSearchOption';

initializeIcons(/* optional base url */);

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export function MSXGridCommandBars(props: any) {
    const [enableFocusTrap, setEnableFocusTrap] = React.useState(false);
    const [menus, setMenus] = React.useState<ICommandBarItemProps[]>([]);
    const onChangeEnableFocusTrap = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            setEnableFocusTrap(!!checked);
        },
        [],
    );
    const getMenus = () => {
        return [
            { key: 'Filter', text: 'Filter', iconProps: { iconName: 'Filter' }, onClick: () => props.OnSaveClick() },
            { key: 'Refresh', text: 'Refresh', iconProps: { iconName: 'Refresh' }, onClick: () => console.log('Refresh') },
            { key: 'Save', text: 'Save', iconProps: { iconName: 'Save' }, onClick: () => props.OnSaveClick() },
        ];
    };
    React.useEffect(() => {
        setMenus(getMenus());
    }, []);
    return (
        <div>
            <FocusTrapZone disabled={!enableFocusTrap}>
                <CommandBar
                    items={[]}
                    farItems={menus}
                    ariaLabel="Views Selection"
                    primaryGroupAriaLabel="Views Selection"
                    farItemsGroupAriaLabel="Views Selection"
                />
            </FocusTrapZone>
        </div>
    );
};