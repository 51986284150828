export const opportunity_attributes = [
  {
    "attributeDescriptor": {
      "LogicalName": "accountid",
      "Type": "lookup",
      "DisplayName": "Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "actualclosedate",
      "Type": "datetime",
      "DisplayName": "Actual Close Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "actualvalue",
      "Type": "money",
      "DisplayName": "Actual Revenue",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "actualvalue_base",
      "Type": "money",
      "DisplayName": "Actual Revenue (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetamount",
      "Type": "money",
      "DisplayName": "Budget Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetamount_base",
      "Type": "money",
      "DisplayName": "Budget Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetstatus",
      "Type": "picklist",
      "DisplayName": "Budget",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "1"
        },
        {
          "Label": "No",
          "Value": "0"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "campaignid",
      "Type": "lookup",
      "DisplayName": "Source Campaign",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "captureproposalfeedback",
      "Type": "boolean",
      "DisplayName": "Proposal Feedback Captured",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "closeprobability",
      "Type": "integer",
      "DisplayName": "Probability",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "completefinalproposal",
      "Type": "boolean",
      "DisplayName": "Final Proposal Ready",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "completeinternalreview",
      "Type": "boolean",
      "DisplayName": "Complete Internal Review",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "confirminterest",
      "Type": "boolean",
      "DisplayName": "Confirm Interest",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "contactid",
      "Type": "lookup",
      "DisplayName": "Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "crac3_alignonsolutionapproach",
      "Type": "picklist",
      "DisplayName": "Align on Solution Approach",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "137710000"
        },
        {
          "Label": "Completed",
          "Value": "137710001"
        },
        {
          "Label": "Not Applicable",
          "Value": "137710002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "137710003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdby",
      "Type": "lookup",
      "DisplayName": "Created By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdon",
      "Type": "datetime",
      "DisplayName": "Created On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdonbehalfby",
      "Type": "lookup",
      "DisplayName": "Created By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "currentsituation",
      "Type": "memo",
      "DisplayName": "Current Situation",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customerid",
      "Type": "customer",
      "DisplayName": "Potential Customer",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customerneed",
      "Type": "memo",
      "DisplayName": "Customer Need",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customerpainpoints",
      "Type": "memo",
      "DisplayName": "Customer Pain Points",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "decisionmaker",
      "Type": "boolean",
      "DisplayName": "Decision Maker?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "description",
      "Type": "memo",
      "DisplayName": "Need",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "developproposal",
      "Type": "boolean",
      "DisplayName": "Develop Proposal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "discountamount",
      "Type": "money",
      "DisplayName": "Opportunity Discount Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "discountamount_base",
      "Type": "money",
      "DisplayName": "Opportunity Discount Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "discountpercentage",
      "Type": "decimal",
      "DisplayName": "Opportunity Discount (%)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress",
      "Type": "string",
      "DisplayName": "Email Address",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedclosedate",
      "Type": "datetime",
      "DisplayName": "Est. Close Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedvalue",
      "Type": "money",
      "DisplayName": "Est. Revenue",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedvalue_base",
      "Type": "money",
      "DisplayName": "Est. Revenue (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "evaluatefit",
      "Type": "boolean",
      "DisplayName": "Evaluate Fit",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "exchangerate",
      "Type": "decimal",
      "DisplayName": "Exchange Rate",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "filedebrief",
      "Type": "boolean",
      "DisplayName": "File Debrief",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "finaldecisiondate",
      "Type": "datetime",
      "DisplayName": "Final Decision Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "freightamount",
      "Type": "money",
      "DisplayName": "Freight Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "freightamount_base",
      "Type": "money",
      "DisplayName": "Freight Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "identifycompetitors",
      "Type": "boolean",
      "DisplayName": "Identify Competitors",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "identifycustomercontacts",
      "Type": "boolean",
      "DisplayName": "Identify Customer Contacts",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "identifypursuitteam",
      "Type": "boolean",
      "DisplayName": "Identify Sales Team",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "importsequencenumber",
      "Type": "integer",
      "DisplayName": "Import Sequence Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "initialcommunication",
      "Type": "picklist",
      "DisplayName": "Initial Communication",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Contacted",
          "Value": "0"
        },
        {
          "Label": "Not Contacted",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isprivate",
      "Type": "boolean",
      "DisplayName": "Is Private",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isrevenuesystemcalculated",
      "Type": "boolean",
      "DisplayName": "Revenue",
      "Format": null,
      "OptionSet": [
        {
          "Label": "User Provided",
          "Value": "0"
        },
        {
          "Label": "System Calculated",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastonholdtime",
      "Type": "datetime",
      "DisplayName": "Last On Hold Time",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_companyid",
      "Type": "integer",
      "DisplayName": "LinkedIn Company Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_isinfluenced",
      "Type": "boolean",
      "DisplayName": "Is Influenced",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_membertoken",
      "Type": "string",
      "DisplayName": "LinkedIn Member Token",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedby",
      "Type": "lookup",
      "DisplayName": "Modified By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedon",
      "Type": "datetime",
      "DisplayName": "Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedonbehalfby",
      "Type": "lookup",
      "DisplayName": "Modified By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_forecastcategory",
      "Type": "picklist",
      "DisplayName": "Forecast category",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Pipeline",
          "Value": "100000001"
        },
        {
          "Label": "Best case",
          "Value": "100000002"
        },
        {
          "Label": "Committed",
          "Value": "100000003"
        },
        {
          "Label": "Omitted",
          "Value": "100000004"
        },
        {
          "Label": "Won",
          "Value": "100000005"
        },
        {
          "Label": "Lost",
          "Value": "100000006"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_gdproptout",
      "Type": "boolean",
      "DisplayName": "GDPR Optout",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_opportunitygrade",
      "Type": "picklist",
      "DisplayName": "(Deprecated) Opportunity Grade",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Grade A",
          "Value": "0"
        },
        {
          "Label": "Grade B",
          "Value": "1"
        },
        {
          "Label": "Grade C",
          "Value": "2"
        },
        {
          "Label": "Grade D",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_opportunitykpiid",
      "Type": "lookup",
      "DisplayName": "KPI",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_opportunityscore",
      "Type": "integer",
      "DisplayName": "(Deprecated) Opportunity Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_opportunityscoretrend",
      "Type": "picklist",
      "DisplayName": "(Deprecated) Opportunity Score Trend",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Improving",
          "Value": "0"
        },
        {
          "Label": "Steady",
          "Value": "1"
        },
        {
          "Label": "Declining",
          "Value": "2"
        },
        {
          "Label": "Not enough info",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_predictivescoreid",
      "Type": "lookup",
      "DisplayName": "Predictive Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_scorehistory",
      "Type": "memo",
      "DisplayName": "(Deprecated) Score History",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_scorereasons",
      "Type": "memo",
      "DisplayName": "(Deprecated) Score Reasons",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_segmentid",
      "Type": "lookup",
      "DisplayName": "Segment Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_similaropportunities",
      "Type": "memo",
      "DisplayName": "msdyn_similaropportunities",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_accessecif",
      "Type": "picklist",
      "DisplayName": "Access ECIF",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_accountteamorchestratedwithclearrr",
      "Type": "boolean",
      "DisplayName": "Account team orchestrated with clear R&R",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_activeprocess",
      "Type": "string",
      "DisplayName": "Active Process",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_activesalesstage",
      "Type": "string",
      "DisplayName": "Active Sales Stage",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_actualbilledclosedate",
      "Type": "datetime",
      "DisplayName": "Actual Billed Close Date",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_actualclosedatetime",
      "Type": "datetime",
      "DisplayName": "actualclosedatetime",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_adhocsurvey",
      "Type": "integer",
      "DisplayName": "AdhocSurvey",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_agreedtobuy",
      "Type": "boolean",
      "DisplayName": "Agreed To Buy",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_agreement",
      "Type": "string",
      "DisplayName": "Agreement #",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_agreementreachedbyallparties",
      "Type": "picklist",
      "DisplayName": "Agreement reached by all parties?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_agreementtoproceedcontractnegotiationbpf",
      "Type": "boolean",
      "DisplayName": "Agreement to proceed to contract negotiation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_alignonbusinessimpact",
      "Type": "picklist",
      "DisplayName": "Align on Business Impact",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_alignonsolutionapproach",
      "Type": "picklist",
      "DisplayName": "Align on Solution Approach",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_alignonsuccesscriteriawithcustomer",
      "Type": "picklist",
      "DisplayName": "Align on success criteria with customer",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_apengagementtype",
      "Type": "lookup",
      "DisplayName": "Solution Assessment Engagement Type",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_attachedservices",
      "Type": "boolean",
      "DisplayName": "Attached Services",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureactivedevicesnonrecurring",
      "Type": "decimal",
      "DisplayName": "Azure ActiveDevices NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureactivedevicesrecurring",
      "Type": "decimal",
      "DisplayName": "Azure ActiveDevices Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azurecapacitytype",
      "Type": "multiselectpicklist",
      "DisplayName": "Azure Capacity Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "App Service (Cores)",
          "Value": "861980075"
        },
        {
          "Label": "Archive Storage (TB)",
          "Value": "861980057"
        },
        {
          "Label": "Av2/Dv2/Dv3/Ev3/Dsv3/Esv3 (Intel)  (Cores)",
          "Value": "861980037"
        },
        {
          "Label": "AV36 (AVS) (Cores)",
          "Value": "861980067"
        },
        {
          "Label": "AV36P (AVS) (Cores)",
          "Value": "861980076"
        },
        {
          "Label": "AV52 (AVS) (Cores)",
          "Value": "861980077"
        },
        {
          "Label": "Azure Cosmos DB (RU/s)",
          "Value": "861980064"
        },
        {
          "Label": "Azure Data Lake Storage (TB)",
          "Value": "861980058"
        },
        {
          "Label": "Azure Data LakeStorage Gen 2 (TB)",
          "Value": "861980059"
        },
        {
          "Label": "Azure Databricks  (Cores)",
          "Value": "861980070"
        },
        {
          "Label": "Azure HPC Cache (TB)",
          "Value": "861980061"
        },
        {
          "Label": "Azure NetApp Files (ANF) (TB)",
          "Value": "861980062"
        },
        {
          "Label": "Azure SQL Database (Cores or DTUs)",
          "Value": "861980065"
        },
        {
          "Label": "Azure SQL Managed Instance (Cores)",
          "Value": "861980066"
        },
        {
          "Label": "Azure xDirect (TB)",
          "Value": "861980060"
        },
        {
          "Label": "Confidential Compute AMD",
          "Value": "861980071"
        },
        {
          "Label": "Confidential Compute SGX",
          "Value": "861980072"
        },
        {
          "Label": "Dav4/EaV4/DAsv4/EAsv4 (AMD) (Cores)",
          "Value": "861980040"
        },
        {
          "Label": "Ddsv5/Edsv5 (Intel)  (Cores)",
          "Value": "861980068"
        },
        {
          "Label": "Dpdsv5/Dpldsv5/Dpsv5/Dplsv5/Epdv5/Epsv5 (ARM)  (Cores)",
          "Value": "861980069"
        },
        {
          "Label": "Dv4/Ev4/Dsv4/Esv4/Ddsv4/Edsv4 (Intel)  (Cores)",
          "Value": "861980038"
        },
        {
          "Label": "Dv5/Ev5/DSv5/Esv5 (Intel)  (Cores)",
          "Value": "861980039"
        },
        {
          "Label": "FSv2 (Cores)",
          "Value": "861980041"
        },
        {
          "Label": "G (Cores)",
          "Value": "861980042"
        },
        {
          "Label": "H (Cores)",
          "Value": "861980045"
        },
        {
          "Label": "Hbv2 (Cores)",
          "Value": "861980043"
        },
        {
          "Label": "Hbv3 (Cores)",
          "Value": "861980073"
        },
        {
          "Label": "Hbv4 (Cores)",
          "Value": "861980074"
        },
        {
          "Label": "Hc (Cores)",
          "Value": "861980044"
        },
        {
          "Label": "LSv3 (Cores)",
          "Value": "861980046"
        },
        {
          "Label": "M (Cores)",
          "Value": "861980047"
        },
        {
          "Label": "Mv2 (Cores)",
          "Value": "861980048"
        },
        {
          "Label": "NC (Cores)",
          "Value": "861980051"
        },
        {
          "Label": "NCasT4V3 (Cores)",
          "Value": "861980049"
        },
        {
          "Label": "NCsv2 (Cores)",
          "Value": "861980052"
        },
        {
          "Label": "NCSv3 (Cores)",
          "Value": "861980053"
        },
        {
          "Label": "Nd A100 V4 (Cores)",
          "Value": "861980050"
        },
        {
          "Label": "NDs (Cores)",
          "Value": "861980054"
        },
        {
          "Label": "NVv3 (Cores)",
          "Value": "861980055"
        },
        {
          "Label": "NVV4 (Cores)",
          "Value": "861980056"
        },
        {
          "Label": "NVV5  (Cores)",
          "Value": "861980022"
        },
        {
          "Label": "Ultra SSD Storage (TB)",
          "Value": "861980063"
        },
        {
          "Label": "Other",
          "Value": "861980032"
        },
        {
          "Label": "None",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureconsumednonrecurring",
      "Type": "money",
      "DisplayName": "Azure Consumed NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureconsumednonrecurring_base",
      "Type": "money",
      "DisplayName": "Azure Consumed NonRecurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureconsumedrecurring",
      "Type": "money",
      "DisplayName": "Azure Consumed Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_azureconsumedrecurring_base",
      "Type": "money",
      "DisplayName": "Azure Consumed Recurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_baseopportunity",
      "Type": "picklist",
      "DisplayName": "Base Opportunity",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        },
        {
          "Label": "No",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_billedclosingcomments",
      "Type": "memo",
      "DisplayName": "Billed Closing Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_billedrevenue",
      "Type": "money",
      "DisplayName": "Billed Actual Revenue",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_billedrevenue_base",
      "Type": "money",
      "DisplayName": "Billed Actual Revenue (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_billedrevenueclosereason",
      "Type": "picklist",
      "DisplayName": "Billed Revenue Close Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Cancelled/Delayed",
          "Value": "606820000"
        },
        {
          "Label": "Competitive Loss",
          "Value": "606820001"
        },
        {
          "Label": "Competitive Win",
          "Value": "606820002"
        },
        {
          "Label": "Hygiene/Duplicate",
          "Value": "606820003"
        },
        {
          "Label": "Kept Existing Solution",
          "Value": "606820004"
        },
        {
          "Label": "MS Sales Validated",
          "Value": "606820005"
        },
        {
          "Label": "Non-Compete",
          "Value": "606820006"
        },
        {
          "Label": "OEM Automated Closure",
          "Value": "606820007"
        },
        {
          "Label": "Partial Win",
          "Value": "606820008"
        },
        {
          "Label": "Consolidated",
          "Value": "606820009"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_billedrevenuestatus",
      "Type": "picklist",
      "DisplayName": "Billed Revenue Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "N/A",
          "Value": "606820000"
        },
        {
          "Label": "Lost",
          "Value": "861980000"
        },
        {
          "Label": "Open",
          "Value": "861980001"
        },
        {
          "Label": "Won",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsactivedevicesnonrecurring",
      "Type": "decimal",
      "DisplayName": "BizApps ActiveDevices NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsactivedevicesrecurring",
      "Type": "decimal",
      "DisplayName": "BizApps ActiveDevices Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsbacvnonrecurring",
      "Type": "money",
      "DisplayName": "BizApps BACV NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsbacvnonrecurring_base",
      "Type": "money",
      "DisplayName": "BizApps BACV NonRecurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsbacvrecurring",
      "Type": "money",
      "DisplayName": "BizApps BACV Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappsbacvrecurring_base",
      "Type": "money",
      "DisplayName": "BizApps BACV Recurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizappssalesplay",
      "Type": "picklist",
      "DisplayName": "Biz Apps Sales Play",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Intelligent Sales and Marketing",
          "Value": "606820000"
        },
        {
          "Label": "Proactive Customer Service",
          "Value": "606820001"
        },
        {
          "Label": "Connected Field Service",
          "Value": "606820002"
        },
        {
          "Label": "Modernize Finance and Operations",
          "Value": "606820003"
        },
        {
          "Label": "Connected Commerce",
          "Value": "606820004"
        },
        {
          "Label": "Power Platform – Modern Analytics",
          "Value": "606820005"
        },
        {
          "Label": "Power Platform – Rapid Application Development",
          "Value": "606820006"
        },
        {
          "Label": "Power Platform – Automate Business Process",
          "Value": "606820007"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_businessvalueassessmentcompleted",
      "Type": "boolean",
      "DisplayName": "Business Value Assessment completed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_businessvalueconsiderationscompleted",
      "Type": "picklist",
      "DisplayName": "Customer reviewed & endorsed the business value?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_busvalueandsuccessoutcomemonitoringinplac",
      "Type": "picklist",
      "DisplayName": "Bus Value and Success Outcome monitoring in place?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bvsuccessoutcomesmonitoringinplace",
      "Type": "boolean",
      "DisplayName": "BV / Success Outcomes monitoring in place",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_changemanagementplaninplace",
      "Type": "boolean",
      "DisplayName": "Change Management Plan in place",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_changemanagementplanplace",
      "Type": "picklist",
      "DisplayName": "Change Management Plan in place?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_claimtpid",
      "Type": "string",
      "DisplayName": "Claim TPID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_clanumber",
      "Type": "string",
      "DisplayName": "CLA Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_closeopty",
      "Type": "boolean",
      "DisplayName": "CloseOpty",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_closependingstatus",
      "Type": "picklist",
      "DisplayName": "Close Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Draft Pricing Sent",
          "Value": "861980000"
        },
        {
          "Label": "Final Pricing Sent",
          "Value": "861980008"
        },
        {
          "Label": "Pending Customer Signatures",
          "Value": "861980001"
        },
        {
          "Label": "Pending LSP Submission",
          "Value": "861980003"
        },
        {
          "Label": "Processing at ROC",
          "Value": "861980004"
        },
        {
          "Label": "On Hold",
          "Value": "861980009"
        },
        {
          "Label": "Order Processed",
          "Value": "861980010"
        },
        {
          "Label": "Order Invoiced",
          "Value": "861980005"
        },
        {
          "Label": "Slipped",
          "Value": "861980006"
        },
        {
          "Label": "Rework",
          "Value": "861980011"
        },
        {
          "Label": "Cancelled",
          "Value": "861980012"
        },
        {
          "Label": "Misparented",
          "Value": "861980007"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_closeplan",
      "Type": "lookup",
      "DisplayName": "Close Plan",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_commercialconstructapprovedbycustomer",
      "Type": "picklist",
      "DisplayName": "Commercial Construct Approved by Customer",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_competethreatlevel",
      "Type": "picklist",
      "DisplayName": "Compete Threat Level",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Low/ None",
          "Value": "861980001"
        },
        {
          "Label": "Medium",
          "Value": "861980002"
        },
        {
          "Label": "High",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completecloseplan",
      "Type": "boolean",
      "DisplayName": "Complete Close Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completedeploymentworkshop",
      "Type": "datetime",
      "DisplayName": "Complete Deployment Workshop",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completejointcatalystengagementplan",
      "Type": "picklist",
      "DisplayName": "Complete Joint Catalyst Engagement Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completeorder",
      "Type": "boolean",
      "DisplayName": "Complete Order",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completepredeploymentworkshop",
      "Type": "picklist",
      "DisplayName": "Complete Pre-Deployment Workshop",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completequote",
      "Type": "boolean",
      "DisplayName": "Complete Quote",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completetechnicalassessment",
      "Type": "picklist",
      "DisplayName": "Complete Technical Assessment",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completetechnicalcloseplan",
      "Type": "datetime",
      "DisplayName": "Complete Technical Close Plan",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completetrial",
      "Type": "picklist",
      "DisplayName": "Complete Trial",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completeworkshop",
      "Type": "datetime",
      "DisplayName": "Complete Workshop",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_complexity",
      "Type": "picklist",
      "DisplayName": "Complexity",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Custom Billing",
          "Value": "861980000"
        },
        {
          "Label": "DemandResponse-At Risk",
          "Value": "861980009"
        },
        {
          "Label": "Enrollment with Standard Amendments",
          "Value": "861980007"
        },
        {
          "Label": "Generic Enrollments No Amendment",
          "Value": "861980003"
        },
        {
          "Label": "Global Enrollment",
          "Value": "861980008"
        },
        {
          "Label": "Multi-Affiliate Billing Enrollment",
          "Value": "861980004"
        },
        {
          "Label": "Multi-Tenant Enrollment",
          "Value": "861980005"
        },
        {
          "Label": "Others",
          "Value": "861980006"
        },
        {
          "Label": "With Custom Amendments",
          "Value": "861980002"
        },
        {
          "Label": "Low",
          "Value": "606820000"
        },
        {
          "Label": "Medium",
          "Value": "606820001"
        },
        {
          "Label": "High",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_conclusionlettercode",
      "Type": "boolean",
      "DisplayName": "Conclusion Letter",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not sent",
          "Value": "0"
        },
        {
          "Label": "sent",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_conductpoc",
      "Type": "picklist",
      "DisplayName": "Conduct POC",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_confirmtimelineandbudgetstatusbpf",
      "Type": "boolean",
      "DisplayName": "Confirm timeline and budget status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_confirmtrial",
      "Type": "datetime",
      "DisplayName": "Confirm Trial",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consenttosendtopartnerby",
      "Type": "lookup",
      "DisplayName": "Consent To Send To Partner By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consenttosendtopartnercode",
      "Type": "picklist",
      "DisplayName": "Consent To Send To Partner",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        },
        {
          "Label": "No",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionactivedevicesnonrecurring",
      "Type": "decimal",
      "DisplayName": "Consumption ActiveDevices NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionactivedevicesrecurring",
      "Type": "decimal",
      "DisplayName": "Consumption ActiveDevices Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionclosepcfcomponent",
      "Type": "string",
      "DisplayName": "consumptionclosepcfcomponent",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionconsumednonrecurring",
      "Type": "money",
      "DisplayName": "Consumed NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionconsumednonrecurring_base",
      "Type": "money",
      "DisplayName": "Consumed Revenue NonRecurring ($)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionconsumedrecurring",
      "Type": "money",
      "DisplayName": "Consumed Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionconsumedrecurring_base",
      "Type": "money",
      "DisplayName": "Consumed Revenue Recurring ($)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionplancspupdated",
      "Type": "boolean",
      "DisplayName": "Consumption Plan / CSP updated",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactrecommendationmex",
      "Type": "string",
      "DisplayName": "ContactRecommendationMEX",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contractsignaturetype",
      "Type": "picklist",
      "DisplayName": "Contract Signature Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Digital Signature",
          "Value": "606820000"
        },
        {
          "Label": "Physical Signature",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contractsignedbyallparties",
      "Type": "boolean",
      "DisplayName": "Contract signed by all parties",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_correctedecid",
      "Type": "string",
      "DisplayName": "Corrected ECID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_cosell",
      "Type": "picklist",
      "DisplayName": "CoSell",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "861980000"
        },
        {
          "Label": "Yes",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_cpumodelseries",
      "Type": "picklist",
      "DisplayName": "Silicon Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "MediaTek MT3620",
          "Value": "606820002"
        },
        {
          "Label": "NXP 8ULP-CS",
          "Value": "606820000"
        },
        {
          "Label": "AMD - A10",
          "Value": "861980030"
        },
        {
          "Label": "AMD - A4",
          "Value": "861980000"
        },
        {
          "Label": "AMD - A6",
          "Value": "861980001"
        },
        {
          "Label": "AMD - A8",
          "Value": "861980002"
        },
        {
          "Label": "AMD - E1",
          "Value": "861980003"
        },
        {
          "Label": "AMD - E2",
          "Value": "861980004"
        },
        {
          "Label": "AMD - FX 7500",
          "Value": "861980005"
        },
        {
          "Label": "AMD - FX 7600P",
          "Value": "861980006"
        },
        {
          "Label": "AMD - FX 9370",
          "Value": "861980007"
        },
        {
          "Label": "AMD - FX 9590",
          "Value": "861980008"
        },
        {
          "Label": "AMD - G Series",
          "Value": "861980009"
        },
        {
          "Label": "AMD - Others",
          "Value": "861980010"
        },
        {
          "Label": "AMD - R Series",
          "Value": "861980011"
        },
        {
          "Label": "AMD - Rest of FX Models",
          "Value": "861980012"
        },
        {
          "Label": "Intel - Atom",
          "Value": "861980013"
        },
        {
          "Label": "Intel - Celeron - N1900",
          "Value": "861980014"
        },
        {
          "Label": "Intel - Celeron - N2807",
          "Value": "861980015"
        },
        {
          "Label": "Intel - Celeron - N2930",
          "Value": "861980016"
        },
        {
          "Label": "Intel - Celeron - N3010",
          "Value": "861980017"
        },
        {
          "Label": "Intel - Celeron - N3060",
          "Value": "861980018"
        },
        {
          "Label": "Intel - Celeron - N3160",
          "Value": "861980019"
        },
        {
          "Label": "Intel - Core i3",
          "Value": "861980020"
        },
        {
          "Label": "Intel - Core i5",
          "Value": "861980021"
        },
        {
          "Label": "Intel - Core i7",
          "Value": "861980022"
        },
        {
          "Label": "Intel - Core M",
          "Value": "861980023"
        },
        {
          "Label": "Intel - Others",
          "Value": "861980024"
        },
        {
          "Label": "Intel - Pentium",
          "Value": "861980025"
        },
        {
          "Label": "Intel - Rest of Celeron",
          "Value": "861980026"
        },
        {
          "Label": "Intel - XEON",
          "Value": "861980027"
        },
        {
          "Label": "Others",
          "Value": "861980028"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_createbusinessvalueassessment",
      "Type": "picklist",
      "DisplayName": "Create Business Value Assessment",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820002"
        },
        {
          "Label": "Completed",
          "Value": "606820003"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820004"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_criticaldeal",
      "Type": "boolean",
      "DisplayName": "Critical Deal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_currentsalesstagemodifiedon",
      "Type": "datetime",
      "DisplayName": "Current Sales Stage Modified On (Deprecated)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreementcode",
      "Type": "boolean",
      "DisplayName": "Customer Agreement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreementinplace",
      "Type": "picklist",
      "DisplayName": "Exit Criteria: Customer Agreement In Place",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreessolutiontechnicallyviablbpf",
      "Type": "boolean",
      "DisplayName": "Customer agrees solution is technically viable",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreestoperformaproofofconceptbpf",
      "Type": "boolean",
      "DisplayName": "Customer agrees to perform a proof of concept",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreeswithmsvaluepropositionbpf",
      "Type": "boolean",
      "DisplayName": "Customer agrees with MS value proposition",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerapprovesbusinesscaseframeworkbpf",
      "Type": "boolean",
      "DisplayName": "Customer approves business case framework",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerapprovestechnicalcriteriabpf",
      "Type": "boolean",
      "DisplayName": "Customer approves technical criteria",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customercanoperateandmaintainsolution",
      "Type": "picklist",
      "DisplayName": "Customer can operate and maintain solution?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customercommittedtoproposal",
      "Type": "boolean",
      "DisplayName": "Customer committed to proposal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customercommittoproposal",
      "Type": "picklist",
      "DisplayName": "Customer committed to proposal?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerhealthmetricsagreedwithmonitoring",
      "Type": "picklist",
      "DisplayName": "Customer health metrics agreed with monitoring?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerneedsandoutcomesdefine",
      "Type": "picklist",
      "DisplayName": "Customer needs and outcomes defined?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerneedsandoutcomesdefined",
      "Type": "boolean",
      "DisplayName": "Customer needs and outcomes defined",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerprioritiesidentified",
      "Type": "picklist",
      "DisplayName": "Customer priorities identified?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customerprioritiesidentifiedandmapped",
      "Type": "boolean",
      "DisplayName": "(IGNORE) Customer priorities identified and mapped",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersignedoffonproposedsolution",
      "Type": "boolean",
      "DisplayName": "Customer signed-off on proposed solution",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersignedoffonproposesolution",
      "Type": "picklist",
      "DisplayName": "Proposed solution confirmed by Customer?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersignoffonassessments",
      "Type": "picklist",
      "DisplayName": "Customer sign-off on assessments",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersignscontractbpf",
      "Type": "boolean",
      "DisplayName": "Customer signs contract or agrees to place an orde",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersuccessplancreated",
      "Type": "boolean",
      "DisplayName": "Customer Success Plan created",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersuccessplanreviewedwithcustomer",
      "Type": "picklist",
      "DisplayName": "Customer Success Plan reviewed and updated?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersuccessplanupdated",
      "Type": "picklist",
      "DisplayName": "Customer Success Plan updated?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customersuccessplanworkshopcomplete",
      "Type": "picklist",
      "DisplayName": "Customer Success Plan Workshop Complete",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Partner led",
          "Value": "606820000"
        },
        {
          "Label": "Services led",
          "Value": "606820001"
        },
        {
          "Label": "CSU led",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-Out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_daysinstage",
      "Type": "integer",
      "DisplayName": "Days in Stage (Deprecated)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dealrole",
      "Type": "picklist",
      "DisplayName": "Deal Role",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Solution",
          "Value": "861980001"
        },
        {
          "Label": "Transaction",
          "Value": "861980002"
        },
        {
          "Label": "Independent Software Vendor (ISV)",
          "Value": "861980003"
        },
        {
          "Label": "OEM IoT",
          "Value": "861980004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dealtype",
      "Type": "picklist",
      "DisplayName": "Revenue Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Future Month Revenue",
          "Value": "861980001"
        },
        {
          "Label": "Current Month Revenue",
          "Value": "861980005"
        },
        {
          "Label": "Non-Revenue Deal (Amendments)",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_deliverbusinessvalueenlighten",
      "Type": "picklist",
      "DisplayName": "Deliver Business Value Enlighten",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_deliverenvisioningworkshop",
      "Type": "picklist",
      "DisplayName": "Deliver Envisioning Workshop",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_deliverinitialpitch",
      "Type": "picklist",
      "DisplayName": "Deliver Initial Pitch",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980003"
        },
        {
          "Label": "Completed",
          "Value": "861980000"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980001"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_delivertailoredconversation",
      "Type": "picklist",
      "DisplayName": "Deliver tailored conversation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_delivertailoreddemonstration",
      "Type": "picklist",
      "DisplayName": "Deliver Tailored Demonstration",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_delivertechnicalcase",
      "Type": "picklist",
      "DisplayName": "Deliver Technical Case",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_delivertechnicaldesign",
      "Type": "picklist",
      "DisplayName": "Deliver Technical Design",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_delivertransformationplan",
      "Type": "picklist",
      "DisplayName": "Deliver Transformation Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_demandresponsedealtype",
      "Type": "picklist",
      "DisplayName": "Demand Response Deal Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Win+Bookings",
          "Value": "606820004"
        },
        {
          "Label": "Low",
          "Value": "606820000"
        },
        {
          "Label": "High",
          "Value": "606820001"
        },
        {
          "Label": "High+Low",
          "Value": "606820002"
        },
        {
          "Label": "Bookings Only",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_demonstrateroi",
      "Type": "picklist",
      "DisplayName": "Demonstrate ROI",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_description",
      "Type": "string",
      "DisplayName": "Description",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_designagreedwithcustomer",
      "Type": "picklist",
      "DisplayName": "Exit Criteria: Customer aligned to Solution",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_devicecategory",
      "Type": "picklist",
      "DisplayName": "Device Category",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Azure Sphere Component",
          "Value": "606820002"
        },
        {
          "Label": "Azure Sphere Board",
          "Value": "606820001"
        },
        {
          "Label": "Azure Sphere Module",
          "Value": "606820000"
        },
        {
          "Label": "Arcade/Consumer Gaming Device",
          "Value": "861980031"
        },
        {
          "Label": "ATM",
          "Value": "861980000"
        },
        {
          "Label": "Automotive & Transportation Systems",
          "Value": "861980001"
        },
        {
          "Label": "Azure Sphere Guardian",
          "Value": "861980034"
        },
        {
          "Label": "Building Automation",
          "Value": "861980002"
        },
        {
          "Label": "Casino Gaming Device",
          "Value": "861980032"
        },
        {
          "Label": "Communication Devices",
          "Value": "861980003"
        },
        {
          "Label": "Consumer Internet Device",
          "Value": "861980004"
        },
        {
          "Label": "Consumer Wearable",
          "Value": "861980005"
        },
        {
          "Label": "Digital Picture Frame",
          "Value": "861980006"
        },
        {
          "Label": "Digital Signage",
          "Value": "861980007"
        },
        {
          "Label": "Gateway",
          "Value": "861980033"
        },
        {
          "Label": "HHT/Mobile",
          "Value": "861980008"
        },
        {
          "Label": "Industrial Automation Device",
          "Value": "861980009"
        },
        {
          "Label": "Industry Tablet (Non-POS)",
          "Value": "861980010"
        },
        {
          "Label": "Kiosk",
          "Value": "861980011"
        },
        {
          "Label": "Media Player",
          "Value": "861980012"
        },
        {
          "Label": "Medical Device",
          "Value": "861980013"
        },
        {
          "Label": "Mobile POS",
          "Value": "861980014"
        },
        {
          "Label": "Navigation Device",
          "Value": "861980015"
        },
        {
          "Label": "Network Projector",
          "Value": "861980016"
        },
        {
          "Label": "Other",
          "Value": "861980017"
        },
        {
          "Label": "Other Banking Device",
          "Value": "861980018"
        },
        {
          "Label": "Other Consumer Electronic Device",
          "Value": "861980019"
        },
        {
          "Label": "Other Device",
          "Value": "861980020"
        },
        {
          "Label": "Other Enterprise Device",
          "Value": "861980021"
        },
        {
          "Label": "Other Sensor / Node",
          "Value": "861980022"
        },
        {
          "Label": "Point of Sale Device",
          "Value": "861980023"
        },
        {
          "Label": "Printing Device",
          "Value": "861980024"
        },
        {
          "Label": "Security/Surveillance",
          "Value": "861980025"
        },
        {
          "Label": "Server",
          "Value": "861980026"
        },
        {
          "Label": "Set-Top Box",
          "Value": "861980027"
        },
        {
          "Label": "Smart TV",
          "Value": "861980028"
        },
        {
          "Label": "Test and Measurement Device",
          "Value": "861980029"
        },
        {
          "Label": "Thin Client Device",
          "Value": "861980030"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dfsselection",
      "Type": "picklist",
      "DisplayName": "(IGNORE) DO_NOT_USE Suggestion_Entity_Field",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Inspire & Design",
          "Value": "861980000"
        },
        {
          "Label": "Listen & Consult",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_elpagreementcode",
      "Type": "boolean",
      "DisplayName": "ELP Agreement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_elpuploadedcode",
      "Type": "boolean",
      "DisplayName": "ELP Uploaded",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not uploaded",
          "Value": "0"
        },
        {
          "Label": "uploaded",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_enablemcem",
      "Type": "boolean",
      "DisplayName": "Enable MCEM",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagecosellprioritizedpartner",
      "Type": "picklist",
      "DisplayName": "Engage Co-Sell Prioritized Partner",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagedwrawrforecif",
      "Type": "picklist",
      "DisplayName": "Engage DWR/AWR for ECIF",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagefasttrack",
      "Type": "picklist",
      "DisplayName": "Engage FastTrack",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagegbb",
      "Type": "picklist",
      "DisplayName": "Engage GBB",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementclosedate",
      "Type": "datetime",
      "DisplayName": "Consumption Actual Closing Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementclosingcomments",
      "Type": "memo",
      "DisplayName": "Consumption Closing Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementid",
      "Type": "string",
      "DisplayName": "Migrated Engagement Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementnumber",
      "Type": "string",
      "DisplayName": "Migrated Engagement Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementstatus",
      "Type": "picklist",
      "DisplayName": "Consumption Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In-Progress",
          "Value": "861980000"
        },
        {
          "Label": "Closed",
          "Value": "861980003"
        },
        {
          "Label": "N/A",
          "Value": "606820000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementstatusreason",
      "Type": "picklist",
      "DisplayName": "Consumption Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Completed",
          "Value": "861820003"
        },
        {
          "Label": "Cancelled by Customer",
          "Value": "861820000"
        },
        {
          "Label": "Cancelled/Delayed",
          "Value": "861820004"
        },
        {
          "Label": "Pipeline Hygiene/Duplicate",
          "Value": "861820001"
        },
        {
          "Label": "Lost",
          "Value": "861820002"
        },
        {
          "Label": "Consolidated",
          "Value": "606820009"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagemicrosoftexecutivewithcustomercxo",
      "Type": "picklist",
      "DisplayName": "Engage Microsoft Executive with Customer CXO",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engageservices",
      "Type": "lookup",
      "DisplayName": "Engage Services",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_enrollmentdetails",
      "Type": "string",
      "DisplayName": "Enrollment Details",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_enrollmentnumber",
      "Type": "string",
      "DisplayName": "Enrollment Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_estcompletiondate",
      "Type": "datetime",
      "DisplayName": "Est. Completion Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_estrocarrivaldate",
      "Type": "datetime",
      "DisplayName": "Est. ROC Arrival Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_eststartdate",
      "Type": "datetime",
      "DisplayName": "Est. Start Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_evaluationplan",
      "Type": "boolean",
      "DisplayName": "Evaluation Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_executedigitalimmersion",
      "Type": "picklist",
      "DisplayName": "Execute Digital Immersion",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_finalconsumptionplan",
      "Type": "boolean",
      "DisplayName": "Final Consumption Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcomments",
      "Type": "memo",
      "DisplayName": "Forecast Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcomments_lastmodifiedby",
      "Type": "string",
      "DisplayName": "ForecastComments_LastModifiedBy",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcomments_lastmodifiedby_lookup",
      "Type": "lookup",
      "DisplayName": "Forecast Comments Modified By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcomments_lastmodifiedon",
      "Type": "string",
      "DisplayName": "ForecastComments_LastModifiedOn",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcomments_lastmodifiedon_datetime",
      "Type": "datetime",
      "DisplayName": "Forecast Comments Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcommentsjsonfield",
      "Type": "memo",
      "DisplayName": "ForecastCommentsJsonField",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_handovertocsmorpcsm",
      "Type": "boolean",
      "DisplayName": "Handover to CSM or P-CSM",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_healthchecktrackinginplace",
      "Type": "boolean",
      "DisplayName": "Health Check tracking in place",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_healthcomments",
      "Type": "memo",
      "DisplayName": "Engagement Next Steps",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_highlevelsolutiondesign",
      "Type": "boolean",
      "DisplayName": "High Level Solution Design",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_identifybusinessdecisionmakers",
      "Type": "picklist",
      "DisplayName": "Identify Business Decision Makers",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_identifybuyingteamstakeholdersbpf",
      "Type": "boolean",
      "DisplayName": "Identify buying team stakeholders",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_identifyproductlineitems",
      "Type": "boolean",
      "DisplayName": "Identify Line Items",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_initialbusinesscase",
      "Type": "boolean",
      "DisplayName": "Initial Business Case",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_initialstakeholderidentificationcomplete",
      "Type": "picklist",
      "DisplayName": "Initial stakeholder identification complete?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_initiatetechnicalcloseplan",
      "Type": "picklist",
      "DisplayName": "Initiate Technical Close Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_internalagreementcode",
      "Type": "boolean",
      "DisplayName": "Internal Agreement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_invoice",
      "Type": "string",
      "DisplayName": "Invoice #",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_landattachmotions",
      "Type": "picklist",
      "DisplayName": "Land Attach motions",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastshipmentdate",
      "Type": "datetime",
      "DisplayName": "Last Shipment Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_letterofengagement",
      "Type": "boolean",
      "DisplayName": "Letter of Engagement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not accepted",
          "Value": "0"
        },
        {
          "Label": "accepted",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_licensingcontact",
      "Type": "lookup",
      "DisplayName": "Commercial Executive",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_licensingprogram",
      "Type": "lookup",
      "DisplayName": "Licensing Program",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lssodmcomments",
      "Type": "memo",
      "DisplayName": "CE/ODM Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_microsoftazureiotcertifieddevice",
      "Type": "boolean",
      "DisplayName": "MS Azure IoT Cert. Device",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_migratedengagementcreatedby",
      "Type": "string",
      "DisplayName": "Migrated Engagement Created By",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonesflag",
      "Type": "string",
      "DisplayName": "Milestones Flag",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernactivedevicesnonrecurring",
      "Type": "decimal",
      "DisplayName": "Modern ActiveDevices NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernactivedevicesrecurring",
      "Type": "decimal",
      "DisplayName": "Modern ActiveDevices Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernconsumednonrecurring",
      "Type": "money",
      "DisplayName": "Modern Consumed NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernconsumednonrecurring_base",
      "Type": "money",
      "DisplayName": "Modern Consumed NonRecurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernconsumedrecurring",
      "Type": "money",
      "DisplayName": "Modern Consumed Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_modernconsumedrecurring_base",
      "Type": "money",
      "DisplayName": "Modern Consumed Recurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_navigatetotab",
      "Type": "string",
      "DisplayName": "NavigateToTab",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_newenrollmentnumber",
      "Type": "string",
      "DisplayName": "New Enrollment Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_newpcfeditgrid",
      "Type": "string",
      "DisplayName": "NewPCFEditGrid",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_nextsetofcustomerneedsidentified",
      "Type": "boolean",
      "DisplayName": "Next set of customer needs identified",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_nextsetofcustomerneedsidentify",
      "Type": "picklist",
      "DisplayName": "Next set of customer needs identified?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_nextsteps",
      "Type": "memo",
      "DisplayName": "Engagement Narrative",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_nooftenants",
      "Type": "integer",
      "DisplayName": "No. of Tenants",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_numberofdials",
      "Type": "integer",
      "DisplayName": "Number Of Dials",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_operationscontact",
      "Type": "lookup",
      "DisplayName": "Operations Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunityage",
      "Type": "integer",
      "DisplayName": "Opportunity Age",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunityclosedby",
      "Type": "lookup",
      "DisplayName": "OpportunityClosedBy",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunitycompletedandnextstepsidentify",
      "Type": "picklist",
      "DisplayName": "Exit Criteria: Oppty done, next steps identified?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunitycopytype",
      "Type": "picklist",
      "DisplayName": "OpportunityCopyType",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Copy Opportunity",
          "Value": "861980000"
        },
        {
          "Label": "Reorder Opportunity",
          "Value": "861980001"
        },
        {
          "Label": "Copy Opportunity Using Quick Create Form",
          "Value": "606820000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunitynumber",
      "Type": "string",
      "DisplayName": "Opportunity Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunityscore",
      "Type": "decimal",
      "DisplayName": "Opportunity Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunitysourcecode",
      "Type": "picklist",
      "DisplayName": "Source",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Account Planning",
          "Value": "861980002"
        },
        {
          "Label": "Artificial Intelligence",
          "Value": "861980010"
        },
        {
          "Label": "Azure Customer Portal",
          "Value": "861980019"
        },
        {
          "Label": "Bulk Excel Import",
          "Value": "861980016"
        },
        {
          "Label": "Compete",
          "Value": "861980008"
        },
        {
          "Label": "Customer Success",
          "Value": "606820000"
        },
        {
          "Label": "Daily Recommender",
          "Value": "861980014"
        },
        {
          "Label": "DMS - Auto PSC Create",
          "Value": "861980021"
        },
        {
          "Label": "DMS - User Request",
          "Value": "861980012"
        },
        {
          "Label": "Marketing",
          "Value": "861980000"
        },
        {
          "Label": "Marketplace",
          "Value": "861980009"
        },
        {
          "Label": "MSX CRM UI",
          "Value": "861980015"
        },
        {
          "Label": "LinkedIn Sales Navigator",
          "Value": "861980005"
        },
        {
          "Label": "MSX Recommendation",
          "Value": "861980013"
        },
        {
          "Label": "Others",
          "Value": "861980018"
        },
        {
          "Label": "Partner",
          "Value": "861980004"
        },
        {
          "Label": "Partner Referral",
          "Value": "861980020"
        },
        {
          "Label": "Portfolio Planning",
          "Value": "861980017"
        },
        {
          "Label": "Retail",
          "Value": "861980011"
        },
        {
          "Label": "Sales",
          "Value": "861980001"
        },
        {
          "Label": "SAM",
          "Value": "861980003"
        },
        {
          "Label": "STU NBF Planning",
          "Value": "861980007"
        },
        {
          "Label": "Territory Planning",
          "Value": "861980006"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunitytype",
      "Type": "multiselectpicklist",
      "DisplayName": "Opportunity Intent",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Billed",
          "Value": "606820000"
        },
        {
          "Label": "Consumption",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunityviolationid",
      "Type": "lookup",
      "DisplayName": "Opportunity Violation",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_originalpartnermodifiedby",
      "Type": "string",
      "DisplayName": "OriginalPartnerModifiedBy",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_originatingopportunity",
      "Type": "lookup",
      "DisplayName": "Originating Opportunity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_othercompetitor",
      "Type": "string",
      "DisplayName": "Other Competitor",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_outcomesmetandbaselinemetricsinplace",
      "Type": "picklist",
      "DisplayName": "Exit Criteria: Outcomes met and metrics in place",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_parentopportunityid",
      "Type": "lookup",
      "DisplayName": "Parent Opportunity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partneraccountid",
      "Type": "lookup",
      "DisplayName": "Partner Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnercenterreferralengagementid",
      "Type": "string",
      "DisplayName": "Partner Referral",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnercontact",
      "Type": "lookup",
      "DisplayName": "PartnerContact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnercosell",
      "Type": "picklist",
      "DisplayName": "Partner co-sell",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "861980000"
        },
        {
          "Label": "Yes",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnercoseller",
      "Type": "lookup",
      "DisplayName": "Partner Coseller",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnerfundingcode",
      "Type": "boolean",
      "DisplayName": "Partner Funding",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnermotion",
      "Type": "picklist",
      "DisplayName": "Partner Motion",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Applicable",
          "Value": "861980000"
        },
        {
          "Label": "Sell through- C2PC",
          "Value": "861980001"
        },
        {
          "Label": "Sell through- Partner Center",
          "Value": "861980002"
        },
        {
          "Label": "Sell through- Partner Connect",
          "Value": "861980003"
        },
        {
          "Label": "Sell with- Partner Connect",
          "Value": "861980004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnersidentifiedandengaged",
      "Type": "picklist",
      "DisplayName": "Partner(s) identified and engaged?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnersidentifiedandselected",
      "Type": "boolean",
      "DisplayName": "Partner(s) identified and selected",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnersolutionrecommendation",
      "Type": "integer",
      "DisplayName": "Partner Solution Recommendation",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pcfeditablegrid",
      "Type": "string",
      "DisplayName": "pcfeditablegrid",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pilotsolution",
      "Type": "picklist",
      "DisplayName": "Pilot Solution",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_predictiveindicator",
      "Type": "string",
      "DisplayName": "Predictive Indicator",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_preferredazureregion",
      "Type": "picklist",
      "DisplayName": "Preferred Azure Region",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Australia Central 1 - Canberra 1",
          "Value": "861980011"
        },
        {
          "Label": "Australia Central 2 - Canberra 2",
          "Value": "861980012"
        },
        {
          "Label": "Australia East - Sydney",
          "Value": "861980047"
        },
        {
          "Label": "Australia Southeast - Melbourne",
          "Value": "861980032"
        },
        {
          "Label": "Austria East - Vienna (future)",
          "Value": "861980063"
        },
        {
          "Label": "Belgium Central - Brussels (future)",
          "Value": "861980068"
        },
        {
          "Label": "Brazil South - Campinas",
          "Value": "861980010"
        },
        {
          "Label": "Brazil Southeast - Rio",
          "Value": "861980065"
        },
        {
          "Label": "Canada Central - Toronto",
          "Value": "861980049"
        },
        {
          "Label": "Canada East - Quebec",
          "Value": "861980039"
        },
        {
          "Label": "Central India - Pune",
          "Value": "861980038"
        },
        {
          "Label": "Central US - Des Moines",
          "Value": "861980018"
        },
        {
          "Label": "Chile Central - Santiago (future)",
          "Value": "606820002"
        },
        {
          "Label": "China East 2 - Shanghai 2",
          "Value": "861980045"
        },
        {
          "Label": "China East 3 (future) - Shanghai 3",
          "Value": "861980061"
        },
        {
          "Label": "China North 2 - Beijing 2",
          "Value": "861980004"
        },
        {
          "Label": "China North 3 (future) - Beijing 3",
          "Value": "861980062"
        },
        {
          "Label": "Denmark East - Copenhagen (future)",
          "Value": "861980074"
        },
        {
          "Label": "East Asia - Hong Kong",
          "Value": "861980026"
        },
        {
          "Label": "East US - Blue Ridge",
          "Value": "861980005"
        },
        {
          "Label": "East US 2 - Boydton",
          "Value": "861980006"
        },
        {
          "Label": "France Central - Paris",
          "Value": "861980035"
        },
        {
          "Label": "Germany West Central - Frankfurt",
          "Value": "861980052"
        },
        {
          "Label": "Indonesia Central - Jakarta (future)",
          "Value": "861980075"
        },
        {
          "Label": "Israel Central (future)",
          "Value": "861980059"
        },
        {
          "Label": "Italy North (future) - Milan",
          "Value": "861980058"
        },
        {
          "Label": "Japan East - Tokyo",
          "Value": "861980048"
        },
        {
          "Label": "Japan West - Osaka",
          "Value": "861980034"
        },
        {
          "Label": "Korea Central - Seoul",
          "Value": "861980043"
        },
        {
          "Label": "Korea South - Busan",
          "Value": "861980009"
        },
        {
          "Label": "Malaysia West (Micro) - Kuala Lumpur (future)",
          "Value": "861980072"
        },
        {
          "Label": "Mexico Central - Santiago de Querétaro (future)",
          "Value": "606820001"
        },
        {
          "Label": "New Zealand - Auckland (future)",
          "Value": "861980066"
        },
        {
          "Label": "New Zealand North (Micro) - Auckland (future)",
          "Value": "861980073"
        },
        {
          "Label": "North Central US - Chicago",
          "Value": "861980017"
        },
        {
          "Label": "North Europe - Dublin",
          "Value": "861980022"
        },
        {
          "Label": "Norway East - Oslo",
          "Value": "861980054"
        },
        {
          "Label": "Poland Central - Warsaw (future)",
          "Value": "606820003"
        },
        {
          "Label": "Qatar Central - Doha",
          "Value": "861980060"
        },
        {
          "Label": "South Africa North - Johannesburg",
          "Value": "861980027"
        },
        {
          "Label": "South Central US - San Antonio",
          "Value": "861980041"
        },
        {
          "Label": "South India - Chennai",
          "Value": "861980015"
        },
        {
          "Label": "Southeast Asia - Singapore",
          "Value": "861980046"
        },
        {
          "Label": "Spain Central (future) - Madrid",
          "Value": "861980057"
        },
        {
          "Label": "Sweden Central - Gavle",
          "Value": "606820000"
        },
        {
          "Label": "Switzerland North - Zurich",
          "Value": "861980056"
        },
        {
          "Label": "Switzerland West - Geneva",
          "Value": "861980025"
        },
        {
          "Label": "Taiwan North - Taipei (future)",
          "Value": "861980070"
        },
        {
          "Label": "Taiwan Northwest - Taoyuan (future)",
          "Value": "861980071"
        },
        {
          "Label": "UAE Central - Abu Dhabi",
          "Value": "861980067"
        },
        {
          "Label": "UAE North - Dubai",
          "Value": "861980021"
        },
        {
          "Label": "UK North - Durham GS",
          "Value": "861980023"
        },
        {
          "Label": "UK South - London",
          "Value": "861980029"
        },
        {
          "Label": "UK West - Cardiff",
          "Value": "861980014"
        },
        {
          "Label": "USDoD Central - Des Moines DoD",
          "Value": "861980019"
        },
        {
          "Label": "USDoD East - Boydton DoD",
          "Value": "861980007"
        },
        {
          "Label": "USGov Arizona - Phoenix Gov",
          "Value": "861980037"
        },
        {
          "Label": "USGov Iowa - Des Moines Gov",
          "Value": "861980020"
        },
        {
          "Label": "USGov Texas - San Antonio Gov",
          "Value": "861980042"
        },
        {
          "Label": "USGov Virginia - Boydton Gov",
          "Value": "861980008"
        },
        {
          "Label": "West Central US - Cheyenne",
          "Value": "861980016"
        },
        {
          "Label": "West Europe - Amsterdam",
          "Value": "861980001"
        },
        {
          "Label": "West India - Mumbai",
          "Value": "861980033"
        },
        {
          "Label": "West US - Bay",
          "Value": "861980002"
        },
        {
          "Label": "West US 2 - Quincy",
          "Value": "861980040"
        },
        {
          "Label": "West US 3 - Phoenix",
          "Value": "861980036"
        },
        {
          "Label": "None",
          "Value": "861980076"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_prelimconsumptionplan",
      "Type": "boolean",
      "DisplayName": "Prelim Consumption Plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_premiersunsetbitmap",
      "Type": "memo",
      "DisplayName": "PremierSunSetBitMap",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_premiersupportproductbackendfield",
      "Type": "string",
      "DisplayName": "Premier Support Product Backend Field",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_presentbusinesscase",
      "Type": "picklist",
      "DisplayName": "Present Business Case",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Business Case Created",
          "Value": "861980000"
        },
        {
          "Label": "Deal Options Presented",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_presentcustomerfinalvaluecaseproposalbpf",
      "Type": "boolean",
      "DisplayName": "Present customer with final value case proposal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_presentdealoptions",
      "Type": "picklist",
      "DisplayName": "Present Deal Options",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pricepointdetails",
      "Type": "memo",
      "DisplayName": "Price Point details",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_primarycompetitor",
      "Type": "lookup",
      "DisplayName": "Primary Competitor",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_primarypriority",
      "Type": "lookup",
      "DisplayName": "Customer Priority",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_prioritycode",
      "Type": "picklist",
      "DisplayName": "Priority",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Cloud Platform",
          "Value": "861980002"
        },
        {
          "Label": "Cloud Productivity",
          "Value": "861980000"
        },
        {
          "Label": "Data Platform",
          "Value": "861980003"
        },
        {
          "Label": "Mobility & Devices",
          "Value": "861980004"
        },
        {
          "Label": "One Microsoft",
          "Value": "861980005"
        },
        {
          "Label": "Sales Productivity",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_productflags",
      "Type": "string",
      "DisplayName": "Product Flags",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_productsku",
      "Type": "string",
      "DisplayName": "Product SKU",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_proofofconceptrequirements",
      "Type": "boolean",
      "DisplayName": "Proof of Concept requirements",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pullforwarddate",
      "Type": "datetime",
      "DisplayName": "Pull Forward Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_purchasearrangementcode",
      "Type": "boolean",
      "DisplayName": "Purchase Arrangement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qualifiedopportunity",
      "Type": "picklist",
      "DisplayName": "Exit Criteria: Qualified Opportunity",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rapidresponse",
      "Type": "boolean",
      "DisplayName": "Rapid Response",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_recommendationcode",
      "Type": "picklist",
      "DisplayName": "Recommendation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Committed",
          "Value": "861980000"
        },
        {
          "Label": "Committed At Risk",
          "Value": "861980001"
        },
        {
          "Label": "Upside",
          "Value": "861980002"
        },
        {
          "Label": "Uncommitted",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_registrationdate",
      "Type": "datetime",
      "DisplayName": "Registration Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_requestatrial",
      "Type": "picklist",
      "DisplayName": "Request a trial",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_resourcesalignagainstdeliveryplan",
      "Type": "picklist",
      "DisplayName": "Resources aligned against delivery plan?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_resourcesalignedagainstdeliveryplan",
      "Type": "boolean",
      "DisplayName": "Resources aligned against delivery plan",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_responserequestedby",
      "Type": "datetime",
      "DisplayName": "Response Requested By",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_riskscore",
      "Type": "double",
      "DisplayName": "Risk Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rollupestrevenue",
      "Type": "money",
      "DisplayName": "Rollup Est. Billed Revenue",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rollupestrevenue_base",
      "Type": "money",
      "DisplayName": "Rollup Est. Billed Revenue (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rollupestrevenue_date",
      "Type": "datetime",
      "DisplayName": "Rollup Est. Revenue (Last Updated On)",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rollupestrevenue_state",
      "Type": "integer",
      "DisplayName": "Rollup Est. Revenue (State)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rvtstatus",
      "Type": "picklist",
      "DisplayName": "RVT Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Data Hygiene Ready",
          "Value": "606820001"
        },
        {
          "Label": "In Queue",
          "Value": "606820000"
        },
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "ISR Action",
          "Value": "861980003"
        },
        {
          "Label": "KICKBACK",
          "Value": "861980002"
        },
        {
          "Label": "NOTVALID",
          "Value": "861980008"
        },
        {
          "Label": "NOTVALID-FIN",
          "Value": "861980009"
        },
        {
          "Label": "NOTVALID-Influence",
          "Value": "861980010"
        },
        {
          "Label": "RECHECK",
          "Value": "861980001"
        },
        {
          "Label": "REVVALID",
          "Value": "861980006"
        },
        {
          "Label": "RVTADDON",
          "Value": "861980007"
        },
        {
          "Label": "RVTier2",
          "Value": "861980004"
        },
        {
          "Label": "RVTier3",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rvtvalidationready",
      "Type": "boolean",
      "DisplayName": "RVT Validation Ready",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesguidancecomponentpcffield",
      "Type": "string",
      "DisplayName": "Sales Guidance Component PCF field",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesplay",
      "Type": "picklist",
      "DisplayName": "Solution Play",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Accelerate Developer Productivity",
          "Value": "861980006"
        },
        {
          "Label": "Accelerate Innovation with Low Code",
          "Value": "861980068"
        },
        {
          "Label": "Accelerate Revenue Generation",
          "Value": "861980020"
        },
        {
          "Label": "Advanced Networking",
          "Value": "861980000"
        },
        {
          "Label": "Automate Business Processes",
          "Value": "861980051"
        },
        {
          "Label": "Build a Real Time and Sustainable Supply Chain",
          "Value": "861980079"
        },
        {
          "Label": "Build a Strong Foundation with Unified",
          "Value": "861980087"
        },
        {
          "Label": "Build a Sustainable IT Infrastructure",
          "Value": "861980083"
        },
        {
          "Label": "Build and Modernize AI Apps",
          "Value": "861980037"
        },
        {
          "Label": "Build Your Games in the Cloud with Azure",
          "Value": "861980008"
        },
        {
          "Label": "Cloud Endpoints",
          "Value": "861980056"
        },
        {
          "Label": "Collaborative Apps",
          "Value": "861980060"
        },
        {
          "Label": "Combat Financial Crime",
          "Value": "861980071"
        },
        {
          "Label": "Connected Commerce",
          "Value": "861980052"
        },
        {
          "Label": "Converged Communications",
          "Value": "861980055"
        },
        {
          "Label": "Converged Communications Teams Rooms",
          "Value": "861980034"
        },
        {
          "Label": "Create Sustainable Value Chains",
          "Value": "861980084"
        },
        {
          "Label": "Data Governance",
          "Value": "861980054"
        },
        {
          "Label": "Data Security",
          "Value": "861980027"
        },
        {
          "Label": "Deliver Differentiated Customer Experiences",
          "Value": "861980072"
        },
        {
          "Label": "Developer Engagement",
          "Value": "861980001"
        },
        {
          "Label": "Discover Business Insights",
          "Value": "861980053"
        },
        {
          "Label": "Elevate the Shopping Experience",
          "Value": "861980080"
        },
        {
          "Label": "Employee Experience",
          "Value": "861980029"
        },
        {
          "Label": "Empower Employees Through Teamwork",
          "Value": "861980073"
        },
        {
          "Label": "Empower Health Team Collaboration",
          "Value": "861980075"
        },
        {
          "Label": "Empower Your Store Associate",
          "Value": "861980081"
        },
        {
          "Label": "Enable a Resilient and Sustainable Supply Chain",
          "Value": "861980021"
        },
        {
          "Label": "Enable Customer Success",
          "Value": "861980058"
        },
        {
          "Label": "Enable Unified Data Governance",
          "Value": "861980066"
        },
        {
          "Label": "Enhance Clinician Experiences",
          "Value": "861980076"
        },
        {
          "Label": "Enhance Patient Engagement",
          "Value": "861980077"
        },
        {
          "Label": "Ensure coverage for your key solutions",
          "Value": "861980088"
        },
        {
          "Label": "Frontline Workers",
          "Value": "861980028"
        },
        {
          "Label": "Improve Clinical and Operational Insights",
          "Value": "861980078"
        },
        {
          "Label": "Innovate Across Hybrid and Edge with Azure Arc and IoT",
          "Value": "861980010"
        },
        {
          "Label": "Innovate and Scale with Cloud Native Apps",
          "Value": "861980004"
        },
        {
          "Label": "Innovate with HPC, AI Infrastructure",
          "Value": "861980009"
        },
        {
          "Label": "IoT",
          "Value": "861980011"
        },
        {
          "Label": "Manage Risk Across the Organization",
          "Value": "861980074"
        },
        {
          "Label": "Maximize the Value of Your Data",
          "Value": "861980082"
        },
        {
          "Label": "Microsoft Azure Consumption Commitment (MACC)",
          "Value": "861980091"
        },
        {
          "Label": "Migrate and Modernize Your Data Estate",
          "Value": "861980018"
        },
        {
          "Label": "Migrate and Secure Windows Server and SQL Server",
          "Value": "861980067"
        },
        {
          "Label": "Migrate Enterprise Apps",
          "Value": "861980069"
        },
        {
          "Label": "Migrate Linux and OSS DBs",
          "Value": "861980012"
        },
        {
          "Label": "Migrate SAP",
          "Value": "861980016"
        },
        {
          "Label": "Migrate to Azure VMware Solution",
          "Value": "861980002"
        },
        {
          "Label": "Mixed Reality",
          "Value": "861980041"
        },
        {
          "Label": "Modernize .NET and Java apps with PaaS, low code and managed databases",
          "Value": "861980015"
        },
        {
          "Label": "Modernize Service",
          "Value": "861980024"
        },
        {
          "Label": "Modernize VDI to Azure Virtual Desktop",
          "Value": "861980019"
        },
        {
          "Label": "Modernize with Surface",
          "Value": "861980032"
        },
        {
          "Label": "Modernize your digital estate with Unified Enterprise",
          "Value": "861980089"
        },
        {
          "Label": "Multi Cloud Security",
          "Value": "861980057"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980040"
        },
        {
          "Label": "Optimize Finance and Supply Chain",
          "Value": "861980026"
        },
        {
          "Label": "Personalize Customer Experience",
          "Value": "861980025"
        },
        {
          "Label": "Power Business Decisions with Cloud Scale Analytics",
          "Value": "861980038"
        },
        {
          "Label": "Protect Your Data and Ensure Business Resiliency with BCDR",
          "Value": "861980070"
        },
        {
          "Label": "Rapidly Build Apps",
          "Value": "861980022"
        },
        {
          "Label": "Reduce Environmental Impact of Operations",
          "Value": "861980085"
        },
        {
          "Label": "Secure Identities and Access",
          "Value": "861980042"
        },
        {
          "Label": "Secure Productivity",
          "Value": "861980030"
        },
        {
          "Label": "Teams Platform",
          "Value": "861980035"
        },
        {
          "Label": "Threat Protection with XDR and SIEM",
          "Value": "861980031"
        },
        {
          "Label": "Unify Data Intelligence",
          "Value": "861980086"
        },
        {
          "Label": "Accelerate Innovation with Integration Services",
          "Value": "606820011"
        },
        {
          "Label": "Drive Cloud Success with Enhanced Solutions",
          "Value": "606820009"
        },
        {
          "Label": "Migrate Linux Estate",
          "Value": "606820002"
        },
        {
          "Label": "Migrate Oracle",
          "Value": "606820003"
        },
        {
          "Label": "Modern Sec Ops",
          "Value": "606820006"
        },
        {
          "Label": "Secure Identity and Takeout Okta and Ping",
          "Value": "606820007"
        },
        {
          "Label": "SMB Scale Business Operations",
          "Value": "606820000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesplayflags",
      "Type": "string",
      "DisplayName": "salesplayflags",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesplaysidentifiedachievecustoutcomes",
      "Type": "boolean",
      "DisplayName": "Sales Plays identified to achieve customer outcome",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesstagecode",
      "Type": "picklist",
      "DisplayName": "Sales Stage (Deprecated)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "1-Select Lead",
          "Value": "0"
        },
        {
          "Label": "2-Qualify Lead",
          "Value": "1"
        },
        {
          "Label": "3-Listen & Consult",
          "Value": "2"
        },
        {
          "Label": "4-Inspire & Design",
          "Value": "3"
        },
        {
          "Label": "5-Empower & Achieve",
          "Value": "4"
        },
        {
          "Label": "6-Realize Value",
          "Value": "5"
        },
        {
          "Label": "7-Manage & Optimize",
          "Value": "10"
        },
        {
          "Label": "1-Identify Account Segment",
          "Value": "6"
        },
        {
          "Label": "2-Understand Your Customer",
          "Value": "7"
        },
        {
          "Label": "3-Execute The Customer Meeting",
          "Value": "8"
        },
        {
          "Label": "4-Capture Next Steps",
          "Value": "9"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityactivedevicesnonrecurring",
      "Type": "decimal",
      "DisplayName": "Security ActiveDevices NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityactivedevicesrecurring",
      "Type": "decimal",
      "DisplayName": "Security ActiveDevices Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityconsumednonrecurring",
      "Type": "money",
      "DisplayName": "Security Consumed NonRecurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityconsumednonrecurring_base",
      "Type": "money",
      "DisplayName": "Security Consumed NonRecurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityconsumedrecurring",
      "Type": "money",
      "DisplayName": "Security Consumed Recurring",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_securityconsumedrecurring_base",
      "Type": "money",
      "DisplayName": "Security Consumed Recurring (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_selectsalesplay",
      "Type": "picklist",
      "DisplayName": "Select Checklist",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Biz Apps (Catalyst)",
          "Value": "606820000"
        },
        {
          "Label": "Default",
          "Value": "861980000"
        },
        {
          "Label": "M365 E5 Compliance Upsell",
          "Value": "861980002"
        },
        {
          "Label": "M365 E5 Security Upsell",
          "Value": "861980001"
        },
        {
          "Label": "Teams Calling",
          "Value": "394380001"
        },
        {
          "Label": "Teams Meetings",
          "Value": "394380000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sendproactivecustomerletter",
      "Type": "picklist",
      "DisplayName": "Send Proactive Customer Letter",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sendtopartner",
      "Type": "boolean",
      "DisplayName": "SendToPartner",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sendtopartnerpriceenabledflag",
      "Type": "string",
      "DisplayName": "SendToPartnerPriceEnabledFlag",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sharebompricing",
      "Type": "picklist",
      "DisplayName": "Share BOM/Pricing",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "861980000"
        },
        {
          "Label": "Completed",
          "Value": "861980001"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_showcaseplatformvalue",
      "Type": "picklist",
      "DisplayName": "Showcase platform value",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "606820000"
        },
        {
          "Label": "Completed",
          "Value": "606820001"
        },
        {
          "Label": "Not Applicable",
          "Value": "606820002"
        },
        {
          "Label": "Customer Opt-out",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionarea",
      "Type": "picklist",
      "DisplayName": "Solution Area",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Business Applications",
          "Value": "394380001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980000"
        },
        {
          "Label": "Infrastructure",
          "Value": "394380000"
        },
        {
          "Label": "Modern Work",
          "Value": "394380002"
        },
        {
          "Label": "Digital and Application Innovation",
          "Value": "861980004"
        },
        {
          "Label": "Financial Services",
          "Value": "861980007"
        },
        {
          "Label": "Healthcare",
          "Value": "861980008"
        },
        {
          "Label": "Retail",
          "Value": "861980009"
        },
        {
          "Label": "Security",
          "Value": "861980005"
        },
        {
          "Label": "Sustainability",
          "Value": "861980010"
        },
        {
          "Label": "Unified Support",
          "Value": "861980011"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionassessmentvalidatedwithcustomer",
      "Type": "boolean",
      "DisplayName": "Solution Assessment validated with customer",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutiondeliveredagainstagreedoutcome",
      "Type": "picklist",
      "DisplayName": "Solution delivered against agreed outcomes?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutiondeliveredagainstagreedoutcomes",
      "Type": "boolean",
      "DisplayName": "Solution delivered against agreed outcomes",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutiondesignindate",
      "Type": "datetime",
      "DisplayName": "Solution Design In Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutiondesignvalidatedwithcustomer",
      "Type": "picklist",
      "DisplayName": "Customer Success Plan created?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionplayotherreason",
      "Type": "picklist",
      "DisplayName": "Solution Play Other Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Industry Priority Solution with ISV",
          "Value": "861980000"
        },
        {
          "Label": "Product or Workload not covered",
          "Value": "861980001"
        },
        {
          "Label": "Customer need, not available (Emerging)",
          "Value": "861980002"
        },
        {
          "Label": "Renewals",
          "Value": "861980003"
        },
        {
          "Label": "MACC",
          "Value": "861980004"
        },
        {
          "Label": "Other",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionplaysconfirmeddealteamorchest",
      "Type": "picklist",
      "DisplayName": "Solution Plays confirmed & team orchestrated?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionstartdate",
      "Type": "datetime",
      "DisplayName": "Solution Start Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sourcesubtypecode",
      "Type": "picklist",
      "DisplayName": "Source Sub-Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Annuity Renewal",
          "Value": "861980114"
        },
        {
          "Label": "Automation",
          "Value": "861981000"
        },
        {
          "Label": "Azure",
          "Value": "861980000"
        },
        {
          "Label": "Azure Marketplace",
          "Value": "861981143"
        },
        {
          "Label": "Bing",
          "Value": "606820000"
        },
        {
          "Label": "Biz Apps CSM",
          "Value": "100000000"
        },
        {
          "Label": "Campus & School Agreement",
          "Value": "861980001"
        },
        {
          "Label": "CRM",
          "Value": "861980003"
        },
        {
          "Label": "CSS Chat",
          "Value": "861981145"
        },
        {
          "Label": "CSS Outbound",
          "Value": "861981148"
        },
        {
          "Label": "CSS Phone",
          "Value": "861981144"
        },
        {
          "Label": "DR:Business Rules",
          "Value": "861980045"
        },
        {
          "Label": "DR:CourtesyCall",
          "Value": "861981234"
        },
        {
          "Label": "EA Renewal",
          "Value": "861980006"
        },
        {
          "Label": "EA TUP",
          "Value": "861980007"
        },
        {
          "Label": "Enterprise Sales Campaign",
          "Value": "100000002"
        },
        {
          "Label": "GDC-Pilot",
          "Value": "100000001"
        },
        {
          "Label": "Inbound",
          "Value": "861981141"
        },
        {
          "Label": "MQL",
          "Value": "861981086"
        },
        {
          "Label": "MQL Accelerated",
          "Value": "861981236"
        },
        {
          "Label": "O365",
          "Value": "861980012"
        },
        {
          "Label": "OEM IOT",
          "Value": "861981146"
        },
        {
          "Label": "Office",
          "Value": "861980013"
        },
        {
          "Label": "Prospect",
          "Value": "861981101"
        },
        {
          "Label": "SAM",
          "Value": "861980018"
        },
        {
          "Label": "SMB & Edu Retail",
          "Value": "861981240"
        },
        {
          "Label": "SMB Vendor Tele",
          "Value": "861981238"
        },
        {
          "Label": "SPE",
          "Value": "861981103"
        },
        {
          "Label": "VT - Ent Educ",
          "Value": "861981239"
        },
        {
          "Label": "Windows Intune",
          "Value": "861980026"
        },
        {
          "Label": "Artificial Intelligence",
          "Value": "606820002"
        },
        {
          "Label": "CSS Inbound",
          "Value": "861980002"
        },
        {
          "Label": "Prospecting Recommendation",
          "Value": "606820001"
        },
        {
          "Label": "Solution Assessment",
          "Value": "606820003"
        },
        {
          "Label": "Concierge",
          "Value": "384200001"
        },
        {
          "Label": "Support for Partners",
          "Value": "384200002"
        },
        {
          "Label": "DSE Signal Derived",
          "Value": "606820004"
        },
        {
          "Label": "DSE Proactive Hunting",
          "Value": "606820005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_stakeholderidentificationandmapping",
      "Type": "boolean",
      "DisplayName": "Stakeholder identification and mapping verified",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_subscriptionid",
      "Type": "string",
      "DisplayName": "Subscription Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_surveyresponserejectioncounts",
      "Type": "integer",
      "DisplayName": "SurveyResponseRejectionCounts",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_tags",
      "Type": "memo",
      "DisplayName": "Tags",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_technicalbusinessproofrequirementside",
      "Type": "picklist",
      "DisplayName": "Technical/Business Proof requirements identified?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_technicalcapability",
      "Type": "picklist",
      "DisplayName": "Technical Capability",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Advanced Networking",
          "Value": "861980000"
        },
        {
          "Label": "Advisory Services",
          "Value": "861980055"
        },
        {
          "Label": "Appliance Migration to Azure Synapse",
          "Value": "861980073"
        },
        {
          "Label": "Azure AI and ML",
          "Value": "861980002"
        },
        {
          "Label": "Azure Arc",
          "Value": "861980074"
        },
        {
          "Label": "Azure Stack Edge",
          "Value": "861980075"
        },
        {
          "Label": "Azure Stack Hub",
          "Value": "861980076"
        },
        {
          "Label": "Azure VMWare Solutions",
          "Value": "861980005"
        },
        {
          "Label": "Calling",
          "Value": "861980068"
        },
        {
          "Label": "Citrix Cloud on Azure",
          "Value": "861980029"
        },
        {
          "Label": "Cloud Adoption Framework",
          "Value": "861980011"
        },
        {
          "Label": "Cloud Scale Analytics",
          "Value": "861980077"
        },
        {
          "Label": "Cloud Security",
          "Value": "861980063"
        },
        {
          "Label": "Cloud to Cloud Migration",
          "Value": "861980008"
        },
        {
          "Label": "Commerce",
          "Value": "861980034"
        },
        {
          "Label": "Custom Solutions",
          "Value": "861980071"
        },
        {
          "Label": "Customer Insights",
          "Value": "861980050"
        },
        {
          "Label": "Customer Service",
          "Value": "861980046"
        },
        {
          "Label": "Device Deployment & Management",
          "Value": "861980060"
        },
        {
          "Label": "DevOps with GitHub",
          "Value": "861980009"
        },
        {
          "Label": "Field Service",
          "Value": "861980047"
        },
        {
          "Label": "Finance",
          "Value": "861980051"
        },
        {
          "Label": "Firstline Workers",
          "Value": "861980057"
        },
        {
          "Label": "Forms Pro",
          "Value": "861980078"
        },
        {
          "Label": "Fraud Protection",
          "Value": "861980052"
        },
        {
          "Label": "Gaming",
          "Value": "861980014"
        },
        {
          "Label": "High Performance Compute",
          "Value": "861980015"
        },
        {
          "Label": "HR",
          "Value": "861980035"
        },
        {
          "Label": "Identity & Access Management",
          "Value": "861980059"
        },
        {
          "Label": "Information Protection & Governance",
          "Value": "861980054"
        },
        {
          "Label": "Insider Risk",
          "Value": "861980056"
        },
        {
          "Label": "IoT",
          "Value": "861980018"
        },
        {
          "Label": "Knowledge & Insights",
          "Value": "861980058"
        },
        {
          "Label": "Linux & OSS DB Migration to Azure",
          "Value": "861980019"
        },
        {
          "Label": "Marketing",
          "Value": "861980032"
        },
        {
          "Label": "Meeting Rooms",
          "Value": "861980067"
        },
        {
          "Label": "Meetings",
          "Value": "861980086"
        },
        {
          "Label": "Migration On-Prem to Manage Financial Risk",
          "Value": "861980079"
        },
        {
          "Label": "Migration On-Prem to Supply Chain Management",
          "Value": "861980080"
        },
        {
          "Label": "Mixed Reality",
          "Value": "861980022"
        },
        {
          "Label": "Mobile Device Management",
          "Value": "861980061"
        },
        {
          "Label": "Modernize .NET Apps with App Service, Azure SQL DB",
          "Value": "861980023"
        },
        {
          "Label": "Modernize/New Cloud Native Apps with AKS and Azure Cosmos/Postgres DB",
          "Value": "861980007"
        },
        {
          "Label": "New Analytics with Synapse & PowerBI",
          "Value": "861980085"
        },
        {
          "Label": "OSS DB Migration to Azure OSS DB",
          "Value": "861980020"
        },
        {
          "Label": "Power Apps",
          "Value": "861980041"
        },
        {
          "Label": "Power Automate",
          "Value": "861980081"
        },
        {
          "Label": "Power BI",
          "Value": "861980049"
        },
        {
          "Label": "Power Platform for Teams",
          "Value": "861980070"
        },
        {
          "Label": "Power Virtual Agents",
          "Value": "861980082"
        },
        {
          "Label": "Project Operations",
          "Value": "861980083"
        },
        {
          "Label": "Sales",
          "Value": "861980031"
        },
        {
          "Label": "SAP on Azure",
          "Value": "861980024"
        },
        {
          "Label": "SQL Server Migration to Azure SQL MI",
          "Value": "861980027"
        },
        {
          "Label": "Supply Chain Management",
          "Value": "861980033"
        },
        {
          "Label": "Surface & Partner Devices",
          "Value": "861980064"
        },
        {
          "Label": "Teams Store Apps",
          "Value": "861980069"
        },
        {
          "Label": "Teamwork Deployment",
          "Value": "861980065"
        },
        {
          "Label": "Threat Protection",
          "Value": "861980062"
        },
        {
          "Label": "VMWare Horizon Cloud Service for Azure",
          "Value": "861980030"
        },
        {
          "Label": "Well Architected",
          "Value": "861980072"
        },
        {
          "Label": "Windows & SQL Server Migration to Azure",
          "Value": "861980026"
        },
        {
          "Label": "Workplace Analytics",
          "Value": "861980084"
        },
        {
          "Label": "WVD Native",
          "Value": "861980028"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_technicalprototypingcomplete",
      "Type": "picklist",
      "DisplayName": "Technical prototyping complete?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "606820000"
        },
        {
          "Label": "No",
          "Value": "606820001"
        },
        {
          "Label": "NA",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_tenantid",
      "Type": "string",
      "DisplayName": "Tenant Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transactiontype",
      "Type": "picklist",
      "DisplayName": "Transaction Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "DSS-RVT",
          "Value": "606820000"
        },
        {
          "Label": "Agreement",
          "Value": "861980000"
        },
        {
          "Label": "Amendment",
          "Value": "861980002"
        },
        {
          "Label": "DemandResponse-RVT",
          "Value": "861980003"
        },
        {
          "Label": "Order",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transitionrejectedby",
      "Type": "lookup",
      "DisplayName": "Rejected By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transitionrejectedon",
      "Type": "datetime",
      "DisplayName": "Rejected On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transitionrejectioncomments",
      "Type": "memo",
      "DisplayName": "Rejection Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transitionrejectionreason",
      "Type": "picklist",
      "DisplayName": "Rejection Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Qualified, missing BANT",
          "Value": "861980000"
        },
        {
          "Label": "I am busy, Current Owner to take it forward",
          "Value": "861980001"
        },
        {
          "Label": "Other",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_transitiontocsupartner",
      "Type": "picklist",
      "DisplayName": "Transition to CSU/Partner",
      "Format": null,
      "OptionSet": [
        {
          "Label": "CSU",
          "Value": "861980000"
        },
        {
          "Label": "Partner",
          "Value": "861980001"
        },
        {
          "Label": "MCS",
          "Value": "861980002"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980003"
        },
        {
          "Label": "Customer Opt-Out",
          "Value": "861980004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_updatecosellmodified",
      "Type": "datetime",
      "DisplayName": "updatecosellmodified",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "name",
      "Type": "string",
      "DisplayName": "Topic",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "need",
      "Type": "picklist",
      "DisplayName": "Need Code",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Must have",
          "Value": "0"
        },
        {
          "Label": "Should have",
          "Value": "1"
        },
        {
          "Label": "Good to have",
          "Value": "2"
        },
        {
          "Label": "No need",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "onholdtime",
      "Type": "integer",
      "DisplayName": "On Hold Time (Minutes)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "opportunityid",
      "Type": "uniqueidentifier",
      "DisplayName": "Opportunity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "opportunityratingcode",
      "Type": "picklist",
      "DisplayName": "Rating",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Hot",
          "Value": "1"
        },
        {
          "Label": "Warm",
          "Value": "2"
        },
        {
          "Label": "Cold",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "originatingleadid",
      "Type": "lookup",
      "DisplayName": "Originating Lead",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "overriddencreatedon",
      "Type": "datetime",
      "DisplayName": "Record Created On",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "ownerid",
      "Type": "owner",
      "DisplayName": "Owner",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningbusinessunit",
      "Type": "lookup",
      "DisplayName": "Owning Business Unit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningteam",
      "Type": "lookup",
      "DisplayName": "Owning Team",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owninguser",
      "Type": "lookup",
      "DisplayName": "Owning User",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentaccountid",
      "Type": "lookup",
      "DisplayName": "Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentcontactid",
      "Type": "lookup",
      "DisplayName": "Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "participatesinworkflow",
      "Type": "boolean",
      "DisplayName": "Participates in Workflow",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "presentfinalproposal",
      "Type": "boolean",
      "DisplayName": "Present Final Proposal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "presentproposal",
      "Type": "boolean",
      "DisplayName": "Presented Proposal",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "pricelevelid",
      "Type": "lookup",
      "DisplayName": "Price List",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "pricingerrorcode",
      "Type": "picklist",
      "DisplayName": "Pricing Error ",
      "Format": null,
      "OptionSet": [
        {
          "Label": "None",
          "Value": "0"
        },
        {
          "Label": "Detail Error",
          "Value": "1"
        },
        {
          "Label": "Missing Price Level",
          "Value": "2"
        },
        {
          "Label": "Inactive Price Level",
          "Value": "3"
        },
        {
          "Label": "Missing Quantity",
          "Value": "4"
        },
        {
          "Label": "Missing Unit Price",
          "Value": "5"
        },
        {
          "Label": "Missing Product",
          "Value": "6"
        },
        {
          "Label": "Invalid Product",
          "Value": "7"
        },
        {
          "Label": "Missing Pricing Code",
          "Value": "8"
        },
        {
          "Label": "Invalid Pricing Code",
          "Value": "9"
        },
        {
          "Label": "Missing UOM",
          "Value": "10"
        },
        {
          "Label": "Product Not In Price Level",
          "Value": "11"
        },
        {
          "Label": "Missing Price Level Amount",
          "Value": "12"
        },
        {
          "Label": "Missing Price Level Percentage",
          "Value": "13"
        },
        {
          "Label": "Missing Price",
          "Value": "14"
        },
        {
          "Label": "Missing Current Cost",
          "Value": "15"
        },
        {
          "Label": "Missing Standard Cost",
          "Value": "16"
        },
        {
          "Label": "Invalid Price Level Amount",
          "Value": "17"
        },
        {
          "Label": "Invalid Price Level Percentage",
          "Value": "18"
        },
        {
          "Label": "Invalid Price",
          "Value": "19"
        },
        {
          "Label": "Invalid Current Cost",
          "Value": "20"
        },
        {
          "Label": "Invalid Standard Cost",
          "Value": "21"
        },
        {
          "Label": "Invalid Rounding Policy",
          "Value": "22"
        },
        {
          "Label": "Invalid Rounding Option",
          "Value": "23"
        },
        {
          "Label": "Invalid Rounding Amount",
          "Value": "24"
        },
        {
          "Label": "Price Calculation Error",
          "Value": "25"
        },
        {
          "Label": "Invalid Discount Type",
          "Value": "26"
        },
        {
          "Label": "Discount Type Invalid State",
          "Value": "27"
        },
        {
          "Label": "Invalid Discount",
          "Value": "28"
        },
        {
          "Label": "Invalid Quantity",
          "Value": "29"
        },
        {
          "Label": "Invalid Pricing Precision",
          "Value": "30"
        },
        {
          "Label": "Missing Product Default UOM",
          "Value": "31"
        },
        {
          "Label": "Missing Product UOM Schedule ",
          "Value": "32"
        },
        {
          "Label": "Inactive Discount Type",
          "Value": "33"
        },
        {
          "Label": "Invalid Price Level Currency",
          "Value": "34"
        },
        {
          "Label": "Price Attribute Out Of Range",
          "Value": "35"
        },
        {
          "Label": "Base Currency Attribute Overflow",
          "Value": "36"
        },
        {
          "Label": "Base Currency Attribute Underflow",
          "Value": "37"
        },
        {
          "Label": "Transaction currency is not set for the product price list item",
          "Value": "38"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "prioritycode",
      "Type": "picklist",
      "DisplayName": "Priority (Not Used)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "processid",
      "Type": "uniqueidentifier",
      "DisplayName": "Process",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "proposedsolution",
      "Type": "memo",
      "DisplayName": "Proposed Solution",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "purchaseprocess",
      "Type": "picklist",
      "DisplayName": "Purchase Process",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Individual",
          "Value": "0"
        },
        {
          "Label": "Committee",
          "Value": "1"
        },
        {
          "Label": "Unknown",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "purchasetimeframe",
      "Type": "picklist",
      "DisplayName": "Purchase Timeframe",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Q1-This Fiscal Year",
          "Value": "0"
        },
        {
          "Label": "Q2-This Fiscal Year",
          "Value": "1"
        },
        {
          "Label": "Q3-This Fiscal Year",
          "Value": "2"
        },
        {
          "Label": "Q4-This Fiscal Year",
          "Value": "3"
        },
        {
          "Label": "Next Fiscal Year",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "pursuitdecision",
      "Type": "boolean",
      "DisplayName": "Decide Go/No-Go",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "qualificationcomments",
      "Type": "memo",
      "DisplayName": "Qualification Comments",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "quotecomments",
      "Type": "memo",
      "DisplayName": "Quote Comments",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "resolvefeedback",
      "Type": "boolean",
      "DisplayName": "Feedback Resolved",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salesstage",
      "Type": "picklist",
      "DisplayName": "Sales Stage (Old)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Qualify",
          "Value": "0"
        },
        {
          "Label": "Develop",
          "Value": "1"
        },
        {
          "Label": "Propose",
          "Value": "2"
        },
        {
          "Label": "Close",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salesstagecode",
      "Type": "picklist",
      "DisplayName": "Process Code",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Microsoft Sales Process",
          "Value": "100000000"
        },
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "schedulefollowup_prospect",
      "Type": "datetime",
      "DisplayName": "Scheduled Follow up (Prospect)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "schedulefollowup_qualify",
      "Type": "datetime",
      "DisplayName": "Scheduled Follow up (Qualify)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "scheduleproposalmeeting",
      "Type": "datetime",
      "DisplayName": "Schedule Proposal Meeting",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "sendthankyounote",
      "Type": "boolean",
      "DisplayName": "Send Thank You Note",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "skippricecalculation",
      "Type": "picklist",
      "DisplayName": "Skip Price Calculation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "DoPriceCalcAlways",
          "Value": "0"
        },
        {
          "Label": "SkipPriceCalcOnRetrieve",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slaid",
      "Type": "lookup",
      "DisplayName": "SLA",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slainvokedid",
      "Type": "lookup",
      "DisplayName": "Last SLA applied",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "stageid",
      "Type": "uniqueidentifier",
      "DisplayName": "Process Stage",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statecode",
      "Type": "state",
      "DisplayName": "Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Open",
          "Value": "0"
        },
        {
          "Label": "Won",
          "Value": "1"
        },
        {
          "Label": "Lost",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statuscode",
      "Type": "status",
      "DisplayName": "Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "In Progress",
          "Value": "1"
        },
        {
          "Label": "Competitive Win",
          "Value": "861980019"
        },
        {
          "Label": "Partial Win",
          "Value": "861980018"
        },
        {
          "Label": "Non-Compete",
          "Value": "861980003"
        },
        {
          "Label": "MS Sales Validated",
          "Value": "861980017"
        },
        {
          "Label": "OEM Automated Closure",
          "Value": "861980015"
        },
        {
          "Label": "N/A",
          "Value": "861980021"
        },
        {
          "Label": "Competitive Loss",
          "Value": "861980020"
        },
        {
          "Label": "Kept Existing Solution",
          "Value": "861980002"
        },
        {
          "Label": "Project Canceled/Delayed",
          "Value": "4"
        },
        {
          "Label": "Pipeline Hygiene/Duplicate",
          "Value": "861980001"
        },
        {
          "Label": "N/A",
          "Value": "861980022"
        },
        {
          "Label": "Consolidated",
          "Value": "861980023"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "stepid",
      "Type": "uniqueidentifier",
      "DisplayName": "Step",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "stepname",
      "Type": "string",
      "DisplayName": "Pipeline Phase",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "teamsfollowed",
      "Type": "integer",
      "DisplayName": "TeamsFollowed",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timeline",
      "Type": "picklist",
      "DisplayName": "Timeline",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Immediate",
          "Value": "0"
        },
        {
          "Label": "This Quarter",
          "Value": "1"
        },
        {
          "Label": "Next Quarter",
          "Value": "2"
        },
        {
          "Label": "This Year",
          "Value": "3"
        },
        {
          "Label": "Not known",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timespentbymeonemailandmeetings",
      "Type": "string",
      "DisplayName": "Time Spent by me",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timezoneruleversionnumber",
      "Type": "integer",
      "DisplayName": "Time Zone Rule Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totalamount",
      "Type": "money",
      "DisplayName": "Total Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totalamount_base",
      "Type": "money",
      "DisplayName": "Total Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totalamountlessfreight",
      "Type": "money",
      "DisplayName": "Total Pre-Freight Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totalamountlessfreight_base",
      "Type": "money",
      "DisplayName": "Total Pre-Freight Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totaldiscountamount",
      "Type": "money",
      "DisplayName": "Total Discount Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totaldiscountamount_base",
      "Type": "money",
      "DisplayName": "Total Discount Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totallineitemamount",
      "Type": "money",
      "DisplayName": "Total Detail Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totallineitemamount_base",
      "Type": "money",
      "DisplayName": "Total Detail Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totallineitemdiscountamount",
      "Type": "money",
      "DisplayName": "Total Line Item Discount Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totallineitemdiscountamount_base",
      "Type": "money",
      "DisplayName": "Total Line Item Discount Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totaltax",
      "Type": "money",
      "DisplayName": "Total Tax",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "totaltax_base",
      "Type": "money",
      "DisplayName": "Total Tax (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "transactioncurrencyid",
      "Type": "lookup",
      "DisplayName": "Currency",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "traversedpath",
      "Type": "string",
      "DisplayName": "Traversed Path",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "utcconversiontimezonecode",
      "Type": "integer",
      "DisplayName": "UTC Conversion Time Zone Code",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "versionnumber",
      "Type": "bigint",
      "DisplayName": "Version Number",
      "Format": null,
      "OptionSet": null
    }
  }
]