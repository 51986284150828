import { D365_ROOT_URL, D365_API_URL, STRING_CONSTANTS } from '../Utils/Constants'
import { NUMBER_CONSTANTS } from "../Utils/Constants";
import { iSearchOption } from "../Interfaces/iSearchOption";
import { IEntityDefinition } from "../Interfaces/IEntityDefinition";

export async function getSearchData(keyword: string, viewSelected: string, tmpToken: string) {
    let tmpData: any[] = [];
    var reqbody = { "search": keyword, "top": "20", "useFuzzy": "true", "suggestType": "advanced", "entities": ["" + viewSelected + ""] };
    // let tmpToken = await getToken();
    const request: RequestInit = {
        method: 'POST',
        body: JSON.stringify(reqbody),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tmpToken}`,
        },
    }
    await fetch(D365_ROOT_URL + "/api/search/v1.0/query", request)
        .then((response) => {
            if (!response.ok) {
                //throw Error(response.statusText);
            }
            console.log(response);
            return response.json();
            //return response.json()
        })
        .then((data) => {
            tmpData = data.value;
        })
    return tmpData;
}
export async function SearchByAll(keyword: string, viewsSelected: any, tmpToken: string) {
    let tmpPromizes: any[] = [];
    viewsSelected.map((tmpSelectedOption: iSearchOption) => {
        if (keyword.length > 0) {
            if (tmpSelectedOption.useSearchAPI) {
                var reqbody = { "search": keyword, "top": "" + NUMBER_CONSTANTS.MAX_SEARCH_RECORDS.toString() + "", "useFuzzy": "true", "suggestType": "advanced", "entities": ["" + tmpSelectedOption.entity + ""], "returntotalrecordcount": "true" };
                const request: RequestInit = {
                    method: 'POST',
                    body: JSON.stringify(reqbody),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tmpToken}`,
                    },
                }
                tmpPromizes.push({ url: D365_ROOT_URL + "/api/search/v1.0/query", req: request });
            }
            else {
                if (tmpSelectedOption.withSearchTextQuery) {
                    const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                    const request: RequestInit = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${tmpToken}`,
                            'Prefer': 'odata.include-annotations=\"OData.Community.Display.V1.FormattedValue\"'
                        },
                    }
                    tmpPromizes.push({ url: tmpAPIEndpoint, req: request });
                }
            }
        }
        else {
            if (tmpSelectedOption.withoutSearchTextQuery) {
                const tmpAPIEndpoint = D365_API_URL + tmpSelectedOption.withoutSearchTextQuery.replaceAll(STRING_CONSTANTS.MORECONDITION, keyword);
                const request: RequestInit = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tmpToken}`,
                        'Prefer': 'odata.include-annotations=\"OData.Community.Display.V1.FormattedValue\"'
                    },
                }
                tmpPromizes.push({ url: tmpAPIEndpoint, req: request });
            }
        }
    })

    const tmpSearchResult = await Promise.all(tmpPromizes.map(async (tempReq: any) => {
        const resp = await fetch(tempReq.url, tempReq.req).then((response) => { return response.json() }).then((data: any) => { return data });
        return resp;
    }));
    return tmpSearchResult;
}
export async function getData(record: any, tmpToken: string, entityDefinition: IEntityDefinition) {
    // debugger;
    const tmpRecordId = record[entityDefinition.primaryKey];
    let tmpData: any = {};
    const tmpAPIEndpoint = D365_API_URL + "/" + entityDefinition.pluralName + "(" + tmpRecordId + ")";
    const request: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tmpToken}`,
            'Prefer': 'odata.include-annotations=\"OData.Community.Display.V1.FormattedValue\"'
        },
    }
    await fetch(tmpAPIEndpoint, request)
        .then((response) => {
            if (!response.ok) {
            }
            return response.json()
        })
        .then((data) => {
            tmpData = data;
        })
    return tmpData;
}