export const contact_attributes = [
  {
    "attributeDescriptor": {
      "LogicalName": "accountid",
      "Type": "lookup",
      "DisplayName": "Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "accountrolecode",
      "Type": "picklist",
      "DisplayName": "Role",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Decision Maker",
          "Value": "1"
        },
        {
          "Label": "Employee",
          "Value": "2"
        },
        {
          "Label": "Influencer",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_addressid",
      "Type": "uniqueidentifier",
      "DisplayName": "Address 1: ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_addresstypecode",
      "Type": "picklist",
      "DisplayName": "Address 1: Address Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Bill To",
          "Value": "1"
        },
        {
          "Label": "Ship To",
          "Value": "2"
        },
        {
          "Label": "Primary",
          "Value": "3"
        },
        {
          "Label": "Other",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_city",
      "Type": "string",
      "DisplayName": "Address 1: City",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_composite",
      "Type": "memo",
      "DisplayName": "Address 1",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_country",
      "Type": "string",
      "DisplayName": "Country/Region",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_county",
      "Type": "string",
      "DisplayName": "Address 1: County",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_fax",
      "Type": "string",
      "DisplayName": "Address 1: Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_freighttermscode",
      "Type": "picklist",
      "DisplayName": "Address 1: Freight Terms",
      "Format": null,
      "OptionSet": [
        {
          "Label": "FOB",
          "Value": "1"
        },
        {
          "Label": "No Charge",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_latitude",
      "Type": "double",
      "DisplayName": "Address 1: Latitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line1",
      "Type": "string",
      "DisplayName": "Address 1: Street 1",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line2",
      "Type": "string",
      "DisplayName": "Address 1: Street 2",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line3",
      "Type": "string",
      "DisplayName": "Address 1: Street 3",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_longitude",
      "Type": "double",
      "DisplayName": "Address 1: Longitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_name",
      "Type": "string",
      "DisplayName": "Address 1: Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_postalcode",
      "Type": "string",
      "DisplayName": "Address 1: ZIP/Postal Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_postofficebox",
      "Type": "string",
      "DisplayName": "Address 1: Post Office Box",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_primarycontactname",
      "Type": "string",
      "DisplayName": "Address 1: Primary Contact Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Address 1: Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Airborne",
          "Value": "1"
        },
        {
          "Label": "DHL",
          "Value": "2"
        },
        {
          "Label": "FedEx",
          "Value": "3"
        },
        {
          "Label": "UPS",
          "Value": "4"
        },
        {
          "Label": "Postal Mail",
          "Value": "5"
        },
        {
          "Label": "Full Load",
          "Value": "6"
        },
        {
          "Label": "Will Call",
          "Value": "7"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_stateorprovince",
      "Type": "string",
      "DisplayName": "Address 1: State/Province",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone1",
      "Type": "string",
      "DisplayName": "Address 1: Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone2",
      "Type": "string",
      "DisplayName": "Address 1: Telephone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone3",
      "Type": "string",
      "DisplayName": "Address 1: Telephone 3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_upszone",
      "Type": "string",
      "DisplayName": "Address 1: UPS Zone",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_utcoffset",
      "Type": "integer",
      "DisplayName": "Address 1: UTC Offset",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_addressid",
      "Type": "uniqueidentifier",
      "DisplayName": "Address 2: ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_addresstypecode",
      "Type": "picklist",
      "DisplayName": "Address 2: Address Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_city",
      "Type": "string",
      "DisplayName": "Address 2: City",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_composite",
      "Type": "memo",
      "DisplayName": "Address 2",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_country",
      "Type": "string",
      "DisplayName": "Address 2: Country/Region",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_county",
      "Type": "string",
      "DisplayName": "Address 2: County",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_fax",
      "Type": "string",
      "DisplayName": "Address 2: Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_freighttermscode",
      "Type": "picklist",
      "DisplayName": "Address 2: Freight Terms",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_latitude",
      "Type": "double",
      "DisplayName": "Address 2: Latitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line1",
      "Type": "string",
      "DisplayName": "Address 2: Street 1",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line2",
      "Type": "string",
      "DisplayName": "Address 2: Street 2",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line3",
      "Type": "string",
      "DisplayName": "Address 2: Street 3",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_longitude",
      "Type": "double",
      "DisplayName": "Address 2: Longitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_name",
      "Type": "string",
      "DisplayName": "Address 2: Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_postalcode",
      "Type": "string",
      "DisplayName": "Address 2: ZIP/Postal Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_postofficebox",
      "Type": "string",
      "DisplayName": "Address 2: Post Office Box",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_primarycontactname",
      "Type": "string",
      "DisplayName": "Address 2: Primary Contact Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Address 2: Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_stateorprovince",
      "Type": "string",
      "DisplayName": "Address 2: State/Province",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone1",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 1",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone2",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone3",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_upszone",
      "Type": "string",
      "DisplayName": "Address 2: UPS Zone",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_utcoffset",
      "Type": "integer",
      "DisplayName": "Address 2: UTC Offset",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_addressid",
      "Type": "uniqueidentifier",
      "DisplayName": "Address 3: ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_addresstypecode",
      "Type": "picklist",
      "DisplayName": "Address 3: Address Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_city",
      "Type": "string",
      "DisplayName": "Address 3: City",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_composite",
      "Type": "memo",
      "DisplayName": "Address 3",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_country",
      "Type": "string",
      "DisplayName": "Address3: Country/Region",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_county",
      "Type": "string",
      "DisplayName": "Address 3: County",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_fax",
      "Type": "string",
      "DisplayName": "Address 3: Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_freighttermscode",
      "Type": "picklist",
      "DisplayName": "Address 3: Freight Terms",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_latitude",
      "Type": "double",
      "DisplayName": "Address 3: Latitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_line1",
      "Type": "string",
      "DisplayName": "Address3: Street 1",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_line2",
      "Type": "string",
      "DisplayName": "Address3: Street 2",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_line3",
      "Type": "string",
      "DisplayName": "Address3: Street 3",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_longitude",
      "Type": "double",
      "DisplayName": "Address 3: Longitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_name",
      "Type": "string",
      "DisplayName": "Address 3: Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_postalcode",
      "Type": "string",
      "DisplayName": "Address3: ZIP/Postal Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_postofficebox",
      "Type": "string",
      "DisplayName": "Address 3: Post Office Box",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_primarycontactname",
      "Type": "string",
      "DisplayName": "Address 3: Primary Contact Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Address 3: Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_stateorprovince",
      "Type": "string",
      "DisplayName": "Address3: State/Province",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_telephone1",
      "Type": "string",
      "DisplayName": "Address 3: Telephone1",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_telephone2",
      "Type": "string",
      "DisplayName": "Address 3: Telephone2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_telephone3",
      "Type": "string",
      "DisplayName": "Address 3: Telephone3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_upszone",
      "Type": "string",
      "DisplayName": "Address 3: UPS Zone",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address3_utcoffset",
      "Type": "integer",
      "DisplayName": "Address 3: UTC Offset",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging30",
      "Type": "money",
      "DisplayName": "Aging 30",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging30_base",
      "Type": "money",
      "DisplayName": "Aging 30 (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging60",
      "Type": "money",
      "DisplayName": "Aging 60",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging60_base",
      "Type": "money",
      "DisplayName": "Aging 60 (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging90",
      "Type": "money",
      "DisplayName": "Aging 90",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "aging90_base",
      "Type": "money",
      "DisplayName": "Aging 90 (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "anniversary",
      "Type": "datetime",
      "DisplayName": "Anniversary",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "annualincome",
      "Type": "money",
      "DisplayName": "Annual Income",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "annualincome_base",
      "Type": "money",
      "DisplayName": "Annual Income (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "assistantname",
      "Type": "string",
      "DisplayName": "Assistant",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "assistantphone",
      "Type": "string",
      "DisplayName": "Assistant Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "birthdate",
      "Type": "datetime",
      "DisplayName": "Birthday",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "business2",
      "Type": "string",
      "DisplayName": "Business Phone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "businesscard",
      "Type": "memo",
      "DisplayName": "Business Card",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "businesscardattributes",
      "Type": "string",
      "DisplayName": "BusinessCardAttributes",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "callback",
      "Type": "string",
      "DisplayName": "Callback Number",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "childrensnames",
      "Type": "string",
      "DisplayName": "Children's Names",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "company",
      "Type": "string",
      "DisplayName": "Company Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "contactid",
      "Type": "uniqueidentifier",
      "DisplayName": "Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdby",
      "Type": "lookup",
      "DisplayName": "Created By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdbyexternalparty",
      "Type": "lookup",
      "DisplayName": "Created By (External Party)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdon",
      "Type": "datetime",
      "DisplayName": "Created On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdonbehalfby",
      "Type": "lookup",
      "DisplayName": "Created By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "creditlimit",
      "Type": "money",
      "DisplayName": "Credit Limit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "creditlimit_base",
      "Type": "money",
      "DisplayName": "Credit Limit (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "creditonhold",
      "Type": "boolean",
      "DisplayName": "Credit Hold",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customersizecode",
      "Type": "picklist",
      "DisplayName": "Customer Size",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customertypecode",
      "Type": "picklist",
      "DisplayName": "Relationship Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "defaultpricelevelid",
      "Type": "lookup",
      "DisplayName": "Price List",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "department",
      "Type": "string",
      "DisplayName": "Department Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "description",
      "Type": "memo",
      "DisplayName": "Description",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotbulkemail",
      "Type": "boolean",
      "DisplayName": "Do not allow Bulk Emails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotbulkpostalmail",
      "Type": "boolean",
      "DisplayName": "Do not allow Bulk Mails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotemail",
      "Type": "boolean",
      "DisplayName": "Do not allow Emails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotfax",
      "Type": "boolean",
      "DisplayName": "Do not allow Faxes",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotphone",
      "Type": "boolean",
      "DisplayName": "Do not allow Phone Calls",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotpostalmail",
      "Type": "boolean",
      "DisplayName": "Do not allow Mails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotsendmm",
      "Type": "boolean",
      "DisplayName": "Send Marketing Materials",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Send",
          "Value": "0"
        },
        {
          "Label": "Do Not Send",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "educationcode",
      "Type": "picklist",
      "DisplayName": "Education",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress1",
      "Type": "string",
      "DisplayName": "Email",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress2",
      "Type": "string",
      "DisplayName": "Email Address 2",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress3",
      "Type": "string",
      "DisplayName": "Email Address 3",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "employeeid",
      "Type": "string",
      "DisplayName": "Employee",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "entityimageid",
      "Type": "uniqueidentifier",
      "DisplayName": "Entity Image Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "exchangerate",
      "Type": "decimal",
      "DisplayName": "Exchange Rate",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "externaluseridentifier",
      "Type": "string",
      "DisplayName": "External User Identifier",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "familystatuscode",
      "Type": "picklist",
      "DisplayName": "Marital Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Single",
          "Value": "1"
        },
        {
          "Label": "Married",
          "Value": "2"
        },
        {
          "Label": "Divorced",
          "Value": "3"
        },
        {
          "Label": "Widowed",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "fax",
      "Type": "string",
      "DisplayName": "Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "firstname",
      "Type": "string",
      "DisplayName": "First Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "followemail",
      "Type": "boolean",
      "DisplayName": "Follow Email Activity",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Do Not Allow",
          "Value": "0"
        },
        {
          "Label": "Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "ftpsiteurl",
      "Type": "string",
      "DisplayName": "FTP Site",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "fullname",
      "Type": "string",
      "DisplayName": "Full Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "gendercode",
      "Type": "picklist",
      "DisplayName": "Gender",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unspecified",
          "Value": "100000000"
        },
        {
          "Label": "Male",
          "Value": "1"
        },
        {
          "Label": "Female",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "governmentid",
      "Type": "string",
      "DisplayName": "Government",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "haschildrencode",
      "Type": "picklist",
      "DisplayName": "Has Children",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "home2",
      "Type": "string",
      "DisplayName": "Home Phone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "importsequencenumber",
      "Type": "integer",
      "DisplayName": "Import Sequence Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isautocreate",
      "Type": "boolean",
      "DisplayName": "Auto-created",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isbackofficecustomer",
      "Type": "boolean",
      "DisplayName": "Back Office Customer",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isprivate",
      "Type": "boolean",
      "DisplayName": null,
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "jobtitle",
      "Type": "string",
      "DisplayName": "Job Title",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastname",
      "Type": "string",
      "DisplayName": "Last Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastonholdtime",
      "Type": "datetime",
      "DisplayName": "Last On Hold Time",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastusedincampaign",
      "Type": "datetime",
      "DisplayName": "Last Date Included in Campaign",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "leadsourcecode",
      "Type": "picklist",
      "DisplayName": "Lead Source",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Advertisement",
          "Value": "100000000"
        },
        {
          "Label": "Cold Call",
          "Value": "100000001"
        },
        {
          "Label": "Employee Referral",
          "Value": "100000002"
        },
        {
          "Label": "Default Value",
          "Value": "1"
        },
        {
          "Label": "LISN",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_companyid",
      "Type": "integer",
      "DisplayName": "LinkedIn Company Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_membertoken",
      "Type": "string",
      "DisplayName": "LinkedIn Member Token",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "managername",
      "Type": "string",
      "DisplayName": "Manager(Deprecated)",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "managerphone",
      "Type": "string",
      "DisplayName": "Manager Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "marketingonly",
      "Type": "boolean",
      "DisplayName": "Marketing Only",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "masterid",
      "Type": "lookup",
      "DisplayName": "Master ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "merged",
      "Type": "boolean",
      "DisplayName": "Merged",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "middlename",
      "Type": "string",
      "DisplayName": "Middle Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "mobilephone",
      "Type": "string",
      "DisplayName": "Mobile Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedby",
      "Type": "lookup",
      "DisplayName": "Modified By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedbyexternalparty",
      "Type": "lookup",
      "DisplayName": "Modified By (External Party)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedon",
      "Type": "datetime",
      "DisplayName": "Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedonbehalfby",
      "Type": "lookup",
      "DisplayName": "Modified By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_contactkpiid",
      "Type": "lookup",
      "DisplayName": "KPI",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_decisioninfluencetag",
      "Type": "picklist",
      "DisplayName": "Decision influence labels",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Decision maker",
          "Value": "0"
        },
        {
          "Label": "Influencer",
          "Value": "1"
        },
        {
          "Label": "Blocker",
          "Value": "2"
        },
        {
          "Label": "Unknown",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_gdproptout",
      "Type": "boolean",
      "DisplayName": "GDPR Optout",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_isassistantinorgchart",
      "Type": "boolean",
      "DisplayName": "Is Assistant",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_orgchangestatus",
      "Type": "picklist",
      "DisplayName": "Not at Company Flag",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No Feedback",
          "Value": "0"
        },
        {
          "Label": "Not at Company",
          "Value": "1"
        },
        {
          "Label": "Ignore",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_segmentid",
      "Type": "lookup",
      "DisplayName": "Segment Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_adaptabilitytochange",
      "Type": "picklist",
      "DisplayName": "Adaptability to Change",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Innovator (I)",
          "Value": "861980000"
        },
        {
          "Label": "Visionary (V)",
          "Value": "861980001"
        },
        {
          "Label": "Pragmatist (P)",
          "Value": "861980002"
        },
        {
          "Label": "Conservative (C)",
          "Value": "861980003"
        },
        {
          "Label": "Legacy (L)",
          "Value": "861980004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_ccmaffinity",
      "Type": "memo",
      "DisplayName": "CCM Affinity",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_ccmid",
      "Type": "string",
      "DisplayName": "CcmId",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_ccmknownness",
      "Type": "memo",
      "DisplayName": "CCM Knownness",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_consumptionplancustexec",
      "Type": "lookup",
      "DisplayName": "Consumption Plan Cust Exec",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contact_watermark",
      "Type": "picklist",
      "DisplayName": "Contact Watermark",
      "Format": null,
      "OptionSet": [
        {
          "Label": "EXTERNAL_SYSTEM_NOT_VERIFIED",
          "Value": "861980000"
        },
        {
          "Label": "EXTERNAL_SYSTEM_VERIFIED",
          "Value": "861980001"
        },
        {
          "Label": "EXTERNAL_HUMAN_NOT_VERIFIED",
          "Value": "861980002"
        },
        {
          "Label": "EXTERNAL_HUMAN_VERIFIED",
          "Value": "861980003"
        },
        {
          "Label": "INTERNAL_SYSTEM_NOT_VERIFIED",
          "Value": "861980004"
        },
        {
          "Label": "INTERNAL_SYSTEM_VERIFIED",
          "Value": "861980005"
        },
        {
          "Label": "INTERNAL_HUMAN_NOT_VERIFIED",
          "Value": "861980006"
        },
        {
          "Label": "INTERNAL_HUMAN_VERIFIED",
          "Value": "861980007"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactfreshnesstracker",
      "Type": "memo",
      "DisplayName": "Contact Freshness Tracker",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactimage",
      "Type": "file",
      "DisplayName": "Contact Image",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactmanager",
      "Type": "lookup",
      "DisplayName": "Manager",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactnotinaccount",
      "Type": "boolean",
      "DisplayName": "Contact Not in Account",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactnumber",
      "Type": "string",
      "DisplayName": "Contact Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactsource",
      "Type": "string",
      "DisplayName": "Contact Source",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_countrycode",
      "Type": "picklist",
      "DisplayName": "Country",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Afghanistan",
          "Value": "4909"
        },
        {
          "Label": "Åland Islands",
          "Value": "228517"
        },
        {
          "Label": "Albania",
          "Value": "4910"
        },
        {
          "Label": "Algeria",
          "Value": "4911"
        },
        {
          "Label": "American Samoa",
          "Value": "4912"
        },
        {
          "Label": "Andorra",
          "Value": "4913"
        },
        {
          "Label": "Angola",
          "Value": "4914"
        },
        {
          "Label": "Anguilla",
          "Value": "4915"
        },
        {
          "Label": "Antarctica",
          "Value": "4916"
        },
        {
          "Label": "Antigua and Barbuda",
          "Value": "4917"
        },
        {
          "Label": "Argentina",
          "Value": "4918"
        },
        {
          "Label": "Armenia",
          "Value": "4919"
        },
        {
          "Label": "Aruba",
          "Value": "4920"
        },
        {
          "Label": "Australia",
          "Value": "4921"
        },
        {
          "Label": "Austria",
          "Value": "4922"
        },
        {
          "Label": "Azerbaijan",
          "Value": "4923"
        },
        {
          "Label": "Bahamas",
          "Value": "4924"
        },
        {
          "Label": "Bahrain",
          "Value": "4925"
        },
        {
          "Label": "Bangladesh",
          "Value": "4926"
        },
        {
          "Label": "Barbados",
          "Value": "4927"
        },
        {
          "Label": "Belarus",
          "Value": "4928"
        },
        {
          "Label": "Belgium",
          "Value": "4929"
        },
        {
          "Label": "Belize",
          "Value": "4930"
        },
        {
          "Label": "Benin",
          "Value": "4931"
        },
        {
          "Label": "Bermuda",
          "Value": "4932"
        },
        {
          "Label": "Bhutan",
          "Value": "4933"
        },
        {
          "Label": "Bolivia",
          "Value": "4934"
        },
        {
          "Label": "Bonaire",
          "Value": "2082124"
        },
        {
          "Label": "Bosnia and Herzegovina",
          "Value": "4935"
        },
        {
          "Label": "Botswana",
          "Value": "4936"
        },
        {
          "Label": "Bouvet Island",
          "Value": "4937"
        },
        {
          "Label": "Brazil",
          "Value": "4938"
        },
        {
          "Label": "British Indian Ocean Territory",
          "Value": "4939"
        },
        {
          "Label": "British Virgin Islands",
          "Value": "5139"
        },
        {
          "Label": "Brunei",
          "Value": "4940"
        },
        {
          "Label": "Bulgaria",
          "Value": "4941"
        },
        {
          "Label": "Burkina Faso",
          "Value": "4942"
        },
        {
          "Label": "Burundi",
          "Value": "4943"
        },
        {
          "Label": "Cabo Verde",
          "Value": "4947"
        },
        {
          "Label": "Cambodia",
          "Value": "4944"
        },
        {
          "Label": "Cameroon",
          "Value": "4945"
        },
        {
          "Label": "Canada",
          "Value": "4946"
        },
        {
          "Label": "Cayman Islands",
          "Value": "4948"
        },
        {
          "Label": "Central African Republic",
          "Value": "4949"
        },
        {
          "Label": "Chad",
          "Value": "4950"
        },
        {
          "Label": "Chile",
          "Value": "4951"
        },
        {
          "Label": "China",
          "Value": "4952"
        },
        {
          "Label": "Christmas Island",
          "Value": "4953"
        },
        {
          "Label": "Cocos (Keeling) Islands",
          "Value": "4954"
        },
        {
          "Label": "Colombia",
          "Value": "4955"
        },
        {
          "Label": "Comoros",
          "Value": "4956"
        },
        {
          "Label": "Congo",
          "Value": "4957"
        },
        {
          "Label": "Congo (DRC)",
          "Value": "4958"
        },
        {
          "Label": "Cook Islands",
          "Value": "4959"
        },
        {
          "Label": "Costa Rica",
          "Value": "4960"
        },
        {
          "Label": "Côte d’Ivoire",
          "Value": "4961"
        },
        {
          "Label": "Croatia",
          "Value": "4962"
        },
        {
          "Label": "Cuba",
          "Value": "4963"
        },
        {
          "Label": "Curaçao",
          "Value": "2082125"
        },
        {
          "Label": "Cyprus",
          "Value": "4964"
        },
        {
          "Label": "Czechia",
          "Value": "4965"
        },
        {
          "Label": "Denmark",
          "Value": "4966"
        },
        {
          "Label": "Djibouti",
          "Value": "4967"
        },
        {
          "Label": "Dominica",
          "Value": "4968"
        },
        {
          "Label": "Dominican Republic",
          "Value": "4969"
        },
        {
          "Label": "Ecuador",
          "Value": "4971"
        },
        {
          "Label": "Egypt",
          "Value": "4972"
        },
        {
          "Label": "El Salvador",
          "Value": "4973"
        },
        {
          "Label": "Equatorial Guinea",
          "Value": "4974"
        },
        {
          "Label": "Eritrea",
          "Value": "4975"
        },
        {
          "Label": "Estonia",
          "Value": "4976"
        },
        {
          "Label": "Eswatini",
          "Value": "5110"
        },
        {
          "Label": "Ethiopia",
          "Value": "4977"
        },
        {
          "Label": "Falkland Islands",
          "Value": "4978"
        },
        {
          "Label": "Faroe Islands",
          "Value": "4979"
        },
        {
          "Label": "Fiji",
          "Value": "4980"
        },
        {
          "Label": "Finland",
          "Value": "4981"
        },
        {
          "Label": "France",
          "Value": "4982"
        },
        {
          "Label": "French Guiana",
          "Value": "4983"
        },
        {
          "Label": "French Polynesia",
          "Value": "4984"
        },
        {
          "Label": "French Southern Territories",
          "Value": "4985"
        },
        {
          "Label": "Gabon",
          "Value": "4986"
        },
        {
          "Label": "Gambia",
          "Value": "4987"
        },
        {
          "Label": "Georgia",
          "Value": "4988"
        },
        {
          "Label": "Germany",
          "Value": "4989"
        },
        {
          "Label": "Ghana",
          "Value": "4990"
        },
        {
          "Label": "Gibraltar",
          "Value": "4991"
        },
        {
          "Label": "Greece",
          "Value": "4992"
        },
        {
          "Label": "Greenland",
          "Value": "4993"
        },
        {
          "Label": "Grenada",
          "Value": "4994"
        },
        {
          "Label": "Guadeloupe",
          "Value": "4995"
        },
        {
          "Label": "Guam",
          "Value": "4996"
        },
        {
          "Label": "Guatemala",
          "Value": "4997"
        },
        {
          "Label": "Guernsey",
          "Value": "85385"
        },
        {
          "Label": "Guinea",
          "Value": "4998"
        },
        {
          "Label": "Guinea-Bissau",
          "Value": "4999"
        },
        {
          "Label": "Guyana",
          "Value": "5000"
        },
        {
          "Label": "Haiti",
          "Value": "5001"
        },
        {
          "Label": "Heard Island and McDonald Islands",
          "Value": "5002"
        },
        {
          "Label": "Honduras",
          "Value": "5003"
        },
        {
          "Label": "Hong Kong SAR",
          "Value": "5004"
        },
        {
          "Label": "Hungary",
          "Value": "5005"
        },
        {
          "Label": "Iceland",
          "Value": "5006"
        },
        {
          "Label": "India",
          "Value": "5007"
        },
        {
          "Label": "Indonesia",
          "Value": "5008"
        },
        {
          "Label": "Iran",
          "Value": "5009"
        },
        {
          "Label": "Iraq",
          "Value": "5010"
        },
        {
          "Label": "Ireland",
          "Value": "5011"
        },
        {
          "Label": "Isle of Man",
          "Value": "24459"
        },
        {
          "Label": "Israel",
          "Value": "5012"
        },
        {
          "Label": "Italy",
          "Value": "5013"
        },
        {
          "Label": "Jamaica",
          "Value": "5014"
        },
        {
          "Label": "Jan Mayen",
          "Value": "24458"
        },
        {
          "Label": "Japan",
          "Value": "5015"
        },
        {
          "Label": "Jersey",
          "Value": "85388"
        },
        {
          "Label": "Jordan",
          "Value": "5016"
        },
        {
          "Label": "Kazakhstan",
          "Value": "5017"
        },
        {
          "Label": "Kenya",
          "Value": "5018"
        },
        {
          "Label": "Kiribati",
          "Value": "5019"
        },
        {
          "Label": "Korea",
          "Value": "5020"
        },
        {
          "Label": "Kosovo",
          "Value": "30006102"
        },
        {
          "Label": "Kuwait",
          "Value": "5021"
        },
        {
          "Label": "Kyrgyzstan",
          "Value": "5022"
        },
        {
          "Label": "Laos",
          "Value": "5023"
        },
        {
          "Label": "Latvia",
          "Value": "5024"
        },
        {
          "Label": "Lebanon",
          "Value": "5025"
        },
        {
          "Label": "Lesotho",
          "Value": "5026"
        },
        {
          "Label": "Liberia",
          "Value": "5027"
        },
        {
          "Label": "Libya",
          "Value": "5028"
        },
        {
          "Label": "Liechtenstein",
          "Value": "5029"
        },
        {
          "Label": "Lithuania",
          "Value": "5030"
        },
        {
          "Label": "Luxembourg",
          "Value": "5031"
        },
        {
          "Label": "Macao SAR",
          "Value": "5032"
        },
        {
          "Label": "Madagascar",
          "Value": "5034"
        },
        {
          "Label": "Malawi",
          "Value": "5035"
        },
        {
          "Label": "Malaysia",
          "Value": "5036"
        },
        {
          "Label": "Maldives",
          "Value": "5037"
        },
        {
          "Label": "Mali",
          "Value": "5038"
        },
        {
          "Label": "Malta",
          "Value": "5039"
        },
        {
          "Label": "Marshall Islands",
          "Value": "5040"
        },
        {
          "Label": "Martinique",
          "Value": "5041"
        },
        {
          "Label": "Mauritania",
          "Value": "5042"
        },
        {
          "Label": "Mauritius",
          "Value": "5043"
        },
        {
          "Label": "Mayotte",
          "Value": "5044"
        },
        {
          "Label": "Mexico",
          "Value": "5045"
        },
        {
          "Label": "Micronesia",
          "Value": "5046"
        },
        {
          "Label": "Moldova",
          "Value": "5047"
        },
        {
          "Label": "Monaco",
          "Value": "5048"
        },
        {
          "Label": "Mongolia",
          "Value": "5049"
        },
        {
          "Label": "Montenegro",
          "Value": "85393"
        },
        {
          "Label": "Montserrat",
          "Value": "5050"
        },
        {
          "Label": "Morocco",
          "Value": "5051"
        },
        {
          "Label": "Mozambique",
          "Value": "5052"
        },
        {
          "Label": "Myanmar",
          "Value": "5053"
        },
        {
          "Label": "Namibia",
          "Value": "5054"
        },
        {
          "Label": "Nauru",
          "Value": "5055"
        },
        {
          "Label": "Nepal",
          "Value": "5056"
        },
        {
          "Label": "Netherlands",
          "Value": "5058"
        },
        {
          "Label": "New Caledonia",
          "Value": "5059"
        },
        {
          "Label": "New Zealand",
          "Value": "5060"
        },
        {
          "Label": "Nicaragua",
          "Value": "5061"
        },
        {
          "Label": "Niger",
          "Value": "5062"
        },
        {
          "Label": "Nigeria",
          "Value": "5063"
        },
        {
          "Label": "Niue",
          "Value": "5064"
        },
        {
          "Label": "Norfolk Island",
          "Value": "5065"
        },
        {
          "Label": "North Korea",
          "Value": "5066"
        },
        {
          "Label": "North Macedonia",
          "Value": "5033"
        },
        {
          "Label": "Northern Mariana Islands",
          "Value": "5067"
        },
        {
          "Label": "Norway",
          "Value": "5068"
        },
        {
          "Label": "Oman",
          "Value": "5069"
        },
        {
          "Label": "Pakistan",
          "Value": "5070"
        },
        {
          "Label": "Palau",
          "Value": "5071"
        },
        {
          "Label": "Palestinian Authority",
          "Value": "10193"
        },
        {
          "Label": "Panama",
          "Value": "5072"
        },
        {
          "Label": "Papua New Guinea",
          "Value": "5073"
        },
        {
          "Label": "Paraguay",
          "Value": "5074"
        },
        {
          "Label": "Peru",
          "Value": "5075"
        },
        {
          "Label": "Philippines",
          "Value": "5076"
        },
        {
          "Label": "Pitcairn Islands",
          "Value": "5077"
        },
        {
          "Label": "Poland",
          "Value": "5078"
        },
        {
          "Label": "Portugal",
          "Value": "5079"
        },
        {
          "Label": "Puerto Rico",
          "Value": "5080"
        },
        {
          "Label": "Qatar",
          "Value": "5081"
        },
        {
          "Label": "Réunion",
          "Value": "5082"
        },
        {
          "Label": "Romania",
          "Value": "5083"
        },
        {
          "Label": "Russia",
          "Value": "5084"
        },
        {
          "Label": "Rwanda",
          "Value": "5085"
        },
        {
          "Label": "Saba",
          "Value": "2082126"
        },
        {
          "Label": "Saint Barthélemy",
          "Value": "1527812"
        },
        {
          "Label": "Saint Kitts and Nevis",
          "Value": "5103"
        },
        {
          "Label": "Saint Lucia",
          "Value": "5104"
        },
        {
          "Label": "Saint Martin",
          "Value": "1527813"
        },
        {
          "Label": "Saint Pierre and Miquelon",
          "Value": "5087"
        },
        {
          "Label": "Saint Vincent and the Grenadines",
          "Value": "5105"
        },
        {
          "Label": "Samoa",
          "Value": "5088"
        },
        {
          "Label": "San Marino",
          "Value": "5089"
        },
        {
          "Label": "São Tomé and Príncipe",
          "Value": "5090"
        },
        {
          "Label": "Saudi Arabia",
          "Value": "5091"
        },
        {
          "Label": "Senegal",
          "Value": "5092"
        },
        {
          "Label": "Serbia",
          "Value": "75314"
        },
        {
          "Label": "Seychelles",
          "Value": "5093"
        },
        {
          "Label": "Sierra Leone",
          "Value": "5094"
        },
        {
          "Label": "Singapore",
          "Value": "5095"
        },
        {
          "Label": "Sint Eustatius",
          "Value": "2082127"
        },
        {
          "Label": "Sint Maarten",
          "Value": "2082128"
        },
        {
          "Label": "Slovakia",
          "Value": "5096"
        },
        {
          "Label": "Slovenia",
          "Value": "5097"
        },
        {
          "Label": "Solomon Islands",
          "Value": "5098"
        },
        {
          "Label": "Somalia",
          "Value": "5099"
        },
        {
          "Label": "South Africa",
          "Value": "5100"
        },
        {
          "Label": "South Georgia and South Sandwich Islands",
          "Value": "5106"
        },
        {
          "Label": "South Sudan",
          "Value": "2313196"
        },
        {
          "Label": "Spain",
          "Value": "5101"
        },
        {
          "Label": "Sri Lanka",
          "Value": "5102"
        },
        {
          "Label": "St Helena, Ascension, Tristan da Cunha",
          "Value": "5086"
        },
        {
          "Label": "Sudan",
          "Value": "5107"
        },
        {
          "Label": "Suriname",
          "Value": "5108"
        },
        {
          "Label": "Svalbard",
          "Value": "5109"
        },
        {
          "Label": "Sweden",
          "Value": "5111"
        },
        {
          "Label": "Switzerland",
          "Value": "5112"
        },
        {
          "Label": "Syria",
          "Value": "5113"
        },
        {
          "Label": "Taiwan",
          "Value": "5114"
        },
        {
          "Label": "Tajikistan",
          "Value": "5115"
        },
        {
          "Label": "Tanzania",
          "Value": "5116"
        },
        {
          "Label": "Thailand",
          "Value": "5117"
        },
        {
          "Label": "Timor-Leste",
          "Value": "93100"
        },
        {
          "Label": "Togo",
          "Value": "5118"
        },
        {
          "Label": "Tokelau",
          "Value": "5119"
        },
        {
          "Label": "Tonga",
          "Value": "5120"
        },
        {
          "Label": "Trinidad and Tobago",
          "Value": "5121"
        },
        {
          "Label": "Tunisia",
          "Value": "5122"
        },
        {
          "Label": "Türkiye",
          "Value": "5123"
        },
        {
          "Label": "Turkmenistan",
          "Value": "5124"
        },
        {
          "Label": "Turks and Caicos Islands",
          "Value": "5125"
        },
        {
          "Label": "Tuvalu",
          "Value": "5126"
        },
        {
          "Label": "U.S. Outlying Islands",
          "Value": "24460"
        },
        {
          "Label": "U.S. Virgin Islands",
          "Value": "5138"
        },
        {
          "Label": "Uganda",
          "Value": "5127"
        },
        {
          "Label": "Ukraine",
          "Value": "5128"
        },
        {
          "Label": "United Arab Emirates",
          "Value": "5129"
        },
        {
          "Label": "United Kingdom",
          "Value": "5130"
        },
        {
          "Label": "United States",
          "Value": "5131"
        },
        {
          "Label": "Unknown",
          "Value": "0"
        },
        {
          "Label": "Uruguay",
          "Value": "5132"
        },
        {
          "Label": "Uzbekistan",
          "Value": "5133"
        },
        {
          "Label": "Vanuatu",
          "Value": "5134"
        },
        {
          "Label": "Vatican City",
          "Value": "5135"
        },
        {
          "Label": "Venezuela",
          "Value": "5136"
        },
        {
          "Label": "Vietnam",
          "Value": "5137"
        },
        {
          "Label": "Wallis and Futuna",
          "Value": "5140"
        },
        {
          "Label": "Yemen",
          "Value": "5141"
        },
        {
          "Label": "Zambia",
          "Value": "5143"
        },
        {
          "Label": "Zimbabwe",
          "Value": "5144"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_cpesurveyresponse",
      "Type": "picklist",
      "DisplayName": "CPE Survey Response",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Survey Response Received",
          "Value": "606820000"
        },
        {
          "Label": "Hard Bounce Back",
          "Value": "606820001"
        },
        {
          "Label": "Unsubscribed",
          "Value": "606820002"
        },
        {
          "Label": "No Response",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_departmentcode",
      "Type": "picklist",
      "DisplayName": "Department",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Chief Information Office, Enterprise Business Solutions & Services",
          "Value": "100000000"
        },
        {
          "Label": "Division of Enforcement & Compliance Assistance",
          "Value": "100000001"
        },
        {
          "Label": "End User Services Division",
          "Value": "100000002"
        },
        {
          "Label": "Federal Reserve National IT (FRIT)",
          "Value": "100000003"
        },
        {
          "Label": "GIS",
          "Value": "100000004"
        },
        {
          "Label": "Hosting Services/Service Delivery/ITSSO",
          "Value": "100000005"
        },
        {
          "Label": "Information & Technology Services",
          "Value": "100000006"
        },
        {
          "Label": "Information Resources, NISC",
          "Value": "100000007"
        },
        {
          "Label": "Information Technology Division",
          "Value": "100000008"
        },
        {
          "Label": "Network Management Division",
          "Value": "100000009"
        },
        {
          "Label": "Office of Environmental Information",
          "Value": "100000010"
        },
        {
          "Label": "Office of Environmental Information (OEI)",
          "Value": "100000011"
        },
        {
          "Label": "Office of Environmental Information, USEPA",
          "Value": "100000012"
        },
        {
          "Label": "ONRR",
          "Value": "100000013"
        },
        {
          "Label": "PN Regional Office",
          "Value": "100000014"
        },
        {
          "Label": "Resource Information Services Division | National Information Services Center",
          "Value": "100000015"
        },
        {
          "Label": "Technology Engineering and Computing Services",
          "Value": "100000016"
        },
        {
          "Label": "Bureau of Indian Affairs",
          "Value": "100000017"
        },
        {
          "Label": "Bureau of Land Management",
          "Value": "100000018"
        },
        {
          "Label": "Bureau of Reclamation",
          "Value": "100000019"
        },
        {
          "Label": "Bureau of Safety & Environmental Enforcement",
          "Value": "100000020"
        },
        {
          "Label": "Interior Business Center",
          "Value": "100000021"
        },
        {
          "Label": "National Park Service",
          "Value": "100000022"
        },
        {
          "Label": "National Parks Service",
          "Value": "100000023"
        },
        {
          "Label": "Office of Special Trustee",
          "Value": "100000024"
        },
        {
          "Label": "Office of the Chief Information Officer",
          "Value": "100000025"
        },
        {
          "Label": "Office of the Inspector General",
          "Value": "100000026"
        },
        {
          "Label": "Office of the Solicitor",
          "Value": "100000027"
        },
        {
          "Label": "US Fish & Wildlife Service",
          "Value": "100000028"
        },
        {
          "Label": "US Forest Service",
          "Value": "100000029"
        },
        {
          "Label": "US Geological Survey",
          "Value": "100000030"
        },
        {
          "Label": "Advanced Programs Group",
          "Value": "100000031"
        },
        {
          "Label": "Bureau of Industry and Security",
          "Value": "100000032"
        },
        {
          "Label": "EMERGING MEDIA GROUP",
          "Value": "100000033"
        },
        {
          "Label": "FINANCIAL CRIMES ENFORCEMENT NETWORK",
          "Value": "100000034"
        },
        {
          "Label": "House Permanent Select",
          "Value": "100000035"
        },
        {
          "Label": "J2",
          "Value": "100000036"
        },
        {
          "Label": "Joint Intelligence Center",
          "Value": "100000037"
        },
        {
          "Label": "National Security",
          "Value": "100000038"
        },
        {
          "Label": "NIMITZ OPERATIONAL INTELLIGENCE CENTE~R",
          "Value": "100000039"
        },
        {
          "Label": "Office of Mobile Security Deployments Bureau of Diplomatic Security",
          "Value": "100000040"
        },
        {
          "Label": "Offtce of Transportation Threat Assessment and Credentialing",
          "Value": "100000041"
        },
        {
          "Label": "SerVicest &amp",
          "Value": "100000042"
        },
        {
          "Label": "Support Services",
          "Value": "100000043"
        },
        {
          "Label": "U.S. Department of Homeland Security",
          "Value": "100000044"
        },
        {
          "Label": "Worldwide Public Sector",
          "Value": "100000045"
        },
        {
          "Label": "Administration",
          "Value": "861980000"
        },
        {
          "Label": "Admission/Patient Registration",
          "Value": "861980001"
        },
        {
          "Label": "Ambulatory Services",
          "Value": "861980002"
        },
        {
          "Label": "Audit/Compliance",
          "Value": "861980003"
        },
        {
          "Label": "Auxiliary/Volunteer",
          "Value": "861980004"
        },
        {
          "Label": "Board of Directors",
          "Value": "861980005"
        },
        {
          "Label": "Consulting Services",
          "Value": "861980006"
        },
        {
          "Label": "Corporate Banking",
          "Value": "861980007"
        },
        {
          "Label": "Corporate Library",
          "Value": "861980008"
        },
        {
          "Label": "Corporate Planning Group",
          "Value": "861980009"
        },
        {
          "Label": "Corporate Strategy Group",
          "Value": "861980010"
        },
        {
          "Label": "Credit Cards",
          "Value": "861980011"
        },
        {
          "Label": "Customer Service/Support",
          "Value": "861980012"
        },
        {
          "Label": "Development",
          "Value": "861980013"
        },
        {
          "Label": "Distribution & Logistics",
          "Value": "861980014"
        },
        {
          "Label": "E-commerce",
          "Value": "861980015"
        },
        {
          "Label": "Education & Training",
          "Value": "861980016"
        },
        {
          "Label": "Engineering",
          "Value": "861980017"
        },
        {
          "Label": "Equities",
          "Value": "861980018"
        },
        {
          "Label": "Executive/General Management",
          "Value": "861980019"
        },
        {
          "Label": "Finance/Accounting",
          "Value": "861980020"
        },
        {
          "Label": "Fixed Income",
          "Value": "861980021"
        },
        {
          "Label": "Fundraising/Foundation",
          "Value": "861980022"
        },
        {
          "Label": "Health Services",
          "Value": "861980023"
        },
        {
          "Label": "High Net Worth",
          "Value": "861980024"
        },
        {
          "Label": "Human Resources",
          "Value": "861980025"
        },
        {
          "Label": "Information Systems/Technology",
          "Value": "861980026"
        },
        {
          "Label": "Institutional",
          "Value": "861980027"
        },
        {
          "Label": "Investment Banking",
          "Value": "861980028"
        },
        {
          "Label": "Investment Research",
          "Value": "861980029"
        },
        {
          "Label": "Investor Relations",
          "Value": "861980030"
        },
        {
          "Label": "IT Security",
          "Value": "861980031"
        },
        {
          "Label": "Lab Services",
          "Value": "861980032"
        },
        {
          "Label": "Legal & Corporate Affairs",
          "Value": "861980033"
        },
        {
          "Label": "Manufacturing & Production",
          "Value": "861980034"
        },
        {
          "Label": "Marketing/Advertising",
          "Value": "861980035"
        },
        {
          "Label": "Medical Records",
          "Value": "861980036"
        },
        {
          "Label": "Merchandising/Buying",
          "Value": "861980037"
        },
        {
          "Label": "Money Markets",
          "Value": "861980038"
        },
        {
          "Label": "Operations",
          "Value": "861980039"
        },
        {
          "Label": "OSS/BSS",
          "Value": "861980040"
        },
        {
          "Label": "Other",
          "Value": "861980041"
        },
        {
          "Label": "Patient Relations",
          "Value": "861980042"
        },
        {
          "Label": "Payments",
          "Value": "861980043"
        },
        {
          "Label": "Product Development",
          "Value": "861980044"
        },
        {
          "Label": "Property & Casualty",
          "Value": "861980045"
        },
        {
          "Label": "Provider Relations",
          "Value": "861980046"
        },
        {
          "Label": "Public Relations",
          "Value": "861980047"
        },
        {
          "Label": "Purchasing",
          "Value": "861980048"
        },
        {
          "Label": "Real Estate",
          "Value": "861980049"
        },
        {
          "Label": "Research & Development",
          "Value": "861980050"
        },
        {
          "Label": "Retail Banking",
          "Value": "861980051"
        },
        {
          "Label": "Retail Brokerage",
          "Value": "861980052"
        },
        {
          "Label": "Risk Management",
          "Value": "861980053"
        },
        {
          "Label": "Sales",
          "Value": "861980054"
        },
        {
          "Label": "Social Services",
          "Value": "861980055"
        },
        {
          "Label": "Store Systems",
          "Value": "861980056"
        },
        {
          "Label": "Strategic Planning",
          "Value": "861980057"
        },
        {
          "Label": "Unknown",
          "Value": "861980058"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowbusinessphonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowbusinessphonemodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Business Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowemailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Email",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowemailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Email Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowfaxcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Fax",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowfaxmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Fax Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Mail",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Mail Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmobilephonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmobilephonemodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Mobile Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnerbusinessphonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnerbusinessphonemodifiedon",
      "Type": "datetime",
      "DisplayName": "DNU_Do not allow Partner Business Phone ModifiedOn",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartneremailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Email",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartneremailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Partner Email Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mail",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mail Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermobilephonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermobilephonemodifiedon",
      "Type": "datetime",
      "DisplayName": "DNU_Do not allow Partner Mobile Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsraction",
      "Type": "string",
      "DisplayName": "DSRAction",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsrdate",
      "Type": "datetime",
      "DisplayName": "DSRDate",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsrticket",
      "Type": "string",
      "DisplayName": "DSRTicket#",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_emailsenttopartnercontact",
      "Type": "boolean",
      "DisplayName": "Email Sent To Partner Contact",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_flagbusinessphone",
      "Type": "picklist",
      "DisplayName": "Flag Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Validated",
          "Value": "0"
        },
        {
          "Label": "OK",
          "Value": "1"
        },
        {
          "Label": "Cancel",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_flagmobilephone",
      "Type": "picklist",
      "DisplayName": "Flag Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Validated",
          "Value": "0"
        },
        {
          "Label": "OK",
          "Value": "1"
        },
        {
          "Label": "Cancel",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_hcpcountrycode",
      "Type": "picklist",
      "DisplayName": "Country (Not Used)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Afghanistan",
          "Value": "4909"
        },
        {
          "Label": "Åland Islands",
          "Value": "228517"
        },
        {
          "Label": "Albania",
          "Value": "4910"
        },
        {
          "Label": "Algeria",
          "Value": "4911"
        },
        {
          "Label": "American Samoa",
          "Value": "4912"
        },
        {
          "Label": "Andorra",
          "Value": "4913"
        },
        {
          "Label": "Angola",
          "Value": "4914"
        },
        {
          "Label": "Anguilla",
          "Value": "4915"
        },
        {
          "Label": "Antarctica",
          "Value": "4916"
        },
        {
          "Label": "Antigua and Barbuda",
          "Value": "4917"
        },
        {
          "Label": "Argentina",
          "Value": "4918"
        },
        {
          "Label": "Armenia",
          "Value": "4919"
        },
        {
          "Label": "Aruba",
          "Value": "4920"
        },
        {
          "Label": "Australia",
          "Value": "4921"
        },
        {
          "Label": "Austria",
          "Value": "4922"
        },
        {
          "Label": "Azerbaijan",
          "Value": "4923"
        },
        {
          "Label": "Bahamas",
          "Value": "4924"
        },
        {
          "Label": "Bahrain",
          "Value": "4925"
        },
        {
          "Label": "Bangladesh",
          "Value": "4926"
        },
        {
          "Label": "Barbados",
          "Value": "4927"
        },
        {
          "Label": "Belarus",
          "Value": "4928"
        },
        {
          "Label": "Belgium",
          "Value": "4929"
        },
        {
          "Label": "Belize",
          "Value": "4930"
        },
        {
          "Label": "Benin",
          "Value": "4931"
        },
        {
          "Label": "Bermuda",
          "Value": "4932"
        },
        {
          "Label": "Bhutan",
          "Value": "4933"
        },
        {
          "Label": "Bolivia",
          "Value": "4934"
        },
        {
          "Label": "Bonaire",
          "Value": "2082124"
        },
        {
          "Label": "Bosnia and Herzegovina",
          "Value": "4935"
        },
        {
          "Label": "Botswana",
          "Value": "4936"
        },
        {
          "Label": "Bouvet Island",
          "Value": "4937"
        },
        {
          "Label": "Brazil",
          "Value": "4938"
        },
        {
          "Label": "British Indian Ocean Territory",
          "Value": "4939"
        },
        {
          "Label": "British Virgin Islands",
          "Value": "5139"
        },
        {
          "Label": "Brunei",
          "Value": "4940"
        },
        {
          "Label": "Bulgaria",
          "Value": "4941"
        },
        {
          "Label": "Burkina Faso",
          "Value": "4942"
        },
        {
          "Label": "Burundi",
          "Value": "4943"
        },
        {
          "Label": "Cabo Verde",
          "Value": "4947"
        },
        {
          "Label": "Cambodia",
          "Value": "4944"
        },
        {
          "Label": "Cameroon",
          "Value": "4945"
        },
        {
          "Label": "Canada",
          "Value": "4946"
        },
        {
          "Label": "Cayman Islands",
          "Value": "4948"
        },
        {
          "Label": "Central African Republic",
          "Value": "4949"
        },
        {
          "Label": "Chad",
          "Value": "4950"
        },
        {
          "Label": "Chile",
          "Value": "4951"
        },
        {
          "Label": "China",
          "Value": "4952"
        },
        {
          "Label": "Christmas Island",
          "Value": "4953"
        },
        {
          "Label": "Cocos (Keeling) Islands",
          "Value": "4954"
        },
        {
          "Label": "Colombia",
          "Value": "4955"
        },
        {
          "Label": "Comoros",
          "Value": "4956"
        },
        {
          "Label": "Congo",
          "Value": "4957"
        },
        {
          "Label": "Congo (DRC)",
          "Value": "4958"
        },
        {
          "Label": "Cook Islands",
          "Value": "4959"
        },
        {
          "Label": "Costa Rica",
          "Value": "4960"
        },
        {
          "Label": "Côte d'Ivoire",
          "Value": "4961"
        },
        {
          "Label": "Croatia",
          "Value": "4962"
        },
        {
          "Label": "Cuba",
          "Value": "4963"
        },
        {
          "Label": "Curaçao",
          "Value": "2082125"
        },
        {
          "Label": "Cyprus",
          "Value": "4964"
        },
        {
          "Label": "Czech Republic",
          "Value": "4965"
        },
        {
          "Label": "Denmark",
          "Value": "4966"
        },
        {
          "Label": "Djibouti",
          "Value": "4967"
        },
        {
          "Label": "Dominica",
          "Value": "4968"
        },
        {
          "Label": "Dominican Republic",
          "Value": "4969"
        },
        {
          "Label": "Ecuador",
          "Value": "4971"
        },
        {
          "Label": "Egypt",
          "Value": "4972"
        },
        {
          "Label": "El Salvador",
          "Value": "4973"
        },
        {
          "Label": "Equatorial Guinea",
          "Value": "4974"
        },
        {
          "Label": "Eritrea",
          "Value": "4975"
        },
        {
          "Label": "Estonia",
          "Value": "4976"
        },
        {
          "Label": "Ethiopia",
          "Value": "4977"
        },
        {
          "Label": "Falkland Islands",
          "Value": "4978"
        },
        {
          "Label": "Faroe Islands",
          "Value": "4979"
        },
        {
          "Label": "Fiji",
          "Value": "4980"
        },
        {
          "Label": "Finland",
          "Value": "4981"
        },
        {
          "Label": "France",
          "Value": "4982"
        },
        {
          "Label": "French Guiana",
          "Value": "4983"
        },
        {
          "Label": "French Polynesia",
          "Value": "4984"
        },
        {
          "Label": "French Southern and Antarctic Lands",
          "Value": "4985"
        },
        {
          "Label": "Gabon",
          "Value": "4986"
        },
        {
          "Label": "Gambia",
          "Value": "4987"
        },
        {
          "Label": "Georgia",
          "Value": "4988"
        },
        {
          "Label": "Germany",
          "Value": "4989"
        },
        {
          "Label": "Ghana",
          "Value": "4990"
        },
        {
          "Label": "Gibraltar",
          "Value": "4991"
        },
        {
          "Label": "Greece",
          "Value": "4992"
        },
        {
          "Label": "Greenland",
          "Value": "4993"
        },
        {
          "Label": "Grenada",
          "Value": "4994"
        },
        {
          "Label": "Guadeloupe",
          "Value": "4995"
        },
        {
          "Label": "Guam",
          "Value": "4996"
        },
        {
          "Label": "Guatemala",
          "Value": "4997"
        },
        {
          "Label": "Guernsey",
          "Value": "85385"
        },
        {
          "Label": "Guinea",
          "Value": "4998"
        },
        {
          "Label": "Guinea-Bissau",
          "Value": "4999"
        },
        {
          "Label": "Guyana",
          "Value": "5000"
        },
        {
          "Label": "Haiti",
          "Value": "5001"
        },
        {
          "Label": "Heard Island and McDonald Islands",
          "Value": "5002"
        },
        {
          "Label": "Honduras",
          "Value": "5003"
        },
        {
          "Label": "Hong Kong SAR",
          "Value": "5004"
        },
        {
          "Label": "Hungary",
          "Value": "5005"
        },
        {
          "Label": "Iceland",
          "Value": "5006"
        },
        {
          "Label": "India",
          "Value": "5007"
        },
        {
          "Label": "Indonesia",
          "Value": "5008"
        },
        {
          "Label": "Iran",
          "Value": "5009"
        },
        {
          "Label": "Iraq",
          "Value": "5010"
        },
        {
          "Label": "Ireland",
          "Value": "5011"
        },
        {
          "Label": "Isle of Man",
          "Value": "24459"
        },
        {
          "Label": "Israel",
          "Value": "5012"
        },
        {
          "Label": "Italy",
          "Value": "5013"
        },
        {
          "Label": "Jamaica",
          "Value": "5014"
        },
        {
          "Label": "Jan Mayen",
          "Value": "24458"
        },
        {
          "Label": "Japan",
          "Value": "5015"
        },
        {
          "Label": "Jersey",
          "Value": "85388"
        },
        {
          "Label": "Jordan",
          "Value": "5016"
        },
        {
          "Label": "Kazakhstan",
          "Value": "5017"
        },
        {
          "Label": "Kenya",
          "Value": "5018"
        },
        {
          "Label": "Kiribati",
          "Value": "5019"
        },
        {
          "Label": "Korea",
          "Value": "5020"
        },
        {
          "Label": "Kosovo",
          "Value": "30006102"
        },
        {
          "Label": "Kuwait",
          "Value": "5021"
        },
        {
          "Label": "Kyrgyzstan",
          "Value": "5022"
        },
        {
          "Label": "Laos",
          "Value": "5023"
        },
        {
          "Label": "Latvia",
          "Value": "5024"
        },
        {
          "Label": "Lebanon",
          "Value": "5025"
        },
        {
          "Label": "Lesotho",
          "Value": "5026"
        },
        {
          "Label": "Liberia",
          "Value": "5027"
        },
        {
          "Label": "Libya",
          "Value": "5028"
        },
        {
          "Label": "Liechtenstein",
          "Value": "5029"
        },
        {
          "Label": "Lithuania",
          "Value": "5030"
        },
        {
          "Label": "Luxembourg",
          "Value": "5031"
        },
        {
          "Label": "Macao SAR",
          "Value": "5032"
        },
        {
          "Label": "Macedonia, FYRO",
          "Value": "5033"
        },
        {
          "Label": "Madagascar",
          "Value": "5034"
        },
        {
          "Label": "Malawi",
          "Value": "5035"
        },
        {
          "Label": "Malaysia",
          "Value": "5036"
        },
        {
          "Label": "Maldives",
          "Value": "5037"
        },
        {
          "Label": "Mali",
          "Value": "5038"
        },
        {
          "Label": "Malta",
          "Value": "5039"
        },
        {
          "Label": "Marshall Islands",
          "Value": "5040"
        },
        {
          "Label": "Martinique",
          "Value": "5041"
        },
        {
          "Label": "Mauritania",
          "Value": "5042"
        },
        {
          "Label": "Mauritius",
          "Value": "5043"
        },
        {
          "Label": "Mayotte",
          "Value": "5044"
        },
        {
          "Label": "Mexico",
          "Value": "5045"
        },
        {
          "Label": "Micronesia",
          "Value": "5046"
        },
        {
          "Label": "Moldova",
          "Value": "5047"
        },
        {
          "Label": "Monaco",
          "Value": "5048"
        },
        {
          "Label": "Mongolia",
          "Value": "5049"
        },
        {
          "Label": "Montenegro",
          "Value": "85393"
        },
        {
          "Label": "Montserrat",
          "Value": "5050"
        },
        {
          "Label": "Morocco",
          "Value": "5051"
        },
        {
          "Label": "Mozambique",
          "Value": "5052"
        },
        {
          "Label": "Myanmar",
          "Value": "5053"
        },
        {
          "Label": "Namibia",
          "Value": "5054"
        },
        {
          "Label": "Nauru",
          "Value": "5055"
        },
        {
          "Label": "Nepal",
          "Value": "5056"
        },
        {
          "Label": "Netherlands",
          "Value": "5058"
        },
        {
          "Label": "New Caledonia",
          "Value": "5059"
        },
        {
          "Label": "New Zealand",
          "Value": "5060"
        },
        {
          "Label": "Nicaragua",
          "Value": "5061"
        },
        {
          "Label": "Niger",
          "Value": "5062"
        },
        {
          "Label": "Nigeria",
          "Value": "5063"
        },
        {
          "Label": "Niue",
          "Value": "5064"
        },
        {
          "Label": "Norfolk Island",
          "Value": "5065"
        },
        {
          "Label": "North Korea",
          "Value": "5066"
        },
        {
          "Label": "Northern Mariana Islands",
          "Value": "5067"
        },
        {
          "Label": "Norway",
          "Value": "5068"
        },
        {
          "Label": "Oman",
          "Value": "5069"
        },
        {
          "Label": "Pakistan",
          "Value": "5070"
        },
        {
          "Label": "Palau",
          "Value": "5071"
        },
        {
          "Label": "Palestinian Authority",
          "Value": "10193"
        },
        {
          "Label": "Panama",
          "Value": "5072"
        },
        {
          "Label": "Papua New Guinea",
          "Value": "5073"
        },
        {
          "Label": "Paraguay",
          "Value": "5074"
        },
        {
          "Label": "Peru",
          "Value": "5075"
        },
        {
          "Label": "Philippines",
          "Value": "5076"
        },
        {
          "Label": "Pitcairn Islands",
          "Value": "5077"
        },
        {
          "Label": "Poland",
          "Value": "5078"
        },
        {
          "Label": "Portugal",
          "Value": "5079"
        },
        {
          "Label": "Puerto Rico",
          "Value": "5080"
        },
        {
          "Label": "Qatar",
          "Value": "5081"
        },
        {
          "Label": "Réunion",
          "Value": "5082"
        },
        {
          "Label": "Romania",
          "Value": "5083"
        },
        {
          "Label": "Russia",
          "Value": "5084"
        },
        {
          "Label": "Rwanda",
          "Value": "5085"
        },
        {
          "Label": "Saba",
          "Value": "2082126"
        },
        {
          "Label": "Saint Barthélemy",
          "Value": "1527812"
        },
        {
          "Label": "Saint Kitts and Nevis",
          "Value": "5103"
        },
        {
          "Label": "Saint Lucia",
          "Value": "5104"
        },
        {
          "Label": "Saint Martin",
          "Value": "1527813"
        },
        {
          "Label": "Saint Pierre and Miquelon",
          "Value": "5087"
        },
        {
          "Label": "Saint Vincent and the Grenadines",
          "Value": "5105"
        },
        {
          "Label": "Samoa",
          "Value": "5088"
        },
        {
          "Label": "San Marino",
          "Value": "5089"
        },
        {
          "Label": "São Tomé and Príncipe",
          "Value": "5090"
        },
        {
          "Label": "Saudi Arabia",
          "Value": "5091"
        },
        {
          "Label": "Senegal",
          "Value": "5092"
        },
        {
          "Label": "Serbia",
          "Value": "75314"
        },
        {
          "Label": "Seychelles",
          "Value": "5093"
        },
        {
          "Label": "Sierra Leone",
          "Value": "5094"
        },
        {
          "Label": "Singapore",
          "Value": "5095"
        },
        {
          "Label": "Sint Eustatius",
          "Value": "2082127"
        },
        {
          "Label": "Sint Maarten",
          "Value": "2082128"
        },
        {
          "Label": "Slovakia",
          "Value": "5096"
        },
        {
          "Label": "Slovenia",
          "Value": "5097"
        },
        {
          "Label": "Solomon Islands",
          "Value": "5098"
        },
        {
          "Label": "Somalia",
          "Value": "5099"
        },
        {
          "Label": "South Africa",
          "Value": "5100"
        },
        {
          "Label": "South Georgia and the South Sandwich Islands",
          "Value": "5106"
        },
        {
          "Label": "South Sudan",
          "Value": "2313196"
        },
        {
          "Label": "Spain",
          "Value": "5101"
        },
        {
          "Label": "Sri Lanka",
          "Value": "5102"
        },
        {
          "Label": "St Helena, Ascension, Tristan da Cunha",
          "Value": "5086"
        },
        {
          "Label": "Sudan",
          "Value": "5107"
        },
        {
          "Label": "Suriname",
          "Value": "5108"
        },
        {
          "Label": "Svalbard",
          "Value": "5109"
        },
        {
          "Label": "Swaziland",
          "Value": "5110"
        },
        {
          "Label": "Sweden",
          "Value": "5111"
        },
        {
          "Label": "Switzerland",
          "Value": "5112"
        },
        {
          "Label": "Syria",
          "Value": "5113"
        },
        {
          "Label": "Taiwan",
          "Value": "5114"
        },
        {
          "Label": "Tajikistan",
          "Value": "5115"
        },
        {
          "Label": "Tanzania",
          "Value": "5116"
        },
        {
          "Label": "Thailand",
          "Value": "5117"
        },
        {
          "Label": "Timor-Leste",
          "Value": "93100"
        },
        {
          "Label": "Togo",
          "Value": "5118"
        },
        {
          "Label": "Tokelau",
          "Value": "5119"
        },
        {
          "Label": "Tonga",
          "Value": "5120"
        },
        {
          "Label": "Trinidad and Tobago",
          "Value": "5121"
        },
        {
          "Label": "Tunisia",
          "Value": "5122"
        },
        {
          "Label": "Turkey",
          "Value": "5123"
        },
        {
          "Label": "Turkmenistan",
          "Value": "5124"
        },
        {
          "Label": "Turks and Caicos Islands",
          "Value": "5125"
        },
        {
          "Label": "Tuvalu",
          "Value": "5126"
        },
        {
          "Label": "U.S. Outlying Islands",
          "Value": "24460"
        },
        {
          "Label": "U.S. Virgin Islands",
          "Value": "5138"
        },
        {
          "Label": "Uganda",
          "Value": "5127"
        },
        {
          "Label": "Ukraine",
          "Value": "5128"
        },
        {
          "Label": "United Arab Emirates",
          "Value": "5129"
        },
        {
          "Label": "United Kingdom",
          "Value": "5130"
        },
        {
          "Label": "United States",
          "Value": "5131"
        },
        {
          "Label": "Unknown",
          "Value": "0"
        },
        {
          "Label": "Uruguay",
          "Value": "5132"
        },
        {
          "Label": "Uzbekistan",
          "Value": "5133"
        },
        {
          "Label": "Vanuatu",
          "Value": "5134"
        },
        {
          "Label": "Vatican City",
          "Value": "5135"
        },
        {
          "Label": "Venezuela",
          "Value": "5136"
        },
        {
          "Label": "Vietnam",
          "Value": "5137"
        },
        {
          "Label": "Wallis and Futuna",
          "Value": "5140"
        },
        {
          "Label": "Yemen",
          "Value": "5141"
        },
        {
          "Label": "Zambia",
          "Value": "5143"
        },
        {
          "Label": "Zimbabwe",
          "Value": "5144"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_hygieneorder",
      "Type": "string",
      "DisplayName": "Hygiene Order",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_hygienetype",
      "Type": "string",
      "DisplayName": "Hygiene Type",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_influencelevelcode",
      "Type": "picklist",
      "DisplayName": "Influence Level",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Detractor",
          "Value": "861980000"
        },
        {
          "Label": "Neutral",
          "Value": "861980001"
        },
        {
          "Label": "Supporter",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_isemailsuppressed",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Is Email Suppressed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Hard Suppressed",
          "Value": "1"
        },
        {
          "Label": "Not Suppressed",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_ispersonasuppressed",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Is Persona Suppressed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Hard Suppressed",
          "Value": "1"
        },
        {
          "Label": "Not Suppressed",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_issuppressedreason",
      "Type": "string",
      "DisplayName": "Do_Not_Use_Is Suppressed Reason",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_jobrolecode",
      "Type": "picklist",
      "DisplayName": "Job Role",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Chief Risk Officer",
          "Value": "861981305"
        },
        {
          "Label": "Chief Privacy Officer",
          "Value": "861981304"
        },
        {
          "Label": "Data Protection Officer",
          "Value": "861981303"
        },
        {
          "Label": "Chief Compliance Officer",
          "Value": "861981302"
        },
        {
          "Label": "Vice President Compliance",
          "Value": "861981301"
        },
        {
          "Label": "Information Technology Governance Manager",
          "Value": "861981300"
        },
        {
          "Label": "Director of Compliance",
          "Value": "861981299"
        },
        {
          "Label": "Compliance Manager",
          "Value": "861981298"
        },
        {
          "Label": "Assistant",
          "Value": "861980007"
        },
        {
          "Label": "Banker",
          "Value": "861980008"
        },
        {
          "Label": "Board Member",
          "Value": "861980010"
        },
        {
          "Label": "Business Analyst",
          "Value": "861980125"
        },
        {
          "Label": "Business Architect",
          "Value": "861980012"
        },
        {
          "Label": "Compliance Specialist",
          "Value": "861981297"
        },
        {
          "Label": "Chairman",
          "Value": "861980143"
        },
        {
          "Label": "Chief Academic Officer",
          "Value": "861980132"
        },
        {
          "Label": "Chief Architect IT",
          "Value": "861980015"
        },
        {
          "Label": "Chief Data Scientist",
          "Value": "861981288"
        },
        {
          "Label": "Chief Digital Officer",
          "Value": "861980121"
        },
        {
          "Label": "Chief Executive Officer",
          "Value": "861980016"
        },
        {
          "Label": "Chief Financial Officer",
          "Value": "861980017"
        },
        {
          "Label": "Chief Human Resources Officer",
          "Value": "861980141"
        },
        {
          "Label": "Chief Information Officer",
          "Value": "861980018"
        },
        {
          "Label": "Chief Knowledge Officer",
          "Value": "861980019"
        },
        {
          "Label": "Chief Marketing Officer",
          "Value": "861980020"
        },
        {
          "Label": "Chief Medical Info Officer",
          "Value": "861980138"
        },
        {
          "Label": "Chief Medical Officer",
          "Value": "861980021"
        },
        {
          "Label": "Chief Of Legal Department",
          "Value": "861980005"
        },
        {
          "Label": "Chief of Staff",
          "Value": "861980124"
        },
        {
          "Label": "Chief Operations Officer",
          "Value": "861980022"
        },
        {
          "Label": "Chief Procurement Officer",
          "Value": "861980144"
        },
        {
          "Label": "Chief Research Officer",
          "Value": "861980129"
        },
        {
          "Label": "Chief Scientific Officer",
          "Value": "861980130"
        },
        {
          "Label": "Chief Supply Chain Officer",
          "Value": "861980140"
        },
        {
          "Label": "Chief Talent Officer",
          "Value": "861980142"
        },
        {
          "Label": "Chief Technology Officer",
          "Value": "861980025"
        },
        {
          "Label": "Consultant Non-IT",
          "Value": "861980145"
        },
        {
          "Label": "Consultant-IT",
          "Value": "861980146"
        },
        {
          "Label": "Consultant-IT Purchasing",
          "Value": "861980147"
        },
        {
          "Label": "Contract Specialist",
          "Value": "861980027"
        },
        {
          "Label": "Controller",
          "Value": "861980028"
        },
        {
          "Label": "CSO/CISO",
          "Value": "861980148"
        },
        {
          "Label": "Data Analyst",
          "Value": "861981289"
        },
        {
          "Label": "Data Architect",
          "Value": "861981290"
        },
        {
          "Label": "Data Engineer",
          "Value": "861981291"
        },
        {
          "Label": "Data Scientist",
          "Value": "861981292"
        },
        {
          "Label": "Data Specialist",
          "Value": "861981293"
        },
        {
          "Label": "Developer",
          "Value": "861980034"
        },
        {
          "Label": "Developer Architect",
          "Value": "861980149"
        },
        {
          "Label": "Developer Designer",
          "Value": "861980150"
        },
        {
          "Label": "Developer Hobbyist",
          "Value": "861980151"
        },
        {
          "Label": "Developer Web",
          "Value": "861980152"
        },
        {
          "Label": "Director Account Management",
          "Value": "861980153"
        },
        {
          "Label": "Director Accounting",
          "Value": "861980154"
        },
        {
          "Label": "Director Administration",
          "Value": "861980155"
        },
        {
          "Label": "Director Architecture",
          "Value": "861980156"
        },
        {
          "Label": "Director Benefits",
          "Value": "861980157"
        },
        {
          "Label": "Director Business Development",
          "Value": "861980158"
        },
        {
          "Label": "Director Business Intelligence",
          "Value": "861980159"
        },
        {
          "Label": "Director Call Center",
          "Value": "861980160"
        },
        {
          "Label": "Director Customer Service",
          "Value": "861980161"
        },
        {
          "Label": "Director Digital",
          "Value": "861980162"
        },
        {
          "Label": "Director Ecommerce",
          "Value": "861980163"
        },
        {
          "Label": "Director Education",
          "Value": "861980164"
        },
        {
          "Label": "Director Finance",
          "Value": "861980165"
        },
        {
          "Label": "Director Finance Purchasing",
          "Value": "861980166"
        },
        {
          "Label": "Director Human Resources",
          "Value": "861980167"
        },
        {
          "Label": "Director IT",
          "Value": "861980168"
        },
        {
          "Label": "Director IT Purchasing",
          "Value": "861980169"
        },
        {
          "Label": "Director Legal",
          "Value": "861980170"
        },
        {
          "Label": "Director Logistics",
          "Value": "861980171"
        },
        {
          "Label": "Director Manufacturing",
          "Value": "861980172"
        },
        {
          "Label": "Director Marketing",
          "Value": "861980173"
        },
        {
          "Label": "Director Medical",
          "Value": "861980174"
        },
        {
          "Label": "Director of Data Science",
          "Value": "861981294"
        },
        {
          "Label": "Director of Data Services",
          "Value": "861981295"
        },
        {
          "Label": "Director of Education Tech",
          "Value": "861980134"
        },
        {
          "Label": "Director of Instructional Tech",
          "Value": "861980133"
        },
        {
          "Label": "Director Operations",
          "Value": "861980175"
        },
        {
          "Label": "Director Other",
          "Value": "861980176"
        },
        {
          "Label": "Director Planning",
          "Value": "861980177"
        },
        {
          "Label": "Director Procurement",
          "Value": "861980178"
        },
        {
          "Label": "Director Product Management",
          "Value": "861980179"
        },
        {
          "Label": "Director Project Management",
          "Value": "861980180"
        },
        {
          "Label": "Director Property Management",
          "Value": "861980181"
        },
        {
          "Label": "Director Public Relations",
          "Value": "861980182"
        },
        {
          "Label": "Director Public Sector",
          "Value": "861980183"
        },
        {
          "Label": "Director Publication",
          "Value": "861980184"
        },
        {
          "Label": "Director Purchasing",
          "Value": "861980185"
        },
        {
          "Label": "Director Research Development",
          "Value": "861980186"
        },
        {
          "Label": "Director Sales",
          "Value": "861980187"
        },
        {
          "Label": "Director Sales Purchasing",
          "Value": "861980188"
        },
        {
          "Label": "Director Security",
          "Value": "861980189"
        },
        {
          "Label": "Director Service Support",
          "Value": "861980190"
        },
        {
          "Label": "Director Sustainabillity",
          "Value": "861980191"
        },
        {
          "Label": "Director Training",
          "Value": "861980192"
        },
        {
          "Label": "Director Transportation",
          "Value": "861980193"
        },
        {
          "Label": "Director Warehousing",
          "Value": "861980194"
        },
        {
          "Label": "Emergency Response Lead",
          "Value": "861980037"
        },
        {
          "Label": "Enrolled Agent",
          "Value": "861980038"
        },
        {
          "Label": "Environmntl Sustainblty Lead",
          "Value": "861980039"
        },
        {
          "Label": "Executive Other",
          "Value": "861980195"
        },
        {
          "Label": "General/Admiral/Commander",
          "Value": "861980044"
        },
        {
          "Label": "Governor",
          "Value": "861980045"
        },
        {
          "Label": "Head of Applications",
          "Value": "861980126"
        },
        {
          "Label": "Head Of Library/Info Services",
          "Value": "861980049"
        },
        {
          "Label": "Head Of Teaching And Learning",
          "Value": "861980050"
        },
        {
          "Label": "HiED",
          "Value": "861980128"
        },
        {
          "Label": "Infrastructure Architect",
          "Value": "861980052"
        },
        {
          "Label": "IT Analyst",
          "Value": "861980196"
        },
        {
          "Label": "IT Executive",
          "Value": "861980139"
        },
        {
          "Label": "Judge",
          "Value": "861980061"
        },
        {
          "Label": "K12 Teacher",
          "Value": "861980120"
        },
        {
          "Label": "Lawyer",
          "Value": "861980119"
        },
        {
          "Label": "Manager Account Management",
          "Value": "861980197"
        },
        {
          "Label": "Manager Accounting",
          "Value": "861980198"
        },
        {
          "Label": "Manager Administration",
          "Value": "861980199"
        },
        {
          "Label": "Manager Architecture",
          "Value": "861980200"
        },
        {
          "Label": "Manager Benefits",
          "Value": "861980201"
        },
        {
          "Label": "Manager Business Development",
          "Value": "861980202"
        },
        {
          "Label": "Manager Business Intelligence",
          "Value": "861980203"
        },
        {
          "Label": "Manager Call Center",
          "Value": "861980204"
        },
        {
          "Label": "Manager Customer Service",
          "Value": "861980205"
        },
        {
          "Label": "Manager Developer Professional",
          "Value": "861980206"
        },
        {
          "Label": "Manager Digital",
          "Value": "861980207"
        },
        {
          "Label": "Manager Ecommerce",
          "Value": "861980208"
        },
        {
          "Label": "Manager Education",
          "Value": "861980209"
        },
        {
          "Label": "Manager Finance",
          "Value": "861980210"
        },
        {
          "Label": "Manager Finance Purchasing",
          "Value": "861980211"
        },
        {
          "Label": "Manager Human Resources",
          "Value": "861980212"
        },
        {
          "Label": "Manager IT",
          "Value": "861980213"
        },
        {
          "Label": "Manager IT Purchasing",
          "Value": "861980214"
        },
        {
          "Label": "Manager Legal",
          "Value": "861980215"
        },
        {
          "Label": "Manager Logistics",
          "Value": "861980216"
        },
        {
          "Label": "Manager Manufacturing",
          "Value": "861980217"
        },
        {
          "Label": "Manager Marketing",
          "Value": "861980218"
        },
        {
          "Label": "Manager Medical",
          "Value": "861980219"
        },
        {
          "Label": "Manager Mkt Purchasing",
          "Value": "861980220"
        },
        {
          "Label": "Manager Operations",
          "Value": "861980221"
        },
        {
          "Label": "Manager Other",
          "Value": "861980222"
        },
        {
          "Label": "Manager Planning",
          "Value": "861980223"
        },
        {
          "Label": "Manager Procurement",
          "Value": "861980224"
        },
        {
          "Label": "Manager Product Management",
          "Value": "861980225"
        },
        {
          "Label": "Manager Project Management",
          "Value": "861980226"
        },
        {
          "Label": "Manager Property Management",
          "Value": "861980227"
        },
        {
          "Label": "Manager Public Relations",
          "Value": "861980228"
        },
        {
          "Label": "Manager Public Sector",
          "Value": "861980229"
        },
        {
          "Label": "Manager Publication",
          "Value": "861980230"
        },
        {
          "Label": "Manager Purchasing",
          "Value": "861980231"
        },
        {
          "Label": "Manager Research Development",
          "Value": "861980232"
        },
        {
          "Label": "Manager Sales",
          "Value": "861980233"
        },
        {
          "Label": "Manager Security",
          "Value": "861980234"
        },
        {
          "Label": "Manager Service Support",
          "Value": "861980235"
        },
        {
          "Label": "Manager Sustainabillity",
          "Value": "861980236"
        },
        {
          "Label": "Manager Training",
          "Value": "861980237"
        },
        {
          "Label": "Manager Transportation",
          "Value": "861980238"
        },
        {
          "Label": "Manager Warehousing",
          "Value": "861980239"
        },
        {
          "Label": "Managing Director",
          "Value": "861980240"
        },
        {
          "Label": "Mayor",
          "Value": "861980066"
        },
        {
          "Label": "Minister/Secretary",
          "Value": "861980069"
        },
        {
          "Label": "Owner",
          "Value": "861980074"
        },
        {
          "Label": "Police/Fire Chief",
          "Value": "861980077"
        },
        {
          "Label": "President",
          "Value": "861980078"
        },
        {
          "Label": "Principal / Head Of School",
          "Value": "861980079"
        },
        {
          "Label": "Professional Accountant",
          "Value": "861980241"
        },
        {
          "Label": "Professional Accounting",
          "Value": "861980242"
        },
        {
          "Label": "Professional Administration",
          "Value": "861980243"
        },
        {
          "Label": "Professional Architecture",
          "Value": "861980244"
        },
        {
          "Label": "Professional Benefits",
          "Value": "861980245"
        },
        {
          "Label": "Professional Business Development",
          "Value": "861980246"
        },
        {
          "Label": "Professional Business Intelligence",
          "Value": "861980247"
        },
        {
          "Label": "Professional Call Center",
          "Value": "861980248"
        },
        {
          "Label": "Professional Customer Service",
          "Value": "861980249"
        },
        {
          "Label": "Professional Developer",
          "Value": "861980250"
        },
        {
          "Label": "Professional Digital",
          "Value": "861980251"
        },
        {
          "Label": "Professional Ecommerce",
          "Value": "861980252"
        },
        {
          "Label": "Professional Education",
          "Value": "861980253"
        },
        {
          "Label": "Professional Engineering",
          "Value": "861980254"
        },
        {
          "Label": "Professional Finance",
          "Value": "861980255"
        },
        {
          "Label": "Professional Human Resources",
          "Value": "861980256"
        },
        {
          "Label": "Professional IT",
          "Value": "861980257"
        },
        {
          "Label": "Professional IT Apps Lob",
          "Value": "861980258"
        },
        {
          "Label": "Professional IT Desktop End User",
          "Value": "861980259"
        },
        {
          "Label": "Professional IT Generalist",
          "Value": "861980260"
        },
        {
          "Label": "Professional IT Infrastructure",
          "Value": "861980261"
        },
        {
          "Label": "Professional Legal",
          "Value": "861980262"
        },
        {
          "Label": "Professional Logistics",
          "Value": "861980263"
        },
        {
          "Label": "Professional Manufacturing",
          "Value": "861980264"
        },
        {
          "Label": "Professional Marketing",
          "Value": "861980265"
        },
        {
          "Label": "Professional Medical",
          "Value": "861980266"
        },
        {
          "Label": "Professional Operations",
          "Value": "861980267"
        },
        {
          "Label": "Professional Other",
          "Value": "861980268"
        },
        {
          "Label": "Professional Planning",
          "Value": "861980269"
        },
        {
          "Label": "Professional Procurement",
          "Value": "861980270"
        },
        {
          "Label": "Professional Product Management",
          "Value": "861980271"
        },
        {
          "Label": "Professional Project Management",
          "Value": "861980272"
        },
        {
          "Label": "Professional Property Management",
          "Value": "861980273"
        },
        {
          "Label": "Professional Public Relations",
          "Value": "861980274"
        },
        {
          "Label": "Professional Public Sector",
          "Value": "861980275"
        },
        {
          "Label": "Professional Publication",
          "Value": "861980276"
        },
        {
          "Label": "Professional Purchasing",
          "Value": "861980277"
        },
        {
          "Label": "Professional Research Development",
          "Value": "861980278"
        },
        {
          "Label": "Professional Sales",
          "Value": "861980279"
        },
        {
          "Label": "Professional Security",
          "Value": "861980280"
        },
        {
          "Label": "Professional Service Support",
          "Value": "861980281"
        },
        {
          "Label": "Professional Sustainabillity",
          "Value": "861980282"
        },
        {
          "Label": "Professional Training",
          "Value": "861980283"
        },
        {
          "Label": "Professional Transportation",
          "Value": "861980284"
        },
        {
          "Label": "Professional Warehousing",
          "Value": "861980285"
        },
        {
          "Label": "Provost/Vice-Chancellor/Dean",
          "Value": "861980083"
        },
        {
          "Label": "Public Official/Public Worker",
          "Value": "861980084"
        },
        {
          "Label": "Software Analyst",
          "Value": "861981296"
        },
        {
          "Label": "Solutions Architect",
          "Value": "861980094"
        },
        {
          "Label": "Student",
          "Value": "861980099"
        },
        {
          "Label": "Superintendent",
          "Value": "861980101"
        },
        {
          "Label": "Technical Architect",
          "Value": "861980104"
        },
        {
          "Label": "Treasurer/Bursar",
          "Value": "861980109"
        },
        {
          "Label": "Unclassified",
          "Value": "861980286"
        },
        {
          "Label": "Vice President Account Management",
          "Value": "861980287"
        },
        {
          "Label": "Vice President Accounting",
          "Value": "861980288"
        },
        {
          "Label": "Vice President Administration",
          "Value": "861980289"
        },
        {
          "Label": "Vice President Architecture",
          "Value": "861980290"
        },
        {
          "Label": "Vice President Benefits",
          "Value": "861980291"
        },
        {
          "Label": "Vice President Business Development",
          "Value": "861980292"
        },
        {
          "Label": "Vice President Business Intelligence",
          "Value": "861980293"
        },
        {
          "Label": "Vice President Call Center",
          "Value": "861980294"
        },
        {
          "Label": "Vice President Customer Service",
          "Value": "861980295"
        },
        {
          "Label": "Vice President Digital",
          "Value": "861980296"
        },
        {
          "Label": "Vice President Ecommerce",
          "Value": "861980297"
        },
        {
          "Label": "Vice President Education",
          "Value": "861980298"
        },
        {
          "Label": "Vice President Finance",
          "Value": "861980299"
        },
        {
          "Label": "Vice President Human Resources",
          "Value": "861980300"
        },
        {
          "Label": "Vice President IT",
          "Value": "861980301"
        },
        {
          "Label": "Vice President Legal",
          "Value": "861980302"
        },
        {
          "Label": "Vice President Logistics",
          "Value": "861980303"
        },
        {
          "Label": "Vice President Manufacturing",
          "Value": "861980304"
        },
        {
          "Label": "Vice President Marketing",
          "Value": "861980305"
        },
        {
          "Label": "Vice President Medical",
          "Value": "861980306"
        },
        {
          "Label": "Vice President Operations",
          "Value": "861980307"
        },
        {
          "Label": "Vice President Other",
          "Value": "861980308"
        },
        {
          "Label": "Vice President Planning",
          "Value": "861980309"
        },
        {
          "Label": "Vice President Procurement",
          "Value": "861980310"
        },
        {
          "Label": "Vice President Product Management",
          "Value": "861980311"
        },
        {
          "Label": "Vice President Project Management",
          "Value": "861980312"
        },
        {
          "Label": "Vice President Public Relations",
          "Value": "861980313"
        },
        {
          "Label": "Vice President Public Sector",
          "Value": "861980314"
        },
        {
          "Label": "Vice President Publication",
          "Value": "861980315"
        },
        {
          "Label": "Vice President Purchasing",
          "Value": "861980316"
        },
        {
          "Label": "Vice President Research Development",
          "Value": "861980317"
        },
        {
          "Label": "Vice President Sales",
          "Value": "861980318"
        },
        {
          "Label": "Vice President Security",
          "Value": "861980319"
        },
        {
          "Label": "Vice President Service Support",
          "Value": "861980320"
        },
        {
          "Label": "Vice President Sustainabillity",
          "Value": "861980321"
        },
        {
          "Label": "Vice President Training",
          "Value": "861980322"
        },
        {
          "Label": "Vice President Transportation",
          "Value": "861980323"
        },
        {
          "Label": "Vice President Warehousing",
          "Value": "861980324"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastcpesurveysenddate",
      "Type": "datetime",
      "DisplayName": "Last CPE Survey Send Date",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastemaildate",
      "Type": "datetime",
      "DisplayName": "Last Email Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastmarketinginteractiondate",
      "Type": "datetime",
      "DisplayName": "Last Marketing Interaction Date",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastmeetingdate",
      "Type": "datetime",
      "DisplayName": "Last Meeting Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastsolutionareaengaged",
      "Type": "picklist",
      "DisplayName": "Last Solution Area Engaged(Marketing)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Digital and Application Innovation",
          "Value": "861980004"
        },
        {
          "Label": "Financial Services",
          "Value": "861980007"
        },
        {
          "Label": "Healthcare",
          "Value": "861980008"
        },
        {
          "Label": "Retail",
          "Value": "861980009"
        },
        {
          "Label": "Security",
          "Value": "861980005"
        },
        {
          "Label": "Sustainability",
          "Value": "861980010"
        },
        {
          "Label": "Unified Support",
          "Value": "861980011"
        },
        {
          "Label": "Business Applications",
          "Value": "394380001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980000"
        },
        {
          "Label": "Infrastructure",
          "Value": "394380000"
        },
        {
          "Label": "Modern Work",
          "Value": "394380002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_linkedinprofileurl",
      "Type": "string",
      "DisplayName": "LinkedIn Profile URL",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_managedcode",
      "Type": "boolean",
      "DisplayName": "Managed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_marketingaudiencecode",
      "Type": "picklist",
      "DisplayName": "Marketing Audience",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Security and Compliance Decision Maker",
          "Value": "861981305"
        },
        {
          "Label": "Academic",
          "Value": "861980000"
        },
        {
          "Label": "Additional Information Worker",
          "Value": "861980002"
        },
        {
          "Label": "Architect",
          "Value": "861980001"
        },
        {
          "Label": "BDM Other",
          "Value": "861980025"
        },
        {
          "Label": "Business Decision Maker",
          "Value": "861980024"
        },
        {
          "Label": "CEO / Owner BDM",
          "Value": "861980026"
        },
        {
          "Label": "CXO BDM",
          "Value": "861980027"
        },
        {
          "Label": "CXO TDM",
          "Value": "861980028"
        },
        {
          "Label": "Designer",
          "Value": "861980005"
        },
        {
          "Label": "Developer",
          "Value": "861980029"
        },
        {
          "Label": "Educator",
          "Value": "861980030"
        },
        {
          "Label": "Executive BDM",
          "Value": "861980031"
        },
        {
          "Label": "Executive TDM",
          "Value": "861980032"
        },
        {
          "Label": "Finance BDM",
          "Value": "861980019"
        },
        {
          "Label": "Human Resource BDM",
          "Value": "861980020"
        },
        {
          "Label": "IT Decision Maker",
          "Value": "861980007"
        },
        {
          "Label": "IT Implem_App/LOB Spec",
          "Value": "861980008"
        },
        {
          "Label": "IT Implem_Desktop/EndUser Spec",
          "Value": "861980009"
        },
        {
          "Label": "IT Implem_Infrastructure Spec",
          "Value": "861980010"
        },
        {
          "Label": "IT Implem_IT Generalist",
          "Value": "861980011"
        },
        {
          "Label": "IT Manager",
          "Value": "861980012"
        },
        {
          "Label": "IT Pro",
          "Value": "861980033"
        },
        {
          "Label": "ITDM",
          "Value": "861980034"
        },
        {
          "Label": "Marketing BDM",
          "Value": "861980021"
        },
        {
          "Label": "Non-Tech Influencing BDM",
          "Value": "861980014"
        },
        {
          "Label": "NonProfessional Developer",
          "Value": "861980013"
        },
        {
          "Label": "Pro Dev/Programmer",
          "Value": "861980015"
        },
        {
          "Label": "Professional",
          "Value": "861980035"
        },
        {
          "Label": "Project Manager",
          "Value": "861980016"
        },
        {
          "Label": "Sales BDM",
          "Value": "861980022"
        },
        {
          "Label": "Student",
          "Value": "861980036"
        },
        {
          "Label": "Student\\Educator",
          "Value": "861980037"
        },
        {
          "Label": "Tech Influencing BDM",
          "Value": "861980017"
        },
        {
          "Label": "Unclassified",
          "Value": "861980023"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_meetingduration",
      "Type": "double",
      "DisplayName": "Meeting Duration in Hours",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_noofmailinteractions",
      "Type": "integer",
      "DisplayName": "No of Email Interactions",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_noofmeetinginteractions",
      "Type": "integer",
      "DisplayName": "No of Meeting Interactions",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_parentpartnerid",
      "Type": "lookup",
      "DisplayName": "Partner Name",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnerrole",
      "Type": "picklist",
      "DisplayName": "Partner Role",
      "Format": null,
      "OptionSet": [
        {
          "Label": "P-SSP",
          "Value": "861980000"
        },
        {
          "Label": "P-TSP",
          "Value": "861980001"
        },
        {
          "Label": "P-Marketer",
          "Value": "861980002"
        },
        {
          "Label": "P-LSS",
          "Value": "861980003"
        },
        {
          "Label": "P-CSM",
          "Value": "861980004"
        },
        {
          "Label": "Partner",
          "Value": "861980005"
        },
        {
          "Label": "ISV GTM",
          "Value": "861980006"
        },
        {
          "Label": "ISV Sales",
          "Value": "861980007"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnersecurityrole",
      "Type": "picklist",
      "DisplayName": "Partner Security Role",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Admin",
          "Value": "861980000"
        },
        {
          "Label": "Deal Manager",
          "Value": "861980001"
        },
        {
          "Label": "Seller",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_purchasingauthoritycode",
      "Type": "picklist",
      "DisplayName": "Purchasing Authority",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Has Authority",
          "Value": "861980000"
        },
        {
          "Label": "No Authority",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_purchasinglimitamount",
      "Type": "money",
      "DisplayName": "Purchasing Limit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_purchasinglimitamount_base",
      "Type": "money",
      "DisplayName": "Purchasing Limit (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcclosedby",
      "Type": "lookup",
      "DisplayName": "QC Closed By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcclosedon",
      "Type": "datetime",
      "DisplayName": "QC Closed On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcduplicatehash",
      "Type": "string",
      "DisplayName": "QC Duplicate Hash",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcissuetype",
      "Type": "picklist",
      "DisplayName": "QC Issue Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Duplicate Contact",
          "Value": "861980000"
        },
        {
          "Label": "Invalid Owner",
          "Value": "861980001"
        },
        {
          "Label": "Potential Account Change",
          "Value": "861980002"
        },
        {
          "Label": "Invalid Email",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcmodifiedon",
      "Type": "datetime",
      "DisplayName": "QC Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qcstatus",
      "Type": "picklist",
      "DisplayName": "QC Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Open",
          "Value": "861980000"
        },
        {
          "Label": "Closed",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_qualityscore",
      "Type": "double",
      "DisplayName": "Quality Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_reasonfordeactivation",
      "Type": "string",
      "DisplayName": "Reason for Deactivation",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesengagementlevel",
      "Type": "picklist",
      "DisplayName": "Sales Engagement Level",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Highly Engaged",
          "Value": "606820000"
        },
        {
          "Label": "Engaged",
          "Value": "606820001"
        },
        {
          "Label": "Not Engaged",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesplay",
      "Type": "multiselectpicklist",
      "DisplayName": "Sales Play(preview)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Accelerate Developer Productivity",
          "Value": "861980006"
        },
        {
          "Label": "Accelerate Innovation with Integration Services",
          "Value": "606820011"
        },
        {
          "Label": "Accelerate Innovation with Low Code",
          "Value": "861980068"
        },
        {
          "Label": "Accelerate Revenue Generation",
          "Value": "861980020"
        },
        {
          "Label": "Advanced Networking",
          "Value": "861980000"
        },
        {
          "Label": "Automate Business Processes",
          "Value": "861980051"
        },
        {
          "Label": "Build a Real Time and Sustainable Supply Chain",
          "Value": "861980079"
        },
        {
          "Label": "Build a Strong Foundation with Unified",
          "Value": "861980087"
        },
        {
          "Label": "Build a Sustainable IT Infrastructure",
          "Value": "861980083"
        },
        {
          "Label": "Build and Modernize AI Apps",
          "Value": "861980037"
        },
        {
          "Label": "Build Your Games in the Cloud with Azure",
          "Value": "861980008"
        },
        {
          "Label": "Cloud Endpoints",
          "Value": "861980056"
        },
        {
          "Label": "Collaborative Apps",
          "Value": "861980060"
        },
        {
          "Label": "Combat Financial Crime",
          "Value": "861980071"
        },
        {
          "Label": "Connected Commerce",
          "Value": "861980052"
        },
        {
          "Label": "Converged Communications",
          "Value": "861980055"
        },
        {
          "Label": "Converged Communications Teams Rooms",
          "Value": "861980034"
        },
        {
          "Label": "Create Sustainable Value Chains",
          "Value": "861980084"
        },
        {
          "Label": "Data Governance",
          "Value": "861980054"
        },
        {
          "Label": "Data Security",
          "Value": "861980027"
        },
        {
          "Label": "Deliver Differentiated Customer Experiences",
          "Value": "861980072"
        },
        {
          "Label": "Developer Engagement",
          "Value": "861980001"
        },
        {
          "Label": "Discover Business Insights",
          "Value": "861980053"
        },
        {
          "Label": "Drive Cloud Success with Enhanced Solutions",
          "Value": "606820009"
        },
        {
          "Label": "Elevate the Shopping Experience",
          "Value": "861980080"
        },
        {
          "Label": "Employee Experience",
          "Value": "861980029"
        },
        {
          "Label": "Empower Employees Through Teamwork",
          "Value": "861980073"
        },
        {
          "Label": "Empower Health Team Collaboration",
          "Value": "861980075"
        },
        {
          "Label": "Empower Your Store Associate",
          "Value": "861980081"
        },
        {
          "Label": "Enable a Resilient and Sustainable Supply Chain",
          "Value": "861980021"
        },
        {
          "Label": "Enable Customer Success",
          "Value": "861980058"
        },
        {
          "Label": "Enable Unified Data Governance",
          "Value": "861980066"
        },
        {
          "Label": "Enhance Clinician Experiences",
          "Value": "861980076"
        },
        {
          "Label": "Enhance Patient Engagement",
          "Value": "861980077"
        },
        {
          "Label": "Ensure coverage for your key solutions",
          "Value": "861980088"
        },
        {
          "Label": "Frontline Workers",
          "Value": "861980028"
        },
        {
          "Label": "Improve Clinical and Operational Insights",
          "Value": "861980078"
        },
        {
          "Label": "Innovate Across Hybrid and Edge with Azure Arc and IoT",
          "Value": "861980010"
        },
        {
          "Label": "Innovate and Scale with Cloud Native Apps",
          "Value": "861980004"
        },
        {
          "Label": "Innovate with HPC, AI Infrastructure",
          "Value": "861980009"
        },
        {
          "Label": "IoT",
          "Value": "861980011"
        },
        {
          "Label": "Manage Risk Across the Organization",
          "Value": "861980074"
        },
        {
          "Label": "Maximize the Value of Your Data",
          "Value": "861980082"
        },
        {
          "Label": "Microsoft Azure Consumption Commitment (MACC)",
          "Value": "861980091"
        },
        {
          "Label": "Migrate and Modernize Your Data Estate",
          "Value": "861980018"
        },
        {
          "Label": "Migrate and Secure Windows Server and SQL Server",
          "Value": "861980067"
        },
        {
          "Label": "Migrate Enterprise Apps",
          "Value": "861980069"
        },
        {
          "Label": "Migrate Linux and OSS DBs",
          "Value": "861980012"
        },
        {
          "Label": "Migrate Linux Estate",
          "Value": "606820002"
        },
        {
          "Label": "Migrate Oracle",
          "Value": "606820003"
        },
        {
          "Label": "Migrate SAP",
          "Value": "861980016"
        },
        {
          "Label": "Migrate to Azure VMware Solution",
          "Value": "861980002"
        },
        {
          "Label": "Mixed Reality",
          "Value": "861980041"
        },
        {
          "Label": "Modern Sec Ops",
          "Value": "606820006"
        },
        {
          "Label": "Modernize .NET and Java apps with PaaS, low code and managed databases",
          "Value": "861980015"
        },
        {
          "Label": "Modernize Service",
          "Value": "861980024"
        },
        {
          "Label": "Modernize VDI to Azure Virtual Desktop",
          "Value": "861980019"
        },
        {
          "Label": "Modernize with Surface",
          "Value": "861980032"
        },
        {
          "Label": "Modernize your digital estate with Unified Enterprise",
          "Value": "861980089"
        },
        {
          "Label": "Multi Cloud Security",
          "Value": "861980057"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980040"
        },
        {
          "Label": "Optimize Finance and Supply Chain",
          "Value": "861980026"
        },
        {
          "Label": "Personalize Customer Experience",
          "Value": "861980025"
        },
        {
          "Label": "Power Business Decisions with Cloud Scale Analytics",
          "Value": "861980038"
        },
        {
          "Label": "Protect Your Data and Ensure Business Resiliency with BCDR",
          "Value": "861980070"
        },
        {
          "Label": "Rapidly Build Apps",
          "Value": "861980022"
        },
        {
          "Label": "Reduce Environmental Impact of Operations",
          "Value": "861980085"
        },
        {
          "Label": "Secure Identities and Access",
          "Value": "861980042"
        },
        {
          "Label": "Secure Identity and Takeout Okta and Ping",
          "Value": "606820007"
        },
        {
          "Label": "Secure Productivity",
          "Value": "861980030"
        },
        {
          "Label": "SMB Scale Business Operations",
          "Value": "606820000"
        },
        {
          "Label": "Teams Platform",
          "Value": "861980035"
        },
        {
          "Label": "Threat Protection with XDR and SIEM",
          "Value": "861980031"
        },
        {
          "Label": "Unify Data Intelligence",
          "Value": "861980086"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionarea",
      "Type": "multiselectpicklist",
      "DisplayName": "Solution Area",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Digital and Application Innovation",
          "Value": "861980004"
        },
        {
          "Label": "Financial Services",
          "Value": "861980007"
        },
        {
          "Label": "Healthcare",
          "Value": "861980008"
        },
        {
          "Label": "Retail",
          "Value": "861980009"
        },
        {
          "Label": "Security",
          "Value": "861980005"
        },
        {
          "Label": "Sustainability",
          "Value": "861980010"
        },
        {
          "Label": "Unified Support",
          "Value": "861980011"
        },
        {
          "Label": "Business Applications",
          "Value": "394380001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980000"
        },
        {
          "Label": "Infrastructure",
          "Value": "394380000"
        },
        {
          "Label": "Modern Work",
          "Value": "394380002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sourceleadid",
      "Type": "string",
      "DisplayName": "Source Lead Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_statuschangedate",
      "Type": "datetime",
      "DisplayName": "Status Change Date",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_surveylanguagecode",
      "Type": "picklist",
      "DisplayName": "Survey Language",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Afrikaans",
          "Value": "861980049"
        },
        {
          "Label": "Arabic",
          "Value": "861980000"
        },
        {
          "Label": "Bahasa Indonesian",
          "Value": "861980001"
        },
        {
          "Label": "Bosnian",
          "Value": "861980002"
        },
        {
          "Label": "Bulgarian",
          "Value": "861980003"
        },
        {
          "Label": "Catalan",
          "Value": "861980004"
        },
        {
          "Label": "Chinese - Simplified",
          "Value": "861980005"
        },
        {
          "Label": "Chinese - Taiwan",
          "Value": "861980006"
        },
        {
          "Label": "Chinese - Traditional",
          "Value": "861980007"
        },
        {
          "Label": "Croatian",
          "Value": "861980008"
        },
        {
          "Label": "Czech",
          "Value": "861980009"
        },
        {
          "Label": "Danish",
          "Value": "861980010"
        },
        {
          "Label": "DEU",
          "Value": "861980048"
        },
        {
          "Label": "Dutch",
          "Value": "861980011"
        },
        {
          "Label": "English",
          "Value": "861980012"
        },
        {
          "Label": "English - UK",
          "Value": "861980013"
        },
        {
          "Label": "English - United States",
          "Value": "861980014"
        },
        {
          "Label": "Estonian",
          "Value": "861980015"
        },
        {
          "Label": "Finnish",
          "Value": "861980016"
        },
        {
          "Label": "French",
          "Value": "861980017"
        },
        {
          "Label": "French - Canadian",
          "Value": "861980018"
        },
        {
          "Label": "French - Switzerland",
          "Value": "861980019"
        },
        {
          "Label": "Georgian",
          "Value": "861980090"
        },
        {
          "Label": "German",
          "Value": "861980020"
        },
        {
          "Label": "Greek",
          "Value": "861980021"
        },
        {
          "Label": "Hebrew",
          "Value": "861980022"
        },
        {
          "Label": "Hungarian",
          "Value": "861980023"
        },
        {
          "Label": "Italian",
          "Value": "861980024"
        },
        {
          "Label": "Japanese",
          "Value": "861980025"
        },
        {
          "Label": "Korean",
          "Value": "861980026"
        },
        {
          "Label": "Latvian",
          "Value": "861980027"
        },
        {
          "Label": "Lithuanian",
          "Value": "861980028"
        },
        {
          "Label": "Macedonian",
          "Value": "861980029"
        },
        {
          "Label": "Malay",
          "Value": "861980030"
        },
        {
          "Label": "Norwegian",
          "Value": "861980031"
        },
        {
          "Label": "Polish",
          "Value": "861980032"
        },
        {
          "Label": "Portuguese",
          "Value": "861980033"
        },
        {
          "Label": "Portuguese - Brazil",
          "Value": "861980034"
        },
        {
          "Label": "Portuguese - Portugal",
          "Value": "861980035"
        },
        {
          "Label": "Romanian",
          "Value": "861980036"
        },
        {
          "Label": "Russian",
          "Value": "861980037"
        },
        {
          "Label": "Serbian",
          "Value": "861980038"
        },
        {
          "Label": "Slovak",
          "Value": "861980039"
        },
        {
          "Label": "Slovenian",
          "Value": "861980040"
        },
        {
          "Label": "Spanish",
          "Value": "861980041"
        },
        {
          "Label": "Spanish - LATAM",
          "Value": "861980042"
        },
        {
          "Label": "Swedish",
          "Value": "861980043"
        },
        {
          "Label": "Thai",
          "Value": "861980044"
        },
        {
          "Label": "Turkish",
          "Value": "861980045"
        },
        {
          "Label": "Ukrainian",
          "Value": "861980046"
        },
        {
          "Label": "Vietnamese",
          "Value": "861980047"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_watermarkinformation",
      "Type": "memo",
      "DisplayName": "Watermark Information",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "new_stakeholderrole",
      "Type": "picklist",
      "DisplayName": "StakeHolderRole",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Champion",
          "Value": "861980007"
        },
        {
          "Label": "Influencer",
          "Value": "861980008"
        },
        {
          "Label": "Decision Maker",
          "Value": "861980009"
        },
        {
          "Label": "User",
          "Value": "861980010"
        },
        {
          "Label": "Ratifier",
          "Value": "861980011"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "nickname",
      "Type": "string",
      "DisplayName": "Nickname",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "numberofchildren",
      "Type": "integer",
      "DisplayName": "No. of Children",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "onholdtime",
      "Type": "integer",
      "DisplayName": "On Hold Time (Minutes)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "originatingleadid",
      "Type": "lookup",
      "DisplayName": "Originating Lead",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "overriddencreatedon",
      "Type": "datetime",
      "DisplayName": "Record Created On",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "ownerid",
      "Type": "owner",
      "DisplayName": "Owner",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningbusinessunit",
      "Type": "lookup",
      "DisplayName": "Owning Business Unit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningteam",
      "Type": "lookup",
      "DisplayName": "Owning Team",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owninguser",
      "Type": "lookup",
      "DisplayName": "Owning User",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "pager",
      "Type": "string",
      "DisplayName": "Pager",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentcontactid",
      "Type": "lookup",
      "DisplayName": "Parent Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentcustomerid",
      "Type": "customer",
      "DisplayName": "Company Name",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "participatesinworkflow",
      "Type": "boolean",
      "DisplayName": "Participates in Workflow",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "paymenttermscode",
      "Type": "picklist",
      "DisplayName": "Payment Terms",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Net 30",
          "Value": "1"
        },
        {
          "Label": "2% 10, Net 30",
          "Value": "2"
        },
        {
          "Label": "Net 45",
          "Value": "3"
        },
        {
          "Label": "Net 60",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredappointmentdaycode",
      "Type": "picklist",
      "DisplayName": "Preferred Day",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Sunday",
          "Value": "0"
        },
        {
          "Label": "Monday",
          "Value": "1"
        },
        {
          "Label": "Tuesday",
          "Value": "2"
        },
        {
          "Label": "Wednesday",
          "Value": "3"
        },
        {
          "Label": "Thursday",
          "Value": "4"
        },
        {
          "Label": "Friday",
          "Value": "5"
        },
        {
          "Label": "Saturday",
          "Value": "6"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredappointmenttimecode",
      "Type": "picklist",
      "DisplayName": "Preferred Time",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Morning",
          "Value": "1"
        },
        {
          "Label": "Afternoon",
          "Value": "2"
        },
        {
          "Label": "Evening",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredcontactmethodcode",
      "Type": "picklist",
      "DisplayName": "Preferred Method of Contact",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Any",
          "Value": "1"
        },
        {
          "Label": "Email",
          "Value": "2"
        },
        {
          "Label": "Phone",
          "Value": "3"
        },
        {
          "Label": "Fax",
          "Value": "4"
        },
        {
          "Label": "Mail",
          "Value": "5"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredequipmentid",
      "Type": "lookup",
      "DisplayName": "Preferred Facility/Equipment",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredserviceid",
      "Type": "lookup",
      "DisplayName": "Preferred Service",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredsystemuserid",
      "Type": "lookup",
      "DisplayName": "Preferred User",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "processid",
      "Type": "uniqueidentifier",
      "DisplayName": "Process",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salutation",
      "Type": "string",
      "DisplayName": "Prefix",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slaid",
      "Type": "lookup",
      "DisplayName": "SLA",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slainvokedid",
      "Type": "lookup",
      "DisplayName": "Last SLA applied",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "spousesname",
      "Type": "string",
      "DisplayName": "Spouse/Partner Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "stageid",
      "Type": "uniqueidentifier",
      "DisplayName": "(Deprecated) Process Stage",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statecode",
      "Type": "state",
      "DisplayName": "Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Active",
          "Value": "0"
        },
        {
          "Label": "Inactive",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statuscode",
      "Type": "status",
      "DisplayName": "Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Active",
          "Value": "1"
        },
        {
          "Label": "Inactive",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "subscriptionid",
      "Type": "uniqueidentifier",
      "DisplayName": "Subscription",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "suffix",
      "Type": "string",
      "DisplayName": "Suffix",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "teamsfollowed",
      "Type": "integer",
      "DisplayName": "TeamsFollowed",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone1",
      "Type": "string",
      "DisplayName": "Business Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone2",
      "Type": "string",
      "DisplayName": "Home Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone3",
      "Type": "string",
      "DisplayName": "Telephone 3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "territorycode",
      "Type": "picklist",
      "DisplayName": "Territory",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timespentbymeonemailandmeetings",
      "Type": "string",
      "DisplayName": "Time Spent by me",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timezoneruleversionnumber",
      "Type": "integer",
      "DisplayName": "Time Zone Rule Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "transactioncurrencyid",
      "Type": "lookup",
      "DisplayName": "Currency",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "traversedpath",
      "Type": "string",
      "DisplayName": "(Deprecated) Traversed Path",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "utcconversiontimezonecode",
      "Type": "integer",
      "DisplayName": "UTC Conversion Time Zone Code",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "versionnumber",
      "Type": "bigint",
      "DisplayName": "Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "websiteurl",
      "Type": "string",
      "DisplayName": "Website",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomifirstname",
      "Type": "string",
      "DisplayName": "Yomi First Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomifullname",
      "Type": "string",
      "DisplayName": "Yomi Full Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomilastname",
      "Type": "string",
      "DisplayName": "Yomi Last Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomimiddlename",
      "Type": "string",
      "DisplayName": "Yomi Middle Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  }
]