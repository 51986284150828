export const lead_attributes = [
  {
    "attributeDescriptor": {
      "LogicalName": "accountid",
      "Type": "lookup",
      "DisplayName": "Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_addressid",
      "Type": "uniqueidentifier",
      "DisplayName": "Address 1: ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_addresstypecode",
      "Type": "picklist",
      "DisplayName": "Address 1: Address Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_city",
      "Type": "string",
      "DisplayName": "City",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_composite",
      "Type": "memo",
      "DisplayName": "Address 1",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_country",
      "Type": "string",
      "DisplayName": "Country/Region",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_county",
      "Type": "string",
      "DisplayName": "Address 1: County",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_fax",
      "Type": "string",
      "DisplayName": "Address 1: Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_latitude",
      "Type": "double",
      "DisplayName": "Address 1: Latitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line1",
      "Type": "string",
      "DisplayName": "Street 1",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line2",
      "Type": "string",
      "DisplayName": "Street 2",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_line3",
      "Type": "string",
      "DisplayName": "Street 3",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_longitude",
      "Type": "double",
      "DisplayName": "Address 1: Longitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_name",
      "Type": "string",
      "DisplayName": "Address 1: Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_postalcode",
      "Type": "string",
      "DisplayName": "ZIP/Postal Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_postofficebox",
      "Type": "string",
      "DisplayName": "Address 1: Post Office Box",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Address 1: Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_stateorprovince",
      "Type": "string",
      "DisplayName": "State/Province",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone1",
      "Type": "string",
      "DisplayName": "Address 1: Telephone 1",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone2",
      "Type": "string",
      "DisplayName": "Address 1: Telephone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_telephone3",
      "Type": "string",
      "DisplayName": "Address 1: Telephone 3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_upszone",
      "Type": "string",
      "DisplayName": "Address 1: UPS Zone",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address1_utcoffset",
      "Type": "integer",
      "DisplayName": "Address 1: UTC Offset",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_addressid",
      "Type": "uniqueidentifier",
      "DisplayName": "Address 2: ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_addresstypecode",
      "Type": "picklist",
      "DisplayName": "Address 2: Address Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_city",
      "Type": "string",
      "DisplayName": "Address 2: City",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_composite",
      "Type": "memo",
      "DisplayName": "Address 2",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_country",
      "Type": "string",
      "DisplayName": "Address 2: Country/Region",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_county",
      "Type": "string",
      "DisplayName": "Address 2: County",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_fax",
      "Type": "string",
      "DisplayName": "Address 2: Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_latitude",
      "Type": "double",
      "DisplayName": "Address 2: Latitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line1",
      "Type": "string",
      "DisplayName": "Address 2: Street 1",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line2",
      "Type": "string",
      "DisplayName": "Address 2: Street 2",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_line3",
      "Type": "string",
      "DisplayName": "Address 2: Street 3",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_longitude",
      "Type": "double",
      "DisplayName": "Address 2: Longitude",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_name",
      "Type": "string",
      "DisplayName": "Address 2: Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_postalcode",
      "Type": "string",
      "DisplayName": "Address 2: ZIP/Postal Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_postofficebox",
      "Type": "string",
      "DisplayName": "Address 2: Post Office Box",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_shippingmethodcode",
      "Type": "picklist",
      "DisplayName": "Address 2: Shipping Method",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_stateorprovince",
      "Type": "string",
      "DisplayName": "Address 2: State/Province",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone1",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 1",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone2",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 2",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_telephone3",
      "Type": "string",
      "DisplayName": "Address 2: Telephone 3",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_upszone",
      "Type": "string",
      "DisplayName": "Address 2: UPS Zone",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "address2_utcoffset",
      "Type": "integer",
      "DisplayName": "Address 2: UTC Offset",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetamount",
      "Type": "money",
      "DisplayName": "Budget Amount",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetamount_base",
      "Type": "money",
      "DisplayName": "Budget Amount (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "budgetstatus",
      "Type": "picklist",
      "DisplayName": "Budget Available",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "1"
        },
        {
          "Label": "No",
          "Value": "0"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "businesscard",
      "Type": "memo",
      "DisplayName": "Business Card",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "businesscardattributes",
      "Type": "string",
      "DisplayName": "BusinessCardAttributes",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "campaignid",
      "Type": "lookup",
      "DisplayName": "Source Campaign",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "companyname",
      "Type": "string",
      "DisplayName": "Company Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "confirminterest",
      "Type": "boolean",
      "DisplayName": "Confirm Interest",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "contactid",
      "Type": "lookup",
      "DisplayName": "Contact",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "crac3_dqlevel1",
      "Type": "picklist",
      "DisplayName": "DQ Level 1",
      "Format": null,
      "OptionSet": [
        {
          "Label": "L1 option 1",
          "Value": "137710000"
        },
        {
          "Label": "L1 option 2",
          "Value": "137710001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "crac3_dqlevel2",
      "Type": "picklist",
      "DisplayName": "DQ Level 2",
      "Format": null,
      "OptionSet": [
        {
          "Label": "L2 Option 1",
          "Value": "137710000"
        },
        {
          "Label": "L2 Option 2",
          "Value": "137710001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdby",
      "Type": "lookup",
      "DisplayName": "Created By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdon",
      "Type": "datetime",
      "DisplayName": "Created On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdonbehalfby",
      "Type": "lookup",
      "DisplayName": "Created By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "customerid",
      "Type": "customer",
      "DisplayName": "Customer",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "decisionmaker",
      "Type": "boolean",
      "DisplayName": "Decision Maker?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "mark complete",
          "Value": "0"
        },
        {
          "Label": "completed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "description",
      "Type": "memo",
      "DisplayName": "Need",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotbulkemail",
      "Type": "boolean",
      "DisplayName": "Do not allow Bulk Emails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotemail",
      "Type": "boolean",
      "DisplayName": "Do not allow Emails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotfax",
      "Type": "boolean",
      "DisplayName": "Do not allow Faxes",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotphone",
      "Type": "boolean",
      "DisplayName": "Do not allow Phone Calls",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotpostalmail",
      "Type": "boolean",
      "DisplayName": "Do not allow Mails",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Allow",
          "Value": "0"
        },
        {
          "Label": "Do Not Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "donotsendmm",
      "Type": "boolean",
      "DisplayName": "Marketing Material",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Send",
          "Value": "0"
        },
        {
          "Label": "Do Not Send",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress1",
      "Type": "string",
      "DisplayName": "Email",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress2",
      "Type": "string",
      "DisplayName": "Email Address 2",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "emailaddress3",
      "Type": "string",
      "DisplayName": "Email Address 3",
      "Format": "Email",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "entityimageid",
      "Type": "uniqueidentifier",
      "DisplayName": "Entity Image Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedamount",
      "Type": "money",
      "DisplayName": "Est. Value",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedamount_base",
      "Type": "money",
      "DisplayName": "Est. Value (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedclosedate",
      "Type": "datetime",
      "DisplayName": "Est. Close Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "estimatedvalue",
      "Type": "double",
      "DisplayName": "Est. Value (deprecated)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "evaluatefit",
      "Type": "boolean",
      "DisplayName": "Evaluate Fit",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "0"
        },
        {
          "Label": "No",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "exchangerate",
      "Type": "decimal",
      "DisplayName": "Exchange Rate",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "fax",
      "Type": "string",
      "DisplayName": "Fax",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "firstname",
      "Type": "string",
      "DisplayName": "First Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "followemail",
      "Type": "boolean",
      "DisplayName": "Follow Email Activity",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Do Not Allow",
          "Value": "0"
        },
        {
          "Label": "Allow",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "fullname",
      "Type": "string",
      "DisplayName": "Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "importsequencenumber",
      "Type": "integer",
      "DisplayName": "Import Sequence Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "industrycode",
      "Type": "picklist",
      "DisplayName": "Industry",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Education",
          "Value": "100000000"
        },
        {
          "Label": "Government",
          "Value": "100000001"
        },
        {
          "Label": "N/A",
          "Value": "100000002"
        },
        {
          "Label": "Process Mfg & Resources",
          "Value": "100000003"
        },
        {
          "Label": "Professional Services",
          "Value": "100000004"
        },
        {
          "Label": "Public Safety_Natl Security",
          "Value": "100000005"
        },
        {
          "Label": "Retail and Consumer Goods",
          "Value": "100000006"
        },
        {
          "Label": "Telecommunications",
          "Value": "100000007"
        },
        {
          "Label": "Agriculture, Forestry&Fishing",
          "Value": "100000008"
        },
        {
          "Label": "Banking & Capital Markets",
          "Value": "100000009"
        },
        {
          "Label": "Discrete Manufacturing",
          "Value": "100000010"
        },
        {
          "Label": "Health",
          "Value": "100000011"
        },
        {
          "Label": "Hospitality & Travel",
          "Value": "100000012"
        },
        {
          "Label": "Logistics",
          "Value": "100000013"
        },
        {
          "Label": "Media & Cable",
          "Value": "100000014"
        },
        {
          "Label": "Nonprofit",
          "Value": "100000015"
        },
        {
          "Label": "Other - Unsegmented",
          "Value": "100000016"
        },
        {
          "Label": "Power & Utilities",
          "Value": "100000017"
        },
        {
          "Label": "Accounting and Tax Software",
          "Value": "100000018"
        },
        {
          "Label": "Accounting, Tax, Bookkeeping and Payroll",
          "Value": "100000019"
        },
        {
          "Label": "Analytics and Reporting Software",
          "Value": "100000020"
        },
        {
          "Label": "Budgeting and Forecasting Software",
          "Value": "100000021"
        },
        {
          "Label": "Business Associations",
          "Value": "100000022"
        },
        {
          "Label": "Commercial Banking",
          "Value": "100000023"
        },
        {
          "Label": "Commercial Printing Services",
          "Value": "100000024"
        },
        {
          "Label": "Credit and Collection Services",
          "Value": "100000025"
        },
        {
          "Label": "Currency, Commodity & Futures Trading",
          "Value": "100000026"
        },
        {
          "Label": "Diversified Media",
          "Value": "100000027"
        },
        {
          "Label": "Electronics Retail",
          "Value": "100000028"
        },
        {
          "Label": "Engineering Services",
          "Value": "100000029"
        },
        {
          "Label": "Enterprise Resource Planning Software",
          "Value": "100000030"
        },
        {
          "Label": "Executive Search",
          "Value": "100000031"
        },
        {
          "Label": "Food Wholesale Distributors",
          "Value": "100000032"
        },
        {
          "Label": "Industrial Conglomerates",
          "Value": "100000033"
        },
        {
          "Label": "Industrial Equipment and Machinery",
          "Value": "100000034"
        },
        {
          "Label": "Internet Information Services",
          "Value": "100000035"
        },
        {
          "Label": "IT Services and Consulting",
          "Value": "100000036"
        },
        {
          "Label": "Legal Industry Software",
          "Value": "100000037"
        },
        {
          "Label": "Management Consulting",
          "Value": "100000038"
        },
        {
          "Label": "Medical Practice Management and Services",
          "Value": "100000039"
        },
        {
          "Label": "Moving Services",
          "Value": "100000040"
        },
        {
          "Label": "Office Supplies and Stationery Retail",
          "Value": "100000041"
        },
        {
          "Label": "Project Management Software",
          "Value": "100000042"
        },
        {
          "Label": "Real Estate Investment Trusts (REITs)",
          "Value": "100000043"
        },
        {
          "Label": "Real Estate Leasing",
          "Value": "100000044"
        },
        {
          "Label": "Retail REITs",
          "Value": "100000045"
        },
        {
          "Label": "Securities Brokers and Traders",
          "Value": "100000046"
        },
        {
          "Label": "Security Software",
          "Value": "100000047"
        },
        {
          "Label": "Surgical and Medical Devices",
          "Value": "100000048"
        },
        {
          "Label": "Telecommunications Services",
          "Value": "100000049"
        },
        {
          "Label": "Truck, Bus and Big Rig Manufacturing",
          "Value": "100000050"
        },
        {
          "Label": "Wired Telecommunications Carriers",
          "Value": "100000051"
        },
        {
          "Label": "#N/A",
          "Value": "100000052"
        },
        {
          "Label": "0",
          "Value": "100000053"
        },
        {
          "Label": "Chemicals & Agrochemicals",
          "Value": "100000054"
        },
        {
          "Label": "Consumer Goods",
          "Value": "100000055"
        },
        {
          "Label": "Health Provider",
          "Value": "100000056"
        },
        {
          "Label": "Higher Education",
          "Value": "100000057"
        },
        {
          "Label": "Partner Professional Services",
          "Value": "100000058"
        },
        {
          "Label": "Primary & Secondary Edu/K-12",
          "Value": "100000059"
        },
        {
          "Label": "Retailers",
          "Value": "100000060"
        },
        {
          "Label": "Civilian Government",
          "Value": "100000061"
        },
        {
          "Label": "Defense & Intelligence",
          "Value": "100000062"
        },
        {
          "Label": "Local Regional Government",
          "Value": "100000063"
        },
        {
          "Label": "Health Payor",
          "Value": "100000064"
        },
        {
          "Label": "Media & Telco",
          "Value": "100000065"
        },
        {
          "Label": "Mining, Oil & Gas",
          "Value": "100000066"
        },
        {
          "Label": "Pharmaceuticals",
          "Value": "100000067"
        },
        {
          "Label": "Travel & Transportation",
          "Value": "100000068"
        },
        {
          "Label": "Forestry & Fishing",
          "Value": "100000069"
        },
        {
          "Label": "Accounting",
          "Value": "1"
        },
        {
          "Label": "Agriculture and Non-petrol Natural Resource Extraction",
          "Value": "2"
        },
        {
          "Label": "Broadcasting Printing and Publishing",
          "Value": "3"
        },
        {
          "Label": "Brokers",
          "Value": "4"
        },
        {
          "Label": "Building Supply Retail",
          "Value": "5"
        },
        {
          "Label": "Business Services",
          "Value": "6"
        },
        {
          "Label": "Consulting",
          "Value": "7"
        },
        {
          "Label": "Consumer Services",
          "Value": "8"
        },
        {
          "Label": "Design, Direction and Creative Management",
          "Value": "9"
        },
        {
          "Label": "Distributors, Dispatchers and Processors",
          "Value": "10"
        },
        {
          "Label": "Doctor's Offices and Clinics",
          "Value": "11"
        },
        {
          "Label": "Durable Manufacturing",
          "Value": "12"
        },
        {
          "Label": "Eating and Drinking Places",
          "Value": "13"
        },
        {
          "Label": "Entertainment Retail",
          "Value": "14"
        },
        {
          "Label": "Equipment Rental and Leasing",
          "Value": "15"
        },
        {
          "Label": "Financial",
          "Value": "16"
        },
        {
          "Label": "Food and Tobacco Processing",
          "Value": "17"
        },
        {
          "Label": "Inbound Capital Intensive Processing",
          "Value": "18"
        },
        {
          "Label": "Inbound Repair and Services",
          "Value": "19"
        },
        {
          "Label": "Insurance",
          "Value": "20"
        },
        {
          "Label": "Legal Services",
          "Value": "21"
        },
        {
          "Label": "Non-Durable Merchandise Retail",
          "Value": "22"
        },
        {
          "Label": "Outbound Consumer Service",
          "Value": "23"
        },
        {
          "Label": "Petrochemical Extraction and Distribution",
          "Value": "24"
        },
        {
          "Label": "Service Retail",
          "Value": "25"
        },
        {
          "Label": "SIG Affiliations",
          "Value": "26"
        },
        {
          "Label": "Social Services",
          "Value": "27"
        },
        {
          "Label": "Special Outbound Trade Contractors",
          "Value": "28"
        },
        {
          "Label": "Specialty Realty",
          "Value": "29"
        },
        {
          "Label": "Transportation",
          "Value": "30"
        },
        {
          "Label": "Utility Creation and Distribution",
          "Value": "31"
        },
        {
          "Label": "Vehicle Retail",
          "Value": "32"
        },
        {
          "Label": "Wholesale",
          "Value": "33"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "initialcommunication",
      "Type": "picklist",
      "DisplayName": "Initial Communication",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Contacted",
          "Value": "0"
        },
        {
          "Label": "Not Contacted",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isautocreate",
      "Type": "boolean",
      "DisplayName": "Auto-created",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "isprivate",
      "Type": "boolean",
      "DisplayName": "Is Private",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "jobtitle",
      "Type": "string",
      "DisplayName": "Job Title",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastname",
      "Type": "string",
      "DisplayName": "Last Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastonholdtime",
      "Type": "datetime",
      "DisplayName": "Last On Hold Time",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "lastusedincampaign",
      "Type": "datetime",
      "DisplayName": "Last Campaign Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "leadid",
      "Type": "uniqueidentifier",
      "DisplayName": "Lead",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "leadqualitycode",
      "Type": "picklist",
      "DisplayName": "Rating",
      "Format": null,
      "OptionSet": [
        {
          "Label": "HR",
          "Value": "861980000"
        },
        {
          "Label": "Priority 1",
          "Value": "1"
        },
        {
          "Label": "Priority 2",
          "Value": "2"
        },
        {
          "Label": "Priority 3",
          "Value": "3"
        },
        {
          "Label": "MLQML",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "leadsourcecode",
      "Type": "picklist",
      "DisplayName": "Lead Source",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Advertisement",
          "Value": "1"
        },
        {
          "Label": "Employee Referral",
          "Value": "2"
        },
        {
          "Label": "External Referral",
          "Value": "3"
        },
        {
          "Label": "Partner",
          "Value": "4"
        },
        {
          "Label": "Public Relations",
          "Value": "5"
        },
        {
          "Label": "Seminar",
          "Value": "6"
        },
        {
          "Label": "Trade Show",
          "Value": "7"
        },
        {
          "Label": "Web",
          "Value": "8"
        },
        {
          "Label": "Word of Mouth",
          "Value": "9"
        },
        {
          "Label": "Other",
          "Value": "10"
        },
        {
          "Label": "Account Planning",
          "Value": "861980002"
        },
        {
          "Label": "Artificial Intelligence",
          "Value": "861980010"
        },
        {
          "Label": "Customer Success",
          "Value": "606820000"
        },
        {
          "Label": "Marketing",
          "Value": "861980000"
        },
        {
          "Label": "Partner",
          "Value": "861980004"
        },
        {
          "Label": "Retail",
          "Value": "861980011"
        },
        {
          "Label": "Sales",
          "Value": "861980001"
        },
        {
          "Label": "SAM",
          "Value": "861980003"
        },
        {
          "Label": "Territory Planning",
          "Value": "861980006"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_companyid",
      "Type": "integer",
      "DisplayName": "LinkedIn Company Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "li_membertoken",
      "Type": "string",
      "DisplayName": "LinkedIn Member Token",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "masterid",
      "Type": "lookup",
      "DisplayName": "Master ID",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "merged",
      "Type": "boolean",
      "DisplayName": "Merged",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "middlename",
      "Type": "string",
      "DisplayName": "Middle Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "mobilephone",
      "Type": "string",
      "DisplayName": "Mobile Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedby",
      "Type": "lookup",
      "DisplayName": "Modified By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedon",
      "Type": "datetime",
      "DisplayName": "Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedonbehalfby",
      "Type": "lookup",
      "DisplayName": "Modified By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_gdproptout",
      "Type": "boolean",
      "DisplayName": "GDPR Optout",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_leadgrade",
      "Type": "picklist",
      "DisplayName": "(Deprecated) Lead Grade",
      "Format": null,
      "OptionSet": [
        {
          "Label": "HR",
          "Value": "0"
        },
        {
          "Label": "Priority 1",
          "Value": "1"
        },
        {
          "Label": "Priority 2",
          "Value": "2"
        },
        {
          "Label": "Priority 3",
          "Value": "3"
        },
        {
          "Label": "MLQML",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_leadkpiid",
      "Type": "lookup",
      "DisplayName": "KPI",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_leadscore",
      "Type": "integer",
      "DisplayName": "Lead Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_leadscoretrend",
      "Type": "picklist",
      "DisplayName": "(Deprecated) Lead Score Trend",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Improving",
          "Value": "0"
        },
        {
          "Label": "Steady",
          "Value": "1"
        },
        {
          "Label": "Declining",
          "Value": "2"
        },
        {
          "Label": "Not enough info",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_predictivescoreid",
      "Type": "lookup",
      "DisplayName": "Predictive Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_salesassignmentresult",
      "Type": "picklist",
      "DisplayName": "Assignment Rule Result",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Succeeded",
          "Value": "0"
        },
        {
          "Label": "Failed",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_scorehistory",
      "Type": "memo",
      "DisplayName": "(Deprecated) Score History",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_scorereasons",
      "Type": "memo",
      "DisplayName": "(Deprecated) Score Reasons",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msdyn_segmentid",
      "Type": "lookup",
      "DisplayName": "Segment Id",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_activeprocess",
      "Type": "string",
      "DisplayName": "Active Process",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_activesalesstage",
      "Type": "string",
      "DisplayName": "Active Sales Stage",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_additionalinformationfromsource",
      "Type": "memo",
      "DisplayName": "Additional Information from Source",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_allocatedtpid",
      "Type": "string",
      "DisplayName": "Allocated TPID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_apengagementtype",
      "Type": "lookup",
      "DisplayName": "Solution Assessment Engagement Type",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_appsource",
      "Type": "string",
      "DisplayName": "App Source",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bizphonevalidation",
      "Type": "picklist",
      "DisplayName": "Biz Phone Validation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Valid",
          "Value": "606820000"
        },
        {
          "Label": "Invalid",
          "Value": "606820001"
        },
        {
          "Label": "Corrected",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_busphonelocation",
      "Type": "string",
      "DisplayName": "Business Phone Location",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_busphonetype",
      "Type": "string",
      "DisplayName": "Business Phone Type",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_channel",
      "Type": "string",
      "DisplayName": "Channel",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_channeltype",
      "Type": "string",
      "DisplayName": "Channel Type",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_chatphoneid",
      "Type": "string",
      "DisplayName": "Chat/Phone Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_closedate",
      "Type": "datetime",
      "DisplayName": "Close Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_closetime",
      "Type": "datetime",
      "DisplayName": "Close Time",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_competethreatlevel",
      "Type": "picklist",
      "DisplayName": "Compete Threat Level",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Low/ None",
          "Value": "861980001"
        },
        {
          "Label": "Medium",
          "Value": "861980002"
        },
        {
          "Label": "High",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_contactenrichmentmex",
      "Type": "string",
      "DisplayName": "contactenrichmentmex",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_context",
      "Type": "string",
      "DisplayName": "Context",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_countrycode",
      "Type": "picklist",
      "DisplayName": "Country Code",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Afghanistan",
          "Value": "4909"
        },
        {
          "Label": "Åland Islands",
          "Value": "228517"
        },
        {
          "Label": "Albania",
          "Value": "4910"
        },
        {
          "Label": "Algeria",
          "Value": "4911"
        },
        {
          "Label": "American Samoa",
          "Value": "4912"
        },
        {
          "Label": "Andorra",
          "Value": "4913"
        },
        {
          "Label": "Angola",
          "Value": "4914"
        },
        {
          "Label": "Anguilla",
          "Value": "4915"
        },
        {
          "Label": "Antarctica",
          "Value": "4916"
        },
        {
          "Label": "Antigua and Barbuda",
          "Value": "4917"
        },
        {
          "Label": "Argentina",
          "Value": "4918"
        },
        {
          "Label": "Armenia",
          "Value": "4919"
        },
        {
          "Label": "Aruba",
          "Value": "4920"
        },
        {
          "Label": "Australia",
          "Value": "4921"
        },
        {
          "Label": "Austria",
          "Value": "4922"
        },
        {
          "Label": "Azerbaijan",
          "Value": "4923"
        },
        {
          "Label": "Bahamas",
          "Value": "4924"
        },
        {
          "Label": "Bahrain",
          "Value": "4925"
        },
        {
          "Label": "Bangladesh",
          "Value": "4926"
        },
        {
          "Label": "Barbados",
          "Value": "4927"
        },
        {
          "Label": "Belarus",
          "Value": "4928"
        },
        {
          "Label": "Belgium",
          "Value": "4929"
        },
        {
          "Label": "Belize",
          "Value": "4930"
        },
        {
          "Label": "Benin",
          "Value": "4931"
        },
        {
          "Label": "Bermuda",
          "Value": "4932"
        },
        {
          "Label": "Bhutan",
          "Value": "4933"
        },
        {
          "Label": "Bolivia",
          "Value": "4934"
        },
        {
          "Label": "Bonaire",
          "Value": "2082124"
        },
        {
          "Label": "Bosnia and Herzegovina",
          "Value": "4935"
        },
        {
          "Label": "Botswana",
          "Value": "4936"
        },
        {
          "Label": "Bouvet Island",
          "Value": "4937"
        },
        {
          "Label": "Brazil",
          "Value": "4938"
        },
        {
          "Label": "British Indian Ocean Territory",
          "Value": "4939"
        },
        {
          "Label": "British Virgin Islands",
          "Value": "5139"
        },
        {
          "Label": "Brunei",
          "Value": "4940"
        },
        {
          "Label": "Bulgaria",
          "Value": "4941"
        },
        {
          "Label": "Burkina Faso",
          "Value": "4942"
        },
        {
          "Label": "Burundi",
          "Value": "4943"
        },
        {
          "Label": "Cabo Verde",
          "Value": "4947"
        },
        {
          "Label": "Cambodia",
          "Value": "4944"
        },
        {
          "Label": "Cameroon",
          "Value": "4945"
        },
        {
          "Label": "Canada",
          "Value": "4946"
        },
        {
          "Label": "Cayman Islands",
          "Value": "4948"
        },
        {
          "Label": "Central African Republic",
          "Value": "4949"
        },
        {
          "Label": "Chad",
          "Value": "4950"
        },
        {
          "Label": "Chile",
          "Value": "4951"
        },
        {
          "Label": "China",
          "Value": "4952"
        },
        {
          "Label": "Christmas Island",
          "Value": "4953"
        },
        {
          "Label": "Cocos (Keeling) Islands",
          "Value": "4954"
        },
        {
          "Label": "Colombia",
          "Value": "4955"
        },
        {
          "Label": "Comoros",
          "Value": "4956"
        },
        {
          "Label": "Congo",
          "Value": "4957"
        },
        {
          "Label": "Congo (DRC)",
          "Value": "4958"
        },
        {
          "Label": "Cook Islands",
          "Value": "4959"
        },
        {
          "Label": "Costa Rica",
          "Value": "4960"
        },
        {
          "Label": "Côte d’Ivoire",
          "Value": "4961"
        },
        {
          "Label": "Croatia",
          "Value": "4962"
        },
        {
          "Label": "Cuba",
          "Value": "4963"
        },
        {
          "Label": "Curaçao",
          "Value": "2082125"
        },
        {
          "Label": "Cyprus",
          "Value": "4964"
        },
        {
          "Label": "Czechia",
          "Value": "4965"
        },
        {
          "Label": "Denmark",
          "Value": "4966"
        },
        {
          "Label": "Djibouti",
          "Value": "4967"
        },
        {
          "Label": "Dominica",
          "Value": "4968"
        },
        {
          "Label": "Dominican Republic",
          "Value": "4969"
        },
        {
          "Label": "Ecuador",
          "Value": "4971"
        },
        {
          "Label": "Egypt",
          "Value": "4972"
        },
        {
          "Label": "El Salvador",
          "Value": "4973"
        },
        {
          "Label": "Equatorial Guinea",
          "Value": "4974"
        },
        {
          "Label": "Eritrea",
          "Value": "4975"
        },
        {
          "Label": "Estonia",
          "Value": "4976"
        },
        {
          "Label": "Eswatini",
          "Value": "5110"
        },
        {
          "Label": "Ethiopia",
          "Value": "4977"
        },
        {
          "Label": "Falkland Islands",
          "Value": "4978"
        },
        {
          "Label": "Faroe Islands",
          "Value": "4979"
        },
        {
          "Label": "Fiji",
          "Value": "4980"
        },
        {
          "Label": "Finland",
          "Value": "4981"
        },
        {
          "Label": "France",
          "Value": "4982"
        },
        {
          "Label": "French Guiana",
          "Value": "4983"
        },
        {
          "Label": "French Polynesia",
          "Value": "4984"
        },
        {
          "Label": "French Southern Territories",
          "Value": "4985"
        },
        {
          "Label": "Gabon",
          "Value": "4986"
        },
        {
          "Label": "Gambia",
          "Value": "4987"
        },
        {
          "Label": "Georgia",
          "Value": "4988"
        },
        {
          "Label": "Germany",
          "Value": "4989"
        },
        {
          "Label": "Ghana",
          "Value": "4990"
        },
        {
          "Label": "Gibraltar",
          "Value": "4991"
        },
        {
          "Label": "Greece",
          "Value": "4992"
        },
        {
          "Label": "Greenland",
          "Value": "4993"
        },
        {
          "Label": "Grenada",
          "Value": "4994"
        },
        {
          "Label": "Guadeloupe",
          "Value": "4995"
        },
        {
          "Label": "Guam",
          "Value": "4996"
        },
        {
          "Label": "Guatemala",
          "Value": "4997"
        },
        {
          "Label": "Guernsey",
          "Value": "85385"
        },
        {
          "Label": "Guinea",
          "Value": "4998"
        },
        {
          "Label": "Guinea-Bissau",
          "Value": "4999"
        },
        {
          "Label": "Guyana",
          "Value": "5000"
        },
        {
          "Label": "Haiti",
          "Value": "5001"
        },
        {
          "Label": "Heard Island and McDonald Islands",
          "Value": "5002"
        },
        {
          "Label": "Honduras",
          "Value": "5003"
        },
        {
          "Label": "Hong Kong SAR",
          "Value": "5004"
        },
        {
          "Label": "Hungary",
          "Value": "5005"
        },
        {
          "Label": "Iceland",
          "Value": "5006"
        },
        {
          "Label": "India",
          "Value": "5007"
        },
        {
          "Label": "Indonesia",
          "Value": "5008"
        },
        {
          "Label": "Iran",
          "Value": "5009"
        },
        {
          "Label": "Iraq",
          "Value": "5010"
        },
        {
          "Label": "Ireland",
          "Value": "5011"
        },
        {
          "Label": "Isle of Man",
          "Value": "24459"
        },
        {
          "Label": "Israel",
          "Value": "5012"
        },
        {
          "Label": "Italy",
          "Value": "5013"
        },
        {
          "Label": "Jamaica",
          "Value": "5014"
        },
        {
          "Label": "Jan Mayen",
          "Value": "24458"
        },
        {
          "Label": "Japan",
          "Value": "5015"
        },
        {
          "Label": "Jersey",
          "Value": "85388"
        },
        {
          "Label": "Jordan",
          "Value": "5016"
        },
        {
          "Label": "Kazakhstan",
          "Value": "5017"
        },
        {
          "Label": "Kenya",
          "Value": "5018"
        },
        {
          "Label": "Kiribati",
          "Value": "5019"
        },
        {
          "Label": "Korea",
          "Value": "5020"
        },
        {
          "Label": "Kosovo",
          "Value": "30006102"
        },
        {
          "Label": "Kuwait",
          "Value": "5021"
        },
        {
          "Label": "Kyrgyzstan",
          "Value": "5022"
        },
        {
          "Label": "Laos",
          "Value": "5023"
        },
        {
          "Label": "Latvia",
          "Value": "5024"
        },
        {
          "Label": "Lebanon",
          "Value": "5025"
        },
        {
          "Label": "Lesotho",
          "Value": "5026"
        },
        {
          "Label": "Liberia",
          "Value": "5027"
        },
        {
          "Label": "Libya",
          "Value": "5028"
        },
        {
          "Label": "Liechtenstein",
          "Value": "5029"
        },
        {
          "Label": "Lithuania",
          "Value": "5030"
        },
        {
          "Label": "Luxembourg",
          "Value": "5031"
        },
        {
          "Label": "Macao SAR",
          "Value": "5032"
        },
        {
          "Label": "Madagascar",
          "Value": "5034"
        },
        {
          "Label": "Malawi",
          "Value": "5035"
        },
        {
          "Label": "Malaysia",
          "Value": "5036"
        },
        {
          "Label": "Maldives",
          "Value": "5037"
        },
        {
          "Label": "Mali",
          "Value": "5038"
        },
        {
          "Label": "Malta",
          "Value": "5039"
        },
        {
          "Label": "Marshall Islands",
          "Value": "5040"
        },
        {
          "Label": "Martinique",
          "Value": "5041"
        },
        {
          "Label": "Mauritania",
          "Value": "5042"
        },
        {
          "Label": "Mauritius",
          "Value": "5043"
        },
        {
          "Label": "Mayotte",
          "Value": "5044"
        },
        {
          "Label": "Mexico",
          "Value": "5045"
        },
        {
          "Label": "Micronesia",
          "Value": "5046"
        },
        {
          "Label": "Moldova",
          "Value": "5047"
        },
        {
          "Label": "Monaco",
          "Value": "5048"
        },
        {
          "Label": "Mongolia",
          "Value": "5049"
        },
        {
          "Label": "Montenegro",
          "Value": "85393"
        },
        {
          "Label": "Montserrat",
          "Value": "5050"
        },
        {
          "Label": "Morocco",
          "Value": "5051"
        },
        {
          "Label": "Mozambique",
          "Value": "5052"
        },
        {
          "Label": "Myanmar",
          "Value": "5053"
        },
        {
          "Label": "Namibia",
          "Value": "5054"
        },
        {
          "Label": "Nauru",
          "Value": "5055"
        },
        {
          "Label": "Nepal",
          "Value": "5056"
        },
        {
          "Label": "Netherlands",
          "Value": "5058"
        },
        {
          "Label": "New Caledonia",
          "Value": "5059"
        },
        {
          "Label": "New Zealand",
          "Value": "5060"
        },
        {
          "Label": "Nicaragua",
          "Value": "5061"
        },
        {
          "Label": "Niger",
          "Value": "5062"
        },
        {
          "Label": "Nigeria",
          "Value": "5063"
        },
        {
          "Label": "Niue",
          "Value": "5064"
        },
        {
          "Label": "Norfolk Island",
          "Value": "5065"
        },
        {
          "Label": "North Korea",
          "Value": "5066"
        },
        {
          "Label": "North Macedonia",
          "Value": "5033"
        },
        {
          "Label": "Northern Mariana Islands",
          "Value": "5067"
        },
        {
          "Label": "Norway",
          "Value": "5068"
        },
        {
          "Label": "Oman",
          "Value": "5069"
        },
        {
          "Label": "Pakistan",
          "Value": "5070"
        },
        {
          "Label": "Palau",
          "Value": "5071"
        },
        {
          "Label": "Palestinian Authority",
          "Value": "10193"
        },
        {
          "Label": "Panama",
          "Value": "5072"
        },
        {
          "Label": "Papua New Guinea",
          "Value": "5073"
        },
        {
          "Label": "Paraguay",
          "Value": "5074"
        },
        {
          "Label": "Peru",
          "Value": "5075"
        },
        {
          "Label": "Philippines",
          "Value": "5076"
        },
        {
          "Label": "Pitcairn Islands",
          "Value": "5077"
        },
        {
          "Label": "Poland",
          "Value": "5078"
        },
        {
          "Label": "Portugal",
          "Value": "5079"
        },
        {
          "Label": "Puerto Rico",
          "Value": "5080"
        },
        {
          "Label": "Qatar",
          "Value": "5081"
        },
        {
          "Label": "Réunion",
          "Value": "5082"
        },
        {
          "Label": "Romania",
          "Value": "5083"
        },
        {
          "Label": "Russia",
          "Value": "5084"
        },
        {
          "Label": "Rwanda",
          "Value": "5085"
        },
        {
          "Label": "Saba",
          "Value": "2082126"
        },
        {
          "Label": "Saint Barthélemy",
          "Value": "1527812"
        },
        {
          "Label": "Saint Kitts and Nevis",
          "Value": "5103"
        },
        {
          "Label": "Saint Lucia",
          "Value": "5104"
        },
        {
          "Label": "Saint Martin",
          "Value": "1527813"
        },
        {
          "Label": "Saint Pierre and Miquelon",
          "Value": "5087"
        },
        {
          "Label": "Saint Vincent and the Grenadines",
          "Value": "5105"
        },
        {
          "Label": "Samoa",
          "Value": "5088"
        },
        {
          "Label": "San Marino",
          "Value": "5089"
        },
        {
          "Label": "São Tomé and Príncipe",
          "Value": "5090"
        },
        {
          "Label": "Saudi Arabia",
          "Value": "5091"
        },
        {
          "Label": "Senegal",
          "Value": "5092"
        },
        {
          "Label": "Serbia",
          "Value": "75314"
        },
        {
          "Label": "Seychelles",
          "Value": "5093"
        },
        {
          "Label": "Sierra Leone",
          "Value": "5094"
        },
        {
          "Label": "Singapore",
          "Value": "5095"
        },
        {
          "Label": "Sint Eustatius",
          "Value": "2082127"
        },
        {
          "Label": "Sint Maarten",
          "Value": "2082128"
        },
        {
          "Label": "Slovakia",
          "Value": "5096"
        },
        {
          "Label": "Slovenia",
          "Value": "5097"
        },
        {
          "Label": "Solomon Islands",
          "Value": "5098"
        },
        {
          "Label": "Somalia",
          "Value": "5099"
        },
        {
          "Label": "South Africa",
          "Value": "5100"
        },
        {
          "Label": "South Georgia and South Sandwich Islands",
          "Value": "5106"
        },
        {
          "Label": "South Sudan",
          "Value": "2313196"
        },
        {
          "Label": "Spain",
          "Value": "5101"
        },
        {
          "Label": "Sri Lanka",
          "Value": "5102"
        },
        {
          "Label": "St Helena, Ascension, Tristan da Cunha",
          "Value": "5086"
        },
        {
          "Label": "Sudan",
          "Value": "5107"
        },
        {
          "Label": "Suriname",
          "Value": "5108"
        },
        {
          "Label": "Svalbard",
          "Value": "5109"
        },
        {
          "Label": "Sweden",
          "Value": "5111"
        },
        {
          "Label": "Switzerland",
          "Value": "5112"
        },
        {
          "Label": "Syria",
          "Value": "5113"
        },
        {
          "Label": "Taiwan",
          "Value": "5114"
        },
        {
          "Label": "Tajikistan",
          "Value": "5115"
        },
        {
          "Label": "Tanzania",
          "Value": "5116"
        },
        {
          "Label": "Thailand",
          "Value": "5117"
        },
        {
          "Label": "Timor-Leste",
          "Value": "93100"
        },
        {
          "Label": "Togo",
          "Value": "5118"
        },
        {
          "Label": "Tokelau",
          "Value": "5119"
        },
        {
          "Label": "Tonga",
          "Value": "5120"
        },
        {
          "Label": "Trinidad and Tobago",
          "Value": "5121"
        },
        {
          "Label": "Tunisia",
          "Value": "5122"
        },
        {
          "Label": "Türkiye",
          "Value": "5123"
        },
        {
          "Label": "Turkmenistan",
          "Value": "5124"
        },
        {
          "Label": "Turks and Caicos Islands",
          "Value": "5125"
        },
        {
          "Label": "Tuvalu",
          "Value": "5126"
        },
        {
          "Label": "U.S. Outlying Islands",
          "Value": "24460"
        },
        {
          "Label": "U.S. Virgin Islands",
          "Value": "5138"
        },
        {
          "Label": "Uganda",
          "Value": "5127"
        },
        {
          "Label": "Ukraine",
          "Value": "5128"
        },
        {
          "Label": "United Arab Emirates",
          "Value": "5129"
        },
        {
          "Label": "United Kingdom",
          "Value": "5130"
        },
        {
          "Label": "United States",
          "Value": "5131"
        },
        {
          "Label": "Unknown",
          "Value": "0"
        },
        {
          "Label": "Uruguay",
          "Value": "5132"
        },
        {
          "Label": "Uzbekistan",
          "Value": "5133"
        },
        {
          "Label": "Vanuatu",
          "Value": "5134"
        },
        {
          "Label": "Vatican City",
          "Value": "5135"
        },
        {
          "Label": "Venezuela",
          "Value": "5136"
        },
        {
          "Label": "Vietnam",
          "Value": "5137"
        },
        {
          "Label": "Wallis and Futuna",
          "Value": "5140"
        },
        {
          "Label": "Yemen",
          "Value": "5141"
        },
        {
          "Label": "Zambia",
          "Value": "5143"
        },
        {
          "Label": "Zimbabwe",
          "Value": "5144"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_cssdisposition",
      "Type": "picklist",
      "DisplayName": "CSS Disposition",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Direct Sales - Assisted Direct Sales",
          "Value": "861980019"
        },
        {
          "Label": "Direct Sales - Assisted Trial Referral",
          "Value": "861980027"
        },
        {
          "Label": "Direct Sales - Direct Sales",
          "Value": "861980018"
        },
        {
          "Label": "Direct Sales - Trial Referral",
          "Value": "861980028"
        },
        {
          "Label": "Non Sales - Abandon Immediately",
          "Value": "861980000"
        },
        {
          "Label": "Non Sales - Left Voice Mail",
          "Value": "861980020"
        },
        {
          "Label": "Non Sales - Non Supported Market",
          "Value": "861980001"
        },
        {
          "Label": "Non Sales - Partner",
          "Value": "861980002"
        },
        {
          "Label": "Non Sales - Post Sales Inquiry",
          "Value": "861980022"
        },
        {
          "Label": "Non Sales - Refer to Billing",
          "Value": "861980003"
        },
        {
          "Label": "Non Sales - Refer to Consumer",
          "Value": "861980004"
        },
        {
          "Label": "Non Sales - Refer to Existing Partner",
          "Value": "861980006"
        },
        {
          "Label": "Non Sales - Refer to Tech Support",
          "Value": "861980007"
        },
        {
          "Label": "Non Sales - Test Contact/MS Employee",
          "Value": "861980008"
        },
        {
          "Label": "Non Sales - Unreachable",
          "Value": "861980021"
        },
        {
          "Label": "Post Sales - Follow up Credit Status",
          "Value": "861980023"
        },
        {
          "Label": "Post Sales - Follow up Shipping Status",
          "Value": "861980024"
        },
        {
          "Label": "Post Sales - Refunds",
          "Value": "861980025"
        },
        {
          "Label": "Post Sales - Returns & Exchange",
          "Value": "861980026"
        },
        {
          "Label": "Sales - Abandon During",
          "Value": "861980009"
        },
        {
          "Label": "Sales - Presales Support",
          "Value": "861980011"
        },
        {
          "Label": "Sales - Schedule Callback",
          "Value": "861980012"
        },
        {
          "Label": "Sales - Trial Assistance",
          "Value": "861980013"
        },
        {
          "Label": "TQL",
          "Value": "861980029"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_csshygieneissue",
      "Type": "picklist",
      "DisplayName": "Issue or Warning",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Missing Disposition (L1)",
          "Value": "606820001"
        },
        {
          "Label": "No Campaign Code",
          "Value": "606820003"
        },
        {
          "Label": "No Chat/Phone ID",
          "Value": "606820004"
        },
        {
          "Label": "No Market",
          "Value": "606820002"
        },
        {
          "Label": "No Primary Product",
          "Value": "606820005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_cssworkflow",
      "Type": "picklist",
      "DisplayName": "CSS Workflow",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Non Sales",
          "Value": "861980000"
        },
        {
          "Label": "Sales",
          "Value": "861980001"
        },
        {
          "Label": "TQL",
          "Value": "861980002"
        },
        {
          "Label": "Direct Sales",
          "Value": "861980003"
        },
        {
          "Label": "Post Sales",
          "Value": "861980004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_currentsalesstagemodifiedon",
      "Type": "datetime",
      "DisplayName": "Current Sales Stage Modified On (Deprecated)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customeragreementcode",
      "Type": "boolean",
      "DisplayName": "Customer Agreement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customercomments",
      "Type": "memo",
      "DisplayName": "Customer Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_customertype",
      "Type": "picklist",
      "DisplayName": "Customer Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Business",
          "Value": "606820001"
        },
        {
          "Label": "EDU",
          "Value": "606820006"
        },
        {
          "Label": "Non-profit",
          "Value": "606820005"
        },
        {
          "Label": "Partner",
          "Value": "606820002"
        },
        {
          "Label": "Personal",
          "Value": "606820000"
        },
        {
          "Label": "Student",
          "Value": "606820003"
        },
        {
          "Label": "Unknown",
          "Value": "606820004"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_daysinstage",
      "Type": "integer",
      "DisplayName": "Days in Stage (Deprecated)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dialcount",
      "Type": "integer",
      "DisplayName": "Number of Dials",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dials",
      "Type": "integer",
      "DisplayName": "Dials",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl1",
      "Type": "picklist",
      "DisplayName": "Disposition (L1)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "PostSales",
          "Value": "606820000"
        },
        {
          "Label": "Prank/Test/Abandon Immediately",
          "Value": "606820001"
        },
        {
          "Label": "PreSales",
          "Value": "606820002"
        },
        {
          "Label": "Sales",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl2",
      "Type": "picklist",
      "DisplayName": "Disposition (L2)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Feature",
          "Value": "606820000"
        },
        {
          "Label": "Licensing",
          "Value": "606820001"
        },
        {
          "Label": "Pricing",
          "Value": "606820002"
        },
        {
          "Label": "Purchase assistance",
          "Value": "606820003"
        },
        {
          "Label": "Promotion/campaign",
          "Value": "606820004"
        },
        {
          "Label": "Trial inquiry",
          "Value": "606820005"
        },
        {
          "Label": "Unsupported",
          "Value": "606820006"
        },
        {
          "Label": "Request for follow-up",
          "Value": "606820007"
        },
        {
          "Label": "Product availability",
          "Value": "606820008"
        },
        {
          "Label": "Other PreSales inquiry",
          "Value": "606820009"
        },
        {
          "Label": "Technical support",
          "Value": "606820010"
        },
        {
          "Label": "Billing support",
          "Value": "606820011"
        },
        {
          "Label": "Cloud Partner Program (MPN)",
          "Value": "606820012"
        },
        {
          "Label": "Order assistance (HW)",
          "Value": "606820013"
        },
        {
          "Label": "Refund/return/exchange (HW)",
          "Value": "606820014"
        },
        {
          "Label": "Other PostSales inquiry",
          "Value": "606820015"
        },
        {
          "Label": "TQL",
          "Value": "606820016"
        },
        {
          "Label": "Direct Sales - Direct Sales",
          "Value": "606820017"
        },
        {
          "Label": "Direct Sales - Assisted Direct Sales",
          "Value": "606820018"
        },
        {
          "Label": "Direct Sales - Trial Referral",
          "Value": "606820019"
        },
        {
          "Label": "Direct Sales - Assisted Trial Referral",
          "Value": "606820020"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl3",
      "Type": "picklist",
      "DisplayName": "Disposition (L3)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Recommendation",
          "Value": "606820000"
        },
        {
          "Label": "Compatibility",
          "Value": "606820001"
        },
        {
          "Label": "Box/bundle",
          "Value": "606820002"
        },
        {
          "Label": "Product feature",
          "Value": "606820003"
        },
        {
          "Label": "Product licensing",
          "Value": "606820004"
        },
        {
          "Label": "Volume licensing",
          "Value": "606820005"
        },
        {
          "Label": "Discount",
          "Value": "606820006"
        },
        {
          "Label": "Price match",
          "Value": "606820007"
        },
        {
          "Label": "Product pricing",
          "Value": "606820008"
        },
        {
          "Label": "Website navigation",
          "Value": "606820009"
        },
        {
          "Label": "Payment instrument",
          "Value": "606820010"
        },
        {
          "Label": "Account/domain",
          "Value": "606820011"
        },
        {
          "Label": "Tax/invoice",
          "Value": "606820012"
        },
        {
          "Label": "Eligibility",
          "Value": "606820013"
        },
        {
          "Label": "Availability",
          "Value": "606820014"
        },
        {
          "Label": "Request for trial",
          "Value": "606820015"
        },
        {
          "Label": "Unable to sign up/in or access",
          "Value": "606820016"
        },
        {
          "Label": "Unsupported market",
          "Value": "606820017"
        },
        {
          "Label": "Unsupported language",
          "Value": "606820018"
        },
        {
          "Label": "Unsupported product",
          "Value": "606820019"
        },
        {
          "Label": "Out-of-stock product",
          "Value": "606820020"
        },
        {
          "Label": "New product availability",
          "Value": "606820021"
        },
        {
          "Label": "Device pre-order inquiry",
          "Value": "606820022"
        },
        {
          "Label": "Feature/How to",
          "Value": "606820023"
        },
        {
          "Label": "Troubleshooting",
          "Value": "606820024"
        },
        {
          "Label": "Log in/account issues",
          "Value": "606820025"
        },
        {
          "Label": "Charge/credit issues",
          "Value": "606820026"
        },
        {
          "Label": "Renewal/cancel",
          "Value": "606820027"
        },
        {
          "Label": "Change plan/subscription",
          "Value": "606820028"
        },
        {
          "Label": "How to become a partner",
          "Value": "606820029"
        },
        {
          "Label": "Partner benefits",
          "Value": "606820030"
        },
        {
          "Label": "Partner Center Escalation",
          "Value": "606820031"
        },
        {
          "Label": "Cloud Partner program inquiry",
          "Value": "606820032"
        },
        {
          "Label": "Order status (PO/Prepay)",
          "Value": "606820033"
        },
        {
          "Label": "Order status (CC/Online)",
          "Value": "606820034"
        },
        {
          "Label": "Return & refund",
          "Value": "606820035"
        },
        {
          "Label": "Return & exchange",
          "Value": "606820036"
        },
        {
          "Label": "Status",
          "Value": "606820037"
        },
        {
          "Label": "Request for refund",
          "Value": "606820038"
        },
        {
          "Label": "Policy/process",
          "Value": "606820039"
        },
        {
          "Label": "Migration/conversion",
          "Value": "100000001"
        },
        {
          "Label": "Trial extension",
          "Value": "100000002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl4",
      "Type": "memo",
      "DisplayName": "Disposition (L4)",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl5",
      "Type": "picklist",
      "DisplayName": "Action Taken (L5)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Assisted/closed",
          "Value": "606820000"
        },
        {
          "Label": "Abandon during",
          "Value": "606820001"
        },
        {
          "Label": "Callback",
          "Value": "606820002"
        },
        {
          "Label": "Assisted/transferred",
          "Value": "606820003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dispositionl6",
      "Type": "picklist",
      "DisplayName": "Transfer To (L6)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Tech support",
          "Value": "606820000"
        },
        {
          "Label": "Partner",
          "Value": "606820001"
        },
        {
          "Label": "Consumer store",
          "Value": "606820002"
        },
        {
          "Label": "Azure billing",
          "Value": "606820003"
        },
        {
          "Label": "Commerce team",
          "Value": "606820004"
        },
        {
          "Label": "Retention",
          "Value": "606820005"
        },
        {
          "Label": "Consumer A&B",
          "Value": "606820006"
        },
        {
          "Label": "Surface support",
          "Value": "606820007"
        },
        {
          "Label": "Xbox support",
          "Value": "606820008"
        },
        {
          "Label": "HoloLens support",
          "Value": "606820009"
        },
        {
          "Label": "VLSC support",
          "Value": "606820010"
        },
        {
          "Label": "Microsoft Internal",
          "Value": "606820011"
        },
        {
          "Label": "Partner.com/support",
          "Value": "606820012"
        },
        {
          "Label": "Microsoft websites",
          "Value": "606820013"
        },
        {
          "Label": "3rd party websites",
          "Value": "606820014"
        },
        {
          "Label": "Admins",
          "Value": "606820015"
        },
        {
          "Label": "Others",
          "Value": "606820016"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowbusinessphonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowbusinessphonemodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Business Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowemailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Email",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowemailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Email Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowfaxcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Fax",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowfaxmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Fax Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Mail",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Mail Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmobilephonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowmobilephonemodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Mobile Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnerbusinessphonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnerbusinessphonemodifiedon",
      "Type": "datetime",
      "DisplayName": "DNU_Do not allow Partner Business Phone ModifiedOn",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartneremailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Email",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartneremailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Partner Email Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermailcode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mail",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermailmodifiedon",
      "Type": "datetime",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mail Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermobilephonecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Do not allow Partner Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unknown",
          "Value": "861980000"
        },
        {
          "Label": "OK To Contact",
          "Value": "861980001"
        },
        {
          "Label": "Do Not Contact",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_donotallowpartnermobilephonemodifiedon",
      "Type": "datetime",
      "DisplayName": "DNU_Do not allow Partner Mobile Phone Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsraction",
      "Type": "string",
      "DisplayName": "DSRAction",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsrdate",
      "Type": "datetime",
      "DisplayName": "DSRDate",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_dsrticket",
      "Type": "string",
      "DisplayName": "DSRTicket#",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_emailvalidation",
      "Type": "picklist",
      "DisplayName": "Email Validation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Valid",
          "Value": "606820000"
        },
        {
          "Label": "Invalid",
          "Value": "606820001"
        },
        {
          "Label": "Corrected",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementid",
      "Type": "lookup",
      "DisplayName": "Source Engagement",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_enlyftinsightsfield",
      "Type": "string",
      "DisplayName": "Enlyft Insights Field",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_estimatedquantity",
      "Type": "integer",
      "DisplayName": "Est. Quantity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_feedbackforcss",
      "Type": "string",
      "DisplayName": "Feedback for CSS",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_fieldacceptstatus",
      "Type": "picklist",
      "DisplayName": "Field Accept Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Accept",
          "Value": "861980000"
        },
        {
          "Label": "Inside Sales to own",
          "Value": "861980001"
        },
        {
          "Label": "Expired",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_flagbusinessphone",
      "Type": "picklist",
      "DisplayName": "Flag Business Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Validated",
          "Value": "0"
        },
        {
          "Label": "OK",
          "Value": "1"
        },
        {
          "Label": "Cancel",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_flagmobilephone",
      "Type": "picklist",
      "DisplayName": "Flag Mobile Phone",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Not Validated",
          "Value": "0"
        },
        {
          "Label": "OK",
          "Value": "1"
        },
        {
          "Label": "Cancel",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_gep",
      "Type": "string",
      "DisplayName": "GEP",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_handraiser",
      "Type": "boolean",
      "DisplayName": "Hand Raiser",
      "Format": null,
      "OptionSet": [
        {
          "Label": "false",
          "Value": "0"
        },
        {
          "Label": "true",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_internalagreementcode",
      "Type": "boolean",
      "DisplayName": "Internal Agreement",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_isprospect",
      "Type": "boolean",
      "DisplayName": "IsProspect",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_lastdialedat",
      "Type": "datetime",
      "DisplayName": "Last Dialed At",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_leadage",
      "Type": "integer",
      "DisplayName": "Lead Age",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_leadassignmentdate",
      "Type": "datetime",
      "DisplayName": "LeadAssignmentDate",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_leadclosedate",
      "Type": "datetime",
      "DisplayName": "Lead Close Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_leadnumber",
      "Type": "string",
      "DisplayName": "Lead Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_leadscore",
      "Type": "decimal",
      "DisplayName": "(Deprecated) Lead Score (MSX)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_linkedinprofileurl",
      "Type": "string",
      "DisplayName": "LinkedIn Profile URL",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_microsoftinternal",
      "Type": "memo",
      "DisplayName": "Microsoft Internal",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_mobphonelocation",
      "Type": "string",
      "DisplayName": "Mobile Phone Location",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_numberofdials",
      "Type": "integer",
      "DisplayName": "Number of Dials",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_numberofdials_date",
      "Type": "datetime",
      "DisplayName": "Number of Dials (Last Updated On)",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_numberofdials_state",
      "Type": "integer",
      "DisplayName": "Number of Dials (State)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_objectid",
      "Type": "string",
      "DisplayName": "Object ID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_orderid",
      "Type": "string",
      "DisplayName": "Order Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_originatingtopic",
      "Type": "string",
      "DisplayName": "Do_Not_Use_Originating Topic",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_othercompetitor",
      "Type": "string",
      "DisplayName": "Other Competitor",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partneraccountid",
      "Type": "lookup",
      "DisplayName": "Partner Account",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnercenterreferralengagementid",
      "Type": "string",
      "DisplayName": "Partner Referral",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_partnerfundingcode",
      "Type": "boolean",
      "DisplayName": "Partner Funding",
      "Format": null,
      "OptionSet": [
        {
          "Label": "not secured",
          "Value": "0"
        },
        {
          "Label": "secured",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_paymenttype",
      "Type": "picklist",
      "DisplayName": "Payment Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "All Access",
          "Value": "861980000"
        },
        {
          "Label": "Credit Card",
          "Value": "861980001"
        },
        {
          "Label": "Dell Business Credit",
          "Value": "861980002"
        },
        {
          "Label": "Pre-Paid",
          "Value": "861980003"
        },
        {
          "Label": "Purchase Order",
          "Value": "861980004"
        },
        {
          "Label": "Wire Card",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_phonevalidation",
      "Type": "picklist",
      "DisplayName": "Phone Validation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Valid",
          "Value": "606820000"
        },
        {
          "Label": "Invalid",
          "Value": "606820001"
        },
        {
          "Label": "Corrected",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pid",
      "Type": "string",
      "DisplayName": "PID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_possiblebusphonereason",
      "Type": "string",
      "DisplayName": "Possible Business Phone Reason",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_possiblemobphonereason",
      "Type": "string",
      "DisplayName": "Possible Mobile Phone Reason",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_practice",
      "Type": "lookup",
      "DisplayName": "Practice",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_preferencetypecode",
      "Type": "picklist",
      "DisplayName": "Do_Not_Use_Preference Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "MS Wide",
          "Value": "861980000"
        },
        {
          "Label": "Topic",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_preferredlanguagecode",
      "Type": "picklist",
      "DisplayName": "Preferred Language",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Afrikaans",
          "Value": "861980041"
        },
        {
          "Label": "Arabic",
          "Value": "861980054"
        },
        {
          "Label": "Armenian",
          "Value": "861980081"
        },
        {
          "Label": "Azeri",
          "Value": "861980087"
        },
        {
          "Label": "Bahasa Indonesia",
          "Value": "861980014"
        },
        {
          "Label": "Basque",
          "Value": "861980092"
        },
        {
          "Label": "Belarusian",
          "Value": "861980091"
        },
        {
          "Label": "Bengali",
          "Value": "861980015"
        },
        {
          "Label": "Bokmal",
          "Value": "861980016"
        },
        {
          "Label": "Bosnian",
          "Value": "861980056"
        },
        {
          "Label": "Bulgarian",
          "Value": "861980057"
        },
        {
          "Label": "Burmese",
          "Value": "861980017"
        },
        {
          "Label": "Cantonese",
          "Value": "861980018"
        },
        {
          "Label": "Catalan",
          "Value": "861980058"
        },
        {
          "Label": "Chinese - Simplified",
          "Value": "861980059"
        },
        {
          "Label": "Chinese - Taiwan",
          "Value": "861980036"
        },
        {
          "Label": "Chinese - Traditional",
          "Value": "861980060"
        },
        {
          "Label": "Croatian",
          "Value": "861980061"
        },
        {
          "Label": "Czech",
          "Value": "861980049"
        },
        {
          "Label": "Danish",
          "Value": "861980019"
        },
        {
          "Label": "Dutch",
          "Value": "861980020"
        },
        {
          "Label": "English",
          "Value": "861980012"
        },
        {
          "Label": "English - UK",
          "Value": "861980062"
        },
        {
          "Label": "English - United States",
          "Value": "861980037"
        },
        {
          "Label": "Estonian",
          "Value": "861980063"
        },
        {
          "Label": "Ewe",
          "Value": "861980089"
        },
        {
          "Label": "Filipino",
          "Value": "861980084"
        },
        {
          "Label": "Finnish",
          "Value": "861980021"
        },
        {
          "Label": "French",
          "Value": "861980000"
        },
        {
          "Label": "French - Belgium",
          "Value": "861980001"
        },
        {
          "Label": "French - Canada",
          "Value": "861980002"
        },
        {
          "Label": "French - Canadian",
          "Value": "861980064"
        },
        {
          "Label": "French - France",
          "Value": "861980003"
        },
        {
          "Label": "French - Luxembourg",
          "Value": "861980004"
        },
        {
          "Label": "French - Monaco",
          "Value": "861980005"
        },
        {
          "Label": "French - Switzerland",
          "Value": "861980006"
        },
        {
          "Label": "Georgian",
          "Value": "861980090"
        },
        {
          "Label": "German",
          "Value": "861980007"
        },
        {
          "Label": "German - Austria",
          "Value": "861980008"
        },
        {
          "Label": "German - Germany",
          "Value": "861980009"
        },
        {
          "Label": "German - Liechtenstein",
          "Value": "861980010"
        },
        {
          "Label": "German - Luxembourg",
          "Value": "861980011"
        },
        {
          "Label": "German - Switzerland",
          "Value": "861980013"
        },
        {
          "Label": "Greek",
          "Value": "861980065"
        },
        {
          "Label": "Hebrew",
          "Value": "861980052"
        },
        {
          "Label": "Hindi",
          "Value": "861980079"
        },
        {
          "Label": "Hungarian",
          "Value": "861980066"
        },
        {
          "Label": "Igbo",
          "Value": "861980080"
        },
        {
          "Label": "Italian",
          "Value": "861980022"
        },
        {
          "Label": "Japanese",
          "Value": "861980053"
        },
        {
          "Label": "Korean",
          "Value": "861980040"
        },
        {
          "Label": "Latvian",
          "Value": "861980067"
        },
        {
          "Label": "Lithuanian",
          "Value": "861980068"
        },
        {
          "Label": "Luxembourgish",
          "Value": "861980086"
        },
        {
          "Label": "Macedonian",
          "Value": "861980069"
        },
        {
          "Label": "Malay",
          "Value": "861980070"
        },
        {
          "Label": "Mandarin",
          "Value": "861980023"
        },
        {
          "Label": "Mongolian",
          "Value": "861980024"
        },
        {
          "Label": "Morisyen",
          "Value": "861980082"
        },
        {
          "Label": "Nepalese",
          "Value": "861980025"
        },
        {
          "Label": "Norwegian",
          "Value": "861980026"
        },
        {
          "Label": "Nynorsk",
          "Value": "861980027"
        },
        {
          "Label": "Polish",
          "Value": "861980050"
        },
        {
          "Label": "Portuguese",
          "Value": "861980028"
        },
        {
          "Label": "Portuguese - Brazil",
          "Value": "861980038"
        },
        {
          "Label": "Portuguese - Portugal",
          "Value": "861980039"
        },
        {
          "Label": "Romanian",
          "Value": "861980071"
        },
        {
          "Label": "Romansh",
          "Value": "861980029"
        },
        {
          "Label": "Russian",
          "Value": "861980051"
        },
        {
          "Label": "Rwandan",
          "Value": "861980085"
        },
        {
          "Label": "Serbian",
          "Value": "861980072"
        },
        {
          "Label": "Sinhala",
          "Value": "861980030"
        },
        {
          "Label": "Slovak",
          "Value": "861980073"
        },
        {
          "Label": "Slovenian",
          "Value": "861980074"
        },
        {
          "Label": "Soga",
          "Value": "861980083"
        },
        {
          "Label": "Spanish",
          "Value": "861980031"
        },
        {
          "Label": "Spanish - LATAM",
          "Value": "861980075"
        },
        {
          "Label": "Swahili",
          "Value": "861980088"
        },
        {
          "Label": "Swedish",
          "Value": "861980032"
        },
        {
          "Label": "Tamil",
          "Value": "861980033"
        },
        {
          "Label": "Thai",
          "Value": "861980034"
        },
        {
          "Label": "Turkish",
          "Value": "861980077"
        },
        {
          "Label": "Ukrainian",
          "Value": "861980078"
        },
        {
          "Label": "Vietnamese",
          "Value": "861980035"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_preorderinterest",
      "Type": "picklist",
      "DisplayName": "Preorder Interest",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Unspecified",
          "Value": "861980000"
        },
        {
          "Label": "Ready To buy",
          "Value": "861980001"
        },
        {
          "Label": "Interested Uncommitted",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_pricelevelid",
      "Type": "lookup",
      "DisplayName": "Price List",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_primarycompetitor",
      "Type": "lookup",
      "DisplayName": "Primary Competitor",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_primaryproductcode",
      "Type": "picklist",
      "DisplayName": "Primary Product",
      "Format": null,
      "OptionSet": [
        {
          "Label": "1MSFT",
          "Value": "861980021"
        },
        {
          "Label": "AI",
          "Value": "861980014"
        },
        {
          "Label": "All in one",
          "Value": "861980000"
        },
        {
          "Label": "Azure",
          "Value": "861980038"
        },
        {
          "Label": "Azure Sphere",
          "Value": "861980013"
        },
        {
          "Label": "Biztalk Server",
          "Value": "861980001"
        },
        {
          "Label": "CnE CAL Suites",
          "Value": "861980002"
        },
        {
          "Label": "Commercial Retail",
          "Value": "861980048"
        },
        {
          "Label": "Consumer Software",
          "Value": "861980049"
        },
        {
          "Label": "Convertible",
          "Value": "861980003"
        },
        {
          "Label": "Desktop",
          "Value": "861980004"
        },
        {
          "Label": "Developer Tools",
          "Value": "861980005"
        },
        {
          "Label": "Dynamics 365",
          "Value": "861980212"
        },
        {
          "Label": "Dynamics CRM",
          "Value": "861980007"
        },
        {
          "Label": "Dynamics CRM Online",
          "Value": "861980008"
        },
        {
          "Label": "Dynamics ERP",
          "Value": "861980006"
        },
        {
          "Label": "Dynamics ERP Online",
          "Value": "861980160"
        },
        {
          "Label": "Dynamics NAV/GP/SL",
          "Value": "861980009"
        },
        {
          "Label": "Enterprise Mobility",
          "Value": "861980051"
        },
        {
          "Label": "Exchange",
          "Value": "861980010"
        },
        {
          "Label": "Exchange Online",
          "Value": "861980161"
        },
        {
          "Label": "Holographic",
          "Value": "861980132"
        },
        {
          "Label": "ISV Recruit",
          "Value": "861980015"
        },
        {
          "Label": "Identity Services",
          "Value": "861980011"
        },
        {
          "Label": "Intune",
          "Value": "861980040"
        },
        {
          "Label": "IoT Suite",
          "Value": "861980159"
        },
        {
          "Label": "Large Screen",
          "Value": "861980054"
        },
        {
          "Label": "Learning Experiences and IT Pro Sub",
          "Value": "861980052"
        },
        {
          "Label": "M365",
          "Value": "861980213"
        },
        {
          "Label": "MS Learning and IT Pro Subscripts",
          "Value": "861980016"
        },
        {
          "Label": "Notebook",
          "Value": "861980017"
        },
        {
          "Label": "OSD Subs & Trans OSD SMSG",
          "Value": "861980022"
        },
        {
          "Label": "Office",
          "Value": "861980018"
        },
        {
          "Label": "Office 365",
          "Value": "861980019"
        },
        {
          "Label": "Office for Mac",
          "Value": "861980020"
        },
        {
          "Label": "OneDrive for Business",
          "Value": "861980158"
        },
        {
          "Label": "Operations Management Suite",
          "Value": "861980157"
        },
        {
          "Label": "PC Accessories",
          "Value": "861980024"
        },
        {
          "Label": "Phone",
          "Value": "861980026"
        },
        {
          "Label": "Power Automate",
          "Value": "861981286"
        },
        {
          "Label": "Power BI",
          "Value": "861980156"
        },
        {
          "Label": "Power Pages",
          "Value": "861981290"
        },
        {
          "Label": "Power Virtual Agents",
          "Value": "861981287"
        },
        {
          "Label": "PowerApps",
          "Value": "861980023"
        },
        {
          "Label": "Project",
          "Value": "861980028"
        },
        {
          "Label": "Project Online",
          "Value": "861980155"
        },
        {
          "Label": "SQL Server",
          "Value": "861980030"
        },
        {
          "Label": "SVS - ACM & MSM_AI",
          "Value": "606820000"
        },
        {
          "Label": "SVS - ACM & MSM_Apps",
          "Value": "606820001"
        },
        {
          "Label": "SVS - ACM & MSM_BA",
          "Value": "606820002"
        },
        {
          "Label": "SVS - ACM & MSM_Data",
          "Value": "606820003"
        },
        {
          "Label": "SVS - ACM & MSM_Infra",
          "Value": "606820004"
        },
        {
          "Label": "SVS - ACM & MSM_MW",
          "Value": "606820005"
        },
        {
          "Label": "SVS - ACM & MSM_Sec",
          "Value": "606820006"
        },
        {
          "Label": "SVS - AI Innovation_AI",
          "Value": "606820007"
        },
        {
          "Label": "SVS - Accel. Revenue Generation_BA",
          "Value": "384200052"
        },
        {
          "Label": "SVS - All Products",
          "Value": "861980200"
        },
        {
          "Label": "SVS - Azure",
          "Value": "861980033"
        },
        {
          "Label": "SVS - Azure Migration_Azure_Infra",
          "Value": "861980035"
        },
        {
          "Label": "SVS - Azure Migration_OnPrem_Infra",
          "Value": "861980044"
        },
        {
          "Label": "SVS - Cloud Migration_Apps",
          "Value": "384200055"
        },
        {
          "Label": "SVS - Cloud Migration_Data",
          "Value": "384200092"
        },
        {
          "Label": "SVS - Cloud Migration_Infra",
          "Value": "384200068"
        },
        {
          "Label": "SVS - Cloud Modernization_Apps",
          "Value": "384200062"
        },
        {
          "Label": "SVS - Cloud Modernization_Data",
          "Value": "384200076"
        },
        {
          "Label": "SVS - Cloud Modernization_Infra",
          "Value": "384200061"
        },
        {
          "Label": "SVS - Cloud Ops & Opt_Apps",
          "Value": "384200065"
        },
        {
          "Label": "SVS - Cloud Ops & Opt_Infra",
          "Value": "384200057"
        },
        {
          "Label": "SVS - Cloud Ops & Opt_Infra.OnPrem",
          "Value": "384200067"
        },
        {
          "Label": "SVS - Consulting PSfD_Apps",
          "Value": "384200036"
        },
        {
          "Label": "SVS - Customer Engagement_BA.OnPrem",
          "Value": "606820027"
        },
        {
          "Label": "SVS - D365",
          "Value": "861980066"
        },
        {
          "Label": "SVS - Data & Analytics Platform_Data",
          "Value": "384200077"
        },
        {
          "Label": "SVS - Employee Experiences_MW",
          "Value": "384200083"
        },
        {
          "Label": "SVS - Envisioning & Advisory_AI",
          "Value": "384200093"
        },
        {
          "Label": "SVS - Envisioning & Advisory_Apps",
          "Value": "384200054"
        },
        {
          "Label": "SVS - Envisioning & Advisory_BA",
          "Value": "384200053"
        },
        {
          "Label": "SVS - Envisioning & Advisory_Data",
          "Value": "384200082"
        },
        {
          "Label": "SVS - Envisioning & Advisory_Infra",
          "Value": "384200070"
        },
        {
          "Label": "SVS - Envisioning & Advisory_MW",
          "Value": "384200079"
        },
        {
          "Label": "SVS - Envisioning & Advisory_Sec",
          "Value": "384200078"
        },
        {
          "Label": "SVS - Finance & Operations_BA.OnPrem",
          "Value": "606820043"
        },
        {
          "Label": "SVS - Finance & Supply Chain_BA",
          "Value": "384200087"
        },
        {
          "Label": "SVS - Incident Response_Sec",
          "Value": "384200080"
        },
        {
          "Label": "SVS - Industry Innovation_AI",
          "Value": "384200075"
        },
        {
          "Label": "SVS - Industry Innovation_Apps",
          "Value": "384200056"
        },
        {
          "Label": "SVS - Industry Innovation_BA",
          "Value": "384200072"
        },
        {
          "Label": "SVS - Industry Innovation_Data",
          "Value": "384200058"
        },
        {
          "Label": "SVS - Industry Innovation_Infra",
          "Value": "384200063"
        },
        {
          "Label": "SVS - Industry Innovation_MW",
          "Value": "384200088"
        },
        {
          "Label": "SVS - Intel Operations_D365_BA",
          "Value": "861980084"
        },
        {
          "Label": "SVS - Intelligent Apps_AI",
          "Value": "384200089"
        },
        {
          "Label": "SVS - Intelligent Apps_Apps",
          "Value": "384200071"
        },
        {
          "Label": "SVS - Intelligent Apps_Data",
          "Value": "384200090"
        },
        {
          "Label": "SVS - Kaizala",
          "Value": "861980231"
        },
        {
          "Label": "SVS - Low Code Automation_AI",
          "Value": "384200051"
        },
        {
          "Label": "SVS - Low Code Automation_Apps",
          "Value": "384200085"
        },
        {
          "Label": "SVS - Low Code Automation_BA",
          "Value": "606820062"
        },
        {
          "Label": "SVS - Low Code Automation_Data",
          "Value": "384200073"
        },
        {
          "Label": "SVS - Modernization_AI.OnPrem",
          "Value": "384200081"
        },
        {
          "Label": "SVS - Modernization_Apps.OnPrem",
          "Value": "606820071"
        },
        {
          "Label": "SVS - Modernization_Data.OnPrem",
          "Value": "606820072"
        },
        {
          "Label": "SVS - Modernization_Infra.OnPrem",
          "Value": "606820073"
        },
        {
          "Label": "SVS - Modernize Service_BA",
          "Value": "384200060"
        },
        {
          "Label": "SVS - Nuance Solutions_BA",
          "Value": "384200059"
        },
        {
          "Label": "SVS - O365",
          "Value": "861980078"
        },
        {
          "Label": "SVS - Public Sector Innovation_AI",
          "Value": "384200074"
        },
        {
          "Label": "SVS - Public Sector Innovation_Apps",
          "Value": "384200086"
        },
        {
          "Label": "SVS - Public Sector Innovation_BA",
          "Value": "384200091"
        },
        {
          "Label": "SVS - Public Sector Innovation_Data",
          "Value": "384200064"
        },
        {
          "Label": "SVS - Public Sector Innovation_Infra",
          "Value": "384200084"
        },
        {
          "Label": "SVS - Public Sector Innovation_MW",
          "Value": "384200066"
        },
        {
          "Label": "SVS - SAP & Mainframe_Azure_Infra",
          "Value": "861980093"
        },
        {
          "Label": "SVS - SQL Server DW",
          "Value": "861980211"
        },
        {
          "Label": "SVS - Security Moderniztn_Sec",
          "Value": "606820086"
        },
        {
          "Label": "SVS - Security Moderniztn_Sec.OnPrem",
          "Value": "606820087"
        },
        {
          "Label": "SVS - Server Migration",
          "Value": "861980236"
        },
        {
          "Label": "SVS - Services for Enterprise_Sec",
          "Value": "384200069"
        },
        {
          "Label": "SVS - Workplace Modernization_MW",
          "Value": "606820103"
        },
        {
          "Label": "Secure Productive Enterprise",
          "Value": "861980167"
        },
        {
          "Label": "Sharepoint",
          "Value": "861980029"
        },
        {
          "Label": "Sharepoint Online",
          "Value": "861980168"
        },
        {
          "Label": "Skype for Business",
          "Value": "861980012"
        },
        {
          "Label": "Skype for Business Online",
          "Value": "861980169"
        },
        {
          "Label": "Storage",
          "Value": "861980047"
        },
        {
          "Label": "Surface Accessories",
          "Value": "861980154"
        },
        {
          "Label": "Surface Devices",
          "Value": "861980153"
        },
        {
          "Label": "System Center",
          "Value": "861980034"
        },
        {
          "Label": "Tablet",
          "Value": "861980036"
        },
        {
          "Label": "Visio",
          "Value": "861980037"
        },
        {
          "Label": "Visio Online",
          "Value": "861980170"
        },
        {
          "Label": "W365",
          "Value": "861981289"
        },
        {
          "Label": "Windows + Azure",
          "Value": "861980218"
        },
        {
          "Label": "Windows Client",
          "Value": "861980039"
        },
        {
          "Label": "Windows IoT",
          "Value": "861980152"
        },
        {
          "Label": "Windows MDOP",
          "Value": "861980041"
        },
        {
          "Label": "Windows Remote Desktop Services",
          "Value": "861980042"
        },
        {
          "Label": "Windows Server",
          "Value": "861980043"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_prioritizationscore",
      "Type": "decimal",
      "DisplayName": "Prioritization Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_priority",
      "Type": "picklist",
      "DisplayName": "Priority",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Cloud Platform",
          "Value": "861980002"
        },
        {
          "Label": "Cloud Productivity",
          "Value": "861980000"
        },
        {
          "Label": "Data Platform",
          "Value": "861980003"
        },
        {
          "Label": "Mobility & Devices",
          "Value": "861980004"
        },
        {
          "Label": "One Microsoft",
          "Value": "861980005"
        },
        {
          "Label": "Sales Productivity",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_productsku",
      "Type": "string",
      "DisplayName": "Product SKU",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_puid",
      "Type": "string",
      "DisplayName": "PUID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_quantity",
      "Type": "string",
      "DisplayName": "Quantity",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_ratingexplanation",
      "Type": "string",
      "DisplayName": "Rating Explanation",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_registrationdate",
      "Type": "datetime",
      "DisplayName": "Registration Date",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_routeto",
      "Type": "picklist",
      "DisplayName": "Route To",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Field",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesacceptlead",
      "Type": "picklist",
      "DisplayName": "Sales Accept Lead",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        },
        {
          "Label": "No",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesplay",
      "Type": "picklist",
      "DisplayName": "Sales Play/Checklist",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Accelerate Developer Productivity",
          "Value": "861980006"
        },
        {
          "Label": "Accelerate Innovation with Integration Services",
          "Value": "606820011"
        },
        {
          "Label": "Accelerate Innovation with Low Code",
          "Value": "861980068"
        },
        {
          "Label": "Accelerate Revenue Generation",
          "Value": "861980020"
        },
        {
          "Label": "Advanced Networking",
          "Value": "861980000"
        },
        {
          "Label": "Automate Business Processes",
          "Value": "861980051"
        },
        {
          "Label": "Build a Real Time and Sustainable Supply Chain",
          "Value": "861980079"
        },
        {
          "Label": "Build a Strong Foundation with Unified",
          "Value": "861980087"
        },
        {
          "Label": "Build a Sustainable IT Infrastructure",
          "Value": "861980083"
        },
        {
          "Label": "Build and Modernize AI Apps",
          "Value": "861980037"
        },
        {
          "Label": "Build Your Games in the Cloud with Azure",
          "Value": "861980008"
        },
        {
          "Label": "Cloud Endpoints",
          "Value": "861980056"
        },
        {
          "Label": "Collaborative Apps",
          "Value": "861980060"
        },
        {
          "Label": "Combat Financial Crime",
          "Value": "861980071"
        },
        {
          "Label": "Connected Commerce",
          "Value": "861980052"
        },
        {
          "Label": "Converged Communications",
          "Value": "861980055"
        },
        {
          "Label": "Converged Communications Teams Rooms",
          "Value": "861980034"
        },
        {
          "Label": "Create Sustainable Value Chains",
          "Value": "861980084"
        },
        {
          "Label": "Data Governance",
          "Value": "861980054"
        },
        {
          "Label": "Data Security",
          "Value": "861980027"
        },
        {
          "Label": "Deliver Differentiated Customer Experiences",
          "Value": "861980072"
        },
        {
          "Label": "Developer Engagement",
          "Value": "861980001"
        },
        {
          "Label": "Discover Business Insights",
          "Value": "861980053"
        },
        {
          "Label": "Drive Cloud Success with Enhanced Solutions",
          "Value": "606820009"
        },
        {
          "Label": "Elevate the Shopping Experience",
          "Value": "861980080"
        },
        {
          "Label": "Employee Experience",
          "Value": "861980029"
        },
        {
          "Label": "Empower Employees Through Teamwork",
          "Value": "861980073"
        },
        {
          "Label": "Empower Health Team Collaboration",
          "Value": "861980075"
        },
        {
          "Label": "Empower Your Store Associate",
          "Value": "861980081"
        },
        {
          "Label": "Enable a Resilient and Sustainable Supply Chain",
          "Value": "861980021"
        },
        {
          "Label": "Enable Customer Success",
          "Value": "861980058"
        },
        {
          "Label": "Enable Unified Data Governance",
          "Value": "861980066"
        },
        {
          "Label": "Enhance Clinician Experiences",
          "Value": "861980076"
        },
        {
          "Label": "Enhance Patient Engagement",
          "Value": "861980077"
        },
        {
          "Label": "Ensure coverage for your key solutions",
          "Value": "861980088"
        },
        {
          "Label": "Frontline Workers",
          "Value": "861980028"
        },
        {
          "Label": "Improve Clinical and Operational Insights",
          "Value": "861980078"
        },
        {
          "Label": "Innovate Across Hybrid and Edge with Azure Arc and IoT",
          "Value": "861980010"
        },
        {
          "Label": "Innovate and Scale with Cloud Native Apps",
          "Value": "861980004"
        },
        {
          "Label": "Innovate with HPC, AI Infrastructure",
          "Value": "861980009"
        },
        {
          "Label": "IoT",
          "Value": "861980011"
        },
        {
          "Label": "Manage Risk Across the Organization",
          "Value": "861980074"
        },
        {
          "Label": "Maximize the Value of Your Data",
          "Value": "861980082"
        },
        {
          "Label": "Microsoft Azure Consumption Commitment (MACC)",
          "Value": "861980091"
        },
        {
          "Label": "Migrate and Modernize Your Data Estate",
          "Value": "861980018"
        },
        {
          "Label": "Migrate and Secure Windows Server and SQL Server",
          "Value": "861980067"
        },
        {
          "Label": "Migrate Enterprise Apps",
          "Value": "861980069"
        },
        {
          "Label": "Migrate Linux and OSS DBs",
          "Value": "861980012"
        },
        {
          "Label": "Migrate Linux Estate",
          "Value": "606820002"
        },
        {
          "Label": "Migrate Oracle",
          "Value": "606820003"
        },
        {
          "Label": "Migrate SAP",
          "Value": "861980016"
        },
        {
          "Label": "Migrate to Azure VMware Solution",
          "Value": "861980002"
        },
        {
          "Label": "Mixed Reality",
          "Value": "861980041"
        },
        {
          "Label": "Modern Sec Ops",
          "Value": "606820006"
        },
        {
          "Label": "Modernize .NET and Java apps with PaaS, low code and managed databases",
          "Value": "861980015"
        },
        {
          "Label": "Modernize Service",
          "Value": "861980024"
        },
        {
          "Label": "Modernize VDI to Azure Virtual Desktop",
          "Value": "861980019"
        },
        {
          "Label": "Modernize with Surface",
          "Value": "861980032"
        },
        {
          "Label": "Modernize your digital estate with Unified Enterprise",
          "Value": "861980089"
        },
        {
          "Label": "Multi Cloud Security",
          "Value": "861980057"
        },
        {
          "Label": "Not Applicable",
          "Value": "861980040"
        },
        {
          "Label": "Optimize Finance and Supply Chain",
          "Value": "861980026"
        },
        {
          "Label": "Personalize Customer Experience",
          "Value": "861980025"
        },
        {
          "Label": "Power Business Decisions with Cloud Scale Analytics",
          "Value": "861980038"
        },
        {
          "Label": "Protect Your Data and Ensure Business Resiliency with BCDR",
          "Value": "861980070"
        },
        {
          "Label": "Rapidly Build Apps",
          "Value": "861980022"
        },
        {
          "Label": "Reduce Environmental Impact of Operations",
          "Value": "861980085"
        },
        {
          "Label": "Secure Identities and Access",
          "Value": "861980042"
        },
        {
          "Label": "Secure Identity and Takeout Okta and Ping",
          "Value": "606820007"
        },
        {
          "Label": "Secure Productivity",
          "Value": "861980030"
        },
        {
          "Label": "SMB Scale Business Operations",
          "Value": "606820000"
        },
        {
          "Label": "Teams Platform",
          "Value": "861980035"
        },
        {
          "Label": "Threat Protection with XDR and SIEM",
          "Value": "861980031"
        },
        {
          "Label": "Unify Data Intelligence",
          "Value": "861980086"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_salesstagecode",
      "Type": "picklist",
      "DisplayName": "Sales Stage (Deprecated)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "1-Select Lead",
          "Value": "0"
        },
        {
          "Label": "2-Qualify Lead",
          "Value": "1"
        },
        {
          "Label": "3-Listen & Consult",
          "Value": "2"
        },
        {
          "Label": "4-Inspire & Design",
          "Value": "3"
        },
        {
          "Label": "5-Empower & Achieve",
          "Value": "4"
        },
        {
          "Label": "6-Realize Value",
          "Value": "5"
        },
        {
          "Label": "7-Manage & Optimize",
          "Value": "10"
        },
        {
          "Label": "1-Identify Account Segment",
          "Value": "6"
        },
        {
          "Label": "2-Understand Your Customer",
          "Value": "7"
        },
        {
          "Label": "3-Execute The Customer Meeting",
          "Value": "8"
        },
        {
          "Label": "4-Capture Next Steps",
          "Value": "9"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_servicedivision",
      "Type": "picklist",
      "DisplayName": "Services Division",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Consulting",
          "Value": "861980000"
        },
        {
          "Label": "Support",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_skudescription",
      "Type": "string",
      "DisplayName": "SKU Description",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionarea",
      "Type": "picklist",
      "DisplayName": "Solution Area",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Digital and Application Innovation",
          "Value": "861980004"
        },
        {
          "Label": "Financial Services",
          "Value": "861980007"
        },
        {
          "Label": "Healthcare",
          "Value": "861980008"
        },
        {
          "Label": "Retail",
          "Value": "861980009"
        },
        {
          "Label": "Security",
          "Value": "861980005"
        },
        {
          "Label": "Sustainability",
          "Value": "861980010"
        },
        {
          "Label": "Unified Support",
          "Value": "861980011"
        },
        {
          "Label": "Business Applications",
          "Value": "394380001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980000"
        },
        {
          "Label": "Infrastructure",
          "Value": "394380000"
        },
        {
          "Label": "Modern Work",
          "Value": "394380002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sourceleadid",
      "Type": "string",
      "DisplayName": "Source Lead Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sourceplatform",
      "Type": "picklist",
      "DisplayName": "Lead Source Platform",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default",
          "Value": "861980008"
        },
        {
          "Label": "Manual_MSXLeadForm",
          "Value": "861980003"
        },
        {
          "Label": "Manual_MSXQuickForm",
          "Value": "861980004"
        },
        {
          "Label": "Pull_SalesAccelerator",
          "Value": "861980000"
        },
        {
          "Label": "Push_CDS",
          "Value": "861980007"
        },
        {
          "Label": "Push_ExcelImport",
          "Value": "861980006"
        },
        {
          "Label": "Push_LeadDataService",
          "Value": "861980002"
        },
        {
          "Label": "Push_LeadIngestion",
          "Value": "861980001"
        },
        {
          "Label": "Push_SilverBullet",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_sourcesubtypecode",
      "Type": "picklist",
      "DisplayName": "Source Sub-Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Artificial Intelligence",
          "Value": "606820002"
        },
        {
          "Label": "CSS Inbound",
          "Value": "861980002"
        },
        {
          "Label": "Prospecting Recommendation",
          "Value": "606820001"
        },
        {
          "Label": "Solution Assessment",
          "Value": "606820003"
        },
        {
          "Label": "Concierge",
          "Value": "384200001"
        },
        {
          "Label": "Support for Partners",
          "Value": "384200002"
        },
        {
          "Label": "DSE Signal Derived",
          "Value": "606820004"
        },
        {
          "Label": "DSE Proactive Hunting",
          "Value": "606820005"
        },
        {
          "Label": "Annuity Renewal",
          "Value": "861980114"
        },
        {
          "Label": "Automation",
          "Value": "861981000"
        },
        {
          "Label": "Azure",
          "Value": "861980000"
        },
        {
          "Label": "Azure Marketplace",
          "Value": "861981143"
        },
        {
          "Label": "Bing",
          "Value": "606820000"
        },
        {
          "Label": "Biz Apps CSM",
          "Value": "100000000"
        },
        {
          "Label": "Campus & School Agreement",
          "Value": "861980001"
        },
        {
          "Label": "CRM",
          "Value": "861980003"
        },
        {
          "Label": "CSS Chat",
          "Value": "861981145"
        },
        {
          "Label": "CSS Outbound",
          "Value": "861981148"
        },
        {
          "Label": "CSS Phone",
          "Value": "861981144"
        },
        {
          "Label": "DR:Business Rules",
          "Value": "861980045"
        },
        {
          "Label": "DR:CourtesyCall",
          "Value": "861981234"
        },
        {
          "Label": "EA Renewal",
          "Value": "861980006"
        },
        {
          "Label": "EA TUP",
          "Value": "861980007"
        },
        {
          "Label": "Enterprise Sales Campaign",
          "Value": "100000002"
        },
        {
          "Label": "GDC-Pilot",
          "Value": "100000001"
        },
        {
          "Label": "Inbound",
          "Value": "861981141"
        },
        {
          "Label": "MQL",
          "Value": "861981086"
        },
        {
          "Label": "MQL Accelerated",
          "Value": "861981236"
        },
        {
          "Label": "O365",
          "Value": "861980012"
        },
        {
          "Label": "OEM IOT",
          "Value": "861981146"
        },
        {
          "Label": "Office",
          "Value": "861980013"
        },
        {
          "Label": "Prospect",
          "Value": "861981101"
        },
        {
          "Label": "SAM",
          "Value": "861980018"
        },
        {
          "Label": "SMB & Edu Retail",
          "Value": "861981240"
        },
        {
          "Label": "SMB Vendor Tele",
          "Value": "861981238"
        },
        {
          "Label": "SPE",
          "Value": "861981103"
        },
        {
          "Label": "VT - Ent Educ",
          "Value": "861981239"
        },
        {
          "Label": "Windows Intune",
          "Value": "861980026"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_stampstatuschange",
      "Type": "datetime",
      "DisplayName": "Stamp Status Change",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_storedisposition",
      "Type": "picklist",
      "DisplayName": "Store Disposition",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Direct sales - M365",
          "Value": "606820007"
        },
        {
          "Label": "Direct Sales - PO Order Processing",
          "Value": "606820004"
        },
        {
          "Label": "Direct Sales - POBO Order Processing",
          "Value": "606820003"
        },
        {
          "Label": "Direct Sales - Product Inquiry - Commercial",
          "Value": "606820001"
        },
        {
          "Label": "Direct Sales - Product Inquiry - Consumer",
          "Value": "606820002"
        },
        {
          "Label": "Direct Sales - Promo/Campaign Inquiry",
          "Value": "606820005"
        },
        {
          "Label": "Direct Sales - Quote request",
          "Value": "606820000"
        },
        {
          "Label": "Direct sales - Website Navigation",
          "Value": "606820006"
        },
        {
          "Label": "Non Sales - Ghost Call/Chats",
          "Value": "606820017"
        },
        {
          "Label": "Non Sales - MEC related questions/request",
          "Value": "606820027"
        },
        {
          "Label": "Non Sales - Not Supported Market",
          "Value": "606820036"
        },
        {
          "Label": "Non Sales - Partner",
          "Value": "606820020"
        },
        {
          "Label": "Non Sales - Prank Call/Chat",
          "Value": "606820018"
        },
        {
          "Label": "Non Sales - Refer to Billing",
          "Value": "606820021"
        },
        {
          "Label": "Non Sales - Refer to Consumer",
          "Value": "606820022"
        },
        {
          "Label": "Non Sales - Refer to Existing Partner",
          "Value": "606820023"
        },
        {
          "Label": "Non Sales - Refer to Tech Support",
          "Value": "606820024"
        },
        {
          "Label": "Non Sales - Sudden disconnection",
          "Value": "606820016"
        },
        {
          "Label": "Non Sales - Test Call/Chat",
          "Value": "606820019"
        },
        {
          "Label": "Non Sales - Test Contact/MS Employee",
          "Value": "606820025"
        },
        {
          "Label": "Non Sales - Unreachable",
          "Value": "606820026"
        },
        {
          "Label": "Post Sales - Invoice",
          "Value": "606820013"
        },
        {
          "Label": "Post Sales - Order Cancellation",
          "Value": "606820014"
        },
        {
          "Label": "Post Sales - Order Status",
          "Value": "606820008"
        },
        {
          "Label": "Post Sales - PO Credit status",
          "Value": "606820009"
        },
        {
          "Label": "Post Sales - Return & Exchange",
          "Value": "606820012"
        },
        {
          "Label": "Post Sales - Return & Refunds",
          "Value": "606820011"
        },
        {
          "Label": "Post Sales - Shipping Status",
          "Value": "606820010"
        },
        {
          "Label": "Post Sales - Tax",
          "Value": "606820015"
        },
        {
          "Label": "Sales - Abandon During",
          "Value": "606820028"
        },
        {
          "Label": "Sales - Presales Support",
          "Value": "606820029"
        },
        {
          "Label": "Sales - Schedule Callback",
          "Value": "606820030"
        },
        {
          "Label": "Sales - Trial Assistance",
          "Value": "606820031"
        },
        {
          "Label": "TQL",
          "Value": "606820037"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_storeproduct",
      "Type": "lookup",
      "DisplayName": "StoreProduct",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_subscriptionid",
      "Type": "string",
      "DisplayName": "Subscription Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_technicalcapability",
      "Type": "picklist",
      "DisplayName": "Technical Capability",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Advanced Networking",
          "Value": "861980000"
        },
        {
          "Label": "Advisory Services",
          "Value": "861980055"
        },
        {
          "Label": "Appliance Migration to Azure Synapse",
          "Value": "861980073"
        },
        {
          "Label": "Azure AI and ML",
          "Value": "861980002"
        },
        {
          "Label": "Azure Arc",
          "Value": "861980074"
        },
        {
          "Label": "Azure Stack Edge",
          "Value": "861980075"
        },
        {
          "Label": "Azure Stack Hub",
          "Value": "861980076"
        },
        {
          "Label": "Azure VMWare Solutions",
          "Value": "861980005"
        },
        {
          "Label": "Calling",
          "Value": "861980068"
        },
        {
          "Label": "Citrix Cloud on Azure",
          "Value": "861980029"
        },
        {
          "Label": "Cloud Adoption Framework",
          "Value": "861980011"
        },
        {
          "Label": "Cloud Scale Analytics",
          "Value": "861980077"
        },
        {
          "Label": "Cloud Security",
          "Value": "861980063"
        },
        {
          "Label": "Cloud to Cloud Migration",
          "Value": "861980008"
        },
        {
          "Label": "Commerce",
          "Value": "861980034"
        },
        {
          "Label": "Custom Solutions",
          "Value": "861980071"
        },
        {
          "Label": "Customer Insights",
          "Value": "861980050"
        },
        {
          "Label": "Customer Service",
          "Value": "861980046"
        },
        {
          "Label": "Device Deployment & Management",
          "Value": "861980060"
        },
        {
          "Label": "DevOps with GitHub",
          "Value": "861980009"
        },
        {
          "Label": "Field Service",
          "Value": "861980047"
        },
        {
          "Label": "Finance",
          "Value": "861980051"
        },
        {
          "Label": "Firstline Workers",
          "Value": "861980057"
        },
        {
          "Label": "Forms Pro",
          "Value": "861980078"
        },
        {
          "Label": "Fraud Protection",
          "Value": "861980052"
        },
        {
          "Label": "Gaming",
          "Value": "861980014"
        },
        {
          "Label": "High Performance Compute",
          "Value": "861980015"
        },
        {
          "Label": "HR",
          "Value": "861980035"
        },
        {
          "Label": "Identity & Access Management",
          "Value": "861980059"
        },
        {
          "Label": "Information Protection & Governance",
          "Value": "861980054"
        },
        {
          "Label": "Insider Risk",
          "Value": "861980056"
        },
        {
          "Label": "IoT",
          "Value": "861980018"
        },
        {
          "Label": "Knowledge & Insights",
          "Value": "861980058"
        },
        {
          "Label": "Linux & OSS DB Migration to Azure",
          "Value": "861980019"
        },
        {
          "Label": "Marketing",
          "Value": "861980032"
        },
        {
          "Label": "Meeting Rooms",
          "Value": "861980067"
        },
        {
          "Label": "Meetings",
          "Value": "861980086"
        },
        {
          "Label": "Migration On-Prem to Manage Financial Risk",
          "Value": "861980079"
        },
        {
          "Label": "Migration On-Prem to Supply Chain Management",
          "Value": "861980080"
        },
        {
          "Label": "Mixed Reality",
          "Value": "861980022"
        },
        {
          "Label": "Mobile Device Management",
          "Value": "861980061"
        },
        {
          "Label": "Modernize .NET Apps with App Service, Azure SQL DB",
          "Value": "861980023"
        },
        {
          "Label": "Modernize/New Cloud Native Apps with AKS and Azure Cosmos/Postgres DB",
          "Value": "861980007"
        },
        {
          "Label": "New Analytics with Synapse & PowerBI",
          "Value": "861980085"
        },
        {
          "Label": "OSS DB Migration to Azure OSS DB",
          "Value": "861980020"
        },
        {
          "Label": "Power Apps",
          "Value": "861980041"
        },
        {
          "Label": "Power Automate",
          "Value": "861980081"
        },
        {
          "Label": "Power BI",
          "Value": "861980049"
        },
        {
          "Label": "Power Platform for Teams",
          "Value": "861980070"
        },
        {
          "Label": "Power Virtual Agents",
          "Value": "861980082"
        },
        {
          "Label": "Project Operations",
          "Value": "861980083"
        },
        {
          "Label": "Sales",
          "Value": "861980031"
        },
        {
          "Label": "SAP on Azure",
          "Value": "861980024"
        },
        {
          "Label": "SQL Server Migration to Azure SQL MI",
          "Value": "861980027"
        },
        {
          "Label": "Supply Chain Management",
          "Value": "861980033"
        },
        {
          "Label": "Surface & Partner Devices",
          "Value": "861980064"
        },
        {
          "Label": "Teams Store Apps",
          "Value": "861980069"
        },
        {
          "Label": "Teamwork Deployment",
          "Value": "861980065"
        },
        {
          "Label": "Threat Protection",
          "Value": "861980062"
        },
        {
          "Label": "VMWare Horizon Cloud Service for Azure",
          "Value": "861980030"
        },
        {
          "Label": "Well Architected",
          "Value": "861980072"
        },
        {
          "Label": "Windows & SQL Server Migration to Azure",
          "Value": "861980026"
        },
        {
          "Label": "Workplace Analytics",
          "Value": "861980084"
        },
        {
          "Label": "WVD Native",
          "Value": "861980028"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_teleacceptlead",
      "Type": "picklist",
      "DisplayName": "Tele Accept Lead",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        },
        {
          "Label": "No",
          "Value": "861980001"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_tenantid",
      "Type": "string",
      "DisplayName": "TenantId",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_tpid",
      "Type": "string",
      "DisplayName": "TPID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_userrole",
      "Type": "string",
      "DisplayName": "UserRole",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_wwlmresponseid",
      "Type": "string",
      "DisplayName": "WWLM Response Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "need",
      "Type": "picklist",
      "DisplayName": "Need Code",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Must have",
          "Value": "0"
        },
        {
          "Label": "Should have",
          "Value": "1"
        },
        {
          "Label": "Good to have",
          "Value": "2"
        },
        {
          "Label": "No need",
          "Value": "3"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "new_predictivemodelscoreid",
      "Type": "lookup",
      "DisplayName": "Predictive Model Score",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "numberofemployees",
      "Type": "integer",
      "DisplayName": "No. of Employees",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "onholdtime",
      "Type": "integer",
      "DisplayName": "On Hold Time (Minutes)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "originatingcaseid",
      "Type": "lookup",
      "DisplayName": "Originating Case",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "overriddencreatedon",
      "Type": "datetime",
      "DisplayName": "Record Created On",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "ownerid",
      "Type": "owner",
      "DisplayName": "Owner",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningbusinessunit",
      "Type": "lookup",
      "DisplayName": "Owning Business Unit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningteam",
      "Type": "lookup",
      "DisplayName": "Owning Team",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owninguser",
      "Type": "lookup",
      "DisplayName": "Owning User",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "pager",
      "Type": "string",
      "DisplayName": "Pager",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentaccountid",
      "Type": "lookup",
      "DisplayName": "Parent Account for lead",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "parentcontactid",
      "Type": "lookup",
      "DisplayName": "Parent Contact for lead",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "participatesinworkflow",
      "Type": "boolean",
      "DisplayName": "Participates in Workflow",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No",
          "Value": "0"
        },
        {
          "Label": "Yes",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "preferredcontactmethodcode",
      "Type": "picklist",
      "DisplayName": "Preferred Method of Contact",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Any",
          "Value": "1"
        },
        {
          "Label": "Email",
          "Value": "2"
        },
        {
          "Label": "Phone",
          "Value": "3"
        },
        {
          "Label": "Fax",
          "Value": "4"
        },
        {
          "Label": "Mail",
          "Value": "5"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "prioritycode",
      "Type": "picklist",
      "DisplayName": "PriorityCode",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "processid",
      "Type": "uniqueidentifier",
      "DisplayName": "Process",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "purchaseprocess",
      "Type": "picklist",
      "DisplayName": "Purchase Process",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Individual",
          "Value": "0"
        },
        {
          "Label": "Committee",
          "Value": "1"
        },
        {
          "Label": "Unknown",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "purchasetimeframe",
      "Type": "picklist",
      "DisplayName": "Purchase Timeframe",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Q1-This Fiscal Year",
          "Value": "0"
        },
        {
          "Label": "Q2-This Fiscal Year",
          "Value": "1"
        },
        {
          "Label": "Q3-This Fiscal Year",
          "Value": "2"
        },
        {
          "Label": "Q4-This Fiscal Year",
          "Value": "3"
        },
        {
          "Label": "Next Fiscal Year",
          "Value": "4"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "qualificationcomments",
      "Type": "memo",
      "DisplayName": "Qualification Comments",
      "Format": "2",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "qualifyingopportunityid",
      "Type": "lookup",
      "DisplayName": "Qualifying Opportunity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "relatedobjectid",
      "Type": "lookup",
      "DisplayName": "Related Campaign Response",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "revenue",
      "Type": "money",
      "DisplayName": "Annual Revenue",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "revenue_base",
      "Type": "money",
      "DisplayName": "Annual Revenue (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salesstage",
      "Type": "picklist",
      "DisplayName": "Sales Stage (Not Used)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Qualify",
          "Value": "0"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salesstagecode",
      "Type": "picklist",
      "DisplayName": "Sales Stage Code (Not Used)",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Default Value",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "salutation",
      "Type": "string",
      "DisplayName": "Salutation",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "schedulefollowup_prospect",
      "Type": "datetime",
      "DisplayName": "Schedule Follow Up (Prospect)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "schedulefollowup_qualify",
      "Type": "datetime",
      "DisplayName": "Schedule Follow Up (Qualify)",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "sic",
      "Type": "string",
      "DisplayName": "SIC Code",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slaid",
      "Type": "lookup",
      "DisplayName": "SLA",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "slainvokedid",
      "Type": "lookup",
      "DisplayName": "Last SLA applied",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "stageid",
      "Type": "uniqueidentifier",
      "DisplayName": "Process Stage",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statecode",
      "Type": "state",
      "DisplayName": "Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Open",
          "Value": "0"
        },
        {
          "Label": "Qualified",
          "Value": "1"
        },
        {
          "Label": "Disqualified",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statuscode",
      "Type": "status",
      "DisplayName": "Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "New",
          "Value": "1"
        },
        {
          "Label": "Opportunity Generated",
          "Value": "3"
        },
        {
          "Label": "Already Engaged",
          "Value": "2"
        },
        {
          "Label": "Already purchased",
          "Value": "861980011"
        },
        {
          "Label": "Billing Support",
          "Value": "861980014"
        },
        {
          "Label": "Expired",
          "Value": "861980008"
        },
        {
          "Label": "Invalid Contact Info",
          "Value": "861980005"
        },
        {
          "Label": "Misrouted Lead",
          "Value": "7"
        },
        {
          "Label": "Never Reached",
          "Value": "5"
        },
        {
          "Label": "No Interest",
          "Value": "6"
        },
        {
          "Label": "Non-Supported Market",
          "Value": "861980007"
        },
        {
          "Label": "Nurture",
          "Value": "861980006"
        },
        {
          "Label": "Partner",
          "Value": "861980009"
        },
        {
          "Label": "Recycled",
          "Value": "861980012"
        },
        {
          "Label": "Reject",
          "Value": "861980010"
        },
        {
          "Label": "Student",
          "Value": "861980015"
        },
        {
          "Label": "Tech Support",
          "Value": "861980013"
        },
        {
          "Label": "System Only – Converted to Recommendation",
          "Value": "861980016"
        },
        {
          "Label": "System Only - Referral Generated",
          "Value": "861980017"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "subject",
      "Type": "string",
      "DisplayName": "Topic",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "teamsfollowed",
      "Type": "integer",
      "DisplayName": "TeamsFollowed",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone1",
      "Type": "string",
      "DisplayName": "Business Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone2",
      "Type": "string",
      "DisplayName": "Home Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "telephone3",
      "Type": "string",
      "DisplayName": "Other Phone",
      "Format": "Phone",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timespentbymeonemailandmeetings",
      "Type": "string",
      "DisplayName": "Time Spent by me",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timezoneruleversionnumber",
      "Type": "integer",
      "DisplayName": "Time Zone Rule Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "transactioncurrencyid",
      "Type": "lookup",
      "DisplayName": "Currency",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "traversedpath",
      "Type": "string",
      "DisplayName": "Traversed Path",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "utcconversiontimezonecode",
      "Type": "integer",
      "DisplayName": "UTC Conversion Time Zone Code",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "versionnumber",
      "Type": "bigint",
      "DisplayName": "Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "websiteurl",
      "Type": "string",
      "DisplayName": "Website",
      "Format": "Url",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomicompanyname",
      "Type": "string",
      "DisplayName": "Yomi Company Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomifirstname",
      "Type": "string",
      "DisplayName": "Yomi First Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomifullname",
      "Type": "string",
      "DisplayName": "Yomi Full Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomilastname",
      "Type": "string",
      "DisplayName": "Yomi Last Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "yomimiddlename",
      "Type": "string",
      "DisplayName": "Yomi Middle Name",
      "Format": "PhoneticGuide",
      "OptionSet": null
    }
  }
]