import { GetEditIconHTML, IsEmpty } from '../Utils/utils'

export default function lookupFormatter(
  cell: any,
  isReadOnly: boolean,
  fieldName: string
) {
  const div = document.createElement('div')
  div.className = 'grid-common-formatter'
  let divText = '---'
  const tmpLookupDisplayVal =
    cell.getData()[
      '_' + fieldName + '_value@OData.Community.Display.V1.FormattedValue'
    ]
  if (!IsEmpty(tmpLookupDisplayVal)) {
    divText = tmpLookupDisplayVal
  }
  //cell?.getElement().setAttribute("title",divText);
  if (isReadOnly) div.innerHTML = divText
  else div.innerHTML = divText + GetEditIconHTML()
  return div
}
