import { IsEmpty } from '../Utils/utils'

export default function lookupTooltip(
  cell: any,
  isReadOnly: boolean,
  fieldName: string
) {
  let divText = '---'
  const tmpLookupDisplayVal =
    cell.getData()[
      '_' + fieldName + '_value@OData.Community.Display.V1.FormattedValue'
    ]
  if (!IsEmpty(tmpLookupDisplayVal)) {
    divText = tmpLookupDisplayVal
  } else {
    if (!IsEmpty(cell.getValue())) divText = cell.getValue()
  }
  return divText
}
