export const RelatedOpportunityData = [
    {
      "@odata.etag": "W/\"41109936436\"",
      "name": "Door monitoring",
      "estimatedvalue_FormattedValue": "$1,650,000.00",
      "estimatedvalue": 1650000.0000,
      "estimatedclosedate_FormattedValue": "3/15/2020",
      "estimatedclosedate": "2020-03-15",
      "createdon_FormattedValue": "3/3/2020 6:32 AM",
      "createdon": "2020-03-03T01:02:58Z",
      "msp_recommendationcode_FormattedValue": "Uncommitted",
      "msp_recommendationcode": 861980003,
      "msp_opportunitynumber": "7-VKNOJ6DIA",
      "msp_activesalesstage": "Inspire & Design",
      "msp_solutionarea": null,
      "msp_salesplay": null,
      "statuscode_FormattedValue": "Pipeline Hygiene/Duplicate",
      "statuscode": 861980001,
      "statecode_FormattedValue": "Lost",
      "statecode": 2,
      "msp_opportunitytype_FormattedValue": "Billed",
      "msp_opportunitytype": "606820000",
      "msp_estcompletiondate": null,
      "msp_engagementstatus_FormattedValue": "N/A",
      "msp_engagementstatus": 606820000,
      "msp_consumptionactivedevicesrecurring": null,
      "msp_consumptionactivedevicesnonrecurring": null,
      "msp_consumptionconsumedrecurring": null,
      "msp_consumptionconsumednonrecurring": null,
      "opportunityid": "89bdccdd-69e8-47d9-b36e-00005ce90bb4",
      "msp_riskscore": null,
      "_transactioncurrencyid_value_FormattedValue": "US Dollar",
      "_transactioncurrencyid_value": "ff2971d7-b412-e411-8d49-6c3be5a82b68"
    },
    {
      "@odata.etag": "W/\"46388794132\"",
      "name": "FY23 Q3 Ren - SCE - South African Revenue Service - 7969964 - 2023-03-01 - 1145309 USD",
      "estimatedvalue_FormattedValue": "$1,145,312.15",
      "estimatedvalue": 1145312.1500,
      "estimatedclosedate_FormattedValue": "3/1/2023",
      "estimatedclosedate": "2023-03-01",
      "createdon_FormattedValue": "4/20/2022 12:57 PM",
      "createdon": "2022-04-20T07:27:08Z",
      "msp_recommendationcode_FormattedValue": "Committed",
      "msp_recommendationcode": 861980000,
      "msp_opportunitynumber": "7-33OE3ZWJGE",
      "msp_activesalesstage": "Inspire & Design",
      "msp_solutionarea_FormattedValue": "Sustainability",
      "msp_solutionarea": 861980010,
      "msp_salesplay_FormattedValue": "Build a Sustainable IT Infrastructure",
      "msp_salesplay": 861980083,
      "statuscode_FormattedValue": "In Progress",
      "statuscode": 1,
      "statecode_FormattedValue": "Open",
      "statecode": 0,
      "msp_opportunitytype_FormattedValue": "Billed",
      "msp_opportunitytype": "606820000",
      "msp_estcompletiondate": null,
      "msp_engagementstatus_FormattedValue": "In-Progress",
      "msp_engagementstatus": 861980000,
      "msp_consumptionactivedevicesrecurring": null,
      "msp_consumptionactivedevicesnonrecurring": null,
      "msp_consumptionconsumedrecurring": null,
      "msp_consumptionconsumednonrecurring": null,
      "opportunityid": "73e55175-32e9-4088-9a77-00008670eadf",
      "msp_riskscore_FormattedValue": "0.99460",
      "msp_riskscore": 0.9946,
      "_transactioncurrencyid_value_FormattedValue": "US Dollar",
      "_transactioncurrencyid_value": "ff2971d7-b412-e411-8d49-6c3be5a82b68"
    },
    {
      "@odata.etag": "W/\"41109937083\"",
      "name": "IPC",
      "estimatedvalue_FormattedValue": "$30.00",
      "estimatedvalue": 30.0000,
      "estimatedclosedate_FormattedValue": "12/31/2021",
      "estimatedclosedate": "2021-12-31",
      "createdon_FormattedValue": "9/27/2019 7:00 AM",
      "createdon": "2019-09-27T01:30:40Z",
      "msp_recommendationcode_FormattedValue": "Uncommitted",
      "msp_recommendationcode": 861980003,
      "msp_opportunitynumber": "7-UCWHPSJMW",
      "msp_activesalesstage": "Empower & Achieve",
      "msp_solutionarea": null,
      "msp_salesplay": null,
      "statuscode_FormattedValue": "Pipeline Hygiene/Duplicate",
      "statuscode": 861980001,
      "statecode_FormattedValue": "Lost",
      "statecode": 2,
      "msp_opportunitytype_FormattedValue": "Billed",
      "msp_opportunitytype": "606820000",
      "msp_estcompletiondate": null,
      "msp_engagementstatus_FormattedValue": "N/A",
      "msp_engagementstatus": 606820000,
      "msp_consumptionactivedevicesrecurring": null,
      "msp_consumptionactivedevicesnonrecurring": null,
      "msp_consumptionconsumedrecurring": null,
      "msp_consumptionconsumednonrecurring": null,
      "opportunityid": "5b3ec8f4-0fee-4a63-a271-00009c5bc8f2",
      "msp_riskscore": null,
      "_transactioncurrencyid_value_FormattedValue": "US Dollar",
      "_transactioncurrencyid_value": "ff2971d7-b412-e411-8d49-6c3be5a82b68"
    },
    {
      "@odata.etag": "W/\"41109937633\"",
      "name": "FY20 Q4 TUP - EA - MOUVEMENT DESJARDINS - 2020-05-01 - 6960818",
      "estimatedvalue_FormattedValue": "$1,904.61",
      "estimatedvalue": 1904.6100,
      "estimatedclosedate_FormattedValue": "4/1/2020",
      "estimatedclosedate": "2020-04-01",
      "createdon_FormattedValue": "7/16/2019 10:50 AM",
      "createdon": "2019-07-16T05:20:43Z",
      "msp_recommendationcode_FormattedValue": "Committed",
      "msp_recommendationcode": 861980000,
      "msp_opportunitynumber": "7-TQMDWP4X7",
      "msp_activesalesstage": "Inspire & Design",
      "msp_solutionarea": null,
      "msp_salesplay": null,
      "statuscode_FormattedValue": "Non-Compete",
      "statuscode": 861980003,
      "statecode_FormattedValue": "Won",
      "statecode": 1,
      "msp_opportunitytype_FormattedValue": "Billed",
      "msp_opportunitytype": "606820000",
      "msp_estcompletiondate": null,
      "msp_engagementstatus_FormattedValue": "N/A",
      "msp_engagementstatus": 606820000,
      "msp_consumptionactivedevicesrecurring": null,
      "msp_consumptionactivedevicesnonrecurring": null,
      "msp_consumptionconsumedrecurring": null,
      "msp_consumptionconsumednonrecurring": null,
      "opportunityid": "52d6a612-0750-4066-a21d-00010a05f898",
      "msp_riskscore": null,
      "_transactioncurrencyid_value_FormattedValue": "US Dollar",
      "_transactioncurrencyid_value": "ff2971d7-b412-e411-8d49-6c3be5a82b68"
    },
    
  ]
  