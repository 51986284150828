/* istanbul ignore file */

import { AI_KEY } from '../Utils/Constants';
import { ITelemetryConfig } from './TelemetryService.types';

export const getTelemetryConfigForEnv = (envURL: string): ITelemetryConfig => {
    const telemetryConfig = {
        AIKey: AI_KEY,
        TrackedDependencyUrls: []
    };
    return telemetryConfig;
};

export const stringifyProperties = (
    inputProps: { [key: string]: unknown } | null | undefined,
): { [key: string]: string } => {
    if (!inputProps) {
        return {};
    }
    const returnProps: { [key: string]: string } = {};
    Object.keys(inputProps).forEach(function (key: string) {
        if (typeof inputProps[key] == 'object' && inputProps[key] != null)
            returnProps[key] = JSON.stringify(inputProps[key]);
        else
            returnProps[key] = String(inputProps[key]);
    });
    return returnProps;
};