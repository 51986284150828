import { classNames } from "./styles";
import { FontAwesomeIcons, IconToolTip } from "./styles";

export const OpportunityCols = [
  {
    key: "msp_opportunitynumber",
    title: "Opportunity Number",
    field: "msp_opportunitynumber",
    width: 180
  },
  {
    key: "name",
    title: "Name",
    field: "name",
    width: 250
  },
  {
    key: "statecode_FormattedValue",
    title: "Won/Lost",
    field: "statecode_FormattedValue",
    width: 120
  },
  {
    key: "estimatedvalue_FormattedValue",
    title: "Estimated Value",
    field: "estimatedvalue_FormattedValue",
    width: 150
  },
  {
    key: "createdon_FormattedValue",
    title: "Created On",
    field: "createdon_FormattedValue",
    width: 170
  },
  {
    key: "estimatedclosedate_FormattedValue",
    title: "Close Date",
    field: "estimatedclosedate_FormattedValue",
    width: 120
  },
  {
    key: "msp_recommendationcode_FormattedValue",
    title: "Recommendation",
    field: "msp_recommendationcode_FormattedValue",
    width: 150
  },
  {
    key: "statecode_FormattedValue",
    title: "Reason",
    field: "statecode_FormattedValue",
    width: 100
  },
  {
    key: "msp_activesalesstage",
    title: "Active Sales Stage",
    field: "msp_activesalesstage",
    width: 180
  },
  {
    key: "statuscode_FormattedValue",
    title: "Status",
    field: "statuscode_FormattedValue",
    width: 200
  },

  {
    key: "statecode_FormattedValue",
    title: "State",
    field: "statecode_FormattedValue",
    width: 100
  },
];
export const OpportunityViewCols = [
  {
    key: "msp_opportunitynumber",
    title: "Opportunity Number",
    field: "msp_opportunitynumber",
    width: 180
  },
  {
    key: "name",
    title: "Name",
    field: "name",
    width: 250
  },
  {
    key: "statecode@OData.Community.Display.V1.FormattedValue",
    title: "Won/Lost",
    field: "statecode@OData.Community.Display.V1.FormattedValue",
    width: 120
  },
  {
    key: "estimatedvalue@OData.Community.Display.V1.FormattedValue",
    title: "Estimated Value",
    field: "estimatedvalue@OData.Community.Display.V1.FormattedValue",
    width: 150
  },
  {
    key: "createdon@OData.Community.Display.V1.FormattedValue",
    title: "Created On",
    field: "createdon@OData.Community.Display.V1.FormattedValue",
    width: 170
  },
  {
    key: "estimatedclosedate@OData.Community.Display.V1.FormattedValue",
    title: "Close Date",
    field: "estimatedclosedate@OData.Community.Display.V1.FormattedValue",
    width: 120
  },
  {
    key: "msp_recommendationcode@OData.Community.Display.V1.FormattedValue",
    title: "Recommendation",
    field: "msp_recommendationcode@OData.Community.Display.V1.FormattedValue",
    width: 150
  },
  {
    key: "statecode@OData.Community.Display.V1.FormattedValue",
    title: "Reason",
    field: "statecode@OData.Community.Display.V1.FormattedValue",
    width: 100
  },
  {
    key: "msp_activesalesstage",
    title: "Active Sales Stage",
    field: "msp_activesalesstage",
    width: 180
  },
  {
    key: "statuscode@OData.Community.Display.V1.FormattedValue",
    title: "Status",
    field: "statuscode@OData.Community.Display.V1.FormattedValue",
    width: 200
  },

  {
    key: "statecode@OData.Community.Display.V1.FormattedValue",
    title: "State",
    field: "statecode@OData.Community.Display.V1.FormattedValue",
    width: 100
  },
];

export const OpportunitySearchCols = [
  {
    "title": "Topic",
    "field": "name",
    "width": "300",
    "readonly": true
  },
  {
    "title": "Account",
    "field": "parentaccountidname",
    "width": "200",
    "readonly": true
  },
  {
    "title": "Partner Account",
    "field": "msp_partneraccountidname",
    "width": "150",
    "sorter": "string",
    "readonly": true
  },
  {
    "title": "Contact",
    "field": "parentcontactidname",
    "width": "150",
    "sorter": "string",
    "readonly": true
  },
  {
    "title": "Opportunity Id",
    "field": "msp_opportunitynumber",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Status",
    "field": "statecode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Status Reason",
    "field": "statuscode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Est. Close Date",
    "field": "estimatedclosedate",
    "width": "125",
    "readonly": true
  },
  {
    "title": "Est. Revenue",
    "field": "estimatedvalue",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Recommendation",
    "field": "msp_recommendationcode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Licensing Program",
    "field": "msp_licensingprogramname",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Owner",
    "field": "owneridname",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Created On",
    "field": "createdon",
    "width": "125",
    "readonly": true
  }
];

export const AccountSearchCols = [
  {
    "title": "Account Name",
    "field": "name",
    "width": "300",
    "readonly": true
  },
  {
    "title": "Owner",
    "field": "owneridname",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Address 1: City",
    "field": "address1_city",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Account Id",
    "field": "msp_accountnumber",
    "width": "100",
    "readonly": true
  },
  {
    "title": "TPID",
    "field": "msp_mstopparentid",
    "width": "125",
    "readonly": true
  },
  {
    "title": "GPID",
    "field": "msp_gpid",
    "width": "100",
    "readonly": true
  },
  {
    "title": "GP Name",
    "field": "msp_gpname",
    "width": "100",
    "readonly": true
  },
  {
    "title": "HQ Flag",
    "field": "msp_hq",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Segment",
    "field": "msp_endcustomersegmentcode",
    "width": "250",
    "readonly": true
  },
  {
    "title": "Subsegment",
    "field": "msp_endcustomersubsegmentcode",
    "width": "250",
    "readonly": true
  },
  {
    "title": "Vertical",
    "field": "msp_verticalcode",
    "width": "100",
    "sorter": "string",
    "readonly": true
  },
  {
    "title": "SubVertical",
    "field": "msp_subverticalcode",
    "width": "100",
    "sorter": "string",
    "readonly": true
  },
  {
    "title": "Category",
    "field": "msp_verticalcategorycode",
    "width": "125",
    "sorter": "string",
    "readonly": true
  },
  {
    "title": "Parent Account",
    "field": "parentaccountidname",
    "sorter": "string",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Parenting Level",
    "field": "msp_parentinglevelcode",
    "sorter": "string",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Address 1: Country/Region",
    "field": "address1_country",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Address 1: Street 1",
    "field": "address1_line1",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Address 1: Street 2",
    "field": "address1_line2",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Territory",
    "field": "territoryidname",
    "sorter": "string",
    "width": "100",
    "readonly": true
  }
];
export const MilesonteCols = [
  {
    "title": "Name",
    "field": "msp_name",
    "width": "300",
    "readonly": true
  },
  {
    "title": "Owner",
    "field": "owneridname",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Created On",
    "field": "createdon",
    "width": "125",
    "readonly": true
  }
];

export const GitHubCols = [
  {
    "field": "Name",
    "title": "Name",
    "readonly": true,

  },
  {
    "field": "OwnerName",
    "title": "Owner",
    "readonly": true,
  },
  {
    "field": "AccountName",
    "title": "Account",
    "readonly": true,
  },
  {
    "field": "Type",
    "title": "Type",
    "readonly": true,
  },
  {
    "field": "StageName",
    "title": "StageName",
    "readonly": true,
  },
  {
    "field": "CloseDate",
    "title": "CloseDate",
    "readonly": true,
  },
  {
    "field": "Agreement_End_Date__c",
    "title": "Agreement End",
    "readonly": true,
  },
  {
    "field": "FiscalQuarter",
    "title": "Fiscal Quarter",
    "readonly": true,
  },
  {
    "field": "ForecastCategory",
    "title": "Forecast Category",
    "readonly": true,
  },
  {
    "field": "Microsoft_Involvement__c",
    "title": "MS Involvement",
    "readonly": true,
  },
  {
    "field": "Partner_Involvement__c",
    "title": "Partner Involvement",
    "readonly": true,
  },
  {
    "field": "TotalOpportunityQuantity",
    "title": "Quantity",
    "readonly": true,
  },
  {
    "field": "License_ARR_10__c",
    "title": "License",
    "readonly": true,
  },
  {
    "field": "Support_ARR__c",
    "title": "Support",
    "readonly": true,
  },
  {
    "field": "Amount",
    "title": "Amount",
    "readonly": true,
  }
];

export const ContactCols = [
  {
    "title": "Full Name",
    "field": "fullname",
    "width": "300",
    "readonly": true
  },
  {
    "title": "Prefix",
    "field": "salutation",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Company Name",
    "field": "parentcustomeridname",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Partner Name",
    "field": "msp_parentpartneridname",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Contact Id",
    "field": "msp_contactnumber",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Owner",
    "field": "owneridname",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Job Title",
    "field": "jobtitle",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Influence Level",
    "field": "msp_influencelevelcode",
    "width": "125",
    "readonly": true
  },
  {
    "title": "Purchasing Authority",
    "field": "msp_purchasingauthoritycode",
    "width": "125",
    "readonly": true
  },
  {
    "title": "Purchasing Limit",
    "field": "msp_purchasinglimitamount",
    "width": "125",
    "readonly": true
  },
  {
    "title": "Email",
    "field": "emailaddress1",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Business Phone",
    "field": "telephone1",
    "width": "125",
    "readonly": true
  },
  {
    "title": "Managed",
    "field": "msp_managedcode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Job Role",
    "field": "msp_jobrolecode",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Marketing Audience",
    "field": "msp_marketingaudiencecode",
    "width": "150",
    "readonly": true
  },
  {
    "title": "Created On",
    "field": "createdon",
    "width": "100",
    "readonly": true
  }
];

export const LeadCols = [
  {
    "title": "Topic",
    "field": "subject",
    "width": "200",
    "readonly": true
  },
  {
    "title": "Name",
    "field": "fullname",
    "width": "200",
    "readonly": true
  },
  {
    "title": "Lead Id",
    "field": "msp_leadnumber",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Lead Age",
    "field": "msp_leadage",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Owner",
    "field": "owneridname",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Primary Product",
    "field": "msp_primaryproductcode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Est. Quantity",
    "field": "msp_estimatedquantity",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Est. Value",
    "field": "estimatedamount",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Rating",
    "field": "leadqualitycode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Status",
    "field": "statecode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Status Reason",
    "field": "statuscode",
    "width": "100",
    "readonly": true
  },
  {
    "title": "Created On",
    "field": "createdon",
    "width": "125",
    "readonly": true
  }
];
export const STRING_CONSTANTS = {
  MORECONDITION: "MORECONDITION",
  MAX_SEARCH_RECORDS: "MAX_SEARCH_RECORDS",
  rowstate: "rowstate",
  SearchObjectID: "@search.objectid",
  REPLACE_TPID: "REPLACE_TPID",
  WINDOWS_MSX_LITE_SEARCH_CORRELATION_ID: "MsxLiteSearchCorrelationId",
  OpenInNewWindow: "Open in new tab",
}
export const NUMBER_CONSTANTS = {
  MAX_SEARCH_RECORDS: 100
}
export const ENTITIES = {
  opportunity: {
    primaryKey: "opportunityid",
    pluralName: "opportunities",
    name: "opportunity",
    primaryColumn: "name"
  },
  account: {
    primaryKey: "accountid",
    pluralName: "accounts",
    name: "account",
    primaryColumn: "name"
  },
  contact: {
    primaryKey: "contactid",
    pluralName: "contacts",
    name: "contact",
    primaryColumn: "name"
  },
  lead: {
    primaryKey: "leadid",
    pluralName: "leads",
    name: "lead",
    primaryColumn: "name"
  },
  msp_engagementmilestone: {
    primaryKey: "msp_engagementmilestoneid",
    pluralName: "msp_engagementmilestones",
    name: "msp_engagementmilestone",
    primaryColumn: "name"
  }
}
export const TRIGGERS = {
  SearchTextBox: "SearchTextBox",
  EntityView: "EntityView",
  AutoComplete: "AutoComplete",
  SaveRecord: "SaveRecord",
  SaveOnEdit: "SaveOnEdit",
  ViewClicked: "ViewClicked",
  EditClicked: "EditClicked",
  HomePageLoad: "HomePageLoad"
}

export const MSX_LITE_ORIGINE = window.location.origin

export const D365_ROOT_URL = getEnvirnomentDetails().D365_ROOT_URL;
export const AI_KEY = getEnvirnomentDetails().AI_KEY;
export const CLIENT_ID = getEnvirnomentDetails().CLIENT_ID;
export const APPLICATION_ID_URI = getEnvirnomentDetails().APPLICATION_ID_URI;

//DEV02
// export const D365_ROOT_URL = "https://msxdev02.crm.dynamics.com";
// export const AI_KEY = "aff120a8-d82c-4091-9e9c-63a8e4589c2f";
// export const CLIENT_ID = "51d41641-ce1f-4845-a8f7-ca8498bdefa6";
// export const APPLICATION_ID_URI = "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6";

// SQA
// export const D365_ROOT_URL = "https://microsoftsales56-sb2.crm.dynamics.com";
// export const AI_KEY = "aff120a8-d82c-4091-9e9c-63a8e4589c2f";
// export const CLIENT_ID = "b84e16bc-c715-4f04-8d01-4de55ce8119d";
// export const APPLICATION_ID_URI = "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6";

//UAT
// export const D365_ROOT_URL = "https://msxuat.crm.dynamics.com";
// export const AI_KEY = "844e778b-4851-4844-bf25-226481005cb9";
// export const CLIENT_ID = "b84e16bc-c715-4f04-8d01-4de55ce8119d";
// export const APPLICATION_ID_URI = "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6";

//PROD
// export const D365_ROOT_URL = "https://microsoftsales.crm.dynamics.com";
// export const AI_KEY = "9a92ac1e-e7f8-4699-b1cc-8fa92620cf86";
// export const CLIENT_ID = "8c9dc106-0206-45ae-8bd9-a2a751b57ba2";
// export const APPLICATION_ID_URI = "api://8c9dc106-0206-45ae-8bd9-a2a751b57ba2";

//POC
// export const D365_ROOT_URL = "https://msxhyperscale.crm.dynamics.com";
// export const AI_KEY = "e7196212-1f59-4d07-85f5-1843cc1839f2";
// export const CLIENT_ID = "51d41641-ce1f-4845-a8f7-ca8498bdefa6";
// export const APPLICATION_ID_URI = "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6";

export const D365_API_URL = D365_ROOT_URL + "/api/data/v9.2/";
export const ShellHosts = ["crm.dynamics.com"]
export const FEEDBACK_URL = "https://forms.office.com/r/KhgdLuydQW";

function getEnvirnomentDetails() {
  switch (MSX_LITE_ORIGINE) {
    case "https://msxdev02.crm.dynamics.com":
    case "https://dev-msxsearch.microsoft.com":
      return {
        D365_ROOT_URL: "https://msxdev02.crm.dynamics.com",
        AI_KEY: "aff120a8-d82c-4091-9e9c-63a8e4589c2f",
        CLIENT_ID: "51d41641-ce1f-4845-a8f7-ca8498bdefa6",
        APPLICATION_ID_URI: "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6"
      }
    case "https://microsoftsales56-sb2.crm.dynamics.com":
      return {
        D365_ROOT_URL: "https://microsoftsales56-sb2.crm.dynamics.com",
        AI_KEY: "aff120a8-d82c-4091-9e9c-63a8e4589c2f",
        CLIENT_ID: "51d41641-ce1f-4845-a8f7-ca8498bdefa6",
        APPLICATION_ID_URI: "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6"
      }
    case "https://msxuat.crm.dynamics.com":
    case "https://uat-msxsearch.microsoft.com":
    case "https://uat-msxsearch-us-appservice.azurewebsites.net":
    case "https://uat-msxsearch-ap-appservice.azurewebsites.net":
    case "https://uat-msxsearch-eu-appservice.azurewebsites.net":
    case "":
      return {
        D365_ROOT_URL: "https://msxuat.crm.dynamics.com",
        AI_KEY: "844e778b-4851-4844-bf25-226481005cb9",
        CLIENT_ID: "51d41641-ce1f-4845-a8f7-ca8498bdefa6",
        APPLICATION_ID_URI: "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6"
      }
    case "https://microsoftsales.crm.dynamics.com":
    case "https://prod-msxsearch.microsoft.com":
    case "https://prod-msxsearch-ap-appservice.azurewebsites.net":
    case "https://prod-msxsearch-eu-appservice.azurewebsites.net":
    case "https://prod-msxsearch-us-appservice.azurewebsites.net":
      return {
        D365_ROOT_URL: "https://microsoftsales.crm.dynamics.com",
        AI_KEY: "9a92ac1e-e7f8-4699-b1cc-8fa92620cf86",
        CLIENT_ID: "8c9dc106-0206-45ae-8bd9-a2a751b57ba2",
        APPLICATION_ID_URI: "api://8c9dc106-0206-45ae-8bd9-a2a751b57ba2"
      }
    default:
      return {
        D365_ROOT_URL: "https://msxdev02.crm.dynamics.com",
        AI_KEY: "aff120a8-d82c-4091-9e9c-63a8e4589c2f",
        CLIENT_ID: "51d41641-ce1f-4845-a8f7-ca8498bdefa6",
        APPLICATION_ID_URI: "api://51d41641-ce1f-4845-a8f7-ca8498bdefa6"
      }
  }
}
