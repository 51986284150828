import { GetEditIconHTML, IsEmpty } from '../Utils/utils'

export default function formattedValueFormatter(
  cell: any,
  isReadOnly: boolean,
  fieldName: string,
  isNumeric: boolean
) {
  const div = document.createElement('div')
  if (isNumeric) div.className = 'grid-common-formatter grid-number-formatter'
  else div.className = 'grid-common-formatter'
  let divText = '---'
  const tmpLookupDisplayVal =
    cell.getData()[fieldName + '@OData.Community.Display.V1.FormattedValue']
  if (!IsEmpty(tmpLookupDisplayVal)) {
    divText = tmpLookupDisplayVal
  } else {
    if (!IsEmpty(cell.getValue())) {
      divText = cell.getValue()
    }
  }
  //cell?.getElement().setAttribute("title",divText);
  if (isReadOnly) div.innerHTML = divText
  else div.innerHTML = divText + GetEditIconHTML()
  return div
}
