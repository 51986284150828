import React from "react";
import "./App.css";
import "./CSS/grid.css";
import "./CSS/styles.css";

import "./CSS/custom-styles-grid.css"
import Grid from "./Components/Grid";
import View from "./Components/View";
import Edit from "./Components/Edit";
import { OpportunityCols, MilesonteCols, ENTITIES, NUMBER_CONSTANTS, TRIGGERS, STRING_CONSTANTS } from "./Utils/Constants";
import { RelatedOpportunityData } from "./Mock/related_opportunities";
import { MilestoneData } from "./Mock/msp_engagementmilestones";
import MSXUIAutoComplete from "./MaterialUI/MSXUIAutoComplete";
import { IconToolTip } from "./Utils/styles";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import { getData } from "./Common/api"
import { Save, SearchByAllBatch, SearchHomePageData } from "./Common/api-batch";
import TextField from "@mui/material/TextField";
import { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthError, IPublicClientApplication } from "@azure/msal-browser";
import { protectedResources } from '../src/Utils/authConfig';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MSXTabPanel from './MaterialUI/MSXTabPanel'
import Box from '@mui/material/Box';
import { iSearchData } from "./Interfaces/iSearchData";
import { iSearchOption } from "./Interfaces/iSearchOption";
import { SearchEntityOptions, SearchOptions } from './Utils/searchOptions'
import { MSXCommandBars } from "./FluentControls/MSXCommandBars";
import { IsEmpty, IsShellHostedURL, OpenD365Form, OpenD365FormInNewTab, OpenD365Panel, getHostUrl } from "./Utils/utils";
import { IBatchResponse } from "./Interfaces/IBatchResponse";
import { Telemetry } from "./Telemetry/TelemetryService";
import { opportunity_attributes } from "./EntityMetaData/opportunity_attributes";
import commonFormatter from "./formatters/commonFormatter";
import textControl from "./controls/textControl";
import dateControl from "./controls/dateControl";
import numberControl from "./controls/numberControl";
import dropdownControl from "./controls/dropdownControl";
import { IEntityDefinition } from "./Interfaces/IEntityDefinition";
import HomePage from "./Components/HomePage";
import { OpportunityColsMEX } from "./Utils/HomeConstants";
import { iSearchState } from "./Interfaces/iSearchState";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ISavedQuery } from "./Interfaces/ISavedQuery";
import { SavedQueries } from "./Utils/savedqueries";
import lookupFormatter from "./formatters/lookupFormatter";
import formattedValueFormatter from "./formatters/formattedValueFormatter";
import formattedValueTooltip from "./tooltips/formattedValueTooltip";
import lookupTooltip from "./tooltips/lookupTooltip";
import commonTooltip from "./tooltips/commonTooltip";
import { processAccountsData } from "./Utils/Accounts/processAccountsData";
import { processOpportunitiesData } from "./Utils/Opportunities/processOpportunitiesData";

type AppProps = {
  pca: IPublicClientApplication;
  TelemetryService: Telemetry;
};

interface WhoAmIResponse {
  BusinessUnitId: string;
  UserId: string;
  OrganizationId: string;
}

export const App = ({ pca, TelemetryService }: AppProps) => {
  let scope = protectedResources.msxcrm;
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  async function requestAccessToken() {
    try {
      const response = await pca.acquireTokenSilent({
        ...scope,
        account: accounts[0],
      });
      setAccessToken(response.accessToken);
      return response.accessToken;
    } catch (error) {
      if (error instanceof AuthError && error.name === 'InteractionRequiredAuthError') {
        instance.acquireTokenPopup({
          ...scope,
          account: accounts[0],
        })
          .then(response => {
            setAccessToken(response.accessToken);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
    return accessToken;
  }
  const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] =
    useBoolean(false);
  const [isAppOpen, { setTrue: openAppPanel, setFalse: dismissAppPanel }] =
    useBoolean(true);
  const [isViewOpen, { setTrue: openViewPanel, setFalse: dismissViewPanel }] =
    useBoolean(false);
  const [
    isAcccountOpen,
    { setTrue: openAccountPanel, setFalse: dismissAccountPanel },
  ] = useBoolean(false);
  const [
    isMilestoneOpen,
    { setTrue: openMilestonePanel, setFalse: dismissMilestonePanel },
  ] = useBoolean(false);

  const [record, setRecord] = React.useState(undefined);
  const [recordCols, setRecordCols] = React.useState(undefined);
  const [recordEntityMetatada, setRecordEntityMetatada] = React.useState(undefined);
  const [recordEntityDefinition, setRecordEntityDefinition] = React.useState<IEntityDefinition>();
  const [viewSelected, setviewSelected] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [showHomePage, setHowHomePage] = React.useState(true);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showSpinnerForLoadingD365Form, setShowSpinnerForLoadingD365Form] = React.useState(false);

  const [searchResults, setsearchResults] = React.useState<any[]>([]);
  //const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);

  const [preSelectedOptions, setSelectedOptions] = React.useState<any[]>([])
  const selectedOptions: any[] = useDebounce<any[]>(preSelectedOptions, 500)

  const [searchData, SetSearchData] = React.useState<iSearchData[]>([]);
  const [loading, setLoading] = useState(true);
  const [homeData, setHomeData] = React.useState<IBatchResponse[]>([])

  const [autoComleteOptions, setAutoComleteOptions] = React.useState<any[]>([])

  const debouncedSearchTerm: string = useDebounce<string>(searchText, 300);
  const constContentClass: string = IsShellHostedURL() ? "msxui-div-content-shell" : "msxui-div-content";

  const [searchState, SetSearchState] = React.useState<iSearchState>();

  const getGridcolumns = (tmpColsByEntity: any, entityMetaData: any[]) => {
    if (entityMetaData != null) {
      tmpColsByEntity.forEach((tmpCol: any) => {
        const tmpAttrs = entityMetaData.filter((tmpAttr: any) => {
          return tmpAttr.attributeDescriptor.LogicalName === tmpCol.field
        })
        if (tmpAttrs.length > 0) {
          tmpCol["headerTooltip"] = tmpCol.title;
          if (tmpCol.width)
            tmpCol["width"] = tmpCol.width;
          //tmpCol["cellEdited"] = (cell: any) => {OnCellEdited(cell, tmpCol);
          switch (tmpAttrs[0].attributeDescriptor.Type) {
            // case "state":
            // case "status":
            // case "picklist":
            //   tmpCol["formatter"] = (cell: any) =>
            //     dropdownFormatter(cell, tmpAttrs[0].attributeDescriptor.OptionSet, tmpCol?.readonly ?? false);
            //   break;
            case "datetime":
            case "state":
            case "status":
            case "picklist":
            case "multiselectpicklist":
              tmpCol["formatter"] = (cell: any) => formattedValueFormatter(cell, tmpCol?.readonly ?? false, tmpCol.field, false);
              tmpCol["tooltip"] = (cell: any) => formattedValueTooltip(cell, tmpCol?.readonly ?? false, tmpCol.field, false);
              tmpCol["sorter"] = "string";
              break;
            case "money":
            case "decimal":
            case "double":
            case "integer":
              tmpCol["formatter"] = (cell: any) => formattedValueFormatter(cell, tmpCol?.readonly ?? false, tmpCol.field, true);
              tmpCol["tooltip"] = (cell: any) => formattedValueTooltip(cell, tmpCol?.readonly ?? false, tmpCol.field, true);
              break;
            case "lookup":
            case "owner":
              tmpCol["formatter"] = (cell: any) => lookupFormatter(cell, tmpCol?.readonly ?? false, tmpCol.field);
              tmpCol["tooltip"] = (cell: any) => lookupTooltip(cell, tmpCol?.readonly ?? false, tmpCol.field);
              tmpCol["sorter"] = "string";
              break;
            default:
              tmpCol["formatter"] = (cell: any) => commonFormatter(cell, tmpCol?.readonly ?? false);
              tmpCol["tooltip"] = (cell: any) => commonTooltip(cell, tmpCol?.readonly ?? false);
              tmpCol["sorter"] = "string";
              break;
          }
          if (!tmpCol?.readonly) {
            switch (tmpAttrs[0].attributeDescriptor.Type) {
              case "state":
              case "status":
              case "picklist":
                tmpCol["editor"] = dropdownControl;
                tmpCol["editorParams"] = {
                  values: tmpAttrs[0].attributeDescriptor.OptionSet,
                  sortOptions: true,
                };
                break;
              case "datetime":
                tmpCol["editor"] = dateControl;
                break;
              case "money":
                tmpCol["editor"] = numberControl;
                break;
              default:
                tmpCol["editor"] = textControl;
                break;
            }
          }
        }
      });
    }
    return tmpColsByEntity;
  }

  const getButtons = (tmpColsByEntity: any, entityMetaData: any, entityDefinition: IEntityDefinition) => {
    const buttonActions = {
      width: 80,
      headerSort: false,
      formatter: function (cell: any, formatterParams: any, onRendered: any) {
        var table = document.createElement("table");
        var tr = document.createElement("tr");

        var tdLine = document.createElement("td");
        tdLine.innerText = " | ";

        var tdView = document.createElement("td");
        var linkView = document.createElement("a");
        linkView.innerText = "View";
        linkView.className = "egmtHyperLink";
        linkView.onclick = async () => {
          const dtStart: Date = new Date();
          let tmpAccessToken = await requestAccessToken();
          let tmpRecordData = await getData(cell.getData(), tmpAccessToken ?? "", entityDefinition);
          setRecord(tmpRecordData);
          openViewPanel();
          const dtEnd: Date = new Date();
          const msBetweenDates = dtEnd.getTime() - dtStart.getTime();
          TelemetryService?.trackEvent(
            "RecordViewLoad",
            {
              Source: getHostUrl(),
              Action: "RecordViewLoad",
              Duration: msBetweenDates
            }
          );
        };
        tdView.appendChild(linkView);
        tr.appendChild(tdView);
        tr.appendChild(tdLine);

        var td = document.createElement("td");
        var linkEdit = document.createElement("a");
        linkEdit.innerText = "Edit";
        linkEdit.className = "egmtHyperLink";
        linkEdit.onclick = async () => {
          const dtStart: Date = new Date();
          let tmpAccessToken = await requestAccessToken();
          // let tmpRecordData = await getData(cell.getData(), tmpAccessToken ?? "", entityDefinition);
          let tmpRecordData = cell.getData();
          setRecord(tmpRecordData);
          setRecordCols(tmpColsByEntity);
          setRecordEntityMetatada(entityMetaData);
          setRecordEntityDefinition(entityDefinition);
          openEditPanel();
          const dtEnd: Date = new Date();
          const msBetweenDates = dtEnd.getTime() - dtStart.getTime();
          TelemetryService?.trackEvent(
            "RecordEditLoad",
            {
              Source: getHostUrl(),
              Action: "RecordEditLoad",
              Duration: msBetweenDates
            }
          );
        };
        td.appendChild(linkEdit);
        tr.appendChild(td);

        table.appendChild(tr);

        return table;
      },
    };
    return buttonActions;
  }

  const viewButtonClick = async (tmpColsByEntity: any, entityMetaData: any, entityDefinition: IEntityDefinition, cell: any) => {
    if (cell != undefined && cell != null) {
      try {
        let tmpAccessToken = await requestAccessToken();
        let tmpRecordData = await getData(cell.getData(), tmpAccessToken ?? "", entityDefinition);
        setRecord(tmpRecordData);
        openViewPanel();
      }
      catch (e: any) {
        alert(e);
      }

    }
  }

  const getcolumns = (tmpSelectedOption: iSearchOption) => {
    const buttonActions = {
      //width: (entityName === "Account" ? 180 : 130),
      width: (tmpSelectedOption.entityName === "Account" ? 80 : 30),
      headerSort: false,
      formatter: function (cell: any, formatterParams: any, onRendered: any) {
        var table = document.createElement("table");
        table.style.display = "inline";
        var tr = document.createElement("tr");

        var tdLine = document.createElement("td");
        tdLine.innerText = " | ";

        var tdLine3 = document.createElement("td");
        tdLine3.innerText = " | ";

        // var tdView = document.createElement("td");
        // var linkView = document.createElement("a");
        // linkView.innerText = "View";
        // linkView.className = "egmtHyperLink";
        // linkView.onclick = async () => {
        //   const dtStart: Date = new Date();
        //   let tmpAccessToken = await requestAccessToken();
        //   let tmpRecordData = await getData(cell.getData(), tmpAccessToken ?? "", entityDefinition);
        //   setRecord(tmpRecordData);
        //   openViewPanel();
        //   const dtEnd: Date = new Date();
        //   const msBetweenDates = dtEnd.getTime() - dtStart.getTime();
        //   TelemetryService?.trackEvent(
        //     "RecordViewLoad",
        //     {
        //       Source: getHostUrl(),
        //       Action: "RecordViewLoad",
        //       Duration: msBetweenDates
        //     }
        //   );
        // };
        // tdView.appendChild(linkView);
        // tr.appendChild(tdView);
        // tr.appendChild(tdLine);

        // var td = document.createElement("td");
        // var linkEdit = document.createElement("a");
        // linkEdit.innerText = "Edit";
        // linkEdit.className = "egmtHyperLink";
        // linkEdit.onclick = async () => {
        //   const dtStart: Date = new Date();
        //   let tmpAccessToken = await requestAccessToken();
        //   // let tmpRecordData = await getData(cell.getData(), tmpAccessToken ?? "", entityDefinition);
        //   let tmpRecordData = cell.getData();
        //   setRecord(tmpRecordData);
        //   setRecordCols(tmpColsByEntity);
        //   setRecordEntityMetatada(entityMetaData);
        //   setRecordEntityDefinition(entityDefinition);
        //   openEditPanel();
        //   const dtEnd: Date = new Date();
        //   const msBetweenDates = dtEnd.getTime() - dtStart.getTime();
        //   TelemetryService?.trackEvent(
        //     "RecordEditLoad",
        //     {
        //       Source: getHostUrl(),
        //       Action: "RecordEditLoad",
        //       Duration: msBetweenDates
        //     }
        //   );
        // };
        // td.appendChild(linkEdit);
        // tr.appendChild(td);


        /* Before adding icons
        var tdForm = document.createElement("td");
        var linkEditForm = document.createElement("a");
        linkEditForm.innerText = "View/Edit";
        linkEditForm.className = "egmtHyperLink";
        linkEditForm.onclick = async () => {
          OpenD365Form(tmpSelectedOption.entity, cell.getData()[tmpSelectedOption?.entityDefinition?.primaryKey ?? ""], setShowSpinnerForLoadingD365Form);
          TelemetryService?.trackEvent(
            "RecordFormLoad",
            {
              Source: getHostUrl(),
              Action: "RecordEditLoad",
            }
          );
        };
        tdForm.appendChild(linkEditForm);
        */
        var tdForm = document.createElement("td");
        tdForm.style.display = "flex";
        // var btnViewEntityRecord = document.createElement("button");
        // btnViewEntityRecord.style.backgroundColor = "transparent";
        // btnViewEntityRecord.style.border = "none";
        // btnViewEntityRecord.className = "symbolFont OpenEntityRecord-symbol";
        // btnViewEntityRecord.onclick = async () => {
        //   OpenD365Form(tmpSelectedOption.entity, cell.getData()[tmpSelectedOption?.entityDefinition?.primaryKey ?? ""], setShowSpinnerForLoadingD365Form);
        //   TelemetryService?.trackEvent(
        //     "RecordFormLoad",
        //     {
        //       Source: getHostUrl(),
        //       Action: "RecordEditLoad",
        //     }
        //   );
        // };
        // tdForm.appendChild(btnViewEntityRecord);

        var btnViewEntityRecordInNewTab = document.createElement("button");
        btnViewEntityRecordInNewTab.title = STRING_CONSTANTS.OpenInNewWindow;
        btnViewEntityRecordInNewTab.style.backgroundColor = "transparent";
        btnViewEntityRecordInNewTab.style.border = "none";
        btnViewEntityRecordInNewTab.className = "symbolFont OpenInNewWindow-symbol";
        btnViewEntityRecordInNewTab.onclick = async () => {
          OpenD365FormInNewTab(tmpSelectedOption.entity, cell.getData()[tmpSelectedOption?.entityDefinition?.primaryKey ?? ""], setShowSpinnerForLoadingD365Form);
          TelemetryService?.trackEvent(
            "RecordFormLoad",
            {
              Source: getHostUrl(),
              Action: "RecordEditLoad",
            }
          );
        };
        tdForm.appendChild(btnViewEntityRecordInNewTab);
        tr.appendChild(tdForm);

        // if (entityName === "Account") {
        //   var tdLine2 = document.createElement("td");
        //   tdLine2.innerText = " | ";
        //   tr.appendChild(tdLine2);

        //   var tdC360 = document.createElement("td");
        //   var link360 = document.createElement("a");
        //   link360.innerText = IconToolTip.Account;
        //   link360.className = "egmtHyperLink";
        //   link360.onclick = async () => {
        //     OpenD365Panel(cell.getData()["msp_mstopparentid"]);
        //     TelemetryService?.trackEvent(
        //       "OnC360Click",
        //       {
        //         Source: getHostUrl(),
        //         Action: "OnC360Click",
        //       }
        //     );
        //   };
        //   tdC360.appendChild(link360);
        //   tr.appendChild(tdC360);
        // }

        table.appendChild(tr);
        return table;
      },
    };

    const buttonMilestones = {
      minWidth: 100,
      maxWidth: 100,
      headerSort: false,
      title: "Milestones",
      // titleFormatter: function () {
      //   return FontAwesomeIcons.Account;
      // },
      formatter: function (cell: any, formatterParams: any, onRendered: any) {
        const tmpRandom = Math.random() * (10 - 0) + 1;
        var linkButtonEdit = document.createElement("div");
        linkButtonEdit.onclick = () => {
          openMilestonePanel();
        };
        linkButtonEdit.title = IconToolTip.Milestone;
        linkButtonEdit.className = "egmtHyperLink centre-aling";
        linkButtonEdit.innerText = parseInt(tmpRandom.toString()).toString();
        //linkButtonEdit.innerHTML = FontAwesomeIcons.Milestone;
        return linkButtonEdit;
      },
    };

    // let tmpCols: any[] = [
    //   buttonActions,
    //   ...getGridcolumns(tmpColsByEntity, entityMetaData),
    //   buttonMilestones,
    // ];

    let tmpColsByEntity: any = tmpSelectedOption.columns;
    if (tmpSelectedOption.savedqueryid) {
      let tmpSavedQueries = SavedQueries.filter((tmpQuery: ISavedQuery) => {
        return tmpQuery.savedqueryid === tmpSelectedOption.savedqueryid
      })
      if (tmpSavedQueries.length > 0)
        tmpColsByEntity = tmpSavedQueries[0].fields;
    }

    let tmpFinalCols: any[] = getGridcolumns(tmpColsByEntity, tmpSelectedOption.entityMetaData);
    if (tmpFinalCols.length > 0) {

      tmpFinalCols[0]["formatter"] = (cell: any) => {

        let divText = "---";
        if (!IsEmpty(cell.getValue())) {
          divText = cell.getValue();
        }
        var linkEditForm = document.createElement("a");
        linkEditForm.innerText = divText;
        linkEditForm.className = "egmtHyperLink";
        linkEditForm.onclick = async () => {
          OpenD365Form(tmpSelectedOption.entity, cell.getData()[tmpSelectedOption?.entityDefinition?.primaryKey ?? ""], setShowSpinnerForLoadingD365Form);
          TelemetryService?.trackEvent(
            "RecordFormLoad",
            {
              Source: getHostUrl(),
              Action: "RecordEditLoad",
            }
          );
        };
        return linkEditForm;
      }
    }

    //tmpCol["formatter"] = (cell: any) => formattedValueFormatter(cell, tmpCol?.readonly ?? false, tmpCol.field, true);

    // let tmpCols: any[] = [
    //   buttonActions,
    //   ...getGridcolumns(tmpColsByEntity, tmpSelectedOption.entityMetaData),
    // ];
    let tmpCols: any[] = [
      buttonActions,
      ...tmpFinalCols,
    ];
    return tmpCols;
  };

  // const [cols, setColumns] = React.useState<any[]>(getcolumns("opportunity", OpportunitySearchCols, opportunity_attributes, ENTITIES.opportunity));
  const [cols, setColumns] = React.useState<any[]>();


  const handleTextFieldChange = async (e: any) => {
    const tmpSearchText = e.target.value;
    setSearchText(tmpSearchText);
    document.getElementById("tblGrid")?.style.removeProperty("display");
    document.getElementById("msx-pcf-shell")?.style.setProperty("display", "none");
  }
  const onSaveClick = async (tmpPayload: any, startDate: Date, tmpAction: string) => {
    let tmpAccessToken = await requestAccessToken();
    let tmpResponse: IBatchResponse[] = await Save(tmpPayload, tmpAccessToken ?? "");
    const dtEnd: Date = new Date();
    const msBetweenDates = dtEnd.getTime() - startDate.getTime();
    TelemetryService?.trackEvent(
      tmpAction,
      {
        Source: getHostUrl(),
        Action: tmpAction,
        TotalRecordsUpdated: tmpResponse.length,
        BatchResponse: tmpResponse,
        Duration: msBetweenDates
      }
    );
    alert(tmpResponse.length + " records updated successfully in " + msBetweenDates + "ms");
  }

  const callApiWithRetry = async (tmpSearchText: string, tmpSelectedOptions: any[], tmpTriggedControl: string, tmpAccessToken: string) => {
    let tmpDataTrail_1: IBatchResponse[] = await SearchByAllBatch(tmpSearchText, tmpSelectedOptions, tmpAccessToken ?? "");
    let tmpRetryCountAll = 0;
    let tmpRetryCountAllFixed = false
    let tmpRetryCountSub = 0;
    let tmpRetryCountSubFixed = false;

    // Retry 1
    if (tmpDataTrail_1.length !== tmpSelectedOptions.length) {
      tmpRetryCountAll = tmpRetryCountAll + 1;
      tmpDataTrail_1 = await SearchByAllBatch(tmpSearchText, tmpSelectedOptions, tmpAccessToken ?? "");
    }
    // Retry 2
    if (tmpDataTrail_1.length !== tmpSelectedOptions.length) {
      tmpRetryCountAll = tmpRetryCountAll + 1;
      tmpDataTrail_1 = await SearchByAllBatch(tmpSearchText, tmpSelectedOptions, tmpAccessToken ?? "");
    }

    if (tmpDataTrail_1.length === tmpSelectedOptions.length) {
      tmpRetryCountAllFixed = true;
    }

    // Findout errored response and try - 1
    let tmpOptionsTrail_2: any[] = [];
    tmpDataTrail_1.forEach((tmpRes: IBatchResponse, index) => {
      if (tmpRes.StatusCode !== 200) {
        tmpOptionsTrail_2.push(tmpSelectedOptions[index]);
      }
    })

    if (tmpOptionsTrail_2.length > 0) {
      tmpRetryCountSub = tmpRetryCountSub + 1;
      let tmpDataTrail_2: IBatchResponse[] = await SearchByAllBatch(tmpSearchText, tmpOptionsTrail_2, tmpAccessToken ?? "");
      let tmpRetryIndex = 0;
      tmpDataTrail_1.forEach((tmpRes: IBatchResponse, index) => {
        if (tmpRes.StatusCode !== 200) {
          tmpDataTrail_1[index] = tmpDataTrail_2[tmpRetryIndex];
          tmpRetryIndex++;
        }
      })
    }

    // Findout errored response and try - 2
    tmpOptionsTrail_2 = [];
    tmpDataTrail_1.forEach((tmpRes: IBatchResponse, index) => {
      if (tmpRes.StatusCode !== 200) {
        tmpOptionsTrail_2.push(tmpSelectedOptions[index]);
      }
    })

    if (tmpOptionsTrail_2.length > 0) {
      tmpRetryCountSub = tmpRetryCountSub + 2;
      let tmpDataTrail_2: IBatchResponse[] = await SearchByAllBatch(tmpSearchText, tmpOptionsTrail_2, tmpAccessToken ?? "");
      let tmpRetryIndex = 0;
      tmpDataTrail_1.forEach((tmpRes: IBatchResponse, index) => {
        if (tmpRes.StatusCode !== 200) {
          tmpDataTrail_1[index] = tmpDataTrail_2[tmpRetryIndex];
          tmpRetryIndex++;
        }
      })
    }

    // Findout errored response
    tmpOptionsTrail_2 = [];
    tmpDataTrail_1.forEach((tmpRes: IBatchResponse, index) => {
      if (tmpRes.StatusCode !== 200) {
        tmpOptionsTrail_2.push(tmpSelectedOptions[index]);
      }
    })

    if (tmpOptionsTrail_2.length == 0) {
      tmpRetryCountSubFixed = true;
    }

    TelemetryService?.trackEvent(
      "RetryDetails",
      {
        Source: getHostUrl(),
        Action: "RetryDetails",
        "tmpRetryCountAll": tmpRetryCountAll,
        "tmpRetryCountSub": tmpRetryCountSub,
        "tmpRetryCountSubFixed": tmpRetryCountSubFixed,
        "tmpRetryCountAllFixed": tmpRetryCountAllFixed
      }
    );

    return tmpDataTrail_1;

  }

  const repopulateSearchData = async (tmpSearchText: string, tmpOptions: any[], tmpTriggedControl: string) => {
    //if (tmpSearchText.length > 3) {

    setHowHomePage(false);
    setShowSpinner(true);
    setShowSpinnerForLoadingD365Form(false);
    const dtStart: Date = new Date();
    let tmpSelectedOptions = tmpOptions;
    if (tmpSelectedOptions.length === 0) {
      tmpSelectedOptions = SearchEntityOptions.filter((tmpOption: any) => {
        return tmpOption.group === "Entities"
      });
    }

    let tmpAccessToken = await requestAccessToken();
    //let tmpData: IBatchResponse[] = await SearchByAllBatch(tmpSearchText, tmpSelectedOptions, tmpAccessToken ?? "");
    let tmpData: IBatchResponse[] = await callApiWithRetry(tmpSearchText, tmpSelectedOptions, tmpTriggedControl, tmpAccessToken ?? "");

    let tmpSearchData: iSearchData[] = [];
    tmpSelectedOptions.map((tmpSelectedOption: iSearchOption, tmpIndex: number) => {
      if (tmpData && tmpData[tmpIndex]) {
        if (tmpData[tmpIndex].Response) {
          if (tmpData[tmpIndex].StatusCode === 200) {
            let tmpDataResponse = JSON.parse(tmpData[tmpIndex].Response ?? "");

            let tmpTotalRecords: number = 0;
            if (tmpDataResponse["@odata.count"])
              tmpTotalRecords = Number.parseInt(tmpDataResponse["@odata.count"]);
            if (tmpDataResponse["totalrecordcount"])
              tmpTotalRecords = Number.parseInt(tmpDataResponse["totalrecordcount"]);

            if (tmpSelectedOption.entityDefinition) {
              setValue(0);
              tmpSearchData.push({
                entityName: tmpSelectedOption.entityName,
                viewName: tmpSelectedOption.label,
                columns: getcolumns(tmpSelectedOption),
                data: tmpSelectedOption.entity === "account" ? processAccountsData(tmpDataResponse.value, tmpSelectedOption.useSearchAPI ?? false) : (tmpSelectedOption.entity === "opportunity" ? processOpportunitiesData(tmpDataResponse.value, tmpSelectedOption.useSearchAPI ?? false) : tmpDataResponse.value),
                totalRecords: tmpTotalRecords,
                entityMetadata: tmpSelectedOption.entityMetaData,
                entityDefinition: tmpSelectedOption.entityDefinition,
              });
            }
          }
        }
      }
    })

    SetSearchData(tmpSearchData);
    setShowSpinner(false);

    let tmpRecordsCount: any[] = [];

    tmpSearchData.forEach((tmpRes: iSearchData) => {
      tmpRecordsCount.push(
        {
          "EntityOrView": tmpRes.viewName,
          "RecordCount": tmpRes.data.length
        }
      )
    });

    const dtEnd: Date = new Date();
    const msBetweenDates = dtEnd.getTime() - dtStart.getTime();
    const tmpEntities = tmpSelectedOptions.map(o => o["label"])
    TelemetryService?.trackEvent(
      "Search",
      {
        Source: getHostUrl(),
        Action: "Search",
        Entities: tmpEntities,
        SearchText: tmpSearchText,
        Duration: msBetweenDates,
        PageSize: NUMBER_CONSTANTS.MAX_SEARCH_RECORDS,
        ControlTriggered: tmpTriggedControl ?? "",
        RecordCounts: tmpRecordsCount
      }
    );
    // }
    // else {
    //   setsearchResults([]);
    // }
  }


  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        repopulateSearchData(searchText, selectedOptions, TRIGGERS.SearchTextBox);
      }
    },
    [debouncedSearchTerm, selectedOptions]
  );

  useEffect(
    () => {
      if (searchState)
        repopulateSearchData(searchState.searchText ?? "", searchState.searchOptions ?? [], searchState.triggeredBy ?? "");
    },
    [searchState]
  );

  const hideMSXSearch = () => {
    try {
      let tmlMSXSearchBoxs: any = document.getElementsByClassName("ms-SearchBox");
      if (tmlMSXSearchBoxs.length === 0) {
        tmlMSXSearchBoxs = top?.document.getElementsByClassName("ms-SearchBox");
      }
      if (tmlMSXSearchBoxs.length > 0) {
        tmlMSXSearchBoxs[0].style.display = "None";
      }
    }
    catch { }
  }

  const performCombinedSearch = (tmpCombinedSearchText: string) => {
    // debugger;
    let tmpSearchState: iSearchState = { triggeredBy: "From MSX Global Search", searchOptions: [] };
    const tmpTopSplits = tmpCombinedSearchText.split("|");
    if (tmpTopSplits.length > 0) {
      tmpSearchState.searchText = tmpTopSplits[0];
    }
    if (tmpTopSplits.length > 1) {
      const tmpSplits = tmpTopSplits[1].split(",");
      if (tmpSplits.length > 0) {
        let tmpSelectedOptions: any = [];
        tmpSplits.forEach((tmpOption: any) => {
          try {
            const tmpSearchOption = SearchEntityOptions.filter((tmp: iSearchOption) => {
              return tmp.id === parseInt(tmpOption)
            });
            if (tmpSearchOption.length > 0) {
              if (tmpSearchState.searchOptions)
                tmpSearchState.searchOptions.push(tmpSearchOption[0]);
            }
          }
          catch { }
        });
      }
    }
    SetSearchState(tmpSearchState);
  }

  React.useEffect(() => {
    //hideMSXSearch();
    const getData = async () => {
      const tmpHomeData = await homeDataCall();
      setHomeData(tmpHomeData);
      setLoading(false)
      //@ts-ignore
      const dtStart: Date = window.start_loading;
      const dtEnd: Date = new Date();
      const msBetweenDates = dtEnd.getTime() - dtStart?.getTime();
      TelemetryService?.trackEvent(
        TRIGGERS.HomePageLoad,
        {
          Source: getHostUrl(),
          Action: TRIGGERS.HomePageLoad,
          Duration: msBetweenDates,
          PageSize: NUMBER_CONSTANTS.MAX_SEARCH_RECORDS,
        }
      );

    };
    getData();
  }, []);


  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const homeDataCall = async () => {
    const dtStart: Date = new Date()
    const tmpAccessToken = await requestAccessToken()
    const tmpData: IBatchResponse[] = await SearchHomePageData(
      tmpAccessToken ?? ''
    )
    return tmpData;
  }
  return (
    <>

      {/* <Panel
        isOpen={isAppOpen}
        onDismiss={dismissAppPanel}
        type={PanelType.extraLarge}
        closeButtonAriaLabel="Close"
        headerText="Simplified Search"
      > */}
      <div className="App">
        <table align="center" className="table" style={{ width: "100%" }}>
          {!IsShellHostedURL() &&
            <tr className="msxui-header-row">
              <td align="center">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td align="left" style={{ width: "20%" }}>
                      <a className="msxui-header" onClick={() => {
                        setHowHomePage(true);
                      }}>
                        MSX D365 - Simplified
                      </a>
                    </td>
                    <td align="center" style={{ width: "60%" }}>
                      <table style={{ width: "80%" }}>
                        <tr>
                          <td valign="top" style={{ width: "25%" }}>
                            <MSXUIAutoComplete
                              options={autoComleteOptions}
                              handleParentFun={(value: any) => {
                                setSelectedOptions(value);
                                // repopulateSearchData(searchText, value, TRIGGERS.AutoComplete);
                              }}
                            ></MSXUIAutoComplete>
                            <input id="txtAutoCompleteText" style={{ display: "none" }} onChange={() => {
                              //@ts-ignore
                              if (document.getElementById("txtAutoCompleteText").value) {
                                //@ts-ignore
                                performCombinedSearch(document.getElementById("txtAutoCompleteText").value);
                                // //@ts-ignore
                                // const tmpSplits = document.getElementById("txtAutoCompleteText").value.split(",");
                                // if (tmpSplits.length > 0) {
                                //   let tmpSelectedOptions: any = [];
                                //   tmpSplits.forEach((tmpOption: any) => {
                                //     tmpSelectedOptions.push(parseInt(tmpOption));
                                //   });
                                //   setAutoComleteOptions(tmpSelectedOptions);
                                // }
                              }
                            }}></input>
                          </td>
                          <td valign="top" style={{ width: "25%" }}>
                            <TextField
                              fullWidth
                              placeholder="Search Text"
                              hiddenLabel
                              id="margin-normal"
                              onChange={handleTextFieldChange}
                              size="small" variant="outlined" className="msxui-search-textbox" autoFocus
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "20%" }}>
                      &nbsp;
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          }
          {IsShellHostedURL() &&
            <tr className="msxui-header-row-shell">
              <td align="center">
                <table style={{ width: "50%" }}>
                  <tr style={{ display: "none" }}>
                    <td valign="top" style={{ width: "25%" }}>
                      <MSXUIAutoComplete
                        options={autoComleteOptions}
                        handleParentFun={(value: any) => {
                          setSelectedOptions(value);
                          repopulateSearchData(searchText, value, TRIGGERS.AutoComplete);
                        }}
                      ></MSXUIAutoComplete>
                      <input id="txtAutoCompleteText" style={{ display: "none" }} onChange={() => {
                        //@ts-ignore
                        if (document.getElementById("txtAutoCompleteText").value) {
                          //@ts-ignore
                          performCombinedSearch(document.getElementById("txtAutoCompleteText").value);
                          // //@ts-ignore
                          // const tmpSplits = document.getElementById("txtAutoCompleteText").value.split(",");
                          // if (tmpSplits.length > 0) {
                          //   let tmpSelectedOptions: any = [];
                          //   tmpSplits.forEach((tmpOption: any) => {
                          //     tmpSelectedOptions.push(parseInt(tmpOption));
                          //   });
                          //   setAutoComleteOptions(tmpSelectedOptions);
                          // }
                        }
                      }}></input>
                    </td>
                    <td valign="top" style={{ width: "25%", display: "none" }}>
                      <TextField
                        fullWidth
                        placeholder="Search Text (Minimum 3 Chars)"
                        hiddenLabel
                        id="margin-normal"
                        onChange={handleTextFieldChange}
                        size="small" variant="outlined" className="msxui-search-textbox"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          }
          <tr>
            <td className="msxui-menus-td">
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ width: "33%" }}></td>
                  <td style={{ width: "33%" }}>
                    {
                      (showSpinnerForLoadingD365Form) && (
                        <Spinner size={SpinnerSize.xSmall} label="Loading..." labelPosition="right" />
                      )
                    }
                    {
                      (showSpinner) && (
                        <Spinner size={SpinnerSize.xSmall} label="Loading..." labelPosition="right" />
                      )
                    }
                  </td>
                  <td style={{ width: "33%" }}>
                    <MSXCommandBars handleParentFun={(value: any) => {
                      setSelectedOptions(value);
                      repopulateSearchData(searchText, value, TRIGGERS.EntityView);
                    }}
                      homeClick={() => {
                        setHowHomePage(true);
                      }}></MSXCommandBars>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          {/* {
            // (showHomePage && !IsShellHostedURL()) && (
            (showHomePage) && (
              <tr>
                <td className={constContentClass}>
                  {loading ? <p></p> : <HomePage data={homeData} buttonsCol={getButtons(OpportunityColsMEX, opportunity_attributes, ENTITIES.opportunity)}
                    viewButtonClick={(tmpColsByEntity: any, entityMetaData: any, entityDefinition: IEntityDefinition, cell: any) => {
                      return viewButtonClick(tmpColsByEntity, entityMetaData, entityDefinition, cell);
                    }}
                  />}
                </td>
              </tr>
            )
          } */}
          {
            !showHomePage && (
              <>
                {/* <tr>
                  <td>
                    {
                      (showSpinnerForLoadingD365Form) && (
                        <Spinner size={SpinnerSize.xSmall} label="Please wait. Loading..." labelPosition="right" />
                      )
                    }
                  </td>
                </tr> */}
                <tr>
                  <td className="msxui-td-div-content-shell">

                    {
                      (!showSpinner) && (
                        <div className={constContentClass}>
                          <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                              <Tabs value={value} variant={"scrollable"} onChange={handleChange} aria-label="basic tabs example">
                                {searchData.map((tmpEntity: iSearchData, tmpIndex: number) => {
                                  return (
                                    <Tab label={tmpEntity.viewName + ' (' + ((tmpEntity.totalRecords ?? 0) > NUMBER_CONSTANTS.MAX_SEARCH_RECORDS ? "" + NUMBER_CONSTANTS.MAX_SEARCH_RECORDS + "+" : tmpEntity.totalRecords) + ')'} {...a11yProps(tmpIndex)} />
                                  );
                                })}
                              </Tabs>
                            </Box>
                            {searchData.map((tmpEntity: iSearchData, tmpIndex: number) => {
                              return (
                                <MSXTabPanel value={value} index={tmpIndex}>
                                  <Grid columns={tmpEntity.columns} showCommandBar={true} items={tmpEntity.data} entityMetadata={tmpEntity.entityMetadata} entityDefinition={tmpEntity.entityDefinition} OnSave={(payload: any, startDate: Date) => {
                                    onSaveClick(payload, startDate, TRIGGERS.SaveRecord);
                                  }}></Grid>
                                </MSXTabPanel>
                              );
                            })}
                          </Box>
                        </div>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td className="tabulator">
                    <div className="tabulator-footer">
                      <span id="msxui-grid-pagination-holder" className="tabulator-paginator"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Panel
                      isOpen={isEditOpen}
                      onDismiss={dismissEditPanel}
                      type={PanelType.large}
                      closeButtonAriaLabel="Close"
                      headerText="Edit-Opportunity"
                    >
                      <Edit record={record} cols={recordCols} entityMetadata={recordEntityMetatada} entityDefinition={recordEntityDefinition} OnSave={(payload: any, startDate: Date) => {
                        onSaveClick(payload, startDate, TRIGGERS.SaveOnEdit);
                      }}></Edit>
                    </Panel>
                    <Panel
                      isOpen={isViewOpen}
                      onDismiss={dismissViewPanel}
                      type={PanelType.large}
                      closeButtonAriaLabel="Close"
                      headerText="View-Opportunity"
                    >
                      <View record={record}></View>
                    </Panel>
                    <Panel
                      isOpen={isAcccountOpen}
                      onDismiss={dismissAccountPanel}
                      type={PanelType.large}
                      closeButtonAriaLabel="Close"
                      headerText="Related Opportunities"
                    >
                      <div>
                        <Grid columns={OpportunityCols} items={RelatedOpportunityData}></Grid>
                      </div>
                    </Panel>
                    <Panel
                      isOpen={isMilestoneOpen}
                      onDismiss={dismissMilestonePanel}
                      type={PanelType.large}
                      closeButtonAriaLabel="Close"
                      headerText="Related Milestones"
                    >
                      <div>
                        <Grid columns={MilesonteCols} items={MilestoneData}></Grid>
                      </div>
                    </Panel>
                  </td>
                </tr>
              </>
            )
          }
        </table>
      </div>
      {/* </Panel > */}
    </>
  )
}

function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

