import React from "react";
import { OpportunityCols, MilesonteCols, OpportunitySearchCols, OpportunityViewCols } from "../Utils/Constants";
import { OpportunityData } from "../Mock/opportunities";
import { RelatedOpportunityData } from '../Mock/related_opportunities'
import { MilestoneData } from "../Mock/msp_engagementmilestones";
import Grid from "./Grid";
import { MSXGridCommandBars } from "../FluentControls/MSXGridCommandBars";
import { IRecord } from "../Interfaces/IRecord";
import { IsEmpty, IsNumberFieldEmpty } from "../Utils/utils";

function Edit(props: IRecord) {
  const [record, setRecord] = React.useState(props.record);
  const childEntities: any[] = [
    {
      cols: MilesonteCols,
      data: MilestoneData,
      Entity: "msp_engagementmilestone",
      HeaderText: "Related Milestones",
    },
    {
      cols: OpportunitySearchCols,
      data: RelatedOpportunityData,
      Entity: "opportunity",
      HeaderText: "Related Opportunities",
    },
  ];

  const ManageExpandCollapse = (
    currentElement: string,
    nextElement: string
  ) => {
    let tmpCurEle = document.getElementById(currentElement);
    let tmpNextEle = document.getElementById(nextElement);

    let tmpClass = "";

    tmpCurEle?.classList.forEach((tmpcls: string) => {
      if (tmpcls === "collapsible") {
        tmpClass = "collapsible";
      }
      if (tmpcls === "active") {
        tmpClass = "active";
      }
    });

    tmpCurEle?.classList.remove(tmpClass);
    tmpCurEle?.classList.add(tmpClass === "active" ? "collapsible" : "active");

    if (tmpNextEle) {
      if (
        tmpNextEle.style.display === "block" ||
        tmpNextEle.style.display === ""
      ) {
        tmpNextEle.style.display = "None";
      } else {
        tmpNextEle.style.display = "block";
      }
    }
  };
  const OnTextBoxChange = (event: any, tmpField: any) => {
    let tmpRecords = record;
    tmpRecords[tmpField.field] = event.target.value;
    setRecord(tmpRecords);
  };
  const OnSelectChange = (event: any, tmpField: any) => {
    let tmpRecords = record;
    tmpRecords[tmpField.field] = event.target.value;
    setRecord(tmpRecords);
  };
  const GetOptionSets = (tmpField: any) => {
    const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
      return tmpAttr.attributeDescriptor.LogicalName === tmpField.field
    }) ?? [];
    if (tmpAttrs.length > 0) {
      return tmpAttrs[0].attributeDescriptor.OptionSet;
    }
    return [];
  };
  const GetFieldType = (tmpField: any) => {
    let tmpReturn = "string";
    const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
      return tmpAttr.attributeDescriptor.LogicalName === tmpField.field
    }) ?? [];
    if (tmpAttrs.length > 0) {
      switch (tmpAttrs[0].attributeDescriptor.Type) {
        case "money":
          tmpReturn = "string"
          break;
        case "picklist":
          tmpReturn = "picklist"
          break;
      }
    }
    return tmpReturn;
  };
  const GetText = (tmpField: any) => {
    let tmpReturn = props.record[tmpField.field];

    const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
      return tmpAttr.attributeDescriptor.LogicalName === tmpField.field
    }) ?? [];
    if (tmpAttrs.length > 0) {
      switch (tmpAttrs[0].attributeDescriptor.Type) {
        case "state":
        case "status":
        case "picklist":
          const tmpOptionSets = tmpAttrs[0].attributeDescriptor.OptionSet.filter((tmpOption: any) => {
            return tmpOption.value === tmpReturn
          });
          if (tmpOptionSets.length > 0) {
            tmpReturn = tmpOptionSets[0]["Label"];
          }
          break;
      }
    }
    return tmpReturn;
  };
  const [sectionOne, setSectionOne] = React.useState<any[]>([]);
  const [sectionTwo, setSectionTwo] = React.useState<any[]>([]);
  const [sectionThree, setSectionThree] = React.useState<any[]>([]);

  React.useEffect(() => {
    let tmpSectionOne: any = [];
    let tmpSectionTwo: any = [];
    let tmpSectionThree: any = [];

    props.cols.forEach((tmpCol: any, index: number) => {
      if (index % 3 === 0) {
        tmpSectionOne.push(tmpCol);
      }
      if (index % 3 === 1) {
        tmpSectionTwo.push(tmpCol);
      }
      if (index % 3 === 2) {
        tmpSectionThree.push(tmpCol);
      }
    });
    setSectionOne(tmpSectionOne);
    setSectionTwo(tmpSectionTwo);
    setSectionThree(tmpSectionThree);

  }, []);


  const AppendText = (left: string, right: string) => {
    return left + right;
  };
  const onSaveClick = () => {
    const dtStart: Date = new Date();
    let tmpPayloads: any[] = [];
    const tmpURL = (props.entityDefinition?.pluralName ?? "") + "(" + record[props.entityDefinition?.primaryKey ?? ""] + ")";
    const tmpContentId = record[props.entityDefinition?.primaryColumn ?? ""]
    tmpPayloads.push({
      "contentId": tmpContentId,
      "relativeUrl": tmpURL,
      "httpMethod": "PATCH",
      "payload": getRecordForServerCall(record)
    });
    if (props.OnSave)
      props.OnSave(tmpPayloads, dtStart);
  };

  const getRecordForServerCall = (tmpRecord: any) => {
    let tmpRowToUpdate: any = {};
    props.cols.forEach((tmpCol: any) => {
      const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
        return tmpAttr.attributeDescriptor.LogicalName === tmpCol.field
      }) ?? [];
      if (tmpAttrs.length > 0) {
        let tmpFieldDef = tmpAttrs[0];
        if (!tmpCol.readonly) {
          switch (tmpFieldDef.attributeDescriptor.Type) {
            case "money":
              if (!IsNumberFieldEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = Number.parseFloat(tmpRecord[tmpCol.field]);
              }
              break;
            case "datetime":
              break;
            case "picklist":
              if (!IsEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = Number.parseInt(tmpRecord[tmpCol.field]);
              }
              break;
            default:
              if (!IsEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = tmpRecord[tmpCol.field];
              }
              break;
          }
        }
      }

    });
    return tmpRowToUpdate;
  };
  return (
    <div>
      <div>
        <MSXGridCommandBars OnSaveClick={() => {
          onSaveClick();
        }}></MSXGridCommandBars>
      </div>
      <div
        id="divCreateHeader"
        className="egmtHeader active"
        onClick={(event) => {
          ManageExpandCollapse("divCreateHeader", "divCreateDetail");
        }}
      >
        Opportunity Details
      </div>
      <div className="egmtCreateDivMain" id="divCreateDetail">
        <div className="egmtCreateDivWrapperSectionParent">
          <div className="egmtCreateDivWrapperSection">
            {sectionOne.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        {
                          tmpField.readonly && (
                            <div className="egmtInput">{GetText(tmpField)}</div>
                          )
                        }
                        {(!tmpField.readonly && GetFieldType(tmpField) === "string") && (
                          <input
                            className="egmtInput"
                            placeholder={props.record[tmpField.field]}
                            aria-label={tmpField.displayText}
                            type="text"
                            onChange={(event) => {
                              OnTextBoxChange(event, tmpField);
                            }}
                          ></input>
                        )}
                        {!tmpField.readonly && GetFieldType(tmpField) === "picklist" && (
                          <select
                            className="egmtSelect"
                            aria-label={tmpField.displayText}
                            onChange={(event) => {
                              OnSelectChange(event, tmpField);
                            }}
                          >
                            <option value="">---</option>
                            {GetOptionSets(tmpField).map(
                              (tmpOption: any) => {
                                return (
                                  <option value={tmpOption.Value}>
                                    {tmpOption.Label}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="egmtCreateDivWrapperSection">
            {sectionTwo.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        {
                          tmpField.readonly && (
                            <div className="egmtInput">{GetText(tmpField)}</div>
                          )
                        }
                        {(!tmpField.readonly && GetFieldType(tmpField) === "string") && (
                          <input
                            className="egmtInput"
                            placeholder={props.record[tmpField.field]}
                            aria-label={tmpField.displayText}
                            type="text"
                            onChange={(event) => {
                              OnTextBoxChange(event, tmpField);
                            }}
                          ></input>
                        )}
                        {!tmpField.readonly && GetFieldType(tmpField) === "picklist" && (
                          <select
                            className="egmtSelect"
                            aria-label={tmpField.displayText}
                            onChange={(event) => {
                              OnSelectChange(event, tmpField);
                            }}
                          >
                            <option value="">---</option>
                            {GetOptionSets(tmpField).map(
                              (tmpOption: any) => {
                                return (
                                  <option value={tmpOption.Value}>
                                    {tmpOption.Label}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="egmtCreateDivWrapperSection">
            {sectionThree.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        {
                          tmpField.readonly && (
                            <div className="egmtInput">{GetText(tmpField)}</div>
                          )
                        }
                        {(!tmpField.readonly && GetFieldType(tmpField) === "string") && (
                          <input
                            className="egmtInput"
                            placeholder={props.record[tmpField.field]}
                            aria-label={tmpField.displayText}
                            type="text"
                            onChange={(event) => {
                              OnTextBoxChange(event, tmpField);
                            }}
                          ></input>
                        )}
                        {GetFieldType(tmpField) === "picklist" && (
                          <select
                            className="egmtSelect"
                            aria-label={tmpField.displayText}
                            onChange={(event) => {
                              OnSelectChange(event, tmpField);
                            }}
                          >
                            <option value="">---</option>
                            {GetOptionSets(tmpField).map(
                              (tmpOption: any) => {
                                return (
                                  <option value={tmpOption.Value}>
                                    {tmpOption.Label}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>
      {childEntities.map((entity: any) => {
        return (
          <div className="egmtHeaderChild">
            <div
              id={AppendText("divHeader", entity.Entity)}
              className="egmtHeader collapsible"
              onClick={(event) => {
                ManageExpandCollapse(
                  AppendText("divHeader", entity.Entity),
                  AppendText("divDetail", entity.Entity)
                );
              }}
            >
              {entity.HeaderText}
            </div>
            <div
              className="egmtCreateDivMain"
              style={{ display: "none" }}
              id={AppendText("divDetail", entity.Entity)}
            >
              <Grid columns={entity.cols} items={entity.data} showCommandBar={false}></Grid>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Edit;
