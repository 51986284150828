export const msp_engagementmilestone_attributes = [
  {
    "attributeDescriptor": {
      "LogicalName": "createdby",
      "Type": "lookup",
      "DisplayName": "Created By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdon",
      "Type": "datetime",
      "DisplayName": "Created On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "createdonbehalfby",
      "Type": "lookup",
      "DisplayName": "Created By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "exchangerate",
      "Type": "decimal",
      "DisplayName": "Exchange Rate",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "importsequencenumber",
      "Type": "integer",
      "DisplayName": "Import Sequence Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedby",
      "Type": "lookup",
      "DisplayName": "Modified By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedon",
      "Type": "datetime",
      "DisplayName": "Modified On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "modifiedonbehalfby",
      "Type": "lookup",
      "DisplayName": "Modified By (Delegate)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_bacvrate",
      "Type": "decimal",
      "DisplayName": "BACV",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_commitmentrecommendation",
      "Type": "picklist",
      "DisplayName": "Customer Commitment",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Uncommitted",
          "Value": "861980000"
        },
        {
          "Label": "Committed",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_committedby",
      "Type": "lookup",
      "DisplayName": "Committed By",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_committedon",
      "Type": "datetime",
      "DisplayName": "Committed On",
      "Format": "datetime",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_completed",
      "Type": "picklist",
      "DisplayName": "Completed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_deliveryprogramcomponentpcffield",
      "Type": "string",
      "DisplayName": "DeliveryProgramComponentPcfField",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_deliveryspecifiedfield",
      "Type": "picklist",
      "DisplayName": "Delivered By",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Customer",
          "Value": "606820000"
        },
        {
          "Label": "Partner",
          "Value": "606820001"
        },
        {
          "Label": "ISD",
          "Value": "606820002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementhealthescalationflag",
      "Type": "integer",
      "DisplayName": "EngagementHealthEscalationFlag",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementid",
      "Type": "lookup",
      "DisplayName": "Engagement",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementmilestoneid",
      "Type": "uniqueidentifier",
      "DisplayName": "Engagement Milestone",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_engagementviolationid",
      "Type": "lookup",
      "DisplayName": "Engagement Violation",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_forecastcommentsjsonfield",
      "Type": "memo",
      "DisplayName": "Forecast Comments",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_healthescalationcreatedate",
      "Type": "datetime",
      "DisplayName": "Health Escalation Create Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_helpneeded",
      "Type": "picklist",
      "DisplayName": "Help Needed",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Azure Capacity Availability",
          "Value": "861980001"
        },
        {
          "Label": "Azure Service/Product Availability",
          "Value": "606820000"
        },
        {
          "Label": "Azure Tech Skilling",
          "Value": "861980014"
        },
        {
          "Label": "Billing, Licensing & Subscription",
          "Value": "861980000"
        },
        {
          "Label": "COVID-19, No Help Needed",
          "Value": "394380000"
        },
        {
          "Label": "Discounting",
          "Value": "861980002"
        },
        {
          "Label": "Exec Visit",
          "Value": "861980006"
        },
        {
          "Label": "Executive Escalation - Customer",
          "Value": "861980003"
        },
        {
          "Label": "Executive Support (Engineering)",
          "Value": "861980004"
        },
        {
          "Label": "Executive Support (Non-engineering – Corp)",
          "Value": "861980005"
        },
        {
          "Label": "Executive Support (Non-engineering – Field)",
          "Value": "861980017"
        },
        {
          "Label": "Funds [<100K]",
          "Value": "861980007"
        },
        {
          "Label": "Funds [100K+]",
          "Value": "861980008"
        },
        {
          "Label": "Legal",
          "Value": "725300000"
        },
        {
          "Label": "MS Services Support",
          "Value": "861980015"
        },
        {
          "Label": "Non-Addressable",
          "Value": "861980016"
        },
        {
          "Label": "Partner (Business)",
          "Value": "861980009"
        },
        {
          "Label": "Partner (Capability)",
          "Value": "861980018"
        },
        {
          "Label": "Partner (Funds)",
          "Value": "861980019"
        },
        {
          "Label": "Resources [Engineering]",
          "Value": "861980010"
        },
        {
          "Label": "Resources [Non-Engineering]",
          "Value": "861980011"
        },
        {
          "Label": "Technical Blockers",
          "Value": "861980012"
        },
        {
          "Label": "Technical Gaps",
          "Value": "861980013"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestoneazurecapacitytype",
      "Type": "multiselectpicklist",
      "DisplayName": "Azure Capacity Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "App Service (Cores)",
          "Value": "861980075"
        },
        {
          "Label": "Archive Storage (TB)",
          "Value": "861980057"
        },
        {
          "Label": "Av2/Dv2/Dv3/Ev3/Dsv3/Esv3 (Intel)  (Cores)",
          "Value": "861980037"
        },
        {
          "Label": "AV36 (AVS) (Cores)",
          "Value": "861980067"
        },
        {
          "Label": "AV36P (AVS) (Cores)",
          "Value": "861980076"
        },
        {
          "Label": "AV52 (AVS) (Cores)",
          "Value": "861980077"
        },
        {
          "Label": "Azure Cosmos DB (RU/s)",
          "Value": "861980064"
        },
        {
          "Label": "Azure Data Lake Storage (TB)",
          "Value": "861980058"
        },
        {
          "Label": "Azure Data LakeStorage Gen 2 (TB)",
          "Value": "861980059"
        },
        {
          "Label": "Azure Databricks  (Cores)",
          "Value": "861980070"
        },
        {
          "Label": "Azure HPC Cache (TB)",
          "Value": "861980061"
        },
        {
          "Label": "Azure NetApp Files (ANF) (TB)",
          "Value": "861980062"
        },
        {
          "Label": "Azure OpenAI Service",
          "Value": "861980081"
        },
        {
          "Label": "Azure SQL Database (Cores or DTUs)",
          "Value": "861980065"
        },
        {
          "Label": "Azure SQL Managed Instance (Cores)",
          "Value": "861980066"
        },
        {
          "Label": "Azure xDirect (TB)",
          "Value": "861980060"
        },
        {
          "Label": "Dav4/EaV4/DAsv4/EAsv4 (AMD) (Cores)",
          "Value": "861980040"
        },
        {
          "Label": "DCasv5/DCadsv5/ECasv5/ECadsv5 (AMD) (Confidential) (Cores)",
          "Value": "861980071"
        },
        {
          "Label": "DCsv2 (Intel) (Confidential) (Cores)",
          "Value": "861980078"
        },
        {
          "Label": "DCsv3/DCdsv3 (Intel) (Confidential) (Cores)",
          "Value": "861980079"
        },
        {
          "Label": "Ddsv5/Edsv5 (Intel)  (Cores)",
          "Value": "861980068"
        },
        {
          "Label": "Dpdsv5/Dpldsv5/Dpsv5/Dplsv5/Epdv5/Epsv5 (ARM)  (Cores)",
          "Value": "861980069"
        },
        {
          "Label": "Dv4/Ev4/Dsv4/Esv4/Ddsv4/Edsv4 (Intel)  (Cores)",
          "Value": "861980038"
        },
        {
          "Label": "Dv5/Ev5/DSv5/Esv5 (Intel)  (Cores)",
          "Value": "861980039"
        },
        {
          "Label": "FSv2 (Cores)",
          "Value": "861980041"
        },
        {
          "Label": "G (Cores)",
          "Value": "861980042"
        },
        {
          "Label": "H (Cores)",
          "Value": "861980045"
        },
        {
          "Label": "Hbv2 (Cores)",
          "Value": "861980043"
        },
        {
          "Label": "Hbv3 (Cores)",
          "Value": "861980073"
        },
        {
          "Label": "Hbv4 (Cores)",
          "Value": "861980074"
        },
        {
          "Label": "Hc (Cores)",
          "Value": "861980044"
        },
        {
          "Label": "LSv3 (Cores)",
          "Value": "861980046"
        },
        {
          "Label": "M (Cores)",
          "Value": "861980047"
        },
        {
          "Label": "Mv2 (Cores)",
          "Value": "861980048"
        },
        {
          "Label": "NC (Cores)",
          "Value": "861980051"
        },
        {
          "Label": "NCasT4V3 (Cores)",
          "Value": "861980049"
        },
        {
          "Label": "NCsv2 (Cores)",
          "Value": "861980052"
        },
        {
          "Label": "NCSv3 (Cores)",
          "Value": "861980053"
        },
        {
          "Label": "Nd A100 V4 (Cores)",
          "Value": "861980050"
        },
        {
          "Label": "Nd H100 V5 (Cores) (Future)",
          "Value": "861980080"
        },
        {
          "Label": "NDs (Cores)",
          "Value": "861980054"
        },
        {
          "Label": "NVv3 (Cores)",
          "Value": "861980055"
        },
        {
          "Label": "NVV4 (Cores)",
          "Value": "861980056"
        },
        {
          "Label": "NVV5  (Cores)",
          "Value": "861980022"
        },
        {
          "Label": "Ultra SSD Storage (TB)",
          "Value": "861980063"
        },
        {
          "Label": "Other",
          "Value": "861980032"
        },
        {
          "Label": "None",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonecategory",
      "Type": "picklist",
      "DisplayName": "Milestone Category",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Deal Registration",
          "Value": "861980003"
        },
        {
          "Label": "CSA-Led POC/Pilot/MVP",
          "Value": "861980001"
        },
        {
          "Label": "TS-Led POC/Pilot/MVP",
          "Value": "861980000"
        },
        {
          "Label": "Production",
          "Value": "861980002"
        },
        {
          "Label": "Credit Offer",
          "Value": "861980007"
        },
        {
          "Label": "Dual Credit",
          "Value": "861980004"
        },
        {
          "Label": "Optimization",
          "Value": "861980008"
        },
        {
          "Label": "Other Adjustments",
          "Value": "861980005"
        },
        {
          "Label": "Partner Reported ACR",
          "Value": "861980006"
        },
        {
          "Label": "Intent (MW, BA, Sec)",
          "Value": "861980009"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonecomments",
      "Type": "memo",
      "DisplayName": "Risk/Blocker Details",
      "Format": "1",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonedate",
      "Type": "datetime",
      "DisplayName": "Milestone Est. Date",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonenumber",
      "Type": "string",
      "DisplayName": "Milestone Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonepreferredazureregion",
      "Type": "picklist",
      "DisplayName": "Preferred Azure Region",
      "Format": null,
      "OptionSet": [
        {
          "Label": "USDoD Central - Des Moines DoD",
          "Value": "861980019"
        },
        {
          "Label": "USDoD East - Boydton DoD",
          "Value": "861980007"
        },
        {
          "Label": "Australia Central 1 - Canberra 1",
          "Value": "861980011"
        },
        {
          "Label": "Australia Central 2 - Canberra 2",
          "Value": "861980012"
        },
        {
          "Label": "Australia East - Sydney",
          "Value": "861980047"
        },
        {
          "Label": "Australia Southeast - Melbourne",
          "Value": "861980032"
        },
        {
          "Label": "Austria East - Vienna (future)",
          "Value": "861980063"
        },
        {
          "Label": "Belgium Central - Brussels (future)",
          "Value": "861980068"
        },
        {
          "Label": "Brazil South - Campinas",
          "Value": "861980010"
        },
        {
          "Label": "Brazil Southeast - Rio",
          "Value": "861980065"
        },
        {
          "Label": "Canada Central - Toronto",
          "Value": "861980049"
        },
        {
          "Label": "Canada East - Quebec",
          "Value": "861980039"
        },
        {
          "Label": "Central India - Pune",
          "Value": "861980038"
        },
        {
          "Label": "Central US - Des Moines",
          "Value": "861980018"
        },
        {
          "Label": "Chile Central - Santiago (future)",
          "Value": "606820002"
        },
        {
          "Label": "China East 2 - Shanghai 2",
          "Value": "861980045"
        },
        {
          "Label": "China East 3 (future) - Shanghai 3",
          "Value": "861980061"
        },
        {
          "Label": "China North 2 - Beijing 2",
          "Value": "861980004"
        },
        {
          "Label": "China North 3 (future) - Beijing 3",
          "Value": "861980062"
        },
        {
          "Label": "Denmark East - Copenhagen (future)",
          "Value": "861980074"
        },
        {
          "Label": "East Asia - Hong Kong",
          "Value": "861980026"
        },
        {
          "Label": "East US - Blue Ridge",
          "Value": "861980005"
        },
        {
          "Label": "East US 2 - Boydton",
          "Value": "861980006"
        },
        {
          "Label": "France Central - Paris",
          "Value": "861980035"
        },
        {
          "Label": "Germany West Central - Frankfurt",
          "Value": "861980052"
        },
        {
          "Label": "Indonesia Central - Jakarta (future)",
          "Value": "861980075"
        },
        {
          "Label": "Israel Central (future)",
          "Value": "861980059"
        },
        {
          "Label": "Italy North - Milan",
          "Value": "861980058"
        },
        {
          "Label": "Japan East - Tokyo",
          "Value": "861980048"
        },
        {
          "Label": "Japan West - Osaka",
          "Value": "861980034"
        },
        {
          "Label": "Korea Central - Seoul",
          "Value": "861980043"
        },
        {
          "Label": "Korea South - Busan",
          "Value": "861980009"
        },
        {
          "Label": "Malaysia West (Micro) - Kuala Lumpur (future)",
          "Value": "861980072"
        },
        {
          "Label": "Mexico Central - Santiago de Querétaro (future)",
          "Value": "606820001"
        },
        {
          "Label": "New Zealand North (Micro) - Auckland (future)",
          "Value": "861980073"
        },
        {
          "Label": "North Central US - Chicago",
          "Value": "861980017"
        },
        {
          "Label": "North Europe - Dublin",
          "Value": "861980022"
        },
        {
          "Label": "Norway East - Oslo",
          "Value": "861980054"
        },
        {
          "Label": "Poland Central - Warsaw",
          "Value": "606820003"
        },
        {
          "Label": "Qatar Central - Doha",
          "Value": "861980060"
        },
        {
          "Label": "South Africa North - Johannesburg",
          "Value": "861980027"
        },
        {
          "Label": "South Central US - San Antonio",
          "Value": "861980041"
        },
        {
          "Label": "South India - Chennai",
          "Value": "861980015"
        },
        {
          "Label": "Southeast Asia - Singapore",
          "Value": "861980046"
        },
        {
          "Label": "Spain Central (future) - Madrid",
          "Value": "861980057"
        },
        {
          "Label": "Sweden Central - Gavle",
          "Value": "606820000"
        },
        {
          "Label": "Switzerland North - Zurich",
          "Value": "861980056"
        },
        {
          "Label": "Switzerland West - Geneva",
          "Value": "861980025"
        },
        {
          "Label": "Taiwan North - Taipei (future)",
          "Value": "861980070"
        },
        {
          "Label": "Taiwan Northwest - Taoyuan (future)",
          "Value": "861980071"
        },
        {
          "Label": "UAE Central - Abu Dhabi",
          "Value": "861980067"
        },
        {
          "Label": "UAE North - Dubai",
          "Value": "861980021"
        },
        {
          "Label": "UK North - Durham GS",
          "Value": "861980023"
        },
        {
          "Label": "UK South - London",
          "Value": "861980029"
        },
        {
          "Label": "UK West - Cardiff",
          "Value": "861980014"
        },
        {
          "Label": "USGov Arizona - Phoenix Gov",
          "Value": "861980037"
        },
        {
          "Label": "USGov Iowa - Des Moines Gov",
          "Value": "861980020"
        },
        {
          "Label": "USGov Texas - San Antonio Gov",
          "Value": "861980042"
        },
        {
          "Label": "USGov Virginia - Boydton Gov",
          "Value": "861980008"
        },
        {
          "Label": "US Sec",
          "Value": "861980077"
        },
        {
          "Label": "US Sec Central",
          "Value": "861980078"
        },
        {
          "Label": "US Sec East",
          "Value": "861980079"
        },
        {
          "Label": "US Sec West",
          "Value": "861980080"
        },
        {
          "Label": "US Sec West Central",
          "Value": "861980081"
        },
        {
          "Label": "US Sec Zone 1",
          "Value": "861980082"
        },
        {
          "Label": "West Central US - Cheyenne",
          "Value": "861980016"
        },
        {
          "Label": "West Europe - Amsterdam",
          "Value": "861980001"
        },
        {
          "Label": "West India - Mumbai",
          "Value": "861980033"
        },
        {
          "Label": "West US - Bay",
          "Value": "861980002"
        },
        {
          "Label": "West US 2 - Quincy",
          "Value": "861980040"
        },
        {
          "Label": "West US 3 - Phoenix",
          "Value": "861980036"
        },
        {
          "Label": "None",
          "Value": "861980076"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonesolutionarea",
      "Type": "picklist",
      "DisplayName": "Milestone Solution Area",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Business Applications",
          "Value": "861980001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980002"
        },
        {
          "Label": "Digital and Application Innovation",
          "Value": "861980004"
        },
        {
          "Label": "Infrastructure",
          "Value": "861980000"
        },
        {
          "Label": "Modern Work",
          "Value": "861980003"
        },
        {
          "Label": "Security",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonestatus",
      "Type": "picklist",
      "DisplayName": "Milestone Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Completed",
          "Value": "861980003"
        },
        {
          "Label": "On Track",
          "Value": "861980000"
        },
        {
          "Label": "At Risk",
          "Value": "861980001"
        },
        {
          "Label": "Blocked",
          "Value": "861980002"
        },
        {
          "Label": "Cancelled",
          "Value": "861980004"
        },
        {
          "Label": "Lost to Competitor",
          "Value": "861980005"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestonestatusreason",
      "Type": "picklist",
      "DisplayName": "Milestone Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "App combability / product feature or capability",
          "Value": "861980000"
        },
        {
          "Label": "Billing, Licensing and Subscription",
          "Value": "861980001"
        },
        {
          "Label": "Capacity/Service Availability",
          "Value": "861980009"
        },
        {
          "Label": "Cloud Trust",
          "Value": "861980007"
        },
        {
          "Label": "COVID-19",
          "Value": "394380000"
        },
        {
          "Label": "Customer IT Infrastructure and Remediation",
          "Value": "861980002"
        },
        {
          "Label": "Downstream Resources",
          "Value": "606820000"
        },
        {
          "Label": "Executive Sponsorship",
          "Value": "861980008"
        },
        {
          "Label": "IT Change Management and Resourcing",
          "Value": "861980003"
        },
        {
          "Label": "Non-Addressable",
          "Value": "861980010"
        },
        {
          "Label": "Organizational Change Management",
          "Value": "861980004"
        },
        {
          "Label": "Partner engagement",
          "Value": "861980005"
        },
        {
          "Label": "Service reliability",
          "Value": "861980006"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestoneviolationid",
      "Type": "lookup",
      "DisplayName": "Milestone Violation",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_milestoneworkload",
      "Type": "picklist",
      "DisplayName": "Workload Type",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Azure",
          "Value": "861980000"
        },
        {
          "Label": "Dynamics 365",
          "Value": "861980001"
        },
        {
          "Label": "Modern Work",
          "Value": "861980003"
        },
        {
          "Label": "Security",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_monthlyuse",
      "Type": "money",
      "DisplayName": "Est. Change in Monthly Usage",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_monthlyuse_base",
      "Type": "money",
      "DisplayName": "Est. Monthly Usage (Base)",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_name",
      "Type": "string",
      "DisplayName": "Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_nonrecurring",
      "Type": "picklist",
      "DisplayName": "Non-Recurring?",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Yes",
          "Value": "861980000"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_noofactivations",
      "Type": "integer",
      "DisplayName": "Est. Change in active users/devices",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_opportunityid",
      "Type": "lookup",
      "DisplayName": "Opportunity",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_rollupcalculationupdatedonflag",
      "Type": "datetime",
      "DisplayName": "RollUp Calculation Updated On Flag",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_scrumescalation",
      "Type": "picklist",
      "DisplayName": "Scrum Escalation",
      "Format": null,
      "OptionSet": [
        {
          "Label": "No Scrum Escalation",
          "Value": "861980000"
        },
        {
          "Label": "SubRegion/EOU Scrum",
          "Value": "861980001"
        },
        {
          "Label": "Area Scrum",
          "Value": "861980002"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_seatsentitlements",
      "Type": "integer",
      "DisplayName": "# Seats/Entitlements",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_solutionarea",
      "Type": "picklist",
      "DisplayName": "Solution Area",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Apps and Infra",
          "Value": "861980000"
        },
        {
          "Label": "Business Apps",
          "Value": "861980001"
        },
        {
          "Label": "Data and AI",
          "Value": "861980002"
        },
        {
          "Label": "Modern Workplace",
          "Value": "861980003"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_tempshortid",
      "Type": "string",
      "DisplayName": "Temp Short Id",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uataccountname",
      "Type": "string",
      "DisplayName": "UAT Accountname",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uataccountnumber",
      "Type": "string",
      "DisplayName": "UAT Accountnumber",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uataccounttpid",
      "Type": "string",
      "DisplayName": "UAT Account TPID",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatactionpcfcontrol",
      "Type": "string",
      "DisplayName": "UAT Action PcF Control",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatarea",
      "Type": "string",
      "DisplayName": "UAT Area",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatengagementname",
      "Type": "string",
      "DisplayName": "UAT Engagement Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatengagementnumber",
      "Type": "string",
      "DisplayName": "UAT Engagement Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatentityname",
      "Type": "string",
      "DisplayName": "UAT Entity Name",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatentitynumber",
      "Type": "string",
      "DisplayName": "UAT Entity Number",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatentitytype",
      "Type": "string",
      "DisplayName": "UAT Entity Type",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uateou",
      "Type": "string",
      "DisplayName": "UAT EOU",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_uatmilestoneowner",
      "Type": "string",
      "DisplayName": "UAT Milestone Owner",
      "Format": "Text",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_workload",
      "Type": "picklist",
      "DisplayName": "Workload",
      "Format": null,
      "OptionSet": [
        {
          "Label": "AI: AI Infra",
          "Value": "861980168"
        },
        {
          "Label": "AI: Azure Machine Learning",
          "Value": "861980091"
        },
        {
          "Label": "AI: Cognitive Search, Documents (OCR and Forms Recognizer)",
          "Value": "861980100"
        },
        {
          "Label": "AI: Cognitive Speech and Translation Services",
          "Value": "606820005"
        },
        {
          "Label": "AI: Cognitive Vision Services",
          "Value": "861980012"
        },
        {
          "Label": "AI: Conversational Agents and Bots",
          "Value": "861980092"
        },
        {
          "Label": "AI: OpenAI (PAYGO)",
          "Value": "861980165"
        },
        {
          "Label": "AI: OpenAI (PTU)",
          "Value": "861980166"
        },
        {
          "Label": "Apps: API Management (APIM)",
          "Value": "861980130"
        },
        {
          "Label": "Apps: App Service (Migrate App)",
          "Value": "861980013"
        },
        {
          "Label": "Apps: App Service (New App)",
          "Value": "606820006"
        },
        {
          "Label": "Apps: Azure Container Apps (ACA) (Migrate App)",
          "Value": "861980145"
        },
        {
          "Label": "Apps: Azure Container Apps (ACA) (New App)",
          "Value": "606820007"
        },
        {
          "Label": "Apps: Azure Kubernetes Service (AKS) (Migrate App)",
          "Value": "861980081"
        },
        {
          "Label": "Apps: Azure Kubernetes Service (AKS) (New App)",
          "Value": "606820008"
        },
        {
          "Label": "Apps: Azure Redhat Openshift (ARO)",
          "Value": "861980078"
        },
        {
          "Label": "Apps: Azure Spring Apps (Migrate App)",
          "Value": "861980077"
        },
        {
          "Label": "Apps: Azure Spring Apps (New App)",
          "Value": "606820009"
        },
        {
          "Label": "Apps: DevOps (GitHub Consumption)",
          "Value": "861980146"
        },
        {
          "Label": "Apps: DevOps (Azure DevOps , GHAS for AzDO)",
          "Value": "861980080"
        },
        {
          "Label": "Apps: End User Compute (Dev Box, LabServices)",
          "Value": "861980160"
        },
        {
          "Label": "Apps: Event Grid",
          "Value": "606820010"
        },
        {
          "Label": "Apps: Functions",
          "Value": "606820011"
        },
        {
          "Label": "Apps: Gaming",
          "Value": "861980042"
        },
        {
          "Label": "Apps: GitHub Copilot",
          "Value": "606820012"
        },
        {
          "Label": "Apps: Logic Apps",
          "Value": "606820013"
        },
        {
          "Label": "Apps: Others (ACS, App Config, SF, SignalR, Web PS, XCR)",
          "Value": "606820014"
        },
        {
          "Label": "Apps: Redis Cache",
          "Value": "606820015"
        },
        {
          "Label": "Apps: Service Bus",
          "Value": "606820016"
        },
        {
          "Label": "Apps: Testing Services (Load testing, Chaos, Playwright)",
          "Value": "606820017"
        },
        {
          "Label": "D365: Activity",
          "Value": "861980159"
        },
        {
          "Label": "D365: Commerce",
          "Value": "861980057"
        },
        {
          "Label": "D365: Customer Insights",
          "Value": "861980023"
        },
        {
          "Label": "D365: Customer Service",
          "Value": "861980018"
        },
        {
          "Label": "D365: Device",
          "Value": "606820002"
        },
        {
          "Label": "D365: Field Service",
          "Value": "861980016"
        },
        {
          "Label": "D365: Finance",
          "Value": "861980047"
        },
        {
          "Label": "D365: Fraud Protection",
          "Value": "861980035"
        },
        {
          "Label": "D365: HR",
          "Value": "861980058"
        },
        {
          "Label": "D365: Marketing",
          "Value": "861980059"
        },
        {
          "Label": "D365: Project Operations",
          "Value": "861980015"
        },
        {
          "Label": "D365: Remote Assist",
          "Value": "861980125"
        },
        {
          "Label": "D365: Sales",
          "Value": "861980048"
        },
        {
          "Label": "D365: Supply Chain Management",
          "Value": "861980041"
        },
        {
          "Label": "D365: Team Member",
          "Value": "861980126"
        },
        {
          "Label": "D365: Viva Sales",
          "Value": "861980163"
        },
        {
          "Label": "Data: Analytics - Azure Databricks",
          "Value": "861980099"
        },
        {
          "Label": "Data: Analytics - DW SQL Server to Synapse Migration",
          "Value": "861980120"
        },
        {
          "Label": "Data: Analytics - Migration and Modernization to MIDP Lakehouse",
          "Value": "861980006"
        },
        {
          "Label": "Data: Analytics - Net New Use Cases to MIDP Lakehouse, Datamesh",
          "Value": "861980143"
        },
        {
          "Label": "Data: Analytics - New Analytics (Synapse Link for Cosmos DB, Dataverse, SQL, or M365)",
          "Value": "861980144"
        },
        {
          "Label": "Data: Analytics - Power BI Premium",
          "Value": "606820004"
        },
        {
          "Label": "Data: Analytics - Real-Time with ADX",
          "Value": "861980118"
        },
        {
          "Label": "Data: Analytics - Unified Data Governance (Purview)",
          "Value": "861980114"
        },
        {
          "Label": "Data: Cosmos DB (Build Intelligent Apps)",
          "Value": "861980070"
        },
        {
          "Label": "Data: Cosmos DB (Migration from Cassandra, Mongo DB, Oracle, PostgreSQL, etc.)",
          "Value": "861980128"
        },
        {
          "Label": "Data: Hybrid - Arc enabled SQL Managed Instance (PaaS)",
          "Value": "861980117"
        },
        {
          "Label": "Data: MIDP ISV Marketplace",
          "Value": "861980017"
        },
        {
          "Label": "Data: MySQL (Migration or New Apps)",
          "Value": "861980154"
        },
        {
          "Label": "Data: Oracle to Azure SQL Migration",
          "Value": "861980029"
        },
        {
          "Label": "Data: Oracle to PostgreSQL Migration",
          "Value": "861980096"
        },
        {
          "Label": "Data: OSS database migration (MySQL)",
          "Value": "861980155"
        },
        {
          "Label": "Data: PostgreSQL (New Apps)",
          "Value": "861980127"
        },
        {
          "Label": "Data: PostgreSQL (On-prem Migration)",
          "Value": "861980097"
        },
        {
          "Label": "Data: SQL Hybrid - Arc-enabled SQL Server",
          "Value": "861980161"
        },
        {
          "Label": "Data: SQL Modernization to Azure SQL DB (PaaS)",
          "Value": "861980129"
        },
        {
          "Label": "Data: SQL Modernization to Managed Instance (PaaS)",
          "Value": "861980008"
        },
        {
          "Label": "Data: SQL VM Migrations (IaaS)",
          "Value": "861980071"
        },
        {
          "Label": "Infra: AVD (Citrix + AVD)",
          "Value": "861980083"
        },
        {
          "Label": "Infra: AVD (Horizon + AVD (VMware))",
          "Value": "861980084"
        },
        {
          "Label": "Infra: AVD (Native AVD)",
          "Value": "861980001"
        },
        {
          "Label": "Infra: Azure BCDR",
          "Value": "861980002"
        },
        {
          "Label": "Infra: Azure for Operators",
          "Value": "606820018"
        },
        {
          "Label": "Infra: Azure Operations and Governance",
          "Value": "606820019"
        },
        {
          "Label": "Infra: Azure Sec - Defender (SQL, Storage, App Services)",
          "Value": "861980110"
        },
        {
          "Label": "Infra: Azure Sec - Defender EASM (Risk IQ)",
          "Value": "861980158"
        },
        {
          "Label": "Infra: Azure VMWare Solution",
          "Value": "861980026"
        },
        {
          "Label": "Infra: HPC",
          "Value": "861980053"
        },
        {
          "Label": "Infra: Hybrid - Azure Arc-Enabled Kubernetes",
          "Value": "861980116"
        },
        {
          "Label": "Infra: Hybrid - Azure Arc-Enabled servers",
          "Value": "861980115"
        },
        {
          "Label": "Infra: Hybrid - Azure Private MEC",
          "Value": "606820001"
        },
        {
          "Label": "Infra: Hybrid - Azure Stack Edge",
          "Value": "606820000"
        },
        {
          "Label": "Infra: Hybrid - Azure Stack HCI",
          "Value": "861980020"
        },
        {
          "Label": "Infra: Hybrid - Stack Hub",
          "Value": "861980133"
        },
        {
          "Label": "Infra: Linux",
          "Value": "861980162"
        },
        {
          "Label": "Infra: Linux (RedHat)",
          "Value": "861980167"
        },
        {
          "Label": "Infra: Mainframe Migration",
          "Value": "861980102"
        },
        {
          "Label": "Infra: Monitoring (Log Analytics, Monitor, Insights and Analytics)",
          "Value": "861980109"
        },
        {
          "Label": "Infra: Networking",
          "Value": "861980005"
        },
        {
          "Label": "Infra: Oracle",
          "Value": "861980030"
        },
        {
          "Label": "Infra: SAP on Azure",
          "Value": "861980051"
        },
        {
          "Label": "Infra: SAP on Azure (PCE/RISE/HEC)",
          "Value": "861980134"
        },
        {
          "Label": "Infra: SAP on Azure (S/4 HANA)",
          "Value": "861980082"
        },
        {
          "Label": "Infra: Storage - ANF",
          "Value": "861980140"
        },
        {
          "Label": "Infra: Storage and File Systems (Files, Blob, ADLS, Disk, SAN)",
          "Value": "861980021"
        },
        {
          "Label": "Infra: Windows",
          "Value": "861980000"
        },
        {
          "Label": "IoT: Azure Maps",
          "Value": "861980131"
        },
        {
          "Label": "IoT: Azure Sphere Devices",
          "Value": "861980132"
        },
        {
          "Label": "IoT: IoT Services",
          "Value": "861980147"
        },
        {
          "Label": "MR: Commercial Devices",
          "Value": "861980153"
        },
        {
          "Label": "MW: 2P (LOB)",
          "Value": "861980061"
        },
        {
          "Label": "MW: 3P (ISV)",
          "Value": "861980056"
        },
        {
          "Label": "MW: Core Workloads",
          "Value": "861980033"
        },
        {
          "Label": "MW: EXO",
          "Value": "606820020"
        },
        {
          "Label": "MW: FLW (EXO)",
          "Value": "606820021"
        },
        {
          "Label": "MW: FLW (Intune)",
          "Value": "606820022"
        },
        {
          "Label": "MW: FLW (Teams)",
          "Value": "606820027"
        },
        {
          "Label": "MW: FLW (Viva)",
          "Value": "606820045"
        },
        {
          "Label": "MW: Intune",
          "Value": "861980045"
        },
        {
          "Label": "MW: M365 Apps (ProPlus)",
          "Value": "606820023"
        },
        {
          "Label": "MW: M365 Copilot",
          "Value": "606820024"
        },
        {
          "Label": "MW: Meetings",
          "Value": "861980050"
        },
        {
          "Label": "MW: Other E3 Workloads",
          "Value": "606820025"
        },
        {
          "Label": "MW: Platform - MS Apps",
          "Value": "861980135"
        },
        {
          "Label": "MW: Power BI Pro",
          "Value": "861980085"
        },
        {
          "Label": "MW: SPO/OD",
          "Value": "606820026"
        },
        {
          "Label": "MW: Surface MAD",
          "Value": "861980067"
        },
        {
          "Label": "MW: Teams",
          "Value": "861980062"
        },
        {
          "Label": "MW: Teams Events",
          "Value": "606820028"
        },
        {
          "Label": "MW: Teams Phone",
          "Value": "861980136"
        },
        {
          "Label": "MW: Teams Premium",
          "Value": "606820029"
        },
        {
          "Label": "MW: Teams Rooms",
          "Value": "861980137"
        },
        {
          "Label": "MW: Viva Amplify",
          "Value": "606820030"
        },
        {
          "Label": "MW: Viva Connections",
          "Value": "861980079"
        },
        {
          "Label": "MW: Viva Engage",
          "Value": "861980148"
        },
        {
          "Label": "MW: Viva Glint",
          "Value": "606820031"
        },
        {
          "Label": "MW: Viva Goals",
          "Value": "861980157"
        },
        {
          "Label": "MW: Viva Insights (Teams)",
          "Value": "861980004"
        },
        {
          "Label": "MW: Viva Insights Workbench",
          "Value": "861980149"
        },
        {
          "Label": "MW: Viva Learning",
          "Value": "861980014"
        },
        {
          "Label": "MW: Viva Pulse",
          "Value": "606820032"
        },
        {
          "Label": "MW: Viva Topics",
          "Value": "861980009"
        },
        {
          "Label": "MW: W365",
          "Value": "606820033"
        },
        {
          "Label": "MW: Win 11",
          "Value": "606820034"
        },
        {
          "Label": "Power Platform: Power Apps",
          "Value": "861980086"
        },
        {
          "Label": "Power Platform: Power Apps (PayGo)",
          "Value": "861980142"
        },
        {
          "Label": "Power Platform: Power Automate (PayGo)",
          "Value": "606820003"
        },
        {
          "Label": "Power Platform: Power Automate (Per Flow)",
          "Value": "861980031"
        },
        {
          "Label": "Power Platform: Power Automate (Per User)",
          "Value": "861980010"
        },
        {
          "Label": "Power Platform: Power Pages",
          "Value": "861980164"
        },
        {
          "Label": "Power Platform: Power Virtual Agent",
          "Value": "861980022"
        },
        {
          "Label": "Sec: AAD (Conditional Access)",
          "Value": "861980105"
        },
        {
          "Label": "Sec: AAD (SSO)",
          "Value": "861980106"
        },
        {
          "Label": "Sec: AAD ADFS Migration",
          "Value": "861980150"
        },
        {
          "Label": "Sec: AAD Identity & Access Mgt (General)",
          "Value": "861980027"
        },
        {
          "Label": "Sec: Azure Sec - Azure AD B2C",
          "Value": "861980112"
        },
        {
          "Label": "Sec: Azure Sec - Defender for Data (SQL, CosmosDB)",
          "Value": "606820041"
        },
        {
          "Label": "Sec: Azure Sec - Defender for Emerging (IOT, EASM-RiskIQ)",
          "Value": "861980111"
        },
        {
          "Label": "Sec: Azure Sec - Defender for Servers and Storage",
          "Value": "861980156"
        },
        {
          "Label": "Sec: Azure Sec - Network Security (Firewall, DDOS, WAF, Front Door, Bastion)",
          "Value": "861980107"
        },
        {
          "Label": "Sec: Azure Sec - Sentinel",
          "Value": "861980108"
        },
        {
          "Label": "Sec: Azure Sec - Defender for Apps (Container, App Services, DevOps)",
          "Value": "606820042"
        },
        {
          "Label": "Sec: Azure Sec - Defender for CSPM",
          "Value": "606820043"
        },
        {
          "Label": "Sec: Defender Experts for Hunting",
          "Value": "606820040"
        },
        {
          "Label": "Sec: Defender for Threat Intelligence",
          "Value": "606820044"
        },
        {
          "Label": "Sec: Entra - Identity Apps Migration (ADFS, Ping, Okta, Etc)",
          "Value": "606820036"
        },
        {
          "Label": "Sec: Entra - Identity Conditional Access, SSO, MFA (P1)",
          "Value": "606820035"
        },
        {
          "Label": "Sec: Entra - Identity Governance",
          "Value": "606820039"
        },
        {
          "Label": "Sec: Entra - Identity Threat Protection (P2)",
          "Value": "861980151"
        },
        {
          "Label": "Sec: M365 Defender for Cloud Apps",
          "Value": "861980089"
        },
        {
          "Label": "Sec: M365 Defender for Endpoints",
          "Value": "861980066"
        },
        {
          "Label": "Sec: M365 Defender for Identity",
          "Value": "861980090"
        },
        {
          "Label": "Sec: M365 Defender for O365",
          "Value": "861980104"
        },
        {
          "Label": "Sec: Privacy Mangement (Priva Standalone)",
          "Value": "861980152"
        },
        {
          "Label": "Sec: Purview - Data Governance (DLM, eDiscovery, RM, Compliance Mgmt)",
          "Value": "606820038"
        },
        {
          "Label": "Sec: Purview - Data Security (MIP, DLP, IRM)",
          "Value": "606820037"
        },
        {
          "Label": "Sec: Purview Compliance Management",
          "Value": "861980138"
        },
        {
          "Label": "Sec: Purview Discover and Respond",
          "Value": "861980139"
        },
        {
          "Label": "Sec: Purview Info Protection & Lifecycle Mgt",
          "Value": "861980028"
        },
        {
          "Label": "Sec: Purview Insider Risk",
          "Value": "861980076"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "msp_workloadlkid",
      "Type": "lookup",
      "DisplayName": "Workloads",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "overriddencreatedon",
      "Type": "datetime",
      "DisplayName": "Record Created On",
      "Format": "date",
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "ownerid",
      "Type": "owner",
      "DisplayName": "Owner",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningbusinessunit",
      "Type": "lookup",
      "DisplayName": "Owning Business Unit",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owningteam",
      "Type": "lookup",
      "DisplayName": "Owning Team",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "owninguser",
      "Type": "lookup",
      "DisplayName": "Owning User",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statecode",
      "Type": "state",
      "DisplayName": "Status",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Active",
          "Value": "0"
        },
        {
          "Label": "Inactive",
          "Value": "1"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "statuscode",
      "Type": "status",
      "DisplayName": "Status Reason",
      "Format": null,
      "OptionSet": [
        {
          "Label": "Active",
          "Value": "1"
        },
        {
          "Label": "Inactive",
          "Value": "2"
        }
      ]
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "timezoneruleversionnumber",
      "Type": "integer",
      "DisplayName": "Time Zone Rule Version Number",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "transactioncurrencyid",
      "Type": "lookup",
      "DisplayName": "Currency",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "utcconversiontimezonecode",
      "Type": "integer",
      "DisplayName": "UTC Conversion Time Zone Code",
      "Format": null,
      "OptionSet": null
    }
  },
  {
    "attributeDescriptor": {
      "LogicalName": "versionnumber",
      "Type": "bigint",
      "DisplayName": null,
      "Format": null,
      "OptionSet": null
    }
  }
]