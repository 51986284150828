import { IsEmpty } from '../Utils/utils'

export default function commonFormatter(cell: any, isReadOnly: boolean) {
  const div = document.createElement('div')
  div.className = 'grid-common-formatter'
  let divText = '---'
  if (!IsEmpty(cell.getValue())) {
    divText = cell.getValue()
  }
  //cell?.getElement().setAttribute("title",divText);
  if (isReadOnly) div.innerHTML = divText
  else {
    //div.innerHTML = divText + GetEditIconHTML();
    div.className = 'grid-common-formatter-edit-new'
    div.innerHTML = divText
  }
  return div
}
